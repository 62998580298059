import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import ShipmentModel from "src/app/_models/self-ship/shipping/shipment.model";
import { environment } from "src/environments/environment";
import {OrdersFetchFilters} from './self-ship-orders.service';

const SHIPMENTS_URL = `${environment.baseUrl}/shipments`;

@Injectable()
export class ShipmentsService {

    constructor(
        private http: HttpClient
    ) {}

    public fetch(filters?: OrdersFetchFilters): Observable<ShipmentModel[]> {
        return this.http.get<ShipmentModel[]>(SHIPMENTS_URL, {params: filters});
    }

    public fetchByOrderId(orderId: number): Observable<ShipmentModel[]> {
        return this.http.get<ShipmentModel[]>(`${SHIPMENTS_URL}/ShipmentsByOrderId/${orderId}`);
    }
}
