// USA
export const locale = {
  lang: 'en',
  data: {
    TRANSLATOR: {
      SELECT: 'Select your language'
    },
    MENU: {
      NEW: 'new',
      ACTIONS: 'Actions',
      CREATE_POST: 'Create New Post',
      PAGES: 'Pages',
      FEATURES: 'Features',
      APPS: 'Apps',
      DASHBOARD: 'Dashboard'
    },
    ACCOUNTSETUP: {
      CONNECT_STORES: 'Connect your stores',
      CONNECT_STORES_EXPLANATION: 'Welcome to your account! Start by connecting your stores to import all your applicable data to sync with inventory ahead. Please note it may take up to 24 hours for your information to be imported.',

      SETUP_WAREHOUSE: 'Set up your warehouses',
      SETUP_WAREHOUSE_LOCATION: ' When you sign up with Inventory Ahead, the system automatically creates a Warehouse in your account with the address information that was entered during the signup process. We recommend you review your warehouse details & settings to assure accuracy.',

      DEFAULT_STOCK_LOCATION: 'Select your default stock location',
      DEFAULT_STOCK_LOCATION_EXPLANATION: 'With this setting, you can assign a default warehouse from where all your Merchant fulfilled orders and FBA shipments should be deducted automatically, and even if you don\'t fulfill the order or create the shipment directly on Inventory Ahead, the inventory will still be deducted from the selected warehouse. ',

      MAPPING_MAINS: 'Map Mains with Subs and create Kits',
      MAPPING_MAINS_EXPLANATION: 'This is a very important step in order to manage your Inventory and Profits at a glance! Please click here for an article to learn the concept.',

      UPLOAD_INVENTORY: 'Upload Inventory',
      UPLOAD_INVENTORY_EXPLANATION: 'Inventory from your omnichannel platforms have been synced, now upload your most current warehouse stock list so your data is accurate and you can see your reorder and replenish forecasting correctly. There are two options on how to accomplish that;',

      ADD_COST: ' Add your Cost',
      ADD_COST_EXPLANATION: 'In order for you to start keeping track of your profits, we recommend adding the cost to each item. ',

      REORDER_REPLENISH: 'Setup reorder and replenish rules to the desired settings',

      UPDATE_AUTO_ADJUSTMENT: 'Update Auto Adjustment',
      UPDATE_AUTO_ADJUSTMENT_EXPLANATION: 'We strongly suggest you turn off the "allow auto adjustment" button prior to uploading inventory to avoid unnecessary confusion while it is processing.',

      AUTO_ADJUSTMENT_EXPLANATION: 'As a new customer we strongly suggest you turn off the "allow auto adjustment" button prior to uploading inventory to avoid unnecessary confusion while it is processing.',
      UPDATE_COST: 'Update Cost Price',
      PURCHASE_ORDER_EXPLANATION: 'Now that you have completed all major steps you should start with creating your purchase orders  and reorder your products',
      UPDATE_COST_EXPLANATION: 'To have a better grip on reorder, profits and overall picture we highly suggest you to update your cost price accordingly.',


    },

    COMMONSETTINGS: {
      INVENTORY_RULES: 'Inventory rules',
      INVENTORY_RULES_EXPLANATION: 'Inventory rules are the place where you can set your preference on how Inventory Ahead should feed live inventory updates to your Marketplaces based on the stock that you have in your Warehouse. This is an important setting for companies that are dealing with Merchant fulfilled orders and companies that utilize the Amazon dropship feature.',

      SETTINGS_PRICE_RULES: 'Setup price rules',
      SETTINGS_PRICE_RULES_EXPLANATION: 'This feature is ideal for sellers who sell on national brand listings (non-private label) and use repricing software to manage their selling prices. The price rules will give you the ability to set up a minimum price which should be calculated based on your cost, shipping, fees, and advertising. You are also able to set a maximum price.',

      INVITE: 'Invite new users',
      INVITE_EXPLANATION: 'you can invite multiple users to your account and set user permissions to predict which data should be available to each user. ',

      TAGS: 'Tags',
      TAGS_EXPLANATION: 'This feature would be a good idea for companies that deal with multiple types of item categories, like clothing and food. You can set up a tag for each category and then assign the applicable tag to each item. You will be able to utilize this feature by setting up different dashboards for each tag and also searching by tag within a dashboard. '
    },

    OVERVIEW: {
      PURCHASE_ORDER: 'How to do a Purchase order',
      FBA_SHIPMENT: 'How to create an FBA shipment',
      SELF_SHIP: 'How to use the self-ship feature',
      REPORT_PAGE: 'How to use the reports page'
    },
    GENERAL: {
      BUTTONS: {
        CREATE: 'Create',
        ADD: 'Add',
        ADD_NEW: 'Add New',
        EDIT: 'Edit',
        UPDATE: 'Update',
        DELETE: 'Delete',
        DELETE_ALL: 'Delete All',
        SAVE: 'Save',
        CANCEL: 'Cancel',
        BACK: 'Back',
        CLOSE: 'Close',
        SUBMIT: 'Submit',
        CONNECT: 'Connect',
        REAUTORIZE: 'Re-Authorize Store',
        CREATE_NEW: 'Create New',
        Send_To_supplier: 'Send To supplier',
        DONE: 'Done',
        DISCARD: 'Discard',
        DISCARD_CHANGES: 'Discard Changes',
        CONFIRM: 'Confirm',
        NEXT: 'Next',
        APPLY: 'Apply',
        PRINT: 'Print',
        EXPORT: 'Export',
      },
      ACTION: {
        VIEW: 'View'
      },
      VALIDATION: {
        INVALID: '{{name}} is not valid',
        REQUIRED: '{{name}} is required',
        MIN_LENGTH: '{{name}} minimum length is {{min}}',
        NOT_FOUND: 'The requested {{name}} is not found',
        REQUIRED_FIELD: 'Required field',
        MIN_LENGTH_FIELD: 'Minimum field length:',
        MAX_LENGTH_FIELD: 'Maximum field length:',
        INVALID_FIELD: 'Field is not valid',
        CREATE_FAILD: 'Cresate new {{name}} is failed'
      },
      TITLE: {
        COLUMNS_CHOOSER: 'Columns Chooser'
      },
      MESSAGES: {
        ADDED: '{{name}} has been added',
        UPDATED: '{{name}} has been updated',
        DELETED: '{{name}} has been deleted',
        ERROR: 'Something went wrong',
        VISIBLE_COLUMNS: 'Visible Columns',
        HIDDEN_COLUMNS: 'Hidden Columns'
      }
    },
    AUTH: {
      GENERAL: {
        NO_ACCOUNT: 'Don\'t have an account?',
        SIGNUP_BUTTON: 'Sign Up',
        FORGOT_PASSWORD: 'Forgot your password?',
        REMEMBER_ME: 'Remember me?',
        BACK_BUTTON: 'Back',
        PRIVACY: 'Privacy',
        LEGAL: 'Legal',
        CONTACT: 'Contact'
      },
      LOGIN: {
        TITLE: 'Sign in to your account',
        BUTTON: 'Sign In'
      },
      FORGOT: {
        TITLE: 'Forgotten Password?',
        DESC: 'Enter your email to reset your password',
        SUCCESS: 'Your reset passwrod link has been sent to your email.',
        BUTTON: 'Reset Password'
      },
      RESET_PASSWORD: {
        TITLE: 'Reset Password',
        DESC: 'Enter your your new password',
        SUCCESS: 'Your password has been successfully reset.',
        BUTTON: 'Set New Password'
      },
      REGISTER: {
        TITLE: 'Sign Up',
        DESC: 'Enter your details to create your account',
        SUCCESS: 'Your account has been successfuly registered.'
      },
      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'Fullname',
        PASSWORD: 'Password',
        CONFIRM_PASSWORD: 'Confirm Password',
        USERNAME: 'Username'
      },
      VALIDATION: {
        AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
        INVALID_LOGIN: 'The login detail is incorrect',
        MIN_LENGTH_FIELD: 'Length must be at least',
        MAX_LENGTH_FIELD: 'Length must not be more than',
        REQUIRED_FIELD: 'The field is required',
        INVALID_FIELD: 'The field is invalid'
      },
      MESSAGES: {
        ERRORS: {
          EMAIL_EXISTS: 'This email address is already registered',
          EMAIL_NOT_EXISTS: 'This email address is not registered',
          INVALID: 'The registration data is invalid',
          INVALID_TOKEN: 'Your reset link was expired'
        }
      }
    },
    FILTERS: {
      FILTER: 'Filter',
      TITLE: {
        SAVE_FILTER: 'Save new filter set'
      },
      FIELDS: {
        NAME: 'Filter Name'
      },
      BUTTONS: {
        ADD_TO_FILTERS: 'Add to filters',
      }
    },
    INVENTORYDISCREPANCY: {
      INVENTORYDISCREPANCY: 'Inventory Discrepancy',
      FIELDS: {
        FIXSELECTEDDISCREPANCY: 'Fix Selected Discrepancy',
        QUANTITY: 'Quantity',
        LOCATION: 'Location',
        SKU: 'Sku',
        DATE: 'Date'
      }
    },
    DEFAULTLOCATIONS: {
      DEFAULTLOCATION: 'Default Location',
      DEFAULTLOCATIONS: 'Default Locations',
      TITLE: {
        EDIT: 'Edit Default Location'
      },
      FIELDS: {
        NAME: 'Store',
        DEFAULTLOCATION: 'Default Location'
      }
    },
    ADVERTISING: {
      ADVERTISING: 'Advertising',
      CAMPAIGNS: 'Campaigns',
      FIELDS: {
        KEYWORDTYPE: 'Keyword Type',
        CTR: 'Ctr',
        CPC: 'Cpc',
        CLICKS: 'Clicks',
        IMPRESSIONS: 'Impressions',
        BID: 'Bid',
        MATCHTYPE: 'Match type',
        KEYWORDTEXT: 'Keyword text',
        ADVERTISINGRULE: 'Advertising Rule',
        ACOS: 'ACOS',
        SALES: 'Sales',
        ORDERS: 'Orders',
        SPEND: 'Spend',
        BUDGET: 'Budget',
        TARGETING: 'Targeting',
        TYPE: 'Type',
        STATUS: 'Status',
        CAMPAIGNS: 'Campaigns',
        STORE: 'Store',
        CAMPAIGNDATERANGE: 'Campaign Date Range:'
      }
    },
    ADVERTISINGRULE: {
      ADVERTISINGRULE: 'Advertising Rule',
      ADVERTISINGRULES: 'Advertising Rules',
      TITLE: {
        ADVERTISING: 'Advertising',
        RULES: 'Rules',
        ADD: 'Add'
      },
      FIELDS: {
        SECOND: 'Second',
        COMPARETYPE: 'Compare Type',
        FIRST: 'First',
        INCREASEAMOUNT: 'Increase Amount',
        TYPE: 'Type',
        NAME: 'Name'
      }
    },
    ADVERTISINGSKU: {
      ADVERTISINGSKU: 'SKU',
      FIELDS: {
        CAMPAIGNDATERANGE: 'Campaign Date Range:',
        ACOS: 'ACOS',
        SALES: 'Sales',
        ORDERS: 'Orders',
        CPC: 'Cpc',
        SPEND: 'Spend',
        KEYWORDTYPE: 'Keyword Type',
        CTR: 'Ctr',
        CLICKS: 'Clicks',
        IMPRESSIONS: 'Impressions',
        BID: 'Bid',
        MATCHTYPE: 'Match type',
        KEYWORDTEXT: 'Keyword text',
        IMAGE: 'Image',
        SKU: 'SKU',
        STORE: 'Store'
      }
    },
    EXPENSES: {
      EXPENSE: ' Expense',
      EXPENSES: ' Expenses',
      TITLE: {
        ADD: 'Add',
        EDIT: 'Edit Expense',
        DELETE: 'Delete Expense?'
      },
      MESSAGES: {
        DELETE: 'Do you really want to delete the expense?',
        DELETED: '{{name}} has been removed.'
      },
      FIELDS: {
        REPEATE: 'Repeate',
        TYPE: 'Type',
        AMOUNT: 'Amount',
        ACCOUNT: 'Account',
        NAME: 'Name',
        DATE: 'Date'
      }
    },
    INVENTORYADJUSTMENTS: {
      INVENTORYADJUSTMENT: 'Inventory Adjustment',
      INVENTORYADJUSTMENTS: 'Inventory Adjustments',
      FIELDS: {
        ADJUSTMENTDATE: 'Adjustment Date',
        STATUS: 'Status',
        AVAILABLEQUANTITY: 'Available Quantity',
        QUANTITY: 'Quantity',
        PURCHASEORDER: 'Purchase Order',
        EXPIRATIONDATE: 'Expiration Date',
        PRODUCT: 'Product',
        LOCATION: 'Location',
        DATE: 'Date',
        STORE: 'Store',
        SKU: 'Sku',
        TITLE: 'Title',
        ORDERNUMBER: 'Order Number',
        SALEPRICE: 'Sale Price',
        SHIPPING: 'Shipping',
        PROMOTIONREBATES: 'Promotion Rebates',
        FEES: 'Fees',
        COSTPRICE: 'Cost Price',
        TAX: 'Tax',
        STORAGEFEES: 'Storage Fees',
        TYPE: 'Type',
        INVENTORY: 'Inventory',
        ROI: 'ROI',
        YOUREXPENSES: 'Your Expenses'
      }
    },
    FBACONFIRMATIONS: {
      FBACONFIRMATION: 'FBA Confirmation',
      FBACONFIRMATIONS: 'FBA Confirmations',
      FIELDS: {
        DATE: 'Date',
        STORE: 'Store',
        SHIPMENTID: 'Shipment ID',
        SHIPMENTDATE: 'Shipment Date',
        SKU: 'Sku',
        TITLE: 'Title',
        STATUS: 'Status',
        SHIPPED: 'Shipped',
        RECIEVED: 'Recieved',
        DIFFERENCE: 'Difference',
        CONFIRM: 'Confirm',
        CREATECASE: 'Create Case',
        CASEOPENED: 'Case Opened'
      }
    },
    PRODUCTS: {
      PRODUCT: 'Product',
      PRODUCTS: 'Products',
      LISTINGS: 'Listings',
      KITS: 'Kits',
      TITLE: {
        ADD: 'Add Product',
        EDIT: 'Edit Product',
        DELETE: 'Delete Product?',
        CONVERT_TO_SUB:
          'Select main product, which will be associated with converted sub using provided fraction.',
        DETAILS: '{{sku}}\'s Details',
        SUBS: '{{sku}}\'s Subs',
        PROFIT: '{{sku}}\'s Profits',
        ORDERS: '{{sku}}\'s Orders',
        LISTING: '{{sku}}\'s Listing',
        AMAZON_INVENTORY: '{{sku}}\'s Amazon Inventory',
        IMPORT: 'Import Products',
        IMPORT_INVENTORY: 'Import Inventory',
        REORDER_QUEUE: 'Reorder queue',
        SUPPLIERS: 'SUPPLIERS',
        SUPPLIER: 'Supplier',
        ITEMS: 'Items',
        STORES: 'STORES',
        STORE: 'Store',
        PREP_REQUIREMENTS: 'Prep requirements',
        STOCK: 'Stock',
        FBA: 'FBA',
        WFS: 'WFS',
        BOX: 'Box (Units per box)',
        QUANTITY_SEND: 'Quantity to send',
        OPEN_SHIPMENTS: 'OPEN FBA SHIPMENTS',
        SELECT_FBA_SHIPMENT_TO_ADD: 'Select a open FBA Shipment you would like to add to',
        CREATE_SHIPMENT_EXIT: 'Create shipment and exit',
        CREATE_SHIPMENT_CONTINUE: 'Create shipment and continue',
        ADD_TO_SHIPMENT_EXIT: 'Add to shipment and exit',
        ADD_TO_SHIPMENT_CONTINUE: 'Add to shipment and continue',
        CLEAR_QUEUE: 'Clear queue',
        PRINT_UPC: 'Print UPC',
        PRINT_LISTING_ID: 'Print ASIN / Listing ID',
        PRINT_FNSKU: 'Print FNSKU'
      },
      MESSAGES: {
        DELETE: 'Do you really want to delete the products {{skus}}?',
        DELETED: '{{sku}} and all associated subs have been removed.',
        DELETED_WITHOUT_SUBS:
          '{{sku}} has been removed. Associated subs have been converted to mains.',
        CONVERT_TO_MAIN:
          'Do you really want to convert the product {{sku}} to main?',
        COMPLETE_CONVERSION:
          'To complete the conversion from main to kit, add at least one product to the kit.',
        CREATE_KIT_SUCCESS: '{{sku}} has been converted to kit.',
        CONVERT_TO_SUB_SUCCESS: '{{sku}} has been converted to sub of {{newSku}}.',
        ERRORS: {
          INCLUDED_IN_KITS:
            '{{sku}} couldn\'t be removed, because it\'s included in {{kits}}: {{skus}}.',
          HAS_ORDERS: '{{sku}} couldn\'t be removed, because it has orders.',
          IMPORT_FILE_TYPE: 'The imported file is not valid excel file.',
          IMPORT_FILE_SIZE: 'The imported file size cannot be more than 30 MB.',
          INVALID_MAIN: '{{sku}} can\'t be main and sub at the same time',
          HAS_PURCHASE_ITEMS: '{{sku}} can\'t be converted becasue it has purchases',
          DUPLICATE_SKU: '{{sku}} already exists',
          NOT_MAIN: '{{sku}} is not a main SKU'
        },
        IMPORT1: 'Inventory Ahead supports import of product information via Excel file.',
        IMPORT2: ', which contains detailed instructions on how to prepare your data.',
        PRODUCTS_IMPORTED: 'The products listed in the uploaded file are imported successfully',
        INVENTORY_IMPORTED: 'The inventory for the products listed in the uploaded file are imported successfully',
        ACTIVITY: {
          FBASHIPMENT: 'FBA shipment ID',
          WFSSHIPMENT: 'WFS shipment ID',
          ORDERS: '{{count}} Units sold',
          DROPSHIP: '{{count}} Drop ship orders',
          RETURNS: '{{count}} Returns',
          DISPOSED: 'Disposed',
          AMAZONREMOVALS: 'Amazon removal',
          FOUND: 'Found',
          LOSTORDAMAGED: 'Lost or damaged',
          PURCHASEORDER: 'Received PO Number',
          SUPPLIERRETURNS: 'Returned to the supplier',
          TRANSFERRED: 'Warehouse transfer',
          TRANSFERRECEIVED: 'Received transfer'
        }
      },
      BUTTONS: {
        CONVERT_SUBS: 'Convert subs to mains',
        ADD_NEW: 'Add New Product',
        ADD: 'Add',
        EDIT_KITS: 'Edit Kits',
        CREATE_KIT: 'Create Kit',
        UPDATE_KIT: 'Update Kit',
        CONVERT_TO_SUB: 'Convert to Sub',
        CONVERT_TO_MAIN: 'Convert to Main',
        CONVERT_TO_KIT: 'Convert to Kit',
        IMPORT: 'Import',
        IMPORT_TEMPLATE: 'Download import template',
        IMPORT_PRODUCTS: 'Products',
        IMPORT_INVENTORY: 'Inventory',
        SELECT_FILE: 'Select File',
        SHORT_VERSION: 'Select Short Version',
        SELECT_STORES: 'Select Stores',
        SELECT_LOCATIONS: 'Select Locations',
        SELECT_TAGS: 'Select Tags',
      },
      FIELDS: {
        SKU: 'SKU',
        SUPPLIER_SKU: 'Vendor SKU',
        SHORT_DESCRIPTION: 'Short Description',
        DESCRIPTION: 'Title',
        TITLE: 'Title',
        PRICE: 'Cost Price',
        PRICE_PLUS: 'Price + Shipping',
        SHIPPING_PRICE: 'Shipping Price',
        CASE_PRICE: 'Case Price',
        QUANTITY_IN_CASE: 'Quantity In Case',
        MAP: 'MAP',
        DATEADDED: 'Date Added',
        PRODUCT: 'Product *',
        MAIN_PIECES: 'Main Pieces *',
        KIT_PIECES: 'Kit Pieces *',
        MAIN_SUB_PRODUCT: 'Main',
        MAIN_SUB_PIECES: 'Number of Pieces on Main',
        SUB_PIECES: 'Number of Pieces on Sub',
        FRACTION: 'Fraction',
        ORDERS: 'Orders',
        PROFIT: 'Profit',
        INVENTORY: 'Inventory',
        AMAZON_INBOUND: 'Amazon Inbound',
        TYPE: 'Type',
        IMAGE: 'Image',
        BESTSELLERS: 'Best Sellers',
        LOCATION: 'Location',
        ASIN: 'ASIN',
        PRICING: 'Pricing',
        ITEM: 'Item',
        SUPPLIER: 'Supplier',
        UNITS_SOLD_ORDERS: 'Units sold / Orders',
        UNITS_SOLD: 'Units sold',
        LAST_30: 'Last 30 days',
        LAST_14: 'Last 14 days',
        LAST_7: 'Last 7 days',
        PACKS: 'Packs',
        RETURNS: 'Returns',
        SALES: 'Sales',
        SALES_SHIPPED: 'Sales shipped',
        ORGANIC_SALES: 'Organic Sales',
        COST_SHIPPING: 'Cost',
        COST_SHIPPED: 'Cost shipped',
        AVG_COST_PER_UNIT: 'Avg. cost per unit',
        FEES: 'Fees',
        ESTIMATED_FEES: 'Estimated Fees',
        EST_FEE: 'Estimated fees',
        AVG_FEE_PER_UNIT: 'Avg. fee per unit',
        EST_FEE_PER_UNIT: 'Est. fee per unit',
        AD_SPEND: 'Ad Spend',
        ACOS: 'ACOS',
        AD_SALES: 'Ad sales',
        AVG_ADD_SPEND: 'Avg ad spend',
        ESTIMATED_PROFIT: 'Estimated profit',
        AVG_PROFIT: 'Avg Profit',
        ESTIMATED_PROFIT_PER_UNIT: 'Est. profit per unit',
        OPEN_POS: 'Open PO\'s',
        IN_TRANSIT: 'In Transit',
        AGE: 'Age',
        FBA_INVENTORY: 'FBA/WFS Inventory',
        MARKETPLACE_INVENTORY: 'Marketplace Inventory',
        INBOUND: 'Inbound',
        WORKING: 'Working',
        TO_REOREDR: 'To reorder',
        DAYS_OF_SUPPLY: 'Days of Supply',
        ALLOWED_AMOUNT: 'Allowed Amount',
        SELLING_AT: 'Selling at',
        REORDER: 'Reorder',
        EXIT_REORDER: 'Exit Reorder',
        ITEM_SELECTED: 'items Selected',
        LISTING_ID: 'Listing ID',
        RANKING: 'Ranking',
        YOUR_PRICE: 'Your Price',
        BUY_BOX: 'Buy Box Price',
        PRICING_RULE: 'Pricing rule',
        MIN_MAX: 'Min / Max',
        REPLENISH: 'Replenish',
        PACKS_ORDERS: 'Packs/Orders',
        UNITS_SOLID: 'Units Sold',
        SOURCE: 'Source',
        QUANTITY: 'Quantity',
        ADD_PRODUCT: 'Add Product',
        UPC: 'UPC',
        STORE: 'Store',
        SALES_RANK: 'Sales Rank',
        SHIPPING_PER_UNIT: 'Shipping per unit',
        ROI: 'ROI',
        AVG_AD_PER_UNIT: 'Avg. ad per unit',
        UNITS_PER_CASE: 'Units per Case',
        FNSKU: 'FNSKU',
        ADDITIONAL_FEES: 'Additional Fees',
        VENDOR_SKU: 'Vendor SKU'
      },
      ACTIVITY: {
        ALL: 'All',
        FBASHIPMENT: 'FBA shipment',
        WFSSHIPMENT: 'WFS shipment',
        ORDERS: 'Orders',
        DROPSHIP: 'Drop ship orders',
        RETURNS: 'Returns',
        DISPOSED: 'Disposed',
        AMAZONREMOVALS: 'Amazon removal',
        FOUND: 'Found',
        LOSTORDAMAGED: 'Lost or damaged',
        PURCHASEORDER: 'Purchase orders',
        SUPPLIERRETURNS: 'Supplier returns',
      }
    },
    KITS: {
      KIT: 'Kit',
      KITS: 'Kits',
      TITLE: {
        ADD: 'Add Kit',
        EDIT: 'Edit Kit',
        DELETE: 'Delete Kit?'
      },
      MESSAGES: {
        DELETE: 'Do you really want to delete the kit?',
        DELETED: '{{sku}} and all associated subs have been removed.',
        DELETED_WITHOUT_SUBS:
          '{{sku}} has been removed. Associated subs have been converted to mains.',
        ERRORS: {
          INCLUDED_IN_KITS:
            '{{sku}} couldn\'t be removed, because it\'s included in {{kits}}: {{skus}}',
          HAS_ORDERS: '{{sku}} couldn\'t be removed, because it has orders',
          HAS_PURCHASE_ITEMS: '{{sku}} can\'t be converted because it has purchases',
        }
      },
      BUTTONS: {
        CONVERT_SUBS: 'Convert subs to mains',
        ADD_NEW: 'Add New Kit'
      },
      FIELDS: {
        SKU: 'SKU',
        SUPPLIER_SKU: 'Vendor SKU',
        DESCRIPTION: 'Title',
        PRICE: 'Cost Price',
        PRICE_PLUS: 'Price + Shipping',
        SHIPPING_PRICE: 'Shipping Price',
        CASE_PRICE: 'Case Price',
        QUANTITY_IN_CASE: 'Quantity In Case',
        MAP: 'MAP',
        NUMBER_OF_PRODUCTS: 'Number of Products',
        SUPPLIERS: 'Suppliers',
        FRACTION: 'Fraction',
        SUPPLIER: 'Supplier',
        COST_PRICE: 'Cost Price',
        DATE_ADDED: 'Date Added',
        PACKAGE_WEIGHT: 'Package Weight',
        PACKAGE_DIMENSIONS: 'Package Dimensions',
        SEASON: 'Season',
        SOLD_AT: 'Sold At'
      }
    },
    SUPPLIERS: {
      SUPPLIER: 'Supplier',
      SUPPLIERS: 'Suppliers',
      TITLE: {
        ADD: 'Add Supplier',
        EDIT: 'Edit Supplier',
        DELETE: 'Delete Supplier?'
      },
      MESSAGES: {
        DELETE: 'Do you really want to delete the supplier?',
        DELETED: '{{sku}} and all associated subs have been removed.',
        DELETED_WITHOUT_SUBS:
          '{{sku}} has been removed. Associated subs have been converted to mains.',
        ERRORS: {
          HAS_SKUS: '{{supplier}} couldn\'t be removed, because it\'s included in {{skus}}',
          HAS_ORDERS: '{{supplier}} couldn\'t be removed, because it has purchase orders',
          DUPLICATE_NAME: 'There is another supplier has the name "{{supplier}}"'
        }
      },
      BUTTONS: {
        CONVERT_SUBS: 'Convert subs to mains',
        ADD_NEW: 'Add New Supplier'
      },
      FIELDS: {
        NAME: 'Name',
        EMAIL: 'Email',
        NOTES: 'Notes',
        ADDRESSLINE1: 'Address Line 1',
        ADDRESSLINE2: 'Address Line 2',
        CITY: 'City',
        STATECODE: 'State Code',
        POSTALCODE: 'Postal Code',
        COUNTRYCODE: 'Country Code',
        DAYSTOARRIVE: 'Days To Arrive'
      }
    },
    LINKRULES: {
      LINKRULE: 'Link Rule',
      LINKRULES: 'Link Rules',
      TITLE: {
        ADD: 'Add Link Rule',
        EDIT: 'Edit Link Rule',
        DELETE: 'Delete Link Rule?',
        ASSIGNLINKRULE: 'Assign Link Rule to Store Products'
      },
      MESSAGES: {
        DELETE: 'Do you really want to delete the link rule?'
      },
      BUTTONS: {
        ADD_NEW: 'Add',
        ASSIGN: 'Assign'
      },
      FIELDS: {
        NAME: 'Name',
        AMAZONSTORELINKS: 'Amazon Store Links',
        LOCATIONLINKS: 'Location Links',
        SHOPIFYLOCATIONS: 'Shopify Locations',
        ASSIGNTOSTORE: 'Assign To Store'
      }
    },
    SHIPPINGACCOUNTS: {
      TITLE: {
        DELETE: 'Delete Shipping Account?',
      },
      MESSAGES: {
        DELETE: 'Do you really want to delete the Shipping Account?'
      },
      BUTTONS: {
        ADD_NEW: 'Add',
        ASSIGN: 'Assign'
      },
      FIELDS: {
        NAME: 'Name',
        AMAZONSTORELINKS: 'Amazon Store Links',
        LOCATIONLINKS: 'Location Links',
        SHOPIFYLOCATIONS: 'Shopify Locations',
        ASSIGNTOSTORE: 'Assign To Store'
      }
    },
    PRICERULES: {
      PRICERULE: 'Price Rule',
      PRICERULES: 'Price Rules',
      TITLE: {
        PRICING_Rules: 'PRICING RULES',
        ADD_NEW_RULE: 'Add new rule',
        ADD: 'Add Price Rule',
        EDIT: 'Edit Price Rule',
        DELETE: 'Delete Price Rule?',
        ASSIGNPRICERULE: 'Assign Price Rule to Store Products',
        SETDEFAULTSTORE: 'Select Default Store',
        RULE_NAME: 'Rule name',
        COST_CONFIGURATION: 'Cost configuration',
        FEES: 'Fees',
        ADDITIONAL_COST: 'Additional cost',
        SHIPPING_TAX: 'Shipping/TAX',
        MIN_AMOUNT: 'Min amount',
        MAX_AMOUNT: 'Max amount',
        REPRICE: 'Reprice',
        ASSIGNED: 'Assigned'
      },
      MESSAGES: {
        DELETE: 'Do you really want to delete the price rule?'
      },
      BUTTONS: {
        ADD_NEW: 'Add',
        ASSIGN: 'Assign'
      },
      FIELDS: {
        NAME: 'Name',
        AMAZONSTOREPRICES: 'Amazon Store Prices',
        LOCATIONPRICES: 'Location Prices',
        SHOPIFYLOCATIONS: 'Shopify Locations',
        ASSIGNTOSTORE: 'Assign To Store'
      }
    },
    REORDER_RULES: {
      REORDER_RULE: 'Reorder Rule',
      REORDER_RULES: 'Reorder Rules',
      TITLE: {
        DELETE: 'Are you sure?'
      },
      MESSAGES: {
        DELETE: 'Do you really want to delete the reorder rule?',
        ERRORS: {
          IN_USE: 'This reorder rule is in use',
        },
      },
      BUTTONS: {
        ADD_NEW: 'Add',
      },
      FIELDS: {
        NAME: 'Name',
      }
    },
    REPLENISH_RULES: {
      REPLENISH_RULE: 'Reorder Rule',
      REPLENISH_RULES: 'Reorder Rules',
      TITLE: {
        DELETE: 'Are you sure?'
      },
      MESSAGES: {
        DELETE: 'Do you really want to delete the price rule?',
        ERRORS: {
          IN_USE: 'This replenish rule is in use',
        },
      },
      BUTTONS: {
        ADD_NEW: 'Add',
      },
      FIELDS: {
        NAME: 'Name',
      }
    },
    PRINTSHIPMENTLABELS: {
      PRINTSHIPMENTLABEL: 'Print Shipment Labels',
      PRINTSHIPMENTLABELS: 'Print Shipment Labels',
      TITLE: {
        ADD: 'Add Print Shipment Labels',
        EDIT: 'Edit Print Shipment Labels'
      },
      FIELDS: {
        BOXLABEL: 'Box Label',
        PALLETLABEL: 'Pallet Label',
        SHIPPINGLABEL: 'Shipping Label'
      }
    },
    PURCHASES: {
      PURCHASE: 'Purchase',
      PURCHASES: 'Purchasing',
      TITLE: {
        ADD: 'Add Purchase',
        EDIT: 'Edit Purchase',
        DELETE: 'Delete Purchase?',
        DELETE_ITEM: 'Delete Purchase Item?',
        DELETE_RECEIVED: 'Delete Purchase Received Items?',
        DETAILS: 'Purchase Order Details {{identifier}} - {{supplier}}',
        RECEIVING: 'Receving Purchase Order {{identifier}}'
      },
      MENU: {
        RECEIVING: 'Receiving purchased items',
        CLOSE: 'Mark as closed',
        OPEN: 'Mark as open',
        BACKORDERED: 'Mark as backordered',
        PDF: 'Download PDF statement'
      },
      MESSAGES: {
        DELETE: 'Do you really want to delete the purchase?',
        DELETE_ITEM: 'Do you really want to delete the purchase items?',
        DELETE_RECEIVED:
          'Do you really want to delete the purchase received items?',
        DELETED: 'Purchase Order {{identifier}} have been removed.',
        DELETED_RECEIVED:
          'The items received on {{receivedDate}} have been removed.',
      },
      BUTTONS: {
        ADD_SUPPLIER_RETURN: 'Add Supplier Return',
        ADD_ITEM: 'Add New Item',
        RECEIVED: 'Received',
        RECEIVED_ALL: 'Received All Purchased Items',
        BROWSE: 'Browse',
        IMPORT_FROM_CSV: 'Import from csv',
        ADD_TO_PO: 'Add to PO',
        MARK_AS_OPEN: 'Mark as ordered'
      },
      FIELDS: {
        ORDER_NUMBER: 'Order Number',
        ORDER_DATE: 'Order Date',
        SHIPPING_PRICE: 'Shipping Price',
        IMPORT_TAX: 'Import Tax',
        DISCOUNT: 'Discount',
        DISCOUNT_AMOUNT: 'Discount Amount',
        SHIP_TO: 'Ship to',
        PAYMENT_METHOD: 'Payment Method',
        NOTES: 'Notes',
        QUANTITY: 'Quantity',
        CASES: 'Cases',
        ASIN_UPC: 'ASIN/UPC',
        LOCATION: 'Location',
        RECEIVED_DATE: 'Received Date',
        QUANTITY_PURCHASED: 'Quantity Purchased',
        QUANTITY_RECEIVED: 'Quantity Received',
        QUANTITY_ALREADY_RECEIVED: 'Quantity Already Received',
        STATUS: 'Status',
        SUPPLIER: 'Supplier',
        RECEIVED: 'Received',
        ORDER_TOTAL: 'Order total',
        PROFIT: 'Profit',
        SALES_PROGRESS: 'Sales progress',
        RECONCILE: 'Reconcile',
        ACTIONS: 'Actions',
        HISTORY: 'History',
        RECEIVE_AT_LOCATION: 'Receive at location',
        ALL: 'All'
      },
      SUB_TITLE: {
        DATE_CREATED: 'Date created',
        MODIFIED: 'Modified',
        QTY: 'Qty',
        QTY_RECIVED: 'Qty received',
        AMT_RECEIVED: 'Amt received',
        SHIPPING: 'Shipping',
        FILTER: 'Filter',
        ALL: 'All',
        OPEN: 'Open',
        BACKORDERED: 'Backordered',
        DRAFTS: 'Drafts',
        Ordered: 'Ordered',
        Closed: 'Closed',
        SORT: 'Sort',
        SAVE_FILTER_SET: 'Save filter set',
        DAMAGED: 'Damaged',
        MARK_AS_PAID: 'Mark As Paid'
      },
    },
    AMAZON_REMOVALS: {
      AMAZON_REMOVAL: 'Amazon Removal',
      AMAZON_REMOVALS: 'Amazon Removals',
      TITLE: {
        DETAILS: 'Amazon Removal Details {{removalNumber}} - {{store}}',
        RECEIVING: 'Receving Amazon Removal {{removalNumber}}',
        DELETE_RECEIVED: 'Receive all Removal Items'
      },
      MESSAGES: {
        DELETE_RECEIVED: 'All Removal Items have been Received',
        QUANTITYMUSTHAVEVALUE: 'Received Quantity must have value'
      },
      MENU: {
        RECEIVING: 'Receiving removal items',
        CLOSE: 'Mark as closed',
        OPEN: 'Mark as open'
      },
      BUTTONS: {
        RECEIVED: 'Received',
        RECEIVED_ALL: 'Received All Amazon Removal Items',
        BROWSE: 'Browse',
        PASTE_FROM_SHEET: 'Paste from sheet',
        ADD_TO_PO: 'Add to PO',
        MARK_AS_OPEN: 'Mark as ordered',
      },
      FIELDS: {
        REMOVAL_NUMBER: 'Removal Number',
        REQUEST_DATE: 'Request Date',
        PRODUCT: 'Product',
        QUANTITY: 'Quantity',
        CASES: 'Cases',
        ASIN_UPC: 'ASIN/UPC',
        LOCATION: 'Location',
        STORE: 'Store',
        REMOVAL_TYPE: 'Removal Type',
        RECEIVED_DATE: 'Received Date',
        QUANTITY_REMOVED: 'Quantity Removed',
        QUANTITY_RECEIVED: 'Quantity Received',
        QUANTITY_ALREADY_RECEIVED: 'Quantity Already Received',
        AMAZON_STATUS: 'Amazon Status',
        STATUS: 'Status',
        RECEIVED: 'Received',
        ACTIONS: 'Actions',
        HISTORY: 'History',
        QUANTITY_REQUESTED: 'Quantity Requested',
        ALL: 'All',
      },
      SUB_TITLE: {
        DATE_CREATED: 'Date created',
        MODIFIED: 'Modified',
        QTY: 'Qty',
        QTY_RECIVED: 'Qty received',
        AMT_RECEIVED: 'Amt received',
        SHIPPING: 'Shipping',
        FILTER: 'Filter',
        ALL: 'All',
        PENDING: 'Pending',
        COMPLETED: 'Completed',
        CANCELLED: 'Cancelled',
        SORT: 'Sort',
        SAVE_FILTER_SET: 'Save filter set',
      },
      LABEL: {
        RECEIVE_INVENTORY: 'Receive inventory',
        EDIT: 'Edit',
        DUPLICATE: 'Duplicate',
        EXPORT: 'Export',
        GENERAL_INFORMATION: 'GENERAL INFORMATION',
        PRODUCTS: 'PRODUCTS',
        RECEIVING_STATUS: 'Receiving status',
        DESTINATION_WAREHOUSE: 'Destination warehouse',
        REMOVAL_REFERENCE_NUMBER: 'Removal reference number',
        SHIPMENT_DETAILS: 'SHIPMENT DETAILS',
        REMOVAL_TYPE: 'Removal Type',
        ESTIMATES_ARRIVAL_DATE: 'Estimated arrival date',
        TRACKING: 'Tracking',
        ISEN: 'ISEN',
        UNITS: 'Units',
        CASES: 'Cases',
        UNITS_PER_CASE: 'Units per Case',
        QTY_RECEIVED: 'QTY Received',
        QTY_REMOVED: 'QTY Removed',
        SHIPPING: 'Shipping',
        TOTAL: 'Total',
        COMMENTS: 'COMMENTS',
        ADD_COMMENTS: 'Add comments',
        POST_COMMENT: 'Post comment',
        ATTACHMENTS: 'Attachments',
        UPLOAD_FILES: 'Upload PDF files',
        CHECK_TO_CONFIRM: 'Check to confirm',
        ATTACH_FILES: 'ATTACH FILES',
        REMOVAL_DETAILS: 'REMOVAL DETAILS',
        STORE_DETAILS: 'STORE DETAILS',
        STORE: 'Store',
        RECEIVE: 'Receive',
        TOTAL_RECEIVED: 'Total received',
        RECEIVING_AT_LOCATION: 'Receiving at location',
        ACCEPT_ALL_UNRECEIVED_INVENTORY: 'Accept all unreceived inventory',
        ADD_EXPIRATION_DATE: 'Add Expiration date',
      }
    },
    REPORTS: {
      PROFITS: {
        TITLES: {
          PROFITS: 'Profits report',
          ORDERS: 'Orders',
          SALES: 'Sales',
          ORDERS_SHIPPING: 'Orders shipping',
          RETURNS: 'Returns',
          RETURNS_SHIPPING: 'Returns shipping',
          REIMBURSEMENTS: 'Reimbursements',
          COST: 'Cost',
          ORDERS_PROMOTION_REBSTES: 'Orders promotion rebates',
          RETURNS_PROMOTION_REBSTES: 'Returns promotion rebates',
          FEES: 'Fees',
          STORAGE: 'Storage fees',
          SELLING_CHARGES: 'Selling charges',
          FBA_SHIPMENTS_COST: 'Shipping to Amazon Fees',
          ADVERTISING: 'Advertising',
          ORDER_DETAILS: 'Order details',
          REIMBURSEMENT_DETAILS: 'Reimbursement details',
        },
      }
    },
    PROFIT_DETAILS: {
      PROFIT_DETAILS: 'Profit Details',
      FIELDS: {
        DATE: 'Date',
        STORE: 'Store',
        SKU: 'Sku',
        TITLE: 'Title',
        SUPPLIER: 'Supplier',
        BRAND: 'Brand',
        UNITS_SOLD: 'Units Sold',
        RETURNS: 'Returns',
        SALES: 'Sales',
        COST: 'Cost',
        FEES: 'Fees',
        ADVERTISING: 'Advertising',
        TAX: 'Tax',
        TYPE: 'Type',
        PROFIT: 'Profit',
        ROI: 'ROI',
        REIMBURSEMENTS: 'Reimbursements',
        INVENTORY_ADJUSTMENTS: 'Inventory Adjustments',
        TRANSACTION_ID: 'Transaction ID',
        QUANTITY: 'Quantity',
      },
      BUTTONS: {
        GROUP_BY_MAIN: 'Group by Main SKU',
        GROUP_BY_SKU: 'Group by SKU',
        GROUP_BY_VARIATION: 'Group by Variation',
        GROUP_BY_STORE_VARIATION: 'Group by Store Variation',
        GROUP_BY_SUPPLIER: 'Group by Supplier',
        GROUP_BY_BRAND: 'Group by Brand',
        GROUP_BY_TRANSACTION: 'Group by Transaction'
      }
    },
    INVENTORY_BALANCES: {
      FIELDS: {
        DATE: 'Date',
        SKU: 'Sku',
        TITLE: 'Title',
        UNITS: 'Locations Units',
        OPEN_PO_UNITS: 'Open PO Units',
        INBOUND_UNITS: 'Inbound Units',
        FBA_UNITS: 'FBA Units',
        TOTAL_UNITS: 'Total Units',
        AMOUNT: 'Locations Amount',
        OPEN_PO_AMOUNT: 'Open PO Amount',
        INBOUND_AMOUNT: 'Inbound Amount',
        FBA_AMOUNT: 'FBA Amount',
        TOTAL_AMOUNT: 'Total Amount',
        OPEN_PO: 'Open Purchase Orders',
        LOCATIONS: 'Locations',
        INBOUND: 'Inbound',
        FBA: 'Amazon',
      },
    },
    RETURNS: {
      RETURN: 'Return',
      RETURNS: 'Returns',
      AMAZON_RETURNS: 'Amazon Returns',
      FBM_RETURNS: 'FBM Returns',
      FIELDS: {
        STORENAME: 'Store Name',
        STORETYPE: 'Store Type',
        DATEOFRETURN: 'Date of Return',
        AMAZONORDERID: 'Amazon Order Id',
        ORDERID: 'Order Id',
        SKU: 'Sku',
        DESCRIPTIONTITLE: 'Title',
        QUANTITY: 'Quantity',
        DISPOSITION: 'Disposition',
        REASON: 'Reason',
        COMMENTS: 'Comments',
        LICENSEPLATENUMBER: 'License Plate Number'
      }
    },
    RECEIVE: {
      PAGE_TITLE: 'Return Products',
      FIELDS: {
        DATE_OF_RETURN :'Date Of Return',
        HISTORY: 'Receiving History',
        QUANTITY: 'Received Quantity / Total Quantity'
      }
    },
    SETTLEMENTS: {
      SETTLEMENT: 'Settlement',
      SETTLEMENTS: 'Settlements',
      FIELDS: {
        DATE: 'Date',
        AMAZON_ORDER_ID: 'Amazon Order Id',
        SKU: 'Sku',
        TITLE: 'Title',
        QUANTITY: 'Quantity',
        SALES: 'Sales',
        PROMOTION_REBATES: 'Promotion Rebates',
        FEES: 'Fees',
        TAX: 'Tax',
        MARKETPLACE_FACILITATOR_TAX: 'Marketplace Facilitator Tax',
        SELLING_CHARGES: 'Selling Charges',
        OTHER: 'Other',
        ORDERS: 'Orders',
        RETURNS: 'Returns',
        REIMBURSEMENTS: 'Reimbursements',
        COST: 'Cost',
        PROFIT: 'Profit'
      }
    },
    STORES: {
      MESSAGES: {
        SUCCESS: '{{Type}} store {{Name}} has been connected.',
        ERROR: '{{Type}} store {{Name}} has NOT been connected.'
      }
    },
    QUICKBOOKSSETTINGS: {
      QUICKBOOKSSETTING: 'QuickBooks Setting',
      QUICKBOOKSSETTINGS: 'QuickBooks Settings',
      TITLE: {
        ADD: 'Add QuickBooks Settings',
        EDIT: 'Edit QuickBooks Settings',
        DELETE: 'Delete QuickBooks Settings?'
      },
      MESSAGES: {
        DELETE: 'Do you really want to delete the QuickBooks Settings?',
        INACTIVE: `Your QuickBooks Connection is inactive. Please try connecting To QuickBooks again.`
      },
      FIELDS: {
        MERCHANDISESALESITEM: 'Merchandise Sales Item',
        PROMOTIONS: 'Promotions',
        AMAZONFEES: 'Amazon fees',
        OTHER: 'Other',
        COSTOFGOODSSOLD: 'Cost Of Goods Sold',
        AMAZONCAPITOLACCOUNT: 'Amazon Capitol Account',
        INVENTORY: 'Inventory',
        REFUNDSPRODUCTCHARGES: 'Refunds Product Charges'
      }
    },
    SEASONS: {
      SEASON: 'Season',
      SEASONS: 'Seasons',
      TITLE: {
        ADD: 'Add Season',
        EDIT: 'Edit Season',
        DELETE: 'Delete Season?',
        DELETE_ITEM: 'Delete Season Item?'
      },
      MESSAGES: {
        DELETE: 'Do you really want to delete the season?',
        DELETE_ITEM: 'Do you really want to delete the season items?',
        DELETED: 'Season Order {{orderNumber}} have been removed.'
      },
      FIELDS: {
        SEASONS: 'Seasons',
        STARTDATE: 'Start Date',
        ENDDATE: 'End Date'
      }
    },
    USER_MANAGEMENT: {
      USER_MANAGEMENT: 'User Management',
      TITLE: {
        ADVERTISING: 'User Management',
        DELETE_USER: 'Delete user',
        DELETE_INVITATION: 'Revoke invitation'
      },
      MESSAGES: {
        ENABLED: 'Access for {{lastName}} {{firstName}} has been enabled.',
        DISABLED: 'Access for {{lastName}} {{firstName}} has been disabled.',
        NEWROLE: '{{lastName}} {{firstName}} has been assigned the {{newRole}} role.',
        DELETE_USER: 'Do you really want to delete the user?',
        DELETE_INVITATION: 'Do you really want to revoke the invitation?'
      },
      FIELDS: {
        KEYWORDTYPE: 'Keyword Type'
      },
      BUTTONS: {
        REVOKE: 'Revoke'
      }
    },
    WAREHOUSES: {
      WAREHOUSE: 'Warehouse',
      WAREHOUSES: 'Warehouses',
      TITLE: {
        ADD: 'Add Warehouse',
        EDIT: 'Edit Warehouse',
        DELETE: 'Delete Warehouse?',
        DELETE_ITEM: 'Delete Warehouse Item?'
      },
      MESSAGES: {
        DELETE: 'Do you really want to delete the warehouse?',
        DELETE_ITEM: 'Do you really want to delete the warehouse items?',
        DELETED: 'Warehouse Order {{orderNumber}} have been removed.',
        DEACTIVATED: '{{name }} has been deactivated',
        ERRORS: {
          INVALID_CSV: 'Invalid csv link or file format',
          LINKED: 'Cannot delete the address because its associated with {{name}}'
        }
      },
      FIELDS: {
        NAME: 'Name',
        RECIPIENTNAME: 'Recipient Name',
        PHONE: 'Phone',
        ADDRESSLINE1: 'Address Line 1',
        ADDRESSLINE2: 'Address Line 2',
        CITY: 'City',
        STATECODE: 'State',
        POSTALCODE: 'Postal Code',
        COUNTRYCODE: 'Country',
        ALLOWAUTOADJUSTMENT: 'Allow Auto Adjustment'
      }
    },
    WFS_SHIPMENTS: {
      WFS_SHIPMENTS: 'WFS Shipments',
      WFS_SHIPMENT: 'WFS Shipment',
      TITLE: {
        STARTED: 'Started',
        WORKING: 'Working',
        SHIPPED: 'Shipped',
        ADD: 'Add WFS Shipment',
        EDIT: 'Edit WFS Shipment',
        PRINT_BOX: 'Print Box Labels'
      },
      FIELDS: {
        WFS_SHIPMENT_NAME: 'WFS Shipment Name',
        NAME: 'Name',
        SHIPMENT_ID: 'Shipment Id',
        STORE: 'Store',
        TYPE: 'Shipment Type',
        LOCATION: 'Location',
        SHIP_FROM: 'Ship From',
        RETURN: 'Return',
        SHIP_TO: 'Ship To',
        SKUS: 'SKUs',
        EXPECTED_DELIVERY_DATE: 'Expected Delivery Date',
        DATE_CREATED: 'Date Created',
        QUANTITY: 'Quantity',
        UNITS: 'Units',
        BOXES: 'Boxes',
        QUANTITY_PER_BOX: 'Quantity Per Box',
        TOTAL_QUANTITY: 'Total Quantity',
        WEIGHT: 'Weight',
        BOX_DIMENSIONS: 'Box Dimensions',
        EXPIRY_DATE: 'Expiry Date',
        CREATED_MODIFIED_DATE: 'Date created / Modified',
        STATUS: 'Status',
        PACKED_BY: 'Packed by',
        ASIN: 'ASIN',
        SKU: 'SKU',
        FNSKU: 'FNSKU',
        UPC: 'UPC',
      }, MESSAGES: {
        SENT_TO_WALMART: '{{name}} has been sent to Amazon',
        ACCEPTED: '{{name}} has been accepted',
        UPDATED: '{{name}} has been updated'
      }
    },
    FBA_SHIPMENTS: {
      FBA_SHIPMENTS: 'FBA Shipments',
      FBA_SHIPMENT: 'FBA Shipment',
      TITLE: {
        STARTED: 'Started',
        WORKING: 'Working',
        SHIPPED: 'Shipped',
        ADD: 'Add FBA Shipment',
        EDIT: 'Edit FBA Shipment',
        PRINT_BOX: 'Print Box Labels',
        DELETE: 'Delete FBA Shipment'
      },
      FIELDS: {
        FBA_SHIPMENT_NAME: 'FBA Shipment Name',
        NAME: 'Name',
        SHIPMENT_ID: 'Shipment Id',
        STORE: 'Store',
        TYPE: 'Shipment Type',
        LOCATION: 'Location',
        SHIP_FROM: 'Ship From',
        SHIP_TO: 'Ship To',
        SKUS: 'SKUs',
        DATE_CREATED: 'Date Created',
        QUANTITY: 'Quantity',
        UNITS: 'Units',
        BOXES: 'Boxes',
        QUANTITY_PER_BOX: 'Quantity Per Box',
        TOTAL_QUANTITY: 'Total Quantity',
        WEIGHT: 'Weight',
        BOX_DIMENSIONS: 'Box Dimensions',
        EXPIRY_DATE: 'Expiry Date',
        CREATED_MODIFIED_DATE: 'Date created / Modified',
        STATUS: 'Status',
        PACKED_BY: 'Packed by',
        ASIN: 'ASIN',
        SKU: 'SKU',
        FNSKU: 'FNSKU',
        UPC: 'UPC',
        RECEIVED: 'Received',
        QTY: 'Qty',
        QTY_RECEIVED: 'Qty Received',
        STOCK: 'Stock',
        STOCK_BEFORE_AFTER: 'Stock before > after',
        CONFIRM: 'Confirm amt sent',
        SHIPPED: 'Shipped'
      },
      BUTTONS: {
        ADD_PRODUCT: 'Add Product',
        ADD_NEW_PRODUCT: 'Add Another Product',
        SEND_TO_AMAZON: 'Send To Amazon',
        SUBMITTED_PLANS: 'Submitted Plans',
        SORT: 'Sort'
      },
      MESSAGES: {
        SENT_TO_AMAZON: '{{name}} has been sent to Amazon',
        ACCEPTED: '{{name}} has been accepted',
        UPDATED: '{{name}} has been updated',
        SHIPMENT_COST_UPDATED: '{{name}} shipping cost has been updated',
        DELETE: 'Do you really want to delete the FBA shipment?',
        ERRORS: {
          TIMEOUT: 'Shipment update still in progress please visit this page later to check the status of the changes'
        }
      },
      SHIPMENT_TYPES: {
        CASEPACKED: 'Case Packed',
        INDIVIDUAL: 'Individual'
      },
      BOXES_TYPES: {
        WEBFORM: 'Web form',
        BARCODES2D: '2D barcodes',
        SKIPBOXINFO: 'Skip Box Information'
      },
      PACKING_TYPES: {
        PERSKU: 'Per SKU',
        EVERYTHINGIN1BOX: 'Everything in 1 box',
        MIXEDMULTIPLEBOXES: 'Mixed multiple boxes'
      }
    },
    ORDERS: {
      SELF_SHIP_ORDERS: 'Self Ship Orders (BETA)',
      SELF_SHIP_ORDER: 'Self Ship Order',
      TITLE: {
        SHIPPING: 'Shipping',
        ITEMS_ORDERED: 'Items Ordered',
        SELF_SHIP_RATES: 'Self Ship Rates',
        AMAZON_RATES: 'Amazon Shipping Rates',
        DETAILS: 'Order Details {{orderNumber}} - {{store}}'
      },
      MESSAGES: {
        SELF_SHIP_RATES: 'Please select one of the listed rates',
        AMAZON_RATES: 'Please select one of the listed amazon rates',
        ERRORS: {
          UNAVAILABLE:
            'There are not enough availability of {{sku}} to fullfill all selected orders',
          NOT_FOUND: 'The order number {{orderNumber}} is not found'
        }
      },
      FIELDS: {
        ALL: 'All',
        STORE: 'Store',
        AMAZON_STORE: 'Amazon Store',
        ORDER_NUMBER: 'Order Number',
        QUANTITY: 'Quantity',
        ITEM_PRICE: 'Item price',
        SHIPPING: 'Shipping',
        DISCOUNT: 'Discount',
        TAG: 'Tag',
        STATUS: 'Status',
        DATE: 'Date',
        SHIPPING_ACCOUNT: 'Shipping Account',
        SHIPPING_METHOD: 'Shipping Method',
        SHIPPING_SERVICE: 'Shipping Service',
        CARRIER: 'Carrier',
        SKU: 'SKU',
        TITLE: 'Title',
        UPC: 'Upc',
        ASIN: 'Asin',
        FNSKU: 'Fnsku',
        SHIP_DATE: 'Ship Date',
        EARLIEST_DELIVERY_DATE: 'Earliest Delivery Date',
        LATEST_DELIVERY_DATE: 'Latest Delivery Date',
        RATE: 'Rate',
        SHIP_BY: 'Ship By',
      },
      STATUS: {
        UNSHIPPED: 'Unshipped',
        SHIPPED: 'Shipped',
        CANCELED: 'Canceled',
        PENDING: 'Pending',
        PACKING: 'Packing',
        LABELPROCESSING: 'Label Processing',
        LABELREADY: 'Label Ready',
        LABELFAILED: 'Label Failed'
      },
      BUTTONS: {
        ASSIGN_LOCATION: 'Assign Location',
        PRINT_LABEL: 'Print Label',
        SELF_SHIP: 'Self Ship',
        DROP_SHIP: 'Drop Ship',
        AMAZON: 'Amazon',
        CHECK_RATES: 'Check Rates',
        CHECK_LOWEST_RATE: 'Check Lowest Rate',
        PURCHASE: 'Purchase',
        SEND_FROM_FBA: 'Send From FBA',
        PACKING_SLIP: 'Packing Slip',
        ALL_UNSHIPPED: 'All Unshipped',
        ALL: 'All',
        BROWSE: 'Browse',
        ADD_TO_ORDER: 'Add to order',
      },
      LABEL: {
        GENERAL_INFORMATION: 'GENERAL INFORMATION',
        EDIT: 'Edit',
        CUSTOMER: 'Customer',
        PRODUCTS: 'PRODUCTS',
        QTY_ORDERED: 'QTY Ordered',
        PRICE: 'Price',
        TOTAL_PRICE: 'Total price',
        COST_SUMMARY: 'COST SUMMARY',
        SUBTOTAL: 'Subtotal',
        SHIPPING: 'Shipping',
        TOTAL: 'Total',
        CHECK_TO_CONFIRM: 'Check to confirm',
        CONFIRM_COST_AMOUNT: 'Confirm Cost amount',
        QTY_AMOUNT: 'QTY amount',
        COST_AMOUNT: 'Cost amount',
      }
    },
    SHIPPING_SETTINGS: {
      SHIPPING_SETTINGS: 'Shipping Configurations',
      SHIPPING_SETTING: 'Shipping Configuration',
      TITLE: {
        ADD: 'Add Shipping Configuration',
        EDIT: 'Edit Shipping Configuration',
        DELETE: 'Delete Shipping Configuration?',
      },
      FIELDS: {
        NAME: 'Name',
        DELIVERY_EXPERIENCE: 'Delivery Experience',
        ACCOUNTS: 'Shipping Accounts',
        SERVICES: 'Shipping Methods'
      },
      BUTTONS: {
        SELECT_SHIPPING_ACCOUNTS: 'Select Shipping Accounts',
        SELECT_SHIPPING_METHOD: 'Select Shipping Methods',
        SELECT_DELIVERY_EXPERIENCE: 'Select Delivery Experience'
      },
      MESSAGES: {
        DELETE: 'Do you really want to delete the shipping configuration {{name}}?',
        DELETED: '{{name}} has been removed.',
        ERRORS: {
          NO_SERVICE_SELECTED: 'There are no service is selected for {{types}}',
        }
      },
    },
    SHIPPING_ACCOUNT: {
      NAME: 'Account name',
      TYPE: 'Type'
    },
    SELLING_CHANNEL: {
      CONNECT_AMAZON_STORE: 'Connect your Amazon store',
      CONNECT_WAYFAIR_STORE: 'Connect your Wayfair store',
      CONNECT_RAINFOREST_API: 'Connect your Rainforest API',
      UPDATE_STORE_INFO: 'Update Store Information',
      FOLLOW_STEP_TO_CONNECT_YOUR_STORE: 'Follow these steps to connect your {{storeName}} store.',
      CONNECT_YOUR_STORE: 'Connect your {{storeName}} store',
      UPDATE_YOUR_STORE: 'Update your {{storeName}} store',
      STORE_NAME: 'Store name',
      CHANNEL_NAME: 'Channel name',
      SELLER_ID: 'Seller ID',
      MWS_AUTH_TOKEN: 'MWS Auth Token',
      AVAILABLE_PRODUCTS: 'Available Products',
      Default_Stock_Location: 'Default stock location',
      CURRENCY: 'Currency',
      CREATE_LOCAL_STORE: 'Create your local store',
      UPDATE_LOCAL_STORE: 'Update your local store',
      API_KEY: 'API Key',
      API_SECRET: 'API Secret',
      SANDBOX_API_KEY: 'Sandbox API Key',
      SANDBOX_API_SECRET: 'Sandbox API Secret',
      DEDUCT_FOR_FBA: 'Deducting inventory for FBA Shipment',
      BUTTONS: {
        UPDATE_STORE: 'Update Store'
      }
    },
    PURCHASE_RECEIVING: {
      FIELDS: {
        PRODUCT: 'Product',
        SKU: 'SKU',
        VENDOR_SKU: 'Vendor SKU',
        RECEIVE: 'Receive',
        UNITS: 'Units',
        CASES: 'Cases',
        UNITS_PER_CASE: 'Units per Case',
        ADJUSTMENTS: 'Adjustments',
        TOTAL: 'Total'
      },
      LABEL: {
        LOCATION: 'Location',
        PHONE: 'PHONE',
        EMAIL: 'Email',
        NOTES: 'Notes',
        SUPPLIER_DETAILS: 'SUPPLIER DETAILS',
        PO_DETAILS: 'PO DETAILS',
        ACTIVITY: 'ACTIVITY',
        PRODUCTS: 'PRODUCTS',
        VIEW_MORE: 'View more',
        TOTAL_RECEIVED: 'Total received',
        RECEIVING_AT_LOCATION: 'Receiving at location',
        ACCEPT_ALL_UNRECEIVED_INVENTORY: 'Accept all unreceived inventory',
        ADD_EXPIRATION_DATE: 'Add Expiration date',
        RECEIVING_ADJUSTMENT: 'RECEIVING ADJUSTMENT',
        ADJUSTMENT: 'Adjustment',
        AMOUNT: 'Amount',
        PURCHASES_ORDERS: 'Purchase orders'
      }
    },
    FBA_SHIPMENT_EDIT: {
      LABEL: {
        FBA_SHIPMENTS: 'FBA Shipments',
        SHIPMENT_DETAILS: 'SHIPMENT DETAILS',
        DATE_CREATED: 'Date created',
        LAST_MODIFIED: 'Last Modified',
        STEP1: 'Step 1. Choose inventory to send',
        STEP2: 'Step 2. Confirm shipping',
        STEP3: 'Step 3. Print shipping labels',
        GENERAL_INFORMATION: 'GENERAL INFORMATION',
        SHIPMENT_NAME: 'Shipment name',
        SHIPPING_FROM: 'Shipping from',
        SHIPPING_TO: 'Shipping to',
        PACKED_BY: 'Packed by',
        CASE: 'Case',
        INDIVIDUAL: 'Individual',
        LABELED_BY_AMAZON: 'Labeled by Amazon',
        AMAZON_STORE_NAME: 'Amazon store name',
        ITEM_SHIPMENT_DETAILS: 'ITEM SHIPMENT DETAILS',
        LOCATION: 'Location',
        SHIPPING_FROM_NAME: 'Shipping from name (optional)',
        HIDE: 'Hide'
      }
    },
    FBA_SHIPMENT_PLAN: {
      LABEL: {
        TITLE: 'Your Amazon Placement Options',
        PLAN_ID: 'Plan id',
        WAREHOUSE_INFO: 'Send to {{AmazonWarehouse}} amazon warehouse',
        PLAN_INFO: 'You send inventory to minimal locations and we’ll spread the inventory',
        INBOUND_REGION: 'Inbound region',
        SHIP_FROM: 'Ship From',
        SHIP_TO: 'Ship To',
        SHIPMENT_CONTENTS: 'Shipment Contents',
        BOXES: 'Boxes',
        SKUS: 'SKUs',
        UNITS: 'Units'
      }
    },
    WFS_SHIPMENT_EDIT: {
      LABEL: {
        WFS_SHIPMENTS: 'WFS Shipments',
        SHIPMENT_DETAILS: 'SHIPMENT DETAILS',
        DATE_CREATED: 'Date created',
        LAST_MODIFIED: 'Last Modified',
        STEP1: 'Step 1. Choose inventory to send',
        STEP2: 'Step 2. Confirm shipping',
        STEP3: 'Step 3. Print shipping labels',
        GENERAL_INFORMATION: 'GENERAL INFORMATION',
        SHIPMENT_NAME: 'Shipment name',
        SHIPPING_FROM: 'Shipping from',
        SHIPPING_TO: 'Shipping to',
        PACKED_BY: 'Packed by',
        CASE: 'Case',
        INDIVIDUAL: 'Individual',
        EXPECTED_DELIVERY_DATE: 'Expected Delivery Date',
        WALMART_STORE_NAME: 'Walmart store name',
        ITEM_SHIPMENT_DETAILS: 'ITEM SHIPMENT DETAILS',
        LOCATION: 'Location',
        SHIPPING_FROM_NAME: 'Shipping from name (optional)',
        HIDE: 'Hide'
      }
    },
    PURCHASE_EDIT: {
      LABEL: {
        RECEIVE_INVENTORY: 'Receive inventory',
        EDIT: 'Edit',
        DUPLICATE: 'Duplicate',
        EXPORT: 'Export',
        GENERAL_INFORMATION: 'GENERAL INFORMATION',
        PRODUCTS: 'PRODUCTS',
        RECEIVING_STATUS: 'Receiving status',
        SALES_PROGRESS: 'Sales progress',
        PROFIT: 'Profit',
        RECONCILED: 'Reconciled',
        RECONCILE_ORDER: 'Reconcile Order',
        SUPPLIER_NAME: 'Supplier name',
        DESTINATION_WAREHOUSE: 'Destination warehouse',
        PO_REFERENCE_NUMBER: 'PO reference number',
        PAYMENT_TERMS: 'Payment terms',
        PAYMENT_METHOD: 'Payment Method',
        SHIPMENT_DETAILS: 'SHIPMENT DETAILS',
        ESTIMATES_ARRIVAL_DATE: 'Estimated arrival date',
        TRACKING: 'Tracking',
        CURRENCY_CODE: 'Currency Code',
        USD_RATE: 'USD Rate',
        ISEN: 'ISEN',
        UNITS: 'Units',
        CASES: 'Cases',
        UNITS_PER_CASE: 'Units per Case',
        QTY_RECEIVED: 'QTY Received',
        AMT_RECEIVED: '$Amt received',
        QTY_ORDERED: 'QTY Ordered',
        PRICE: 'Price',
        TOTAL_COST: 'Total cost',
        AFTER_DISCOUNT: 'After discount',
        COST_SUMMARY: 'COST SUMMARY',
        IMPORT_TAX: 'Import tax',
        SUBTOTAL: 'Subtotal',
        ITEMS_TOTAL: 'Items Total',
        SHIPPING: 'Shipping',
        DISCOUNT: 'Discount',
        TOTAL: 'Total',
        COMMENTS: 'COMMENTS',
        ADD_COMMENTS: 'Add comments',
        POST_COMMENT: 'Post comment',
        ATTACHMENTS: 'Attachments',
        UPLOAD_FILES: 'Upload files',
        RECONCILE: 'Reconcile',
        CHECK_TO_CONFIRM: 'Check to confirm',
        CONFIRM_COST_AMOUNT: 'Confirm Cost amount',
        QTY_AMOUNT: 'QTY amount',
        MARK_AS_ENTERED_QUICKBOOKS: 'Mark as entered in QuickBooks',
        BY_MARKING_THIS_IT_WILL_OVERWRITE: 'By marking this, it will overwrite the automatic mark after export.',
        COST_AMOUNT: 'Cost amount',
        PAID_AMOUNT: 'Paid',
        ATTACH_FILES: 'ATTACH FILES'
      }
    },
    DAMAGED_INVENTORY: {
      DAMAGED_INVENTORY: 'Total Damaged Inventory',
      QTY: 'Qty',
      AMOUNT: 'Amount',
      FIELDS: {
        DATE: 'Date',
        QUANTITY: 'Quantity',
        COST_PER_UNIT: 'Cost Per Unit',
        TOTAL: 'Total'
      }
    },
    PRODUCT_REORDER: {
      LABEL: {
        OPEN_POS_WITH: 'Open po\'s with',
        SELECT_OPEN_PURCHASE_ORDER: 'Select a open purchase order you would like to add to',
        REMOVE_FROM_REORDER: 'Remove from reorder',
        ADD_TO_PO: 'Add to PO',
        ISEN: 'ISEN',
        GENERAL_INFO: 'General information',
        SHIPMENT_DETAIL: 'SHIPMENT DETAILS',
        CREATE_NEW_PO: 'Create new PO',
        REORDER_PROCESSED_SUCCESSFULLY: 'Reorder processed successfully',
        CHANGE_SUPPLIER: 'Change Supplier',
        ADD_NEW_SUPPLIER: 'Add new supplier',
        MOVE: 'Move',
        EXIT_REPLENISH_MODE: 'Exit replenish mode',
        REPLENISH: 'Replenish',
        REPLENISH_ROLE1: 'Replenish role1',
        REPLENISH_ROLE2: 'Replenish role2',
        LISTINGS: 'Listings',
        SET_AS_TEMPLATE: 'Set as template',
        PACKING_TEMPLATE_NAME: 'Packing template name',
        UNITS_PER_BOX: 'Units per Case',
        BOX_DIMENSIONS: 'Case dimensions (inch)',
        BOX_WEIGHT: 'Case weight (lb)'
      }
    },
    TRANSFERS: {
      INVENTORY_TRANSFERS: 'Inventory Transfers',
      TRANSFER: 'Warehouse Transfer',
      TRANSFERS: 'Warehouse Transfers',
      TITLE: {
        ADD: 'Add Warehouse Transfer',
        EDIT: 'Edit Warehouse Transfer',
        CLOSE: 'Close Warehouse Transfer?',
        DELETE: 'Delete Warehouse Transfer?',
        DELETE_ITEM: 'Delete Warehouse Transfer Item?',
        DELETE_RECEIVED: 'Delete Warehouse Transfer Received Items?',
        DETAILS: 'Warehouse Transfer Details {{id}} - {{toWarehouse}}',
        RECEIVING: 'Receving Purchase Order {{id}}'
      },
      LABEL: {
        RECEIVE_INVENTORY: 'Receive inventory',
        EDIT: 'Edit',
        DUPLICATE: 'Duplicate',
        EXPORT: 'Export',
        GENERAL_INFORMATION: 'GENERAL INFORMATION',
        PRODUCTS: 'PRODUCTS',
        RECEIVING_STATUS: 'Receiving status',
        SHIP_FROM: 'Ship from',
        SHIP_TO: 'Ship to',
        TRANSFER_NUMBER: 'Transfer number',
        SHIPMENT_DETAILS: 'SHIPMENT DETAILS',
        ESTIMATES_ARRIVAL_DATE: 'Estimated arrival date',
        TRACKING: 'Tracking',
        UNITS: 'Units',
        CASES: 'Cases',
        UNITS_PER_CASE: 'Units per Case',
        QTY_RECEIVED: 'QTY Received',
        QTY_ORDERED: 'QTY Ordered',
        TOTAL_COST: 'Total cost',
        CHECK_TO_CONFIRM: 'Check to confirm',
        CONFIRM_COST_AMOUNT: 'Confirm Cost amount',
        QTY_AMOUNT: 'QTY amount',
        BY_MARKING_THIS_IT_WILL_OVERWRITE: 'By marking this, it will overwrite the automatic mark after export.',
        DETAILS: 'TRANSFER DETAILS',
        LOCATION_DETAILS: 'WAREHOUSE DETAILS',
        ADDRESS: 'Address',
        PHONE: 'Phone',
        EMAIL: 'Email',
        TOTAL_RECEIVED: 'Total received',
        RECEIVING_AT_LOCATION: 'Receiving at location',
        ACCEPT_ALL_UNRECEIVED_INVENTORY: 'Accept all unreceived inventory',
        ADD_EXPIRATION_DATE: 'Add Expiration date',
        AECEIVING_ADJUSTMENT: 'RECEIVING ADJUSTMENT',
        ADJUSTMENT: 'Adjustment',
        ADJUSTMENTS: 'Adjustments',
        RECEIVE: 'Receive',
        HISTORY: 'History',
        TOTAL: 'Total',
        AMOUNT: 'Amount',
      },
      MENU: {
        RECEIVING: 'Receiving inventory transfer items',
        CLOSE: 'Mark as closed',
        OPEN: 'Mark as open',
      },
      MESSAGES: {
        CLOSE: 'The inventory transfer still in transit and the remaining inventory that is not received will be lost in transit. Do you really want to close the inventory transfer?',
        DELETE: 'Do you really want to delete the inventory transfer?',
        DELETE_ITEM: 'Do you really want to delete the inventory transfer items?',
        DELETE_RECEIVED:
          'Do you really want to delete the inventory transfer received items?',
        DELETED: 'Inventory transfer {{id}} have been removed.',
        DELETED_RECEIVED:
          'The items received on {{receivedDate}} have been removed.',
      },
      BUTTONS: {
        ADD_ITEM: 'Add New Item',
        RECEIVED: 'Received',
        RECEIVED_ALL: 'Received All Warehouse Transfer Items',
        BROWSE: 'Browse',
        PASTE_FROM_SHEET: 'Paste from sheet',
        ADD_TO_PO: 'Add to inventory transfer',
        MARK_AS_OPEN: 'Mark as ordered'
      },
      FIELDS: {
        TRANSFER_DATE: 'Transfer Date',
        TRANSFER_NUMBER: 'Transfer Number',
        SHIP_FROM: 'Ship from',
        SHIP_TO: 'Ship to',
        NOTES: 'Notes',
        QUANTITY: 'Quantity',
        CASES: 'Cases',
        LOCATION: 'Location',
        RECEIVED_DATE: 'Received Date',
        QUANTITY_TRANSFERED: 'Quantity Transfered',
        QUANTITY_RECEIVED: 'Quantity Received',
        QUANTITY_ALREADY_RECEIVED: 'Quantity Already Received',
        PRODUCT: 'Product',
        SKU: 'SKU',
        SUPPLIER_SKU: 'Vendor SKU',
        STATUS: 'Status',
        RECEIVED: 'Received',
        ACTIONS: 'Actions',
        ALL: 'All'
      },
      SUB_TITLE: {
        DATE_CREATED: 'Date created',
        MODIFIED: 'Modified',
        QTY: 'Qty',
        QTY_RECIVED: 'Qty received',
        SHIPPING: 'Shipping',
        FILTER: 'Filter',
        ALL: 'All',
        OPEN: 'Open',
        DRAFTS: 'Drafts',
        CLOSED: 'Closed',
        SORT: 'Sort',
        SAVE_FILTER_SET: 'Save filter set',
      }
    },
    ADVANCED_REORDER: {
      ADVANCED_REORDER: 'Advanced Reorder',
    },
    ADVANCED_REORDER_RULES: {
      ADVANCED_REORDER_RULES: 'Advanced Reorder Rules',
      TITLE: {
        DELETE: 'Delete Reorder Rules!'
      },
      MESSAGES: {
        DELETE: 'Do you really want to delete the reorder rule and removed it from all products?',
        ERRORS: {
          DEFAULT_RULE: 'Default reorder rule cannot be deleted.',
          IN_USE: 'This reorder rule is in use, you have to remove it from products first',
        }
      }
    },
    SETTING: {
      LABELS: {
        SETTINGS: 'Settings',
        SELLING_CHANNELS: 'Selling Channels',
        SELLING_CHANNELS_DES: 'Connect your marketplaces to Inventory Ahead',
        SHIPPING_ACCOUNTS: 'Shipping Accounts',
        SHIPPING_ACCOUNTS_DES: 'Connect your shipping accounts to process your merchant fulfilled orders',
        SUPPLIERS: 'Suppliers',
        SUPPLIERS_DES: 'Create and edit suppliers information',
        INVENTORY_RULES: 'Inventory Rules',
        INVENTORY_RULES_DES: 'Set up your preference on how to report inventory to your channels (for FBM listings only)',
        PRICING_RULES: 'Pricing Rules',
        PRICING_RULES_DES: 'Set up the min and max pricing rules and feed it to your repricing software',
        QUICKBOOKS_SETTINGS: 'Quickbooks Settings',
        QUICKBOOKS_SETTINGS_DES: 'Connect your QuickBooks account to have your POs exported to QuickBooks',
        USER_MANAGEMENT: 'User Management',
        USER_MANAGEMENT_DES: 'Invite new users and adjust user permissions',
        LOCATIONS: 'Locations',
        LOCATIONS_DES: 'Add or edit your warehouses',
        ADVANCED_REORDER_RULES: 'Advanced Reorder Rules',
        ADVANCED_REORDER_RULES_DES: 'Configure settings for your advanced reorder calculations',
        REORDER_RULES: 'Reorder Rules',
        REORDER_RULES_DES: 'Configure settings for your automatic reorder calculations',
        REPLENISH_RULES: 'Replenish Rules',
        REPLENISH_RULES_DES: 'Configure settings for your automatic replenish calculations',
        BOX_CONFIG: 'Box Configuration',
        BOX_CONFIG_DES: 'Enable/disable box configuration to show up on your POs and FBA shipments',
        TAGS: 'Tags',
        TAGS_DES: 'Create, edit, and delete tags',
        BILLING: 'Billing',
        BILLING_DES: 'Update billing information',
        LISTING: 'Listing',
        LISTING_DES: 'List new products to marketplaces',
        CUSTOMERS: 'Customers',
        CUSTOMERS_DES: 'Create customers for wholesale orders',
        FULFILLMENTLINKS: 'Fulfillment Links',
        FULFILLMENTLINKS_DES: 'Configure preference for automatic multi-channel fulfillment. You can also view your fulfillment history',
        CARTS: 'Carts',
        CARTS_DES: 'Create and edit warehouse carts'
      },
      MESSAGES: {
        ERRORS: {
          INVALID_KEY: 'Invalid store credentials'
        }
      }
    },
    SELF_SHIP: {
      HEADERS: {
        QUICK_OVERVIEW: 'Quick Overview',
        ORDERS: 'Orders',
        READY_TO_SHIP: 'Ready to ship',
        ORDER_IN_PROCESS: 'Orders In Process',
        ACTIONS: 'Actions',
        PROCESSING: 'Processing',
        SHIPPED: 'Shipped',
        OPEN_ORDERS: 'Open Orders',
      },
      ACTIONS: {
        TO_PICK: 'To Pick',
        TO_PACK: 'To Pack',
        ON_HOLD: 'On Hold',
        ORDERS: 'Orders',
        ITEMS: 'Items',
        UNITS: 'Units',
        VIEW: 'View',
        PICK: 'Pick',
        PACK: 'Pack',
        RESOLVE: 'Resolve',
        ASSIGN_ORDERS: 'Assign Orders',
        HIDE_ITEMS: 'Hide items',
        DONE: 'Done',
      },
      FILTERS: {
        UNASSIGNED: 'Unassigned',
        ALL: 'All',
        OPEN: 'Open',
        CLOSED: 'Closed',
        ON_HOLD: 'On Hold',
        CANCELED: 'Canceled',
        PROCESSING_STATUS: 'Processing Status',
        TAGGED_WITH: 'Tagged With',
        MORE_FILTERS: 'More Filters',
        SORT_BY: 'Sort By',
        TODAY: 'Today'
      }
    },

    TAGS: {
      TITLE: {
        DELETE: 'Delete Tag'
      },
      MESSAGES: {
        DELETE: 'Are you sure want to delete this tag?'
      }
    },

    COUNTRIES: {
      US: {
        NAME: 'United States',
        STATES: {
          AL: 'Alabama',
          AK: 'Alaska',
          AZ: 'Arizona',
          AR: 'Arkansas',
          CA: 'California',
          CO: 'Colorado',
          CT: 'Connecticut',
          DE: 'Delaware',
          DC: 'District of Columbia',
          FL: 'Florida',
          GA: 'Georgia',
          HI: 'Hawaii',
          ID: 'Idaho',
          IL: 'Illinois',
          IN: 'Indiana',
          IA: 'Iowa',
          KS: 'Kansas',
          KY: 'Kentucky',
          LA: 'Louisiana',
          ME: 'Maine',
          MD: 'Maryland',
          MA: 'Massachusetts',
          MI: 'Michigan',
          MN: 'Minnesota',
          MS: 'Mississippi',
          MO: 'Missouri',
          MT: 'Montana',
          NE: 'Nebraska',
          NV: 'Nevada',
          NH: 'New Hampshire',
          NJ: 'New Jersey',
          NM: 'New Mexico',
          NY: 'New York',
          NC: 'North Carolina',
          ND: 'North Dakota',
          OH: 'Ohio',
          OK: 'Oklahoma',
          OR: 'Oregon',
          PA: 'Pennsylvania',
          RI: 'Rhode Island',
          SC: 'South Carolina',
          SD: 'South Dakota',
          TN: 'Tennessee',
          TX: 'Texas',
          UT: 'Utah',
          VT: 'Vermont',
          VA: 'Virginia',
          WA: 'Washington',
          WV: 'West Virginia',
          WI: 'Wisconsin',
          WY: 'Wyoming'
        }
      },
      CA: {
        NAME: 'Canada',
        STATES: {
          AB: 'Alberta',
          BC: 'British Columbia',
          MB: 'Manitoba',
          NB: 'New Brunswick',
          NL: 'Newfoundland and Labrador',
          NS: 'Nova Scotia',
          ON: 'Ontario',
          PE: 'Prince Edward Island',
          QC: 'Quebec',
          SK: 'Saskatchewan'
        }
      },
      UK: {
        NAME: 'United Kingdom',
        STATES: {
          EN: 'England',
          SC: 'Scotland',
          WL: 'Wales',
          NI: 'Northern Ireland'
        }
      },
      ES: {
        NAME: 'Spain',
        STATES: {}
      },
      FR: {
        NAME: 'France',
        STATES: {}
      },
      DE: {
        NAME: 'Germany',
        STATES: {}
      },
      CN: {
        NAME: 'China',
        STATES: {}
      },
    }
  }
};
