// Angular
import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/_services/helper.service';
import { ProductsService } from 'src/app/_services/products.service';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { UserActions } from 'src/app/states/user/user.actions';

@Component({
	selector: 'kt-topbar',
	templateUrl: './topbar.component.html',
	styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit {
	userFullName: string;
	constructor(
		private stateStore: Store,
		private helperService: HelperService,
		private productsService: ProductsService,
		private router: Router) {	}

	ngOnInit() {
		let user = this.helperService.currentUser;
		if (user)
			this.userFullName = `${user.firstName} ${user.lastName}`;
	}

	signOut() {
		this.stateStore.dispatch(new UserActions.Logout).subscribe(_ => window.location.href = '');
	}

	toggle(panelId: string) {
		let panel = document.getElementById(panelId);
		let panelClass = panelId.replace(/_/g, '-');
		if (panel.className == panelClass)
			panel.className += ` ${panelClass}--on`;
		else
			panel.className = panelClass;
	}

	searchChanged(e: any, search: any) {
		this.productsService.getIdBySku(e.target.value).subscribe(id => {
			e.target.value = '';
			this.router.navigate(['/'], { skipLocationChange: true }).then(() =>
				this.router.navigate([`/products/${id}`]));
			search.classList.remove('show');
		});
	}
}