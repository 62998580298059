import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { LoadingService } from 'src/app/_services/loading.service';
import { PopUpService } from 'src/app/_services/popup.service';

@Component({
  selector: 'app-oauth-redirect',
  templateUrl: './oauth-redirect.component.html',
  styleUrls: ['./oauth-redirect.component.scss']
})
export class OauthRedirectComponent implements OnInit, OnDestroy {

  readonly location: Location;

  constructor(
        private popUpSevice: PopUpService, 
        private loadingService: LoadingService,
        @Inject(DOCUMENT) private document: Document
    ) {
      this.location = this.document.defaultView.location;
    }

  ngOnInit() {
    this.loadingService.loading = true;
    this.popUpSevice.sendUrlToMainWindow(this.location.toString());
  }

  ngOnDestroy() {
    this.loadingService.loading = false;
  }
}
