// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// RxJS
import { Observable, BehaviorSubject, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
// Models
import { LinkRuleModel } from '../../_models/link-rule.model';

import { HelperService } from '../helper.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

const API_LINKRULES_URL = environment.baseUrl + '/linkrules';

@Injectable()
export class LinkRulesService {
  constructor(private http: HttpClient,
    private helperService: HelperService,
    private toastr: ToastrService,
    private translate: TranslateService) { }

  linkRulesPage(parentId: number = undefined, page: number = 1, pageSize: number = 20, sortField: string = undefined, sort: string = undefined, filters: any = undefined): Observable<any> {
    const url = `${API_LINKRULES_URL}/LinkRulesPage?page=${page}&pageSize=${pageSize}${sortField ? `&sortField=${sortField}` : ''}${sort ? `&sort=${sort}` : ''}&filters=${filters ? JSON.stringify(filters) : ''}${parentId ? `&parentId=${parentId}` : ''}`;
    return this.http.get<any>(url, { headers: this.helperService.getHTTPHeaders() })
      .pipe(
        catchError(this.handleError('linkRulesPage'))
      );
  }
  getAll(): Observable<LinkRuleModel[]> {
    return this.http.get<any>(API_LINKRULES_URL, { headers: this.helperService.getHTTPHeaders() })
      .pipe(
        catchError(this.handleError('getAll'))
      );
  }
  getAllStores(): Observable<any> {
    const url = `${API_LINKRULES_URL}/getAllStores`;
    return this.http.get<any>(url, { headers: this.helperService.getHTTPHeaders() })
      .pipe(
        catchError(this.handleError('getAllStores'))
      );
  }


  getById(id: number): Observable<LinkRuleModel> {
    const url = `${API_LINKRULES_URL}/${id}`;
    return this.http.get<LinkRuleModel>(url, { headers: this.helperService.getHTTPHeaders() }).pipe(
      catchError(this.handleError<LinkRuleModel>(`getById id=${id}`))
    );
  }

  storesWarehouses(): Observable<LinkRuleModel> {
    const url = `${API_LINKRULES_URL}/storesWarehouses`;
    return this.http.get<LinkRuleModel>(url, { headers: this.helperService.getHTTPHeaders() }).pipe(
      catchError(this.handleError<LinkRuleModel>(`storesWarehouses`))
    );
  }

  create(linkRule: LinkRuleModel): Observable<any> {
    const url = `${API_LINKRULES_URL}/items`;
    return this.http.post<LinkRuleModel>(url, linkRule,
      { headers: this.helperService.getHTTPHeaders() }).pipe(
        catchError(this.handleError<any>('createItem'))
      );
  }
  update(linkRule: LinkRuleModel): Observable<any> {
    const url = `${API_LINKRULES_URL}/items`;
    return this.http.put(url, linkRule,
      { headers: this.helperService.getHTTPHeaders() }).pipe(
        catchError(this.handleError<any>('update'))
      );
  }
  assignLinkRule(ruleId: any, ids: any[], types: any[]): Observable<any> {
    const url = `${API_LINKRULES_URL}/assignLinkRule?ruleId=${ruleId}&id=${ids}&type=${types}`;
    return this.http.put(url, ({}),
      { headers: this.helperService.getHTTPHeaders() }).pipe(
        catchError(this.handleError<any>('update'))
      );
  }
  delete(id: number, convertSubsToMain: boolean = false) {
    return this.http.delete(`${API_LINKRULES_URL}/items/${id}`,
      { headers: this.helperService.getHTTPHeaders() }).pipe(
        catchError(this.handleError<LinkRuleModel>('delete'))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      switch (error.status) {
        case 401:
          window.location.href = '/login';
          break;
        case 422:
          this.toastr.error(this.translate.instant('LINKRULES.MESSAGES.ERRORS.' + error.error.reason, error.error));
          break;
        default:
          this.toastr.error(this.translate.instant('GENERAL.MESSAGES.ERROR'));
      }
      return throwError(error);
    };
  }
}
