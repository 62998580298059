import { Injectable } from '@angular/core';
import { State } from '@ngxs/store';
import * as _ from 'lodash';
import { SelectedBatch } from 'src/app/pages/self-ship-orders/create-batches/create-batches.component';
import ProductsEntitiesState from 'src/app/states/products/products-entities.state';
import Order from 'src/app/_entities/orders/order.entity';
import Product from 'src/app/_entities/products/product.entity';
import { SortOrder } from 'src/app/_enums/general/sort-order.enum';
import OrdersEntitiesState, { DisplayedPicklistItem } from '../../../orders/orders-entities.state';
import { ItemSortBy, PickListItemsFiltersModel, TempPickList } from './pick-list-items-filters.model';
import { Computed, DataAction, StateRepository } from '@angular-ru/ngxs/decorators';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import PickListItem from '../../../../_entities/pick-lists/pick-list-item.entity';
import EntityMap from '../../../../_builders/entity-map';
import OrdersFiltersState from 'src/app/states/orders/orders-filters/orders-filters.state';

@StateRepository()
@State<PickListItemsFiltersModel>({
    name: 'orderItemsFilters',
    defaults: new PickListItemsFiltersModel
})
@Injectable()
export class PickListItemsFiltersState extends NgxsDataRepository<PickListItemsFiltersModel> {

    constructor(
        private productsEntitiesState: ProductsEntitiesState,
        private ordersEntitiesState: OrdersEntitiesState,
        private ordersFiltersState: OrdersFiltersState
    ) {
        super();
    }

    @Computed()
    public get displayedPicklistItems(): EntityMap<DisplayedPicklistItem> {
        const RegexStartsWith = new RegExp(`^${this.snapshot.searchTerm.trim()}`, 'gmi');

        // get only selected orders
        const selectedOrders = this.ordersEntitiesState
            .filteredEntityMap
            .filter(order => this.ordersFiltersState.selectedHas(order.id));

        // get all products id from selected order
       const productIds = _.chain(selectedOrders.entitiesArray)
                .flatMap(order => order.items)
                .map(orderItem => orderItem.productId)
                .uniq()
                .value();

      return this.productsEntitiesState
            .productsEntityMap
            .subset(...productIds)
            .filter(product => 
                (this.snapshot.warehouseId && !!product.productWarehouse(this.snapshot.warehouseId)) // Warehouse Filter
                || (!this.snapshot.searchTerm.trim() || RegexStartsWith.test(product.sku)) // Search filter
            )
            .sort(this.filteredItemsSortLambda(this.snapshot.sortField, this.snapshot.sortOrder, this.snapshot.warehouseId))
            .mapEntities(product => <DisplayedPicklistItem> 
                ({
                    orderItemIds: this.ordersEntitiesState.selectedEntities
                        .flatMap(order => order.items)
                        .filter(orderItem => orderItem.productId === product.id)
                        .map(orderItem => orderItem.id),
                    productId: product.id,
                    product: product,
                    warehouseId: this.snapshot.warehouseId,
                    stockQuantity: product.productWarehouse(this.snapshot.warehouseId)?.quantity,
                    orderedQuantity: this.ordersEntitiesState.selectedEntities
                        .flatMap(o => o.items)
                        .filter(orderItem => orderItem.productId === product.id)
                        .reduce((a, b) => a + b.quantityOrdered, 0),
                    pickedQuantity: 0
                })
            ).toEntityMap;
    }

    @Computed()
    public get allDisplayedPickListItemsSelected() {
        return this.snapshot.selected.count === this.displayedPicklistItems.count;
    }

    @Computed()
    public get selectedIncludes() {
        return (picklistItem: DisplayedPicklistItem) => {
            return !!this.snapshot.selected.entities[picklistItem.productId];
        };
    }

    @Computed()
    public get filteredSelectedBatchOrders() {
        return (selectedBatch: SelectedBatch) => {
            const RegexStartsWith = new RegExp(`^${selectedBatch.filters.orderSearchTerm}`, 'gmi');
            return selectedBatch.orders
                .filter(order => RegexStartsWith.test(order.orderNumber));
        };
    }

    // Actions
    @DataAction()
    public setWarehouse(warehouseId: number) {
        this.patchState({
            warehouseId: warehouseId
        });
    }

    @DataAction()
    public toggleSortOrder() {
        const currentVal = this.snapshot.sortOrder;
        this.patchState({
            sortOrder: +!currentVal
        });
    }

    @DataAction()
    public setItemSortField(field: ItemSortBy) {
        this.patchState({
            sortField: field
        });
    }

    @DataAction()
    public toggleSelectItem(item: DisplayedPicklistItem) {
        const newSelectedItems = _.cloneDeep(this.snapshot.selected);
        if (newSelectedItems.entities[item.productId]) {
            delete newSelectedItems.entities[item.productId];
        } else {
            newSelectedItems.entities[item.productId] = item;
        }
        
        this.patchState({
            selected: newSelectedItems
        });
    }

    @DataAction()
    public toggleSelectAllItems() {
        const displayedPicklistItems = this.displayedPicklistItems;
        if (this.snapshot.selected.count === displayedPicklistItems.count) {
            this.patchState({
                selected: new EntityMap<DisplayedPicklistItem>({})
            });
        } else {
            this.patchState({
                selected: displayedPicklistItems
            });
        }
    }

    @DataAction()
    public setSearchTerm(term: string) {
        this.patchState({
            searchTerm: term
        });
    }

    @DataAction()
    public addBatchesToBeCreated(batches: EntityMap<TempPickList>) {
        this.patchState({
            batchesToBeCreated: this.snapshot.batchesToBeCreated.concat(batches)
        });
    }

    @DataAction()
    public resetSelected() {
        this.patchState({
            selected: new EntityMap<DisplayedPicklistItem>({}, 'productId')
        });
    }

    @DataAction()
    public deleteSelectedOrdersFromBatch(batch: TempPickList, selectedOrders: Order[]) {
        const orderItemIdsOfSelectedOrders = selectedOrders.flatMap(order => order.items).map(item => item.id);
        const newBatchesToBeCreated = _.cloneDeep(this.snapshot.batchesToBeCreated);
        const batchRef = newBatchesToBeCreated.entities[batch.tempId];
        if (batchRef) {
            batchRef.items = batchRef.items.map(item => ({
                ...item,
                orderItemIds: item.orderItemIds.filter(orderItemId => !orderItemIdsOfSelectedOrders.includes(orderItemId)),
            })).filter(item => item.orderItemIds.length);

            this.patchState({
                batchesToBeCreated: newBatchesToBeCreated
            });
        }
        return batchRef;
    }

    @DataAction()
    public deleteSelectedItemsFromBatch(batch: TempPickList, selectedItems: PickListItem[] | DisplayedPicklistItem[]) {
        const newBatchesToBeCreated = _.cloneDeep(this.snapshot.batchesToBeCreated);
        const batchRef = newBatchesToBeCreated[batch.tempId];
        if (batchRef) {
            batchRef.items = batchRef.items
                .filter(item => !item.orderItemIds.some(id => selectedItems.flatMap(i => i.orderItemIds).includes(id)));

            this.patchState({
                batchesToBeCreated: newBatchesToBeCreated
            });
        }
        return batchRef;
    }

    @DataAction()
    public renameBatch(batch: TempPickList, name: string) {
        const newBatchesToBeCreated = _.cloneDeep(this.snapshot.batchesToBeCreated);
        const batchRef = newBatchesToBeCreated[batch.tempId];
        if (batchRef && name) {
            batchRef.name = name;

            this.patchState({
                batchesToBeCreated: newBatchesToBeCreated
            });
        }
    }

    @DataAction()
    public addItemsToBatch(batch: TempPickList) {
        const items = this.snapshot.selected;
        const newBatchesToBeCreated = _.cloneDeep(this.snapshot.batchesToBeCreated);
        const batchRef = newBatchesToBeCreated[batch.tempId];
        if (batchRef && items.count) {
            batchRef.items = batchRef.items.concat(items);

            this.patchState({
                batchesToBeCreated: newBatchesToBeCreated,
                selected: new EntityMap<DisplayedPicklistItem>({}, 'productId')
            });
        }
    }

    @DataAction()
    public resetBatchesToBeCreated() {
        this.patchState({
            batchesToBeCreated: new EntityMap<TempPickList>({}, 'tempId')
        });
    }

    private filteredItemsSortLambda(sortField: ItemSortBy, sortOrder: SortOrder, warehouseId: number) {
        switch (sortField) {
            case ItemSortBy.Item_name:
                return (a: Product, b: Product) => a.description > b.description
                    ? (sortOrder === SortOrder.Asc ? 1 : -1) : (sortOrder === SortOrder.Asc ? -1 : 1);
            case ItemSortBy.Stock:
                return (a: Product, b: Product) => a.productLocation(warehouseId)?.quantity > b.productLocation(warehouseId)?.quantity
                    ? (sortOrder === SortOrder.Asc ? 1 : -1) : (sortOrder === SortOrder.Asc ? -1 : 1);
            case ItemSortBy.Aisle:
                return (a: Product, b: Product) => a.productLocation(warehouseId)?.aisle > b.productLocation(warehouseId)?.aisle
                    ? (sortOrder === SortOrder.Asc ? 1 : -1) : (sortOrder === SortOrder.Asc ? -1 : 1);
            default:
                return () => 1;
        }
    }
}
