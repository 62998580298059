import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import Loading from "../_global/loading";

@Injectable({
    providedIn: 'root'
})
export class LoadingService {

    private loaders: { [key: string]: Loading } = {};

    getLoader(key: string) {
        if (!this.loaders[key]) {
            this.loaders[key] = new Loading();
        }
        return this.loaders[key];
    }

    getMainLoader() {
        return this.getLoader('main');
    }

    get loading$(): Observable<boolean> {
        return this.getMainLoader().loading$;
    }

    set loading(value: boolean) {
        this.getMainLoader().loading = value;
    }

    get loading() {
        return this.getMainLoader().loading;
    }

    reset() {
        this.loading = false;
    }
}
