import { Component, Input, ContentChildren, forwardRef } from '@angular/core';

@Component({
  selector: 'menu-item',
  template: `<ng-container></ng-container>`
})
export class MenuItem {
	@Input() id: string;
	@Input() icon: string;
	@Input() title: string;
	@Input() url: string;
	@Input() queryParams: { [key: string]: string };
	@Input() target: '_blank' | '_parent' | '_self' | '_top' = '_self';
	@Input() disabled = false;
	@Input() toolTip = '';
	@ContentChildren(forwardRef(() => MenuItem))

	private _subItems: MenuItem[];

	get subItems(): MenuItem[] {
		return this._subItems.filter(col => col !== this);
	}
  get isRouterLink() {
    return /^\/(?!api\/)/.test(this.url);
  }

  get isHref() {
    return !this.isRouterLink;
  }
}
