import { InjectionToken } from "@angular/core";

export const APP_CONFIG_NAME = "APP_CONFIG";

export const APP_CONFIG = new InjectionToken<AppConfig>(APP_CONFIG_NAME);

export interface AppConfig {
    environmentName?: string;
    baseUrl: string;
    stripeKey: string;
    qboClientId: string;
    shopifyClientId: string;
    googleTagManagerId?: string;
    datadogClientId?: string;
    datadogApplicationId?: string;
    version?: string;
    lastBuild?: string;
    serverEnv?: string;
    isInitialized?: boolean;
}
