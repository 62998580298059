import OrderBoxItem from '../_entities/orders/order-boxes/order-box-item.entity';
import OrderBox from '../_entities/orders/order-boxes/order-box.entity';
import OrderItem from '../_entities/orders/order-item.entity';
import Order from '../_entities/orders/order.entity';
import PickListItem from '../_entities/pick-lists/pick-list-item.entity';
import PickList from '../_entities/pick-lists/pick-list.entity';
import { PickListProcessType } from '../_enums/self-ship/pick-list-process-type.enum';
import { OrderLatestStatus } from '../_entities/orders/processing-statuses/order-latest-status';
import { OrderProcessingStatus } from '../_entities/orders/processing-statuses/order-processing-status';
import { PackingProcessingStatus } from '../_entities/orders/processing-statuses/packing-processing-status';
import { PickingProcessingStatus } from '../_entities/orders/processing-statuses/picking-processing-status';
import { ProcessingStatus } from '../_entities/orders/processing-statuses/processing-status';
import { ShippingProcessingStatus } from '../_entities/orders/processing-statuses/shipping-processing-status';
import Utils from './utils';

export type ProcessingStatusEntities = Order | OrderItem | OrderBox | OrderBoxItem | PickList | PickListItem;

export default class ProcessingStatusHelper {

    public static getProcessingStatus: (context: ProcessingStatusEntities) => ProcessingStatus = Utils.memoizedFunction((context: ProcessingStatusEntities) => {
        if (context instanceof Order) {
            return ProcessingStatusHelper.getOrderStatus(context as Order);
        }
        if (context instanceof OrderItem) {
            return ProcessingStatusHelper.getOrderItemStatus(context as OrderItem);
        }
        if (context instanceof OrderBox) {
            return ProcessingStatusHelper.getOrderBoxStatus(context as OrderBox);
        }
        if (context instanceof OrderBoxItem) {
            return ProcessingStatusHelper.getOrderBoxItemStatus(context as OrderBoxItem);
        }
        if (context instanceof PickList) {
            return ProcessingStatusHelper.getPickListStatus(context as PickList);
        }
        if (context instanceof PickListItem) {
            return ProcessingStatusHelper.getPickListItemStatus(context as PickListItem);
        }
        return ProcessingStatus.None;
    });

    static getOrderBoxItemStatus(orderBoxItem: OrderBoxItem, defaultStatus: ProcessingStatus = ProcessingStatus.None) {
        return ProcessingStatusHelper.getProcessingStatus(orderBoxItem.orderItem);
    }

    static getPickListStatus(pickList: PickList) {
        let statuses = [...new Set(pickList.picklistItems
            .flatMap(pli => pli.orderItems)
            .map(orderItem => ProcessingStatusHelper.getProcessingStatus(orderItem)))]
            .filter(status => !!status)
            .sort((a, b) => b.value - a.value);
        return ProcessingStatusHelper.getAppropriateStatus(statuses);
    }

    static getPickListItemStatus(pickListItem: PickListItem) {
        let statuses = [...new Set(pickListItem.orderItems
            .map(orderItem => ProcessingStatusHelper.getProcessingStatus(orderItem)))]
            .filter(status => !!status)
            .sort((a, b) => b.value - a.value);
        return ProcessingStatusHelper.getAppropriateStatus(statuses);
    }

    static getAllOrderStatuses(order: Order) {
        const statuses = order.orderItemEntities?.map(ProcessingStatusHelper.getOrderItemStatuses).flat();
        return ProcessingStatusHelper.filterStatuses(statuses);
    }

    static getAppropriateStatus(statuses: ProcessingStatus[]) {
        return ProcessingStatusHelper.filterStatuses(statuses).slice(-1)[0] ?? ProcessingStatus.None;
    }

    static filterStatuses(processingStatuses: ProcessingStatus[]): ProcessingStatus[] {
        if (!processingStatuses) {
            return new Array<ProcessingStatus>();
        }

        let statuses = [...new Set(processingStatuses)];

        const allProcessingTypes = [PickingProcessingStatus, PackingProcessingStatus, ShippingProcessingStatus];
        allProcessingTypes.forEach(processingType => {
            const none = processingType.None;
            const complete = processingType.keys.slice(-1)[0];
            const partial = processingType.keys.slice(-2)[0];
            const hasCompleteAndNone = [complete, none]
                .every(status => statuses.includes(status as ProcessingStatus));
            const hasCompleteAndPartial = [complete, partial]
                .every(status => statuses.includes(status as ProcessingStatus));

            if (hasCompleteAndNone) {
                statuses.push(partial);
                statuses = statuses
                    .filter(status => !(status instanceof processingType) || status === partial);
            } else if (hasCompleteAndPartial) {
                statuses = statuses
                    .filter(status => !(status instanceof processingType) || status === partial);
            }
        });

        const filteredStatuses = [...new Set(statuses)].sort((a, b) => a.value - b.value);
        return filteredStatuses;
    }

    static getOrderStatus(order: Order) {
        return ProcessingStatusHelper.getAllOrderStatuses(order).slice(-1)[0];
    }

    static getOrderItemStatus(orderItem: OrderItem, defaultStatus?: ProcessingStatus) {
        const orderProcessingStatus = OrderProcessingStatusHelper.getOrderProcessingStatus(orderItem);
        const shippingStatus = ShippingStatusHelper.getShippingStatus(orderItem);
        const packingStatus = PackingStatusHelper.getPackingStatus(orderItem);
        const pickingStatus = PickingStatusHelper.getPickingStatus(orderItem);

        const statuses = [
            orderProcessingStatus,
            shippingStatus,
            packingStatus,
            pickingStatus
        ];
        return statuses.find(status => status.value != ProcessingStatus.None.value) ?? statuses.slice(-1)[0];
    }

    static getOrderItemStatuses(orderItem: OrderItem): ProcessingStatus[] {
        const orderProcessingStatus = OrderProcessingStatusHelper.getOrderProcessingStatus(orderItem);
        const shippingStatus = ShippingStatusHelper.getShippingStatus(orderItem);
        const packingStatus = PackingStatusHelper.getPackingStatus(orderItem);
        const pickingStatus = PickingStatusHelper.getPickingStatus(orderItem);

        const statuses = [
            orderProcessingStatus,
            shippingStatus,
            packingStatus,
            pickingStatus
        ];
        return statuses;
    }

    static getOrderBoxStatus(orderBox: OrderBox) {
        const statuses = orderBox?.boxItems
            ?.map((ProcessingStatusHelper.getProcessingStatus as (orderBoxItem: OrderBoxItem) => ProcessingStatus))
            .filter(status => !!status);
        return ProcessingStatusHelper.getAppropriateStatus(statuses);
    }
}


export class PickingStatusHelper {
    static getPickingStatus(context: OrderItem | PickList | PickListItem): PickingProcessingStatus {

        if (context instanceof OrderItem) {
            return PickingStatusHelper.getOrderItemPickingStatus(context as OrderItem);
        }
        if (context instanceof PickList) {
            return PickingStatusHelper.getPickListPickingStatus(context as PickList);
        }
        if (context instanceof PickListItem) {
            return PickingStatusHelper.getPickListItemPickingStatus(context as PickListItem);
        }
        return PickingProcessingStatus.None;

    }

    static getOrderItemPickingStatus(orderItem: OrderItem) {
        if (!orderItem?.picklistItem) {
            return PickingProcessingStatus.None;
        }

        let pickListItem = orderItem.picklistItem;
        let pickedQty = pickListItem.pickedBinItems
            ?.filter(pbi => pickListItem.pickList.processType == PickListProcessType.ByProduct || pbi.orderId == orderItem.orderId)
            .reduce((a, b) => a + b.quantity, 0);

        if (pickListItem.markedAsPicked) {
            return PickingProcessingStatus.Picked;
        }

        if (pickedQty > 0 && pickedQty < orderItem.quantityOrdered) {
            return PickingProcessingStatus.Picking;
        }
        if (pickedQty == orderItem.quantityOrdered) {
            return PickingProcessingStatus.Picked;
        }
        return PickingProcessingStatus.None;
    }

    static getPickListPickingStatus(pickList: PickList) {
        const statuses = pickList.picklistItems
            ?.map(PickingStatusHelper.getPickingStatus);
        const appropriateStatus = ProcessingStatusHelper.getAppropriateStatus(statuses);
        return appropriateStatus instanceof PickingProcessingStatus ? appropriateStatus : PickingProcessingStatus.None;
    }

    static getPickListItemPickingStatus(pickListItem: PickListItem) {
        if (!pickListItem) {
            return PickingProcessingStatus.None;
        }

        if (pickListItem.markedAsPicked) {
            return PickingProcessingStatus.Picked;
        }

        let pickedQty = pickListItem.pickedBinItems?.reduce((a, b) => a + b.quantity, 0);
        let maxQtyForPickListItem = pickListItem.orderItems.reduce((a, b) => a + b.quantityOrdered, 0);

        if (pickedQty > 0 && pickedQty < maxQtyForPickListItem) {
            return PickingProcessingStatus.Picking;
        }
        if (pickedQty == maxQtyForPickListItem) {
            return PickingProcessingStatus.Picked;
        }
        return PickingProcessingStatus.None;
    }

    public static mapPickingStatusName(status: PickingProcessingStatus) {
        switch (status) {
            case PickingProcessingStatus.None:
                return "Not started";
            case PickingProcessingStatus.Picking:
                return "Partially picked";
            case PickingProcessingStatus.Picked:
                return "Fully picked";
        }
    }
}

export class PackingStatusHelper {
    static getPackingStatus(context: OrderItem | OrderBoxItem): PackingProcessingStatus {
        if (context instanceof OrderItem) {
            return PackingStatusHelper.getOrderItemPackingStatus(context as OrderItem);
        }
        if (context instanceof OrderBoxItem) {
            return PackingStatusHelper.getOrderBoxItemPackingStatus(context as OrderBoxItem);
        }
        return PackingProcessingStatus.None;
    }

    static getOrderItemPackingStatus(orderItem: OrderItem): PackingProcessingStatus {
        if (!orderItem) {
            return PackingProcessingStatus.None;
        }

        const totalQtyPacked = orderItem.order
            .orderBoxes
            .flatMap(box => box.items)
            .filter(boxItem => boxItem.orderItemId == orderItem.id)
            .reduce((a, b) => a + b.quantity, 0);
        const quantityOrdered = orderItem.quantityOrdered;

        if (totalQtyPacked > 0 && totalQtyPacked < quantityOrdered) {
            return PackingProcessingStatus.Packing;
        } else if (totalQtyPacked > 0 && totalQtyPacked == quantityOrdered) {
            return PackingProcessingStatus.Packed;
        }
        return PackingProcessingStatus.None;
    }

    static getOrderBoxItemPackingStatus(orderBoxItem: OrderBoxItem) {
        return PackingStatusHelper.getOrderItemPackingStatus(orderBoxItem.orderItem);
    }

    // static filterOrderPackingStatus(packingStatuses: PackingProcessingStatus[]) {
    //     var statuses = [...new Set(packingStatuses)];
    //     if (Utils.equalsAll(statuses, PackingProcessingStatus.Packed, PackingProcessingStatus.None) || statuses.includes(PackingProcessingStatus.Packing)) {
    //         statuses.push(PackingProcessingStatus.Packing);
    //         statuses = statuses.filter(status => status != PackingProcessingStatus.Packing)
    //     }
    // }
}

export class ShippingStatusHelper {
    static getShippingStatus(context: OrderItem | OrderBox | OrderBoxItem): ShippingProcessingStatus {
        if (context instanceof OrderItem) {
            return ShippingStatusHelper.getOrderItemShippingStatus(context as OrderItem);
        }
        if (context instanceof OrderBox) {
            return ShippingStatusHelper.getOrderBoxShippingStatus(context as OrderBox);
        }
        if (context instanceof OrderBoxItem) {
            return ShippingStatusHelper.getOrderBoxItemShippingStatus(context as OrderBoxItem);
        }
        return ShippingProcessingStatus.None;

    }

    static getOrderItemShippingStatus(orderItem: OrderItem) {
        if (orderItem?.latestStatus === OrderLatestStatus.Shipped) {
            return ShippingProcessingStatus.Shipped;
        } else if (orderItem?.order?.orderBoxes?.some(ob => !ob.items.length) ?? true) {
            return ShippingProcessingStatus.None;
        }

        if (orderItem.quantityShipped > 0 && orderItem.quantityShipped < orderItem.quantityOrdered) {
            return ShippingProcessingStatus.ShippedPartial;
        } else if (orderItem.quantityShipped > 0 && orderItem.quantityShipped === orderItem.quantityOrdered) {
            return ShippingProcessingStatus.Shipped;
        }
        return ShippingProcessingStatus.None;
    }

    static getOrderBoxShippingStatus(orderBox: OrderBox): ShippingProcessingStatus {
        // Temporarily making order shipped when label is printed
        if (orderBox.shipment?.shippingLabels?.every(label => label)) {
            return ShippingProcessingStatus.Shipped;
        }

        const statuses = [
            ...new Set(orderBox?.boxItems?.map(ShippingStatusHelper.getShippingStatus as (orderBoxItem: OrderBoxItem) => ShippingProcessingStatus))
        ] ?? [];

        if (statuses.length === 1) {
            return statuses[0] ?? ShippingProcessingStatus.None;
        }
        if (statuses.some(status => status === ShippingProcessingStatus.ShippedPartial) || Utils.equalsAll(statuses, ShippingProcessingStatus.Shipped, ShippingProcessingStatus.None)) {
            return ShippingProcessingStatus.ShippedPartial;
        }
        return ShippingProcessingStatus.None;
    }

    static getOrderBoxItemShippingStatus(orderBoxItem: OrderBoxItem) {
        return ShippingStatusHelper.getShippingStatus(orderBoxItem.orderItem) as ShippingProcessingStatus;
    }
}

export class OrderProcessingStatusHelper {
    static getOrderProcessingStatus(context: Order | OrderItem): OrderProcessingStatus {
        if (context instanceof Order) {
            return OrderProcessingStatusHelper.orderProcessingStatus(context as Order);
        }
        if (context instanceof OrderItem) {
            return OrderProcessingStatusHelper.orderProcessingStatus((context as OrderItem).order);
        }
        return OrderProcessingStatus.None;

    }

    static orderProcessingStatus(order: Order): OrderProcessingStatus {
        const isStatus = (status: OrderLatestStatus) => order.items.every(item => item.latestStatus === status);
        if (isStatus(OrderLatestStatus.Canceled)) return OrderProcessingStatus.Canceled;
        if (isStatus(OrderLatestStatus.Pending)) return OrderProcessingStatus.Pending;
        if (isStatus(OrderLatestStatus.OnHold)) return OrderProcessingStatus.OnHold;
        return OrderProcessingStatus.None;
    }
}
