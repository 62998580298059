import { SortOrder } from "src/app/_enums/general/sort-order.enum";
import { PickListModel } from "src/app/_models/self-ship/pick-lists/pick-list.model";
import { DisplayedPicklistItem } from "../../../orders/orders-entities.state";
import PickList from "src/app/_entities/pick-lists/pick-list.entity";
import EntityMap from '../../../../_builders/entity-map';

export class PickListItemsFiltersModel {
    warehouseId: number;
    sortOrder: SortOrder = SortOrder.Asc;
    sortField: ItemSortBy = ItemSortBy.Aisle;
    searchTerm: string = '';
    batchesToBeCreated: EntityMap<TempPickList> = new EntityMap<TempPickList>({}, 'tempId');
    selected: EntityMap<DisplayedPicklistItem> = new EntityMap<DisplayedPicklistItem>({}, 'productId');
}


export enum ItemSortBy {
    Item_name,
    Stock,
    Aisle,
    Total_quantity
}

export type TempPickList = Omit<Partial<PickList>, 'items'> & { tempId: number, items: DisplayedPicklistItem[] };
