import { BaseModel } from "../_base.model";
import IStockLocationModel from "./stock-location.model";
import WarehouseCartBinModel from "./warehouse-cart-bin.model";

export default class WarehouseCartModel extends BaseModel implements IStockLocationModel<string> {
    id: number;
    name: string;
    referenceId: string;
    warehouseId: number;
    bins: WarehouseCartBinModel[]
}