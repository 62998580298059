import {NgModule} from '@angular/core';
import {NgxsModule} from '@ngxs/store';
import {NgxsStoragePluginModule, StorageOption} from '@ngxs/storage-plugin';
import {environment} from 'src/environments/environment';
import QuickBooksState from './quickbooks/quickbooks.state';
import UserState from './user/user.state';
import OrdersEntitiesState from './orders/orders-entities.state';
import OrdersFiltersState from './orders/orders-filters/orders-filters.state';
import {PickListItemsFiltersState} from './self-ship/pick-lists/pick-list-items-filters/pick-list-items-filters.state';
import ProductsEntitiesState from './products/products-entities.state';
import StoreProductsEntitiesState from './products/store-products/store-products-entities.state';
import PickListsEntitiesState from './self-ship/pick-lists/pick-lists-entities.state';
import WarehousesFiltersState from './warehouses/warehouses-filters/warehouses-filters.state';
import ShippingProfilesEntitiesState from './self-ship/shipping-profiles/shipping-profiles-entities.state';
import {AuthService} from '../_services/auth.service';
import {ShippingProfilesService} from '../_services/shipping-profiles.service';
import {ShippingAccountsEntitiesState} from './shipping-accounts/shipping-accounts-entities-state';
import {ShippingAccountsService} from '../_services/shipping-accounts.service';
import AddressesEntitiesState from './common/addresses-entities.state';
import {AddressesService} from '../_services/settings/addresses.service';
import {ShipmentsService} from '../_services/self-ship/shipments.service';
import {CommonService} from '../_services/common.service';
import {BoxConfigsService} from '../_services/box-configs.service';
import {BoxConfigsEntitiesState} from './box-configs/box-configs-state';
import {CurrenciesEntitiesState} from './common/currencies-entities.state';
import {NgxsDataPluginModule} from '@angular-ru/ngxs';
import {NGXS_DATA_STORAGE_PLUGIN} from '@angular-ru/ngxs/storage';
import {PickListsFiltersState} from './self-ship/pick-lists/pick-lists-filters/pick-lists-filters.state';
import {ShipmentsEntitiesState} from './shipments/shipments-entities.state';
import WarehousesEntitiesState from './warehouses/warehouses-entities.state';
import StoresEntitiesState from './stores/stores-entities.state';
import {IntermediaryState} from './intermediary.state';
import {StatesHelper} from './states.helper';
import { SuppliersEntitiesState } from './common/suppliers-entities.state';
import { SuppliersService } from '../_services/settings/suppliers.service';
import LocationsEntitiesState from './common/location-entities.state';

const states = [
    UserState,
    QuickBooksState,
    OrdersFiltersState,
    PickListItemsFiltersState,
    StoresEntitiesState,
    CurrenciesEntitiesState,
    OrdersEntitiesState,
    WarehousesEntitiesState,
    ProductsEntitiesState,
    StoreProductsEntitiesState,
    PickListsEntitiesState,
    PickListsFiltersState,
    WarehousesFiltersState,
    ShippingProfilesEntitiesState,
    ShippingAccountsEntitiesState,
    AddressesEntitiesState,
    LocationsEntitiesState,
    SuppliersEntitiesState,
    ShipmentsEntitiesState,
    BoxConfigsEntitiesState,
    IntermediaryState
];

@NgModule({
    imports: [
        NgxsModule.forRoot(states, {
            developmentMode: !environment.productionMode,
            selectorOptions: {
                // These Selector Settings are recommended in preparation for NGXS v4
                suppressErrors: false,
                injectContainerState: false
            }
        }),
        NgxsStoragePluginModule.forRoot({
            storage: StorageOption.LocalStorage,
            key: [UserState]
        }),
        NgxsDataPluginModule.forRoot([
            NGXS_DATA_STORAGE_PLUGIN
        ])
    ],
    providers: [
        ShippingProfilesService,
        ShippingAccountsService,
        AuthService,
        AddressesService,
        ShipmentsService,
        CommonService,
        BoxConfigsService,
        SuppliersService,
        IntermediaryState,
        StatesHelper
    ]
})
export class StatesModule {
}
