import { Injectable } from '@angular/core';
import { ShipmentsEntitiesState } from './shipments/shipments-entities.state';
import OrdersEntitiesState from './orders/orders-entities.state';
import { IIdMap, OrdersFiltersModel } from '../_models/self-ship/orders/orders-filters.model';
import * as _ from 'lodash';
import OrdersFiltersState from './orders/orders-filters/orders-filters.state';
import { DataAction, StateRepository } from '@angular-ru/ngxs/decorators';
import { State } from '@ngxs/store';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { OrderProcessingStatus } from '../_entities/orders/processing-statuses/order-processing-status';
import { ShippingProcessingStatus } from '../_entities/orders/processing-statuses/shipping-processing-status';
import { ProcessingStatus } from '../_entities/orders/processing-statuses/processing-status';
import { IN_PROCESS_FILTERS, SELFSHIP_ORDER_FILTERS } from '../_entities/orders/processing-statuses/order-filter-status';


@StateRepository()
@State({
    name: 'intermediary',
    defaults: {}
})
@Injectable()
export class IntermediaryState extends NgxsDataRepository<{}> {

    constructor(
        public shipmentEntitiesState: ShipmentsEntitiesState,
        public ordersEntitiesState: OrdersEntitiesState,
        public ordersFiltersState: OrdersFiltersState
    ) {
        super();
    }

    // Getter
    get filteredOrderEntities() {
        return this.ordersEntitiesState.filteredEntityMap;
    }

    // Methods
    @DataAction()
    public setOrderFilters(filterState: OrdersFiltersModel) {
        const originalState = _.cloneDeep(this.ordersFiltersState.snapshot);
        const dateHasChanged = originalState?.dateRange?.from !== filterState?.dateRange?.from ||
            originalState?.dateRange?.to !== filterState?.dateRange?.to;
        filterState.alreadyFetched = {
            shipped: !dateHasChanged && filterState.alreadyFetched.shipped,
            canceled: !dateHasChanged && filterState.alreadyFetched.canceled,
            others: !dateHasChanged && filterState.alreadyFetched.others
        };
        this.ordersFiltersState.setState(_.assignIn(new OrdersFiltersModel(), filterState));
        if (dateHasChanged) {
            this.ordersEntitiesState.load();
        }
    }

    @DataAction()
    public toggleAllFilterStatus() {
        const currentVal = this.ordersFiltersState.snapshot.orderStatuses;
        if (currentVal.length === SELFSHIP_ORDER_FILTERS.length) {
            this.ordersFiltersState.patchState({
                orderStatuses: []
            });
        } else {
            this.ordersFiltersState.patchState({
                orderStatuses: SELFSHIP_ORDER_FILTERS
            });
        }
        this.ordersEntitiesState.load();
    }

    @DataAction()
    public toggleOrderStatus(status: ProcessingStatus) {
        const currentVal = this.ordersFiltersState.snapshot.orderStatuses;
        if (status) {
            const toggleValue = !currentVal.includes(status);
            this.ordersFiltersState.patchState({
                orderStatuses: toggleValue === true
                    ? currentVal.concat([status])
                    : currentVal.filter(s => s !== status)
            });

            const alreadyFetched = this.ordersFiltersState.snapshot.alreadyFetched;
            const statusChanged = toggleValue === true &&
                (
                    (!alreadyFetched.shipped && status === ShippingProcessingStatus.Shipped) ||
                    (!alreadyFetched.canceled && status === OrderProcessingStatus.Canceled)
                );
            if (statusChanged) {
                this.ordersEntitiesState.load();
            }
        }
    }

    @DataAction()
    public toggleSelectAllFilteredOrderEntities() {
        let filteredIdsMap: IIdMap = {};
        if (Object.keys(this.ordersFiltersState.snapshot.selected).length !== this.ordersEntitiesState.filteredEntityMap.count) {
            filteredIdsMap = Object.keys(this.ordersEntitiesState.filteredEntityMap.entities)
                .reduce((results, id) => {
                    results[id] = true;
                    return results;
                }, {});
        }
        this.ordersFiltersState.patchState({
            selected: filteredIdsMap
        });
    }
}
