import { Injectable } from "@angular/core";
import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import { Action, createSelector, Selector, State, StateContext } from "@ngxs/store";
import { shareReplay, tap } from "rxjs/operators";
import Utils from "src/app/_helpers/utils";
import { AuthService } from "src/app/_services/auth.service";
import { UserActions } from "./user.actions";
import { SubscriptionStatus, UserStateModel } from "./user.state.model";

@State<UserStateModel>({
    name: "user",
    defaults: null
})
@Injectable()
export default class UserState {

    constructor(private authService: AuthService) { }

    @Selector()
    static isLoggedIn(state: UserStateModel) {
        return state && state !== new UserStateModel();
    }

    @Selector()
    static subscriptionStatus(state: UserStateModel): SubscriptionStatus {
        return state?.subscriptionStatus;
    }

    @Selector([UserState.subscriptionStatus])
    static isActive(subscriptionStatus: SubscriptionStatus): boolean {
        return subscriptionStatus && Utils.equalsAny<SubscriptionStatus>(subscriptionStatus, "canceled", "incomplete_expired") === false;
    }

    @Selector()
    static getTokenId(state: UserStateModel): string {
        return state?.tokenId;
    }

    @Selector()
    static accessToken(state: UserStateModel): string {
        return state?.accessToken;
    }

    @Selector()
    static exceededDays(state: UserStateModel): number {
        return state?.exceededDays;
    }

    @Selector()
    static inactiveDays(state: UserStateModel): number {
        return state?.inactiveDays;
    }

    @Selector([UserState, UserState.isLoggedIn])
    static roles(state: UserStateModel, isLoggedIn: boolean): string[] {
        return isLoggedIn ? state.roles : [];
    }

    @Selector([UserState, UserState.isLoggedIn])
    static permissions(state: UserStateModel, isLoggedIn: boolean): string[] {
        return isLoggedIn ? state.permissions : [];
    }

    static hasRole(searchRoles: string[]) {
        return createSelector([UserState.roles, UserState.isLoggedIn], (stateRoles: string[], isLoggedIn: boolean) =>
            isLoggedIn && searchRoles?.some(role => stateRoles.includes(role))
        );
    }

    static hasPermission(searchPermissions: string[]) {
        return createSelector([UserState.permissions, UserState.isLoggedIn], (statePermissions: string[], isLoggedIn: boolean) =>
            isLoggedIn && searchPermissions?.every(permisssion => statePermissions.includes(permisssion))
        );
    }

    @Action(UserActions.Login)
    login({ dispatch }: StateContext<UserStateModel>, { username, password }: UserActions.Login) {
        return this.authService.login(username, password).pipe(tap(data =>
            dispatch(new UserActions.SetUser(data)))
        );
    }

    @Action(UserActions.SetUser)
    setUser({ setState }: StateContext<UserStateModel>, { user }: UserActions.SetUser) {
        setState(user);
        UserState.setUserToDatadog(user);
        UserState.updateIntercom(user);
    }

    @Action(UserActions.UpdateUser)
    updateUser({ patchState }: StateContext<UserStateModel>, { user }: UserActions.UpdateUser) {
        patchState(user);
        UserState.setUserToDatadog(user);
        UserState.updateIntercom(user);
    }

    @Action(UserActions.RegenerateUser)
    regenerateUser({ dispatch }: StateContext<UserStateModel>) {
        return this.authService.regenerateUser()
            .pipe(
                shareReplay({
                    bufferSize: 1,
                    refCount: true
                }),
                tap((data: UserStateModel) => dispatch(new UserActions.SetUser(data))
                )
            );
    }

    @Action(UserActions.Logout)
    logoutUser({ setState }: StateContext<UserStateModel>) {
        setState(null);
        datadogRum.clearUser();
        datadogLogs.clearUser();
    }

    public static setUserToDatadog(user: UserStateModel) {
        if (!user) return;

        const logUser = {
            id: user.userName,
            email: user.email,
            name: `${user.firstName} ${user.lastName}`,
            organizationId: user.organizationId,
            organizationName: user.organizationName,
            sessionId: user.tokenId
        } as import("@datadog/browser-core").User;
        datadogRum.setUser(logUser);
        datadogLogs.setUser(logUser);
    }

    public static updateIntercom(user: UserStateModel) {

        console.log('87988', 87988);
        if (!user) return;

        window.Intercom('update', {
            email: user.email,
            name: user.firstName + ' ' + user.lastName,
            organizationId: user.organizationId,
            organizationName: user.organizationName,
        });
    }
}