// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// RxJS
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { HelperService } from './helper.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AdvancedReorderRuleModel } from '../_models/advanced-reorder-rule.model';

const API_ADVANCED_REORDER_RULES_URL = environment.baseUrl + '/advancedreorderrules';

@Injectable()
export class AdvancedReorderRulesService {
  constructor(private http: HttpClient,
    private helperService: HelperService,
    private toastr: ToastrService,
    private translate: TranslateService) { }

  getAll(page: number, pageSize: number, sortField: string, sort: string, filters: any): Observable<any> {
    let params: any = { page: page, pageSize: pageSize };
    if (sortField)
      params.sortField = sortField;
    if (sort)
      params.sort = sort;
    if (filters)
      params.filters = JSON.stringify(filters);
    return this.http.get(API_ADVANCED_REORDER_RULES_URL,
      { headers: this.helperService.getHTTPHeaders(), params: params })
      .pipe(map((res: any) => {
        res.data = res.data.map(item => this.processRule(item));
        return res;
      }),catchError(this.handleError('getAll')));
  }

	getAllRules(): Observable<any> {
		return this.http.get<any>(`${API_ADVANCED_REORDER_RULES_URL}/getAll`,
      { headers: this.helperService.getHTTPHeaders() })
			.pipe(map((res: any) => res.map(item => this.processRule(item))),
        catchError(this.handleError('getAllRules')));
	}

	getById(id: number): Observable<any> {
		return this.http.get(`${API_ADVANCED_REORDER_RULES_URL}/${id}`,
      { headers: this.helperService.getHTTPHeaders() })
      .pipe(map((res: any) => this.processRule(res)),
        catchError(this.handleError(`getById id=${id}`)));
	}

	create(advReorderRule: AdvancedReorderRuleModel): Observable<any> {
    this.updateRule(advReorderRule);
		return this.http.post(API_ADVANCED_REORDER_RULES_URL, advReorderRule,
			{ headers: this.helperService.getHTTPHeaders() })
      .pipe(catchError(this.handleError<any>('create')));
	}

	update(advReorderRule: AdvancedReorderRuleModel): Observable<any> {
    this.updateRule(advReorderRule);
		return this.http.put(API_ADVANCED_REORDER_RULES_URL, advReorderRule,
			{ headers: this.helperService.getHTTPHeaders() })
      .pipe(catchError(this.handleError<any>('update')));
	}

	makeDefault(id: number): Observable<any> {
		return this.http.patch(`${API_ADVANCED_REORDER_RULES_URL}/${id}`, null,
			{ headers: this.helperService.getHTTPHeaders() })
      .pipe(catchError(this.handleError<any>('makeDefault')));
	}

	delete(id: number, force: boolean = false) {
		return this.http.delete(`${API_ADVANCED_REORDER_RULES_URL}/${id}`,
			{ headers: this.helperService.getHTTPHeaders(), params: { force: force } })
      .pipe(catchError(this.handleError('delete')));
	}

  private processRule(rule: any) {
    rule.minusAllInventory = rule.minusInventory == 'true';
    if (!rule.minusAllInventory && rule.minusInventory)
      rule.minusLocations = rule.minusInventory.split(',').map(id => Number(id));
    rule.minusAllStoresInventory = rule.minusStoreInventory == 'true';
    if (!rule.minusAllStoresInventory && rule.minusStoreInventory)
      rule.minusStores = rule.minusStoreInventory.split(',').map(id => Number(id));
    rule.minusAllStoresOverstockedInventory = rule.minusOverstockedStoreInventory == 'true';
    if (!rule.minusAllStoresOverstockedInventory && rule.minusOverstockedStoreInventory)
      rule.minusOverstockedStores = rule.minusOverstockedStoreInventory.split(',').map(id => Number(id));
    return rule;
  }

  private updateRule(rule: AdvancedReorderRuleModel) {
    if (rule.minusAllInventory)
      rule.minusInventory = 'true';
    else if (rule.minusLocations.length)
      rule.minusInventory = rule.minusLocations.join(',');
    else
      delete rule.minusInventory;
    if (rule.minusAllStoresInventory)
      rule.minusStoreInventory = 'true';
    else if (rule.minusStores.length)
      rule.minusStoreInventory = rule.minusStores.join(',');
    else
      delete rule.minusStoreInventory;
    if (rule.minusAllStoresOverstockedInventory)
      rule.minusOverstockedStoreInventory = 'true';
    else if (rule.minusOverstockedStores.length)
      rule.minusOverstockedStoreInventory = rule.minusOverstockedStores.join(',');
    else
      delete rule.minusOverstockedStoreInventory;
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      switch (error.status) {
        case 401:
          window.location.href = '/login';
          break;
        case 422:
          this.toastr.error(this.translate.instant('ADVANCED_REORDER_RULES.MESSAGES.ERRORS.' + error.error.reason, error.error));
          break;
        default:
          this.toastr.error(this.translate.instant('GENERAL.MESSAGES.ERROR'));
      }
      return throwError(error);
    };
  }
}