import * as _ from 'lodash';
import {PackingProcessingStatus} from 'src/app/_entities/orders/processing-statuses/packing-processing-status';
import {PickingProcessingStatus} from 'src/app/_entities/orders/processing-statuses/picking-processing-status';
import {ProcessingStatus} from 'src/app/_entities/orders/processing-statuses/processing-status';
import {ShippingProcessingStatus} from 'src/app/_entities/orders/processing-statuses/shipping-processing-status';
import ProcessingStatusHelper from 'src/app/_helpers/processing-status.helper';
import {OrderItemModel} from 'src/app/_models/orders/order-items/order-item.model';
import Product from '../products/product.entity';
import Order from './order.entity';
import {StatesHelper} from '../../states/states.helper';
import PickListItem from '../pick-lists/pick-list-item.entity';
import Warehouse from '../warehouses/warehouse.entity';

export default class OrderItem extends OrderItemModel {

    constructor(orderItem: OrderItemModel) {
        super();
        _.assignIn(this, orderItem);
    }

    get order(): Order {
        return new Order(
            StatesHelper.ordersEntitiesState
                .entities[this.orderId]
        );
    }

    get picklistItem() {
        const pli  = StatesHelper.picklistsEntitiesState
            .entitiesArray
            .find(pl => pl.items.some(pli => pli.orderItemIds.includes(this.id)))
            ?.items
            ?.find(item => item.orderItemIds.includes(this.id));
        return pli ? new PickListItem(pli) : undefined;
    }

    get productEntity(): Product {
        return new Product(
            StatesHelper
                .productsEntitiesState
                .entities[this.productId]
        );
    }

    get warehouse(): Warehouse {
        return StatesHelper.getEntity(StatesHelper.warehousesEntitiesState, this.warehouseId, Warehouse);
    }

    // Statuses
    get hasNoStatusYet() {
        return !!(ProcessingStatusHelper.getProcessingStatus(this).value & ProcessingStatus.None.value);
    }

    get isBeingPicked() {
        return ProcessingStatusHelper.getProcessingStatus(this).value >= PickingProcessingStatus.Picking.value;
    }

    get isPicked() {
        return ProcessingStatusHelper.getProcessingStatus(this).value >= PickingProcessingStatus.Picked.value;
    }

    get isBeingPacked() {
        return ProcessingStatusHelper.getProcessingStatus(this).value >= PackingProcessingStatus.Packing.value;
    }

    get isPacked() {
        return ProcessingStatusHelper.getProcessingStatus(this).value >= PackingProcessingStatus.Packed.value;
    }

    get isReadyToShip() {
        return this.isPacked;
    }

    get isPartiallyShipped() {
        return ProcessingStatusHelper.getProcessingStatus(this).value >= ShippingProcessingStatus.ShippedPartial.value;
    }

    get isShipped() {
        return ProcessingStatusHelper.getProcessingStatus(this).value >= ShippingProcessingStatus.Shipped.value;
    }

    get isDelivered() {
        return false; // TODO
    }

    get latestProcessingStatus() {
        return ProcessingStatusHelper.getProcessingStatus(this);
    }
}
