import { ProductType } from "src/app/_enums/products/product-type.enum";
import { ProductLocationModel } from "./product-location.model";
import ProductWarehouseModel from "./product-warehouse.model";
import ProductSupplierModel from "./product-supplier.model";

export default class ProductModel {
  id: number;
  sku: string;
  upc: string;
  type: ProductType;
  splittingAllowed: boolean;
  quantityInCase?: number;
  parentId: number;
  subFraction?: number;
  isConvertibleToMain: boolean;
  packageDimensionsHeight: number;
  packageDimensionsWidth: number;
  packageDimensionsDepth: number;
  weight: number = 0;
  productLocations: ProductLocationModel[];
  productWarehouses: ProductWarehouseModel[];
  productSuppliers: ProductSupplierModel[]
  description: string;
  quantityAvailable: number;
  price: number;
  pricePlus: number;
  kitProductIds: number[];
  kitFractions: number[];
  asins: string[];
  fnskus: string[];
}