import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Store } from '@ngxs/store';
import UserState from '../states/user/user.state';
import { BehaviorSubject, from, Observable, throwError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { UserActions } from "../states/user/user.actions";
import { environment } from 'src/environments/environment';
import { UserStateModel } from '../states/user/user.state.model';
import { ADVANCED_REORDER_DISABLED_FOR_ORGS } from './advanced-reorder.service';
import { Router } from '@angular/router';

@Injectable()
export class HelperService {

  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private stateStore: Store,
    private translate: TranslateService,
    private toastr: ToastrService,
    private router: Router) { }

  get currentUser(): UserStateModel {
    return this.stateStore.selectSnapshot(UserState);
  }

  isLoggedIn(): boolean {
    return this.stateStore.selectSnapshot(UserState.isLoggedIn);
  }

  hasPermission(permissions: string[]) {
    return !permissions?.length || this.stateStore.selectSnapshot(UserState.hasPermission(permissions));
  }

  hasRole(roles: string[]) {
    return !roles?.length || this.stateStore.selectSnapshot(UserState.hasRole(roles));
  }

  get isActive() {
    return this.stateStore.selectSnapshot(UserState.isActive);
  }

  get exceededDays() {
    return this.stateStore.selectSnapshot(UserState.exceededDays);
  }

  get inactiveDays() {
    return this.stateStore.selectSnapshot(UserState.inactiveDays);
  }

  get subscriptionStatus() {
    return this.stateStore.selectSnapshot(UserState.subscriptionStatus);
  }

  getHTTPHeaders(contentType: string = 'application/json') {
    // create authorization header with user token
    if (this.isLoggedIn()) {
      let result = new HttpHeaders();
      if (contentType != 'multipart/form-data')
        result = result.set('Content-Type', contentType);
      result = result.set('Authorization', `Bearer ${this.currentUser.accessToken}`);
      return result;
    }
  }

  public onPageReady(func: () => void) {
    const afterStateReady = () => {
      if (document.readyState !== 'loading') {
        func();
        document.removeEventListener('readystatechange', afterStateReady);
      }
    };
    if (document.readyState === 'complete') {
      func();
    } else {
      document.addEventListener('readystatechange', afterStateReady);
    }
  }

  public onUserIsActive(func: () => void, onChange: () => Observable<unknown | unknown[]> = null): void {
    if (this.stateStore.selectSnapshot((UserState.isActive))) {
      func();
    }

    const originalTokenId = this.stateStore.selectSnapshot(UserState.getTokenId);
    this.stateStore.select(UserState.getTokenId).subscribe(tokenId => {
      (onChange ? onChange() : from(Promise.resolve())).subscribe(_ => {
        const isActive = this.stateStore.selectSnapshot(UserState.isActive);
        if (isActive && originalTokenId !== tokenId) {
          func();
        }
      });
    });
  }

  public ifUserIsActiveNow(func: () => void): void {
    this.onPageReady(() => {
      if (this.stateStore.selectSnapshot((UserState.isActive))) {
        func();
      }
    });
  }

  public redirectToLogin() {

    console.debug("redirectToLogin");

    this.stateStore.dispatch(new UserActions.Logout());
    const currentUrl = this.router.url;

    if (!currentUrl.includes('/login')) {
      this.router.navigate(['/login'], { queryParams: { returnUrl: currentUrl } });
    }
  }

  // Temp disable orgs from beta
  public isAdvancedReorderDisabledForOrganization() {
    return ADVANCED_REORDER_DISABLED_FOR_ORGS.includes(this.currentUser.organizationId);
  }

  /**@deprecated */
  public handleError<T>(operation = 'operation', result?: T) {
    return (response: any): Observable<T> => {
      switch (response.status) {
        case 401:
          this.redirectToLogin();
          break;
        case 422:
          const error = response.error;
          const message = error?.reason ? this.translate.instant('SETTING.MESSAGES.ERRORS.' + error.reason, error)
            : error?.message ? error.message
              : error && (typeof error === 'string' || error instanceof String) ? error
                : this.translate.instant('GENERAL.MESSAGES.ERROR');
          this.toastr.error(message, null, { timeOut: 0 });
          break;
        default:
          this.toastr.error(this.translate.instant('GENERAL.MESSAGES.ERROR'));
      }
      return throwError(response);
    };
  }

  public static devLog(content: any) {
    if (!environment.productionMode) {
      console.groupCollapsed(content);
      console.trace();
      console.groupEnd();
    }
  }
  public static devErr(content: string) {
    if (!environment.productionMode) {
      console.error(content);
    }
  }
}
