import {Injectable} from '@angular/core';
import {NgxsAfterBootstrap, NgxsSimpleChange, State} from '@ngxs/store';
import {StoreModel} from 'src/app/_models/store.model';
import {HelperService} from 'src/app/_services/helper.service';
import {RealTimeService} from 'src/app/_services/real-time-connection/real-time.service';
import StoreEntity from 'src/app/_entities/stores/store.entity';
import {StoresService} from 'src/app/_services/stores.service';
import {Computed, DataAction, StateRepository} from '@angular-ru/ngxs/decorators';
import {NgxsDataEntityCollectionsRepository} from '@angular-ru/ngxs/repositories';
import {createEntityCollections, EntityIdType} from '@angular-ru/cdk/entity';
import BaseEntityCollectionsOptions from '../base-entity-collections-options';
import EntityMap from '../../_builders/entity-map';
import {STORE_TYPES} from '../../_enums/stores/store-type.enum';

@StateRepository()
@State({
    name: 'stores',
    defaults: {
        ...createEntityCollections(),
        loading: false
    }
})
@Injectable()
export default class StoresEntitiesState
    extends NgxsDataEntityCollectionsRepository<StoreModel, EntityIdType, BaseEntityCollectionsOptions>
    implements NgxsAfterBootstrap {

    private _ngxsOnChange = (state?: StoresEntitiesState) => {};
    private _changeObserver: typeof this.state$;

    constructor(
        private realtimeService: RealTimeService,
        private helperService: HelperService,
        private storeService: StoresService,
    ) {
        super();
    }

    ngxsOnChanges(_?: NgxsSimpleChange) {
        super.ngxsOnChanges(_);
        if (this.isInitialised && !this._changeObserver) {
            this._changeObserver = this.state$;
            this._changeObserver.subscribe(() => this._ngxsOnChange(this));
        }
    }

    // Computed
    @Computed()
    public get storeEntities() {
        return this.entitiesArray
            .map(store => new StoreEntity(store));
    }

    @Computed()
    public get storesEntityMap(): EntityMap<StoreEntity> {
        return new EntityMap<StoreEntity>(this.ids
            .reduce((results, id) => {
                results[id] = new StoreEntity(this.entities[id]);
                return results;
            }, {}));
    }

    @Computed()
    public get byType() {
        return (type: typeof STORE_TYPES[number]) =>
            this.storeEntities
                .filter(store => store.type === type);

    }

    @Computed()
    public get pathToImage() {
        return (id: number) => {
            return `assets/media/icons/${this.entities[id]?.type.toLowerCase()}-icon.svg`;
        };
    }

    ngxsAfterBootstrap() {
        this.helperService.onUserIsActive(() => this.load());
    }

    // Action
    @DataAction()
    load() {
        this.patchState({loading: true});
        this.storeService.fetch().subscribe(stores => {
            this.setEntitiesAll(stores);
            this.patchState({ loading: false, loaded: true });
        });
    }

    @DataAction()
    lazyLoad() {
        !this.snapshot.loading &&
        !this.snapshot.loaded &&
        this.load();
    }

    public onStateChange(callback: typeof this._ngxsOnChange) {
        if (!this.snapshot.loading) {
            callback(this);
        }
        this._ngxsOnChange = callback;
    }
}
