import { BaseModel } from './_base.model';

export class StoreModel extends BaseModel {
  id: number;
  name: string;
  type: string;
  warehouseId: number;
  warehouseIdFba: number;
  currencyId: number;
  prefix: string;
  favorite: boolean = true;
  syncOrNot: boolean = true;
  onlyActiveProducts: boolean = true;
  deductonstatus: number = 6
}
