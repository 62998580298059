import { Injectable } from '@angular/core';
import { LinkRulesService } from '../_services/settings/link-rules.service';
import { LinkRuleModel } from '../_models/link-rule.model';

@Injectable()
export class LinkRules {
  linkRules: LinkRuleModel[];
  loaded: boolean = false;
  loadRequested: boolean = false;
  callBacks: Function[] = [];

  constructor(private linkRulesService: LinkRulesService) { }

  getAll(callBack: Function = null) {
    if (!this.loaded)
      this.reload(callBack);
    else if (callBack)
      callBack(this.linkRules);
    return this.linkRules;
  }

  reload(callBack: Function = null) {
    if (callBack)
      this.callBacks.push(callBack);
    if (!this.loadRequested) {
      this.loadRequested = true;
      this.linkRulesService.getAll().subscribe((result) => {
        this.linkRules = result;
        this.loaded = true;
        for (let calback of this.callBacks) {
          calback(result);
          this.callBacks = this.callBacks.filter(cb => cb != calback);
        }
        this.loadRequested = false;
      });
    }
  }
}