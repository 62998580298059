import { Injectable } from '@angular/core';
import { TagModel } from '../_models/tag.model';
import { TagsService } from '../_services/settings/tags.service';

@Injectable()
export class Tags {
  tags: TagModel[] = [];
  loaded: boolean = false;
  loadRequested: boolean = false;
  callBacks: Function[] = [];

  constructor(private tagsService: TagsService) { }

  getAll(callBack: Function = null) {
    if (!this.loaded)
      this.reload(callBack);
    else if (callBack)
      callBack(this.tags);
    return this.tags;
  }

  reload(callBack: Function = null) {
    if (callBack)
      this.callBacks.push(callBack);
    if (!this.loadRequested) {
      this.loadRequested = true;
      this.tagsService.getAllTags().subscribe(result => {
        this.tags = result.map(tag => ({ ...tag, tagId: tag.id }));
        this.loaded = true;
        for (let callback of this.callBacks) {
          callback(result);
          this.callBacks = this.callBacks.filter(cb => cb != callback);
        }
        this.loadRequested = false;
      });
    }
  }
}