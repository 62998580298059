import {NgxsDataEntityCollectionsRepository} from '@angular-ru/ngxs/repositories';
import {CurrencyModel} from '../../_models/currency.model';
import BaseEntityCollectionsOptions from '../base-entity-collections-options';
import {State} from '@ngxs/store';
import {createEntityCollections, EntityIdType} from '@angular-ru/cdk/entity';
import {Computed, DataAction, Payload, StateRepository} from '@angular-ru/ngxs/decorators';
import {Injectable} from '@angular/core';
import {CommonService} from '../../_services/common.service';
import {HelperService} from '../../_services/helper.service';


@StateRepository()
@State({
    name: 'currencies',
    defaults: {
        ...createEntityCollections(),
        loading: false
    }
})
@Injectable()
export class CurrenciesEntitiesState
    extends NgxsDataEntityCollectionsRepository<CurrencyModel, EntityIdType, BaseEntityCollectionsOptions> {

    constructor(
        private commonService: CommonService,
        private helperService: HelperService
    ) {
        super();
    }

    // Computed
    @Computed()
    public get loading(): boolean {
        return this.snapshot.loading;
    }

    ngxsAfterBootstrap() {
        super.ngxsAfterBootstrap();
        this.helperService.onUserIsActive(() => {
            this.load();
        });
    }

    // Actions
    @DataAction()
    public load() {
        this.patchState({loading: true});
        this.commonService
            .fetchCurrencies()
            .subscribe(results => {
                this.setAll(results);
                this.patchState({ loading: false, loaded: true });
            });
    }
}
