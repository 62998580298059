import { Component, ContentChild, ContentChildren, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output, TemplateRef, forwardRef } from "@angular/core";
import { DatatableComponent } from "../datatable.component";
import { UntypedFormBuilder, UntypedFormControl, ValidatorFn, Validators } from "@angular/forms";
import { HelperService } from "src/app/_services/helper.service";
import { CurrencyPipe, DatePipe, DecimalPipe, PercentPipe, TitleCasePipe } from "@angular/common";
import { ColumnTemplateDirective } from "../directives/columnTemplate.directive";
import { LookupNotFoundTemplateDirective } from "../directives/lookupNotFound.directive";
import { TitleTemplateDirective } from "../directives/titleTemplate.directive";

@Component({
    selector: 'datacolumn',
    template: `<ng-container></ng-container>`
})
export class Datacolumn implements OnInit {
    [x: string]: any;
    @Input() name: string;
    @Input() custom: string;
    @Input() title: string;
    @Input() width = 100;
    @Input() type: string;
    @Input() visible = true;
    @Input() freezed: string;
    @Input() allowEdit = true;
    @Input() subDatatableColumn = false;
    @Input() align: string;
    @Input() lookupItems: any[];
    @Input() bindLabel: string;
    @Input() bindValue: string;
    @Input() required = false;
    @Input() ngClass: string;
    @Input() permissions: string[];
    @Input() columns: string[];
    @Output() changed: EventEmitter<any> = new EventEmitter();
    @ContentChild(ColumnTemplateDirective, { read: TemplateRef }) template: TemplateRef<any>;
    @ContentChild(LookupNotFoundTemplateDirective, { read: TemplateRef }) lookupNotFoundTemplate: TemplateRef<any>;
    @ContentChild(TitleTemplateDirective, { read: TemplateRef }) titleTemplate: TemplateRef<any>;
    @ContentChildren(forwardRef(() => Datacolumn)) private _subColumns: Datacolumn[];
    formCtrls: UntypedFormControl[] = [];
    validators: ValidatorFn[] = [];
    reg = new RegExp(/(?=[A-Z])/);
    filterType: string;
    columnIndex: number;
    hasPermissions: boolean;

    get subColumns(): Datacolumn[] {
        return this._subColumns.filter(col => col !== this);
    }

    constructor(@Inject(LOCALE_ID) private _locale: string,
        private formBuilder: UntypedFormBuilder,
        private helperService: HelperService,
        private datatable: DatatableComponent) { }

    ngOnInit() {
        if (this.required === true) {
            this.validators.push(Validators.required);
        }
        switch (this.type) {
            case 'number':
            case 'currency':
            case 'percent':
                this.validators.push(Validators.pattern(/^\d+(\.\d+)?$/));
                break;
        }
    }

    get hasPermission() {
        if (this.hasPermissions === undefined) {
            if (this.permissions && this.permissions.length)
                this.hasPermissions = this.helperService.hasPermission(this.permissions);
            else
                this.hasPermissions = true;
        }
        return this.hasPermissions;
    }

    change(value: any, data: any) {
        data.dataChanged = true;
        this.changed.emit({ value, data });
    }

    getFormControl(index) {
        const newIndex = (index + 1) - this.formCtrls.length;
        const editing = this.allowEdit && ((this.datatable._items[index] && this.datatable._items[index].editing)
            || (this.datatable.editing && this.subDatatableColumn === false));
        if (newIndex > 0) {
            for (let i = 0; i < newIndex; i++) {
                const ctrl = this.formBuilder.control(i === newIndex - 1 && this.datatable._items[index] ?
                    this.getValue(this.datatable._items[index], editing) : null, editing ? this.validators : []);
                this.datatable.formGroup.addControl(this.name + '_' + this.formCtrls.length, ctrl);
                this.formCtrls.push(ctrl);
            }
        }
        const newItemsCount = (this.datatable._items || []).length - this.formCtrls.length;
        if (newItemsCount < 0) {
            for (let i = this.formCtrls.length + newItemsCount; i < this.formCtrls.length; i++) {
                this.datatable.formGroup.removeControl(this.name + '_' + i);
            }
            this.formCtrls = this.formCtrls.slice(0, this.formCtrls.length + newItemsCount);
        }
        if (editing && (this.formCtrls[index].validator || []).length === 0 && (this.validators || []).length > 0) {
            this.formCtrls[index].setValidators(this.validators);
        }
        if (!editing && this.formCtrls[index] && (this.formCtrls[index].validator || []).length > 0) {
            this.formCtrls[index].setValidators([]);
        }
        return this.formCtrls[index];
    }

    getValue(item: any, editing: boolean) {
        const value = item[this.name];
        if (editing) {
            if (this.type === 'percent') {
                return value ? value * 100 : value;
            } else {
                return value;
            }
        } else {
            switch (this.type) {
                case 'currency':
                    return new CurrencyPipe(this._locale).transform(value);
                case 'date':
                    return new DatePipe(this._locale).transform(value);
                case 'number':
                    return new DecimalPipe(this._locale).transform(value, '1.0-0');
                case 'percent':
                    return new PercentPipe(this._locale).transform(value);
                case 'title':
                    return new TitleCasePipe().transform(value.split(this.reg).join(' '));
                case 'boolean':
                    return value === true ? 'Yes' : 'No';
                case 'lookup':
                    return this.lookupItems && value != null ? (this.lookupItems.find(p => p.id === value)[this.bindLabel] || value) : value;
                default:
                    return value;
            }
        }
    }

    getLookupIndex(rowIndex: number, columns: Datacolumn[]) {
        const lookups = columns.filter(c => c.visible === true && c.type === 'lookup' && c.allowEdit === true);
        return lookups.indexOf(this) + (rowIndex * lookups.length);
    }
}