import { inject } from "@angular/core";
import { ResolveFn } from "@angular/router";
import { forkJoin, tap } from "rxjs";
import { HelperService } from "src/app/_services/helper.service";
import ProductsEntitiesState from "src/app/states/products/products-entities.state";
import StoreProductsEntitiesState from "src/app/states/products/store-products/store-products-entities.state";

export const SelfShipResolver: ResolveFn<Object> = (route, state) => {
    const helperService = inject(HelperService);
    helperService.loading$.next(true);
    return forkJoin([
        inject(ProductsEntitiesState).initialLoad(),
        inject(StoreProductsEntitiesState).initialLoad()
    ]).pipe(tap(() => {
        helperService.loading$.next(false);
    }));
};