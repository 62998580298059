import { OrderProcessingStatus } from "./order-processing-status";
import { PackingProcessingStatus } from "./packing-processing-status";
import { PickingProcessingStatus } from "./picking-processing-status";
import { ProcessingStatus } from "./processing-status";
import { ShippingProcessingStatus } from "./shipping-processing-status";

export const SELFSHIP_ORDER_FILTERS: OrderProcessingStatus[] = [
    ProcessingStatus.Open,
    PickingProcessingStatus.Picking,
    PickingProcessingStatus.Picked,
    PackingProcessingStatus.Packing,
    PackingProcessingStatus.Packed,
    ShippingProcessingStatus.ShippedPartial,
    ShippingProcessingStatus.Shipped,
];

export const IN_PROCESS_FILTERS = [
    PickingProcessingStatus.Picking,
    PickingProcessingStatus.Picked,
    PackingProcessingStatus.Packing,
];