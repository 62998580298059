import { Validators } from "@angular/forms";
import { Patterns } from "./patterns";

export class CustomValidators {

    private constructor(){}

    public static generateUrlPatern(httpsOnly = false, domainOnly = false, wholeStr = false) {
        const schema = httpsOnly ? Patterns.URL_HTTPS_PATTERN : Patterns.URL_SCHEAMA_PATTERN;
        const pathAndQuery = !domainOnly ? Patterns.URL_PATH_N_QUERY_PATTERN : '';
        const pattern = `${schema}${Patterns.URL_DOMAIN_PATTERN}${pathAndQuery}`
        return wholeStr ? `^(${pattern})$` : pattern;
    }

    public static generatePaternRegex(pattern: string) {
        return new RegExp(pattern);
    }

    public static getUrlValidator(httpsOnly = false, domainOnly = false, wholeWord = false) {
        return Validators.pattern(this.generatePaternRegex(this.generateUrlPatern(httpsOnly, domainOnly, wholeWord)));
    }

    public static getDiscountRateValidator() {
        return Validators.pattern(CustomValidators.generatePaternRegex(Patterns.DISCOUNT_RATE_PATTERN));
    }

    public static getPositiveNumberValidator() {
        return Validators.pattern(CustomValidators.generatePaternRegex(Patterns.POSITIVE_NUMBER_PATTERN));
    }
}