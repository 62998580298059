import { Injectable } from "@angular/core";

@Injectable()
export default class PrintHtmlPageService {
    constructor() { }

    htmlPrintingObject: HTMLElement;

    reset() {
        this.htmlPrintingObject = undefined;
    }
}