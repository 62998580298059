import * as _ from 'lodash';
import ShipmentModel from 'src/app/_models/self-ship/shipping/shipment.model';
import OrderBox from '../orders/order-boxes/order-box.entity';
import {StatesHelper} from '../../states/states.helper';

export class Shipment extends ShipmentModel {

    public readonly totalLabelCost: number;

    constructor(shipment: ShipmentModel) {
        super();
        _.assignIn(this, shipment);
        this.totalLabelCost = this.shippingLabels?.reduce((a, b) => a + b.cost, 0);
    }

    get orderBoxes(): OrderBox[] {
        return StatesHelper.ordersEntitiesState
            .entitiesArray
            .flatMap(order => order.boxes)
            .filter(ob => this.orderBoxIds.includes(ob.id))
            .map(ob => new OrderBox(ob));
    }

    get totalWeight() {
        return this.orderBoxes
            .map(box => box.weight)
            .reduce((result, weight) => result + weight, 0)
    }

    get dimensions() {
        const orderBoxes = this.orderBoxes;
        if (this.orderBoxes.length > 1) {
            return 'Multiple';
        }
        return `${orderBoxes[0]?.width || '--'} x ${orderBoxes[0]?.height || '--'} x ${orderBoxes[0]?.length || '--'}, ${orderBoxes[0]?.weight || '--'} Lb`;
    }
}
