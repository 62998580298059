import {
  Component, EventEmitter, Output, Input, ViewChildren, QueryList, ContentChildren,
  ContentChild, TemplateRef, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { GridColumnModel } from 'src/app/_models/grid-column.model';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragEnd } from '@angular/cdk/drag-drop';
import { Datacolumn } from './datacolumn/datacolumn.component';
import { SortableHeader } from './directives/sortableHeader.directive';
import { SubRowTemplateDirective } from './directives/subRowTemplate.directive';
import _ from 'lodash';
import { MatCheckboxChange } from '@angular/material/checkbox';

export type SortDirection = 'asc' | 'desc' | '';

const STRING_FILTER_TYPES = [undefined, 'title', 'template'];
const NUMBER_FILTER_TYPES = ['number', 'currency', 'percent', 'date'];

export interface SortEvent {
  column: string;
  direction: SortDirection;
}

@Component({
  selector: 'datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss'],
})
export class DatatableComponent implements OnInit, AfterViewInit {

  @Input() set items(items: any[]) {
    this._items = items || [];
    this.expandedRowsHeight = 0;
  }

  @Input() set gridColumns(gridColumns: GridColumnModel[]) {
    if (gridColumns && this._columns) {
      const columns: Datacolumn[] = [];
      for (let gCol of gridColumns) {
        const dataColumn = this._columns.find(col => col.columnIndex === gCol.columnIndex && !columns.includes(col));
        if (dataColumn) {
          dataColumn.visible = gCol.visible;
          dataColumn.width = gCol.width;
          columns.push(dataColumn);
        }
      }
      for (let newCol of this._columns['_results']) {
        const dataColumn = this._columns.find(col => col.columnIndex === newCol.columnIndex && !columns.includes(col));
        if (dataColumn) {
          dataColumn.visible = false;
          dataColumn.width = newCol.width;
          columns.push(dataColumn);
        }
      }
      this._columns.reset(columns);
    }
  }

  @Input() parentKey: number;
  @Input() key = 'id';
  @Input() pageSize = 20;
  @Input() sort: string;
  @Input() sortField: string;
  @Input() groupingField: string;
  @Input() total: number;
  @Input() page = 1;
  @Input() loading = true;
  @Input() editing = false;
  @Input() isExpandable = false;
  @Input() subsCountField: string;
  @Input() selectable = true;
  @Input() showRowNumber: boolean = false;
  @Input() columnResizable = true;
  @Input() showFilters = true;
  @Input() columnsChooser = true;
  @Input() columnsSorting = true;
  @Input() expandedColumn: string;
  @Input() sorting = true;
  @Input() formGroup: UntypedFormGroup;
  @Input() assignedTo: any;
  @Input() headerStickyMargin: number = 80;
  @Input() headerHeight: number;
  @Input() rowHeight: number;
  @Input() maxVisibleRows: number = 20;
  @Input() minBufferPx: number = 1600;
  @Input() maxBufferPx: number = 10000;
  @Input() border = true;
  @Input() selectedItems: any[] = [];
  @Input() disableSelect: any[] = [];
  @Input() showSelectAllHeader: boolean = false;
  @Output() selectedChanged: EventEmitter<any[]> = new EventEmitter();
  @Output() loadRequested: EventEmitter<any> = new EventEmitter();
  @Output() filtersChanged: EventEmitter<[]> = new EventEmitter();
  @Output() columnsChanged: EventEmitter<GridColumnModel[]> = new EventEmitter();
  @Output() rowClick: EventEmitter<any> = new EventEmitter();
  @Output() columnsExpand: EventEmitter<any> = new EventEmitter();
  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;
  @ContentChildren(Datacolumn, { descendants: false, read: Datacolumn }) set columns(columns: QueryList<Datacolumn>) {
    this._columns = _.clone(columns);
    this._columns.forEach((col, i) => col.columnIndex = i);
    this.updateWidth();
  };
  @ContentChild(SubRowTemplateDirective, { read: TemplateRef }) subRowTemplate: TemplateRef<any>;
  @ViewChild('datatable') datatable: ElementRef;
  @ViewChild('tableHeader') tableHeader: ElementRef;
  @ViewChild('expandedColumnHeader') expandedColumnHeader: ElementRef;

  _columns: QueryList<Datacolumn>;
  math = Math;
  filters = {};
  _items: any[] = [];
  highlightedRow: number;
  pageX: number;
  resizedCol: Datacolumn;
  curCol: HTMLElement;
  curColWidth: number;
  columnsExpanded: boolean = true;
  lastGroupValue: any;
  subRowHeights: any = {};
  expandedRows: number[] = [];
  avgRowHeight: number;
  rightWidth = 0;
  leftWidth = 0;
  expandedRowsHeight = 0;

  constructor(private formBuilder: UntypedFormBuilder) { }

  ngAfterViewInit() {
    setTimeout(() => this.updateWidth(), 1000);
  }

  ngOnInit() {
    if (this.formGroup == null) {
      this.formGroup = this.formBuilder.group({});
    }
    if (this.assignedTo) {
      this.assignedTo.datatable = this;
    }
    if (this.columnResizable) {
      document.addEventListener('mousemove', e => this.resizerMouseMove(e));
      document.addEventListener('mouseup', e => this.resizerMouseUp(e));
      window.addEventListener('resize', e => this.refreshAllSubRows());
    }
    this.avgRowHeight = this.rowHeight || 100;
  }

  refreshAllSubRows() {
    for (let i of this.expandedRows)
      this.refreshSubRows(i);
  }

  refreshSubRows(index) {
    const leftSubRow = this.datatable.nativeElement.querySelector(`#subsLeft${index}`) as HTMLElement;
    const datatable = this.datatable.nativeElement as HTMLElement;
    const contentSubRow = this.datatable.nativeElement.querySelectorAll(`#subs${index}, #subsRight${index}`) as NodeListOf<HTMLElement>;
    const leftContent = this.datatable.nativeElement.querySelector('.datatable-left.content') as HTMLElement;
    if (leftSubRow) {
      leftSubRow.style.marginRight = `-${datatable?.offsetWidth - leftContent?.offsetWidth}px`;
      leftSubRow.style.width = `${datatable?.offsetWidth}px`;
      setTimeout(() => contentSubRow?.forEach(r => r.style.height = `${leftSubRow.offsetHeight}px`), 1);
    }
  }

  getGroupValue(item: any) {
    this.lastGroupValue = item[this.groupingField];
    return item[this.groupingField];
  }

  sum(position: string, field: string) {
    return this._columns.filter((c) => c.hasPermission && c.visible && ((position == 'center' && !c.freezed) || c.freezed == position) && !c.subDatatableColumn)
      .reduce((sum, current) => sum + (current.subColumns.reduce((subSum, subCurrent) => subSum + subCurrent.width, 0) || current.width), 0);
  }

  setSubRowHeight(item: any, height: number) {
    this.subRowHeights[item] = height;
    return height;
  }

  getHeaderHeight() {
    if (this.datatable) {
      (<HTMLElement>this.datatable.nativeElement.querySelector(`.datatable-center > .datatable-row`)).style.height = 'auto';
      (<HTMLElement>this.datatable.nativeElement.querySelector(`.datatable-left > .datatable-row`)).style.height = 'auto';
      (<HTMLElement>this.datatable.nativeElement.querySelector(`.datatable-right > .datatable-row`)).style.height = 'auto';
      let height = (<HTMLElement>this.datatable.nativeElement.querySelector(`.datatable-center > .datatable-row`)).offsetHeight;
      let height2 = (<HTMLElement>this.datatable.nativeElement.querySelector(`.datatable-left > .datatable-row`)).offsetHeight;
      if (height2 > height)
        height = height2;
      height2 = (<HTMLElement>this.datatable.nativeElement.querySelector(`.datatable-right > .datatable-row`)).offsetHeight;
      if (height2 > height)
        height = height2;
      (<HTMLElement>this.datatable.nativeElement.querySelector(`.datatable-center > .datatable-row`)).style.height = `${height}px`;
      (<HTMLElement>this.datatable.nativeElement.querySelector(`.datatable-left > .datatable-row`)).style.height = `${height}px`;
      (<HTMLElement>this.datatable.nativeElement.querySelector(`.datatable-right > .datatable-row`)).style.height = `${height}px`;
    }
  }

  getGridColumns(includeTemplate: boolean = true) {
    const gridCols: GridColumnModel[] = [];
    this._columns.forEach((datacol) => {
      const col = new GridColumnModel();
      col.columnIndex = datacol.columnIndex;
      col.permissions = datacol.permissions;
      col.name = datacol.name;
      col.width = datacol.width;
      col.visible = String(datacol.visible) !== 'false';
      if (includeTemplate)
        col.titleTemplate = datacol.titleTemplate;
      col.freezed = datacol.freezed;
      col.columns = datacol.columns;
      gridCols.push(col);
    });
    return gridCols;
  }

  pageChanged(e: any) {
    this.loadRequested.emit({
      parentKey: this.parentKey, page: this.page, pageSize: this.pageSize,
      sort: this.sort, sortField: this.sortField, filters: this.filters
    });
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
    this.loadRequested.emit({
      parentKey: this.parentKey, page: this.page, pageSize: this.pageSize,
      sort: direction, sortField: (column && direction ? column : ''), filters: this.filters
    });
  }

  filter(e: any, col: Datacolumn) {
    if (!col.filterType) {
      if (col.type === 'currency' || col.type === 'number' || col.type === 'percent' || col.type === 'date')
        col.filterType = 'Equal';
      else
        col.filterType = 'Contains';
    }
    if (e.target.value)
      this.filters[col.name] = { type: col.filterType, dataType: col.type, value: e.target.value };
    else
      delete this.filters[col.name];
    this.loadRequested.emit({
      parentKey: this.parentKey, page: this.page, pageSize: this.pageSize,
      sort: this.sort, sortField: this.sortField, filters: this.filters
    });
  }

  selectAll(e: MatCheckboxChange) {
    this.selectedItems.splice(0);
    if (e.checked) {
      this._items.forEach(item => {
        if (!this.disableSelect.includes(item)) {
          this.selectedItems.push(item);
          if (item.subItems && Array.isArray(item.subItems.items)) {
            item.subItems.items.forEach(subItem => {
              if (!this.disableSelect.includes(subItem))
                this.selectedItems.push(subItem);
            });
          }
        }
      });
    }
    this.selectedItems = [...this.selectedItems];
    this.selectedChanged.emit(this.selectedItems);
  }

  isSelected(item: any) {
    return this.selectedItems.some(si => this.key.split(',').every(k => si[k.trim()] == item[k.trim()]));
  }

  select(e: MatCheckboxChange, data) {
    if (e.checked) {
      this.selectedItems.push(data);
    } else {
      this.selectedItems.splice(this.selectedItems.indexOf(data), 1);
    }
    this.selectedItems = [...this.selectedItems];
    this.selectedChanged.emit(this.selectedItems);
  }

  expand(e: any, item: any, index) {
    const expandedIndex = Number(e.currentTarget?.getAttribute('data-index') ?? index);
    if (!this.expandedRows.includes(expandedIndex)) {
      this.expandedRows.push(expandedIndex);
      const callback = this.subRowTemplate ? () => this.refreshSubRows(expandedIndex) : () => { };
      setTimeout(() => {
        const el = document.getElementById('subsLeft' + index);
        this.expandedRowsHeight += el.offsetHeight;
      }, 500);
      if (item.subItems === undefined) {
        let parentKey = this.key.split(',').map(k => item[k.trim()]);
        if (parentKey.length === 1) {
          parentKey = parentKey[0];
        }
        this.loadRequested.emit({
          parentKey: parentKey, page: this.page, pageSize: this.pageSize,
          sort: this.sort, sortField: this.sortField, filters: {}, callback: callback
        });
      } else {
        callback();
      }
    } else {
      const el = document.getElementById('subsLeft' + index);
      if ((this.expandedRowsHeight - el.offsetHeight) > 0) {
        this.expandedRowsHeight -= el.offsetHeight;
      } else {
        this.expandedRowsHeight = 0;
      }
      this.expandedRows = this.expandedRows.filter(i => i != expandedIndex);
    }
    this.expandedRows = [...this.expandedRows];
    return false;
  }

  updateWidth() {
    this.rightWidth = this._columns.filter(col => col.hasPermission && col.visible === true && col.freezed === 'right').reduce((a, b) => a + Number(b.width), 0);
    this.leftWidth = this._columns.filter(col => col.hasPermission && col.visible === true && col.freezed === 'left').reduce((a, b) => a + Number(b.width), 0);
  }

  valueChanged(e: any, item: any, col: Datacolumn) {
    switch (col.type) {
      case 'percent':
        item[col.name] = parseFloat(e.target.value) / 100;
        col.change(item[col.name], item);
        break;
      case 'lookup':
        item[col.name] = col.bindValue && e ? e[col.bindValue] : e;
        col.change(e, item);
        break;
      default:
        item[col.name] = e.target.value;
        col.change(item[col.name], item);
        break;
    }
  }

  openLookup(index: number) {
    setTimeout(() => {
      const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const selectPosition = this.datatable.nativeElement.querySelectorAll('ng-select[appendto="#external"]')[index].getBoundingClientRect();
      const panelStyle = (this.datatable.nativeElement.getElementsByTagName('ng-dropdown-panel')[0] as HTMLElement).style;
      panelStyle.left = `${selectPosition.left + scrollLeft}px`;
      panelStyle.top = `${selectPosition.top + scrollTop + selectPosition.height}px`;
    }, 1);
  }

  rowClicked(e: any, data: any, col: Datacolumn) {
    if (e.target.tagName != 'A' && e.target.tagName != 'BUTTON' && (!e.target.parentElement ||
      (e.target.parentElement.tagName != 'A' && e.target.parentElement.tagName != 'BUTTON')))
      this.rowClick.emit({ event: e, data: data, column: col });
  }

  isValid() {
    if (this.editing) {
      return false;
    }
  }

  resizerMouseDown(e: any, col: Datacolumn) {
    this.resizedCol = col;
    this.curCol = e.target.parentElement;
    this.pageX = e.pageX;
    this.curColWidth = this.curCol.offsetWidth;
    this.headers.forEach(header => header.resizing = true);
  }

  resizerMouseMove(e: any) {
    if (this.curCol) {
      const diffX = e.pageX - this.pageX;
      const newWidth = this.curColWidth + diffX;
      if (newWidth >= 30) {
        this.resizedCol.width = newWidth;
        const colClass = this.curCol.className.split(' ').find(i => i.startsWith('col'));
        (this.datatable.nativeElement.querySelectorAll(`.${colClass}`) as NodeListOf<HTMLElement>)
          .forEach(col => col.style.width = newWidth + 'px');

        this.updateWidth();
      }
    }
  }

  resizerMouseUp(e: any) {
    setTimeout(() => this.headers.forEach(header => header.resizing = false), 1);
    if (this.resizedCol) {
      this.columnsChanged.emit(this.getGridColumns());
    }
    this.resizedCol = undefined;
    this.curCol = undefined;
    this.pageX = undefined;
    this.curColWidth = undefined;
  }

  dragEnded(e: CdkDragEnd<any>) {
    e.source.element.nativeElement.style.removeProperty('transform');
  }

  drop(event: CdkDragDrop<Datacolumn[]>, visible: boolean = true) {
    if (event.container === event.previousContainer) {
      const columns = this._columns.toArray();
      moveItemInArray(columns, event.previousIndex, event.currentIndex);
      this._columns.reset(columns);
    } else {
      event.previousContainer.data[event.previousIndex].visible = visible;
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
    this.columnsChanged.emit(this.getGridColumns());
  }

  showStringFilter(colType: string) {
    return STRING_FILTER_TYPES.includes(colType);
  }

  showNumberFilter(colType: string) {
    return NUMBER_FILTER_TYPES.includes(colType);
  }

  changeFilterType(e: any, col: Datacolumn) {
    const filterType = e.target.getAttribute('data-type');
    e.target.parentElement.parentElement.children[0].children[0].className = e.target.children[0].className;
    col.filterType = filterType;
    const filter = this.filters[col.name];
    if (filter) {
      filter.type = filterType;
      this.loadRequested.emit({
        parentKey: this.parentKey, page: this.page, pageSize: this.pageSize,
        sort: this.sort, sortField: this.sortField, filters: this.filters
      });
    }
  }

  scroll(e) {
    this.tableHeader.nativeElement.scrollTo({ left: e.srcElement.scrollLeft });
    this.expandedColumnHeader?.nativeElement?.scrollTo({ left: e.srcElement.scrollLeft });
  }

  expandColumns() {
    this.columnsExpanded = !this.columnsExpanded;
    this.columnsExpand.emit(this.columnsExpanded);
  }
}
