import {Injectable} from '@angular/core';
import {NgxsOnChanges, NgxsSimpleChange, State} from '@ngxs/store';
import WarehousesFiltersModel from 'src/app/_models/warehouses/warehouses-filters.model';
import WarehousesEntitiesState from '../warehouses-entities.state';
import {Computed, DataAction, StateRepository} from '@angular-ru/ngxs/decorators';
import {NgxsDataRepository} from '@angular-ru/ngxs/repositories';
import WarehouseCart from '../../../_entities/warehouses/carts/warehouse-cart.entity';
import Warehouse from '../../../_entities/warehouses/warehouse.entity';

@StateRepository()
@State<WarehousesFiltersModel>({
    name: 'warehousesFilters',
    defaults: new WarehousesFiltersModel(),
})
@Injectable()
export default class WarehousesFiltersState
    extends NgxsDataRepository<WarehousesFiltersModel>
    implements NgxsOnChanges {

    private _ngxsOnChange = (state?: WarehousesFiltersState) => {};
    private _changeObserver$: typeof this.state$;

    constructor(
        private warehouseEntitiesState: WarehousesEntitiesState
    ) {
        super();
    }

    ngxsOnChanges(_?: NgxsSimpleChange) {
        super.ngxsOnChanges(_);
        if (this.isInitialised && !this._changeObserver$) {
            this._changeObserver$ = this.state$;
            this._changeObserver$.subscribe(() => this._ngxsOnChange(this));
        }
    }

    // Computed
    @Computed()
    public get selectedWarehouse() {
        const warehouse = this.warehouseEntitiesState
            .entities[this.snapshot.selectedWarehouseId]
                ?? this.warehouseEntitiesState.entitiesArray[0];
        if (!warehouse) return;

        return new Warehouse(warehouse);
    }

    @Computed()
    public get selectedWarehouseBinCount() {
        return this.selectedWarehouse?.warehouseCarts?.flatMap(cart => cart.bins)?.length ?? 0;
    }

    @Computed()
    public get selectedCart() {
        const warehouseEntitiesState = this.warehouseEntitiesState;
        const warehouse = warehouseEntitiesState.entities[this.snapshot.selectedWarehouseId]
            ?? warehouseEntitiesState.entitiesArray[0];
        if (!warehouse) return;
        const cart = warehouse.warehouseCarts
            .find(cart => cart.id === this.snapshot.selectedCartId);
        if (!cart) return;

        return new WarehouseCart(cart);
    }

    @Computed()
    public get selectedBin() {
        return this.selectedCart?.warehouseCartBinEntities?.find(bin => bin.id === this.snapshot.selectedBinId);
    }

    public isCartSelected(cartId: number) {
        return this.snapshot.selectedCartId === cartId;
    }

    public isBinSelected(binId: number) {
        return this.snapshot.selectedBinId === binId;
    }

    // Actions
    @DataAction()
    public selectWarehouse(warehouseId: number) {
        if (this.snapshot.selectedWarehouseId !== warehouseId) {
            this.patchState({
                selectedWarehouseId: warehouseId,
                selectedCartId: undefined,
                selectedBinId: undefined
            });
        }
    }

    @DataAction()
    public selectCart(cartId: number) {
        if (this.snapshot.selectedCartId !== cartId) {
            this.patchState({
                selectedCartId: cartId,
                selectedBinId: undefined
            });
        }
    }

    @DataAction()
    public selectBin(binId: number) {
        if (this.snapshot.selectedBinId !== binId) {
            this.patchState({
                selectedBinId: binId
            });
        }
    }

    public onStateChange(callback: typeof this._ngxsOnChange) {
        this._ngxsOnChange = callback;
    }
}
