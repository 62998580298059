import _ from "lodash";
import { WarehouseBinModel } from "src/app/_models/warehouses/warehouse-bin.model";
import { StatesHelper } from "src/app/states/states.helper";
import { WarehouseShelf } from "./warehouse-shelf.entity";
import WarehouseBinItem from "./warehouse-bin-item.entity";


export default class WarehouseBin extends WarehouseBinModel {

    public readonly binItems: WarehouseBinItem[] = [];

    constructor(warehouseBin: WarehouseBinModel) {
        super();
        _.assignIn(this, warehouseBin);

        this.binItems = this.items.map(item => new WarehouseBinItem(item))
    }

    get shelf(): WarehouseShelf {
        return StatesHelper.warehousesEntitiesState.warehouseShelvesEntityMap[this.shelfId];
    }
}