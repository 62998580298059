import { Injectable } from '@angular/core';
import { AdvancedReorderRuleModel } from '../_models/advanced-reorder-rule.model';
import { AdvancedReorderRulesService } from '../_services/advanced-reorder-rules.service';

@Injectable()
export class AdvancedReorderRules {
  rules: AdvancedReorderRuleModel[];
  loaded: boolean = false;
  loadRequested: boolean = false;
  callBacks: Function[] = [];

  constructor(private advReorderRulesService: AdvancedReorderRulesService) { }

  getAll(callBack: Function = null) {
    if (!this.loaded)
      this.reload(callBack);
    else if (callBack)
      callBack(this.rules);
    return this.rules;
  }

  reload(callBack: Function = null) {
    if (callBack)
      this.callBacks.push(callBack);
    if (!this.loadRequested) {
      this.loadRequested = true;
      this.advReorderRulesService.getAllRules().subscribe((result) => {
        this.rules = result;
        this.loaded = true;
        for (let calback of this.callBacks) {
          calback(result);
          this.callBacks = this.callBacks.filter(cb => cb != calback);
        }
        this.loadRequested = false;
      });
    }
  }
}