import { Injectable } from '@angular/core';
import { ProductsService } from '../_services/products.service';
import { OldProductModel } from '../_models/old-product.model';

@Injectable()
export class Products {
  products: OldProductModel[] = [];
  loaded = false;
  loadRequested = false;
  callBacks: Function[] = [];

  constructor(private productsService: ProductsService) { }

  getAll(callBack: Function = null, disableNotMain: boolean = false) {
    if (!this.loaded) {
      this.reload(callBack, disableNotMain);
    }
    else if (callBack) {
      callBack(this.products);
    }
    return this.products;
  }

  reload(callBack: Function = null, disableNotMain: boolean = false) {
    if (callBack) {
      this.callBacks.push(callBack);
    }
    if (!this.loadRequested) {
      this.loadRequested = true;
      this.productsService.getAllSkus().subscribe(result => {
        for (const product of result) {
          product.quantities = product.quantities
            .split(';')
            .map(quantity => quantity.split(":").flatMap(element => Number(element ?? 0)))
            .map(([warehouseId, quantity]) => ({ warehouseId, quantity }));

          if (product.type != 'Main') {
            if (disableNotMain) {
              product.disabled = product.type != 'Main';
            }
            product.skuWithType = `${product.sku} (${product.type})`;
            if (product.kitItems) {
              const ids = product.kitItems.split(';');
              const fractions = product.kitFractions.split(';');
              delete product.kitFractions;
              product.kitItems = [];
              for (const i in ids) {
                product.kitItems.push({ productId: ids[i], fraction: Number(fractions[i]) });
              }
            }
          }

          else {
            product.skuWithType = product.sku;
          }
        }
        this.products = result;
        this.loaded = true;
        for (const calback of this.callBacks) {
          calback(result);
          this.callBacks = this.callBacks.filter(cb => cb != calback);
        }
        this.loadRequested = false;
      });
    }
  }
}
