import { HttpInterceptor, HttpHandler, HttpEvent, HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { HttpHeaderNames, HttpContentTypes } from './http-constants';

@Injectable({
    providedIn: 'root'
})
export default class ParseRawResponseInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            catchError((err: unknown) => {
                const errorResponse = err as HttpErrorResponse;
                if (request.responseType === 'blob' && errorResponse.error instanceof Blob) {
                    return from(errorResponse.error.text()).pipe(
                        switchMap(text => {
                            try {
                                let error = text;
                                if (errorResponse.headers.get(HttpHeaderNames.CONTENT_TYPE)?.includes(HttpContentTypes.APPLICATION_JSON)) {
                                    error = JSON.parse(text);
                                }
                                return throwError(new HttpErrorResponse({ ...errorResponse, error }));
                            } catch (e) {
                                return throwError(errorResponse);
                            }
                        })
                    );
                }
                return throwError(errorResponse);
            })
        );
    }
}