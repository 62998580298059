<section class="≈flex ≈flex-nowrap ≈items-center ≈justify-center ≈gap-0 md:≈gap-12 lg:≈gap-16 xl:≈gap-[10rem] ≈w-full ≈px-4 md:≈px-10 lg:≈px-14 xl:≈px-[16rem]">
    <div class="≈flex ≈flex-col ≈gap-6 md:≈gap-8 lg:≈gap-12">
        <h1 class="≈text-4xl md:≈text-6xl lg:≈text-7xl xl:≈text-8xl ≈text-primary-text">
            Oops.
        </h1>
        <p class="≈text-2xl md:≈text-4xl lg:≈text-5xl xl:≈text-6xl">
            The {{subject}} you're looking for was not found
        </p>
    </div>
    
    <img src="assets/media/illustrations/box-packing.svg" style="max-width: 49%" class="≈hidden md:≈block">
</section>
