import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Store } from '@ngxs/store';
import UserState from '../states/user/user.state';
import { HttpHeaderNames } from './http-constants';

@Injectable({
    providedIn: 'root'
})
export class AuthorizeInterceptor implements HttpInterceptor {

    constructor(private stateStore: Store) { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const accessToken = this.stateStore.selectSnapshot(UserState.accessToken);
        return next.handle(this.requestWithAccessToken(request, accessToken));
    }

    private requestWithAccessToken(request: HttpRequest<unknown>, accessToken: string) {
        const isTrustedUrl = request.url.startsWith(`${environment.baseUrl}/`);

        if (!!accessToken && isTrustedUrl) {
            request = request.clone({
                setHeaders: {
                    [HttpHeaderNames.AUTHORIZATION]: `Bearer ${accessToken}`
                }
            });
        }
        return request;
    }
}