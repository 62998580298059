import { AdvancedReorderSetting } from "../pages/advanced-reorder/advanced-reorder-item";

export class SupplierPrice {
  supplierId: number;
  price: number;
}

export class AddedReorderModel {
  productId: number;
  sku: string;
  supplierId: number;
  supplier: string;
  details: AdvancedReorderSetting;
  prices: string;
  addedQuantity: number;
  allPrices: SupplierPrice[];
}