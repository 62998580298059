
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { CsvFileService } from './csv-file.service';
import { retry } from 'rxjs/operators';
import { Observable, forkJoin } from 'rxjs';
import { UploadType } from '../_enums/uploads/upload-type.enum';
import { ProductInformationsUpload } from '../pages/upload-csv/models/product-informations-upload';
import { ProductTagsUpload } from '../pages/upload-csv/models/product-tags-upload';
import { SuppliersUpload } from '../pages/upload-csv/models/suppliers-upload';
import { CaseConfigurationsUpload } from '../pages/upload-csv/models/case-configurations-upload';
import { MainsAndSubsUpload } from '../pages/upload-csv/models/mains-and-subs-upload';
import { InventoryUpload } from '../pages/upload-csv/models/inventory-upload';
import { KitsUpload } from '../pages/upload-csv/models/kits-upload';
import { FileUploadModel } from '../pages/upload-csv/models/file-upload.model';
import { OrderInformationUpload } from '../pages/upload-csv/models/order-upload';
import { BoxConfigsUpload } from '../pages/upload-csv/models/box-configs';

const API_IMPORT_URL = environment.baseUrl + '/import';
// Real REST API
@Injectable()
export class ImportService {
  constructor(
    private http: HttpClient,
    private papaService: CsvFileService
  ) { }

  private files: any[];

  setFiles(files: any[]) {
    this.files = files;
  }

  getFiles() {
    return this.files;
  }

  importInventory(items: InventoryUpload.PayloadItem[], fileName: string, locationId: number, adjustmentDate: Date = null) {
    const metaData = {
      locationId,
      adjustmentDate
    };
    return this.processUploads(items, fileName, UploadType.ProductInventory, metaData);
  }

  importMainsSubs(items: MainsAndSubsUpload.PayloadItem[], fileName: string) {
    return this.processUploads(items, fileName, UploadType.ProductMainsAndSubs);
  }

  importKits(items: KitsUpload.PayloadItem[], fileName: string) {
    return this.processUploads(items, fileName, UploadType.ProductKits);
  }

  importProductInformation(items: ProductInformationsUpload.PayloadItem[], fileName: string) {
    return this.processUploads(items, fileName, UploadType.ProductInformation);
  }

  importOrderInformation(items: OrderInformationUpload.PayloadItem[], fileName: string) {
    return this.processUploads(items, fileName, UploadType.OrderInformation);
  }

  importCaseConfiguration(items: CaseConfigurationsUpload.PayloadItem[], fileName: string) {
    return this.processUploads(items, fileName, UploadType.ProductCaseConfigurations);
  }

  importProductTag(items: ProductTagsUpload.PayloadItem[], fileName: string) {
    return this.processUploads(items, fileName, UploadType.ProductTags);
  }

  importSuppliers(items: SuppliersUpload.PayloadItem[], fileName: string) {
    return this.processUploads(items, fileName, UploadType.ProductSuppliers);
  }

  importBoxConfigs(items: BoxConfigsUpload.PayloadItem[], fileName: string) {
    return this.processUploads(items, fileName, UploadType.ProductPackageBoxConfigurations);
  }

  processUploads(items: unknown[], fileName: string, uploadType: UploadType, metaData: Record<string, unknown> = null) {
    const csvChunks = this.papaService.toCsvChunks(items, fileName, 1000);
    const observables = csvChunks.map(formData => {
      formData.set("uploadType", UploadType[uploadType]);
      if (metaData) {
        formData.set("metaData", JSON.stringify(metaData));
      }
      return this.http.post(`${API_IMPORT_URL}`, formData, { reportProgress: true }).pipe(retry(3));
    });
    return forkJoin(observables);
  }

  uploadHistory(): Observable<FileUploadModel[]> {
    return this.http.get<FileUploadModel[]>(`${API_IMPORT_URL}/uploadHistory`);
  }

  downloadImportedFile(id: number) {
    return this.http.get(`${API_IMPORT_URL}/downloadImportedFile/${id}`, { responseType: 'text' });
  }
}
