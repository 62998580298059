import * as _ from 'lodash';
import {PickedBinItemModel} from 'src/app/_models/self-ship/pick-lists/picked-bin-item.model';
import Order from '../orders/order.entity';
import {StatesHelper} from '../../states/states.helper';
import WarehouseCartBin from '../warehouses/carts/warehouse-cart-bin.entity';
import PickListItem from './pick-list-item.entity';

export default class PickedBinItem extends PickedBinItemModel {

    constructor(pickedBinItem: PickedBinItemModel) {
        super();
        _.assignIn(this, pickedBinItem);
    }

    get order(): Order {
        return new Order(
            StatesHelper.ordersEntitiesState
                .entities[this.orderId]
        );
    }

    get warehouseCartBinEntity(): WarehouseCartBin {
        return new WarehouseCartBin(
            StatesHelper.warehousesEntitiesState
                .entitiesArray
                .flatMap(w => w.warehouseCarts)
                .flatMap(wc => wc.bins)
                .find(wcb => wcb.id === this.warehouseCartBinId)
        );
    }

    get picklistItemEntity() {
        return new PickListItem(
            StatesHelper.picklistsEntitiesState
                .entitiesArray
                .flatMap(pl => pl.items)
                .find(pli => pli.id === this.pickListItemId)
        );
    }
}
