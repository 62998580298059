import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TempPickList } from '../states/self-ship/pick-lists/pick-list-items-filters/pick-list-items-filters.model';
import { PickListItemModel } from '../_models/self-ship/pick-lists/pick-list-item.model';
import { PickListModel } from '../_models/self-ship/pick-lists/pick-list.model';

const API_PICKLISTS_URL = environment.baseUrl + '/pickLists';

@Injectable({
  providedIn: 'root'
})
export class PicklistsService {

  constructor(private http: HttpClient) { }

  public fetch(): Observable<PickListModel[]> {
    return this.http.get(API_PICKLISTS_URL) as Observable<PickListModel[]>;
  }

  public getPickListGroups(): Observable<any> {
    return this.http.get<any>(`${API_PICKLISTS_URL}/pickListGroups`);
  }

  public savePickLists(pickLists: TempPickList[]) {
    return this.http.post(API_PICKLISTS_URL, pickLists);
  }

  public pickItemToBin(data: { pickListItemId: number, binId: number, quantity: number, orderId?: number }): Observable<PickListItemModel> {
    return this.http.post(`${API_PICKLISTS_URL}/pickItemToBin`, data) as Observable<PickListItemModel>;
  }

  public deletePickedBinItem(data: { picklistItemId: number, binId: number }) {
    return this.http.delete(`${API_PICKLISTS_URL}/deletePickedBinItem`, { body: data });
  }
}
