// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
// Models
import { OrderModel } from '../_models/orders/order.model';

import { environment } from 'src/environments/environment';
import { OrderBoxModel } from '../_models/orders/order-boxes/order-box.model';
import { OrderBoxItemModel } from '../_models/orders/order-boxes/order-box-item.model';
import { OrdersFetchFilters } from './self-ship/self-ship-orders.service';
import { OrdersFiltersModel } from '../_models/self-ship/orders/orders-filters.model';
import OrdersFiltersState from '../states/orders/orders-filters/orders-filters.state';
import { ProcessingStatus } from '../_entities/orders/processing-statuses/processing-status';
import { OrderOutboundDTO } from '../_DTOs/outbound/order/order-outbound.dto';
import OrdersEntitiesState from '../states/orders/orders-entities.state';
import { StatesHelper } from '../states/states.helper';

const API_ORDERS_URL = environment.baseUrl + '/orders';
const API_ORDER_BOXES_URL = environment.baseUrl + '/orderBoxes';

@Injectable()
export class OrdersService {

    constructor(
        private http: HttpClient,
        private ordersFiltersState: OrdersFiltersState
    ) {
    }

    public getOrdersFetchFilterParams(): OrdersFetchFilters {
        const filters: OrdersFiltersModel = this.ordersFiltersState.snapshot;
        return {
            fromDate: filters.dateRange.from.toUTCString(),
            toDate: filters.dateRange.to.toUTCString(),
            includeShipped: !filters.alreadyFetched.shipped
                ? filters.orderStatuses.map(status => status.value).includes(ProcessingStatus.Shipped.value)
                : false,
            includeCanceled: !filters.alreadyFetched.canceled
                ? filters.orderStatuses.map(status => status.value).includes(ProcessingStatus.Canceled.value)
                : false,
            excludeOthers: filters.alreadyFetched.others
        };
    }

    public fetch(filters: OrdersFetchFilters): Observable<OrderModel[]> {
        return (this.http.get(API_ORDERS_URL, { params: filters }) as Observable<OrderModel[]>);
    }

    public fetchSingleOrder(orderId: number): Observable<OrderModel> {
        return this.http.get<OrderModel>(`${API_ORDERS_URL}/${orderId}`);
    }

    public getOrderDetail(storeId: number, orderNumber: string): any {
        return this.http.get(`${API_ORDERS_URL}/${storeId}/${orderNumber}`);
    }

    public getOrderDetailByreimbursementId(storeId: number, orderNumber: string): any {
        return this.http.get(`${API_ORDERS_URL}/${storeId}/GetOrderDetailByreimbursementId/${orderNumber}`);
    }

    public create(outboundOrder: OrderOutboundDTO): Observable<any> {
        return this.http.post(`${API_ORDERS_URL}`, outboundOrder);
    }

    // public deleteOrder(orderId: number) {
    //   return this.http.delete(`${API_ORDERS_URL}/${orderId}`);
    // }

    public update(outboundOrder: OrderOutboundDTO): Observable<any> {
        return this.http.put(`${API_ORDERS_URL}/${outboundOrder.id}`, outboundOrder);
    }

    public updateOrderItems(payload: { orderItemIds: number[], warehouseId?: number, markAsShipped?: boolean; }): Observable<unknown> {
        return this.http.put(`${API_ORDERS_URL}/${this.updateOrderItems.name}`, payload);
    }

    public createOrderBoxes(data: { orderId: number, boxes: OrderBoxModel[], groupId?: number, groupName?: string; }) {
        return this.http.post<OrderBoxModel[]>(`${API_ORDER_BOXES_URL}`, data);
    }

    public createBulkOrdersBoxes(requestData: { orderIds: number[], orderBox: OrderBoxModel, overwriteExistingBoxes: boolean; }) {
        return this.http.post<OrderModel[]>(`${API_ORDER_BOXES_URL}/createBulk`, requestData);
    }

    public updateOrderBox(box: OrderBoxModel) {
        return this.http.put(`${API_ORDER_BOXES_URL}`, box);
    }

    public packItemToBox(orderBoxItem: OrderBoxItemModel) {
        return this.http.put(`${API_ORDER_BOXES_URL}/packItemToBox`, orderBoxItem);
    }

    public selectOrderBoxesForRates(data: { warehouseId: number, shipFromAddressId: number, orderBoxIds: number[]; }) {
        return this.http.put(`${API_ORDER_BOXES_URL}/selectOrderBoxesForRates`, data)
            .pipe(
                tap((result: OrderBoxModel[]) => StatesHelper.ordersEntitiesState.upsertOrderBoxes(...result))
            );
    }

    public Getfulfillments() {
        return this.http.get<any>(`${API_ORDERS_URL}/Fulfilments`);
    }

    public GetReservedOrders(productId: number = null) {
        let params: any = {};
        if (productId) {
            params.productId = productId;
        }
        return this.http.get<any>(`${API_ORDERS_URL}/ReservedOrders`);
    }

    public deleteOrderBox(id: number) {
        return this.http.delete(`${API_ORDER_BOXES_URL}/${id}`);
    }

    public syncAcendaOrder(orderId: number): Observable<any> {
        return this.http.put(`${API_ORDERS_URL}/syncAcendaOrder/${orderId}`, null);
    }
}
