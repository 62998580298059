export enum BaseShippingProviders
{
    Unknown = 0,

    UPS = 1 << 0,
    USPS = 1 << 1,
    FedEx = 1 << 2,
    
    Amazon_UPS = 1 << 4,
    Amazon_USPS = 1 << 5,
    Amazon_FedEx = 1 << 6,
    
    ShipEngine = 1 << 7,

    ShippingCarriers = UPS | USPS | FedEx | ShipEngine,
    AmazonCarriers = Amazon_FedEx | Amazon_UPS | Amazon_USPS
}

export enum ShippingProvider
{
    Unknown = BaseShippingProviders.Unknown,

    UPS = BaseShippingProviders.UPS,
    USPS = BaseShippingProviders.USPS,
    FedEx = BaseShippingProviders.FedEx,
    ShipEngine = BaseShippingProviders.ShipEngine,

    Amazon_USPS = BaseShippingProviders.Amazon_USPS,
    Amazon_UPS = BaseShippingProviders.Amazon_UPS,
    Amazon_FedEx = BaseShippingProviders.Amazon_FedEx,
}

export enum ShippingCarrier
{
    Unknown = ShippingProvider.Unknown,

    UPS = ShippingProvider.UPS,
    USPS = ShippingProvider.USPS,
    FedEx = ShippingProvider.FedEx,
    ShipEngine = ShippingProvider.ShipEngine
}

export enum AmazonCarrier
{
    Unknown = ShippingProvider.Unknown,

    Amazon_USPS = ShippingProvider.Amazon_USPS,
    Amazon_UPS = ShippingProvider.Amazon_UPS,
    Amazon_FedEx = ShippingProvider.Amazon_FedEx
}
