import { Injectable } from '@angular/core';
import { State } from '@ngxs/store';
import { LineItemIncomeType, QuickBooksAccountsMapping } from 'src/app/_models/quickbooks/quickbooks-accounts-mapping.model';
import { QuickBooksConnectionStatus } from 'src/app/_models/quickbooks/quickbooks-connection-status.model';
import { QuickBooksStateModel, QuickBooksStoreLinkType } from 'src/app/states/quickbooks/quickbooks.state.model';
import { QuickBooksAccount } from 'src/app/_models/quickbooks/quickbooks-account.model';
import { RealTimeService } from 'src/app/_services/real-time-connection/real-time.service';
import { QuickbooksSettingsService } from 'src/app/_services/settings/quickbooks-settings.service';
import { tap } from 'rxjs/operators';
import { HelperService } from 'src/app/_services/helper.service';
import { HubClientMethods } from 'src/app/_services/real-time-connection/real-time-connection.constants';
import { Computed, DataAction, StateRepository } from '@angular-ru/ngxs/decorators';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { QuickBooksItem } from '../../_models/quickbooks/quickbooks-item.model';
import { QuickBooksJournalEntryMapping } from 'src/app/_models/quickbooks/quickbooks-journal-entry-mapping';
import { QuickBooksCustomer } from 'src/app/_models/quickbooks/quickbooks-customer.model';
import EntityMap from 'src/app/_builders/entity-map';
import EntityMapper from 'src/app/_builders/entity.mapper';

type SettingRefType = 'EverythingInOneItem' |
    'Income' |
    'Asset' |
    'CostOfGoodsSold' |
    'Shipping' |
    'PromotionRebates' |
    'ProductCharges' |
    'AccountsReceivable' |
    'Tax';

const initialState = {
    accounts: [],
    accountsMapping: new QuickBooksAccountsMapping(),
    connectionStatus: new QuickBooksConnectionStatus(),
    customers: [],
    itemsForEverythingInOneItem: [],
    journalEntryMapping: null,
    quickBooksSellingChargesLinks: [],
    quickBooksStoreLink: [],
    loading: false
} as QuickBooksStateModel;

@StateRepository()
@State<QuickBooksStateModel>({
    name: 'quickbooks',
    defaults: initialState
})
@Injectable()
export default class QuickBooksState extends NgxsDataRepository<QuickBooksStateModel> {

    constructor(
        private quickBooksService: QuickbooksSettingsService,
        private realTimeService: RealTimeService,
        private helperService: HelperService) {
        super();
    }

    @Computed()
    public get connectionStatus(): QuickBooksConnectionStatus {
        return this.snapshot.connectionStatus;
    }

    @Computed()
    public get accountsMapping(): QuickBooksAccountsMapping {
        return this.snapshot.accountsMapping;
    }

    @Computed()
    public get quickBooksStoreLinkEntity(): EntityMap<QuickBooksStoreLinkType> {
        return new EntityMap(EntityMapper.mapById(this.snapshot.quickBooksStoreLink, 'storeId'));
    }

    @Computed()
    public get journalEntryMapping(): QuickBooksJournalEntryMapping {
        return this.snapshot.journalEntryMapping;
    }

    @Computed()
    public get customers(): QuickBooksCustomer[] {
        return this.snapshot.customers;
    }


    @Computed()
    public get itemsForEverythingInOneItem(): QuickBooksItem[] {
        return this.snapshot.itemsForEverythingInOneItem;
    }

    @Computed()
    public get accounts(): QuickBooksAccount[] {
        return this.snapshot.accounts;
    }

    @Computed()
    public get incomeAccounts(): QuickBooksAccount[] {
        return this.filterAccounts('Income');
    }

    @Computed()
    public get assetAccounts(): QuickBooksAccount[] {
        return this.filterAccounts('Asset');
    }

    @Computed()
    public get cogsAccounts(): QuickBooksAccount[] {
        return this.filterAccounts('CostOfGoodsSold');
    }

    @Computed()
    public get productChargesAccounts(): QuickBooksAccount[] {
        return this.filterAccounts('ProductCharges');
    }

    @Computed()
    public get AccountsReceivableAccounts(): QuickBooksAccount[] {
        return this.filterAccounts('AccountsReceivable');
    }

    @Computed()
    public get shippingAccounts(): QuickBooksAccount[] {
        return this.snapshot.accounts;
    }

    @Computed()
    public get taxAccounts(): QuickBooksAccount[] {
        return this.snapshot.accounts;
    }

    ngxsAfterBootstrap() {
        this.helperService.onUserIsActive(() => this.loadQuickBooksState());
        this.realTimeService
            .registerHandler(HubClientMethods.SendQuickBooksState, (data: QuickBooksStateModel) => this.setQuickBooksState(data));
    }

    @DataAction()
    loadQuickBooksState() {
        return this.quickBooksService.get()
            .pipe(tap(data => this.setQuickBooksState(data)));
    }

    updateJournalEntryMapping(journalEntryMapping: QuickBooksJournalEntryMapping) {
        return this.quickBooksService.updateQuickBooksJournalEntryMapping(journalEntryMapping)
            .pipe(tap(_ => this.patchState({ journalEntryMapping })));
    }

    updateAccountsMapping(accountsMapping: QuickBooksAccountsMapping) {
        return this.quickBooksService.updateQuickBooksAccountsMapping(accountsMapping)
            .pipe(tap(_ => this.patchState({ accountsMapping })));
    }

    @DataAction()
    setConnectedProduct(value: 'QBD' | 'QBO') {
        this.patchState({
            connectionStatus: {
                ...this.snapshot.connectionStatus,
                connectedProduct: value
            }
        });
    }

    @DataAction()
    setItemIncomeTypeId(value: LineItemIncomeType) {
        this.patchState({
            accountsMapping: {
                ...this.snapshot.accountsMapping,
                itemIncomeTypeId: value
            }
        });
    }

    @DataAction()
    setEverythingInOneItemRefId(value: number) {
        this.patchState({
            accountsMapping: {
                ...this.snapshot.accountsMapping,
                everythingInOneItemRefId: value
            }
        });
    }

    @DataAction()
    setIncomeAccountRefId(value: number) {
        this.patchState({
            accountsMapping: {
                ...this.snapshot.accountsMapping,
                incomeAccountRefId: value
            }
        });
    }

    @DataAction()
    setAssetsAccountRefId(value: number) {
        this.patchState({
            accountsMapping: {
                ...this.snapshot.accountsMapping,
                assetAccountRefId: value
            }
        });
    }

    @DataAction()
    setCogsAccountRefId(value: number) {
        this.patchState({
            accountsMapping: {
                ...this.snapshot.accountsMapping,
                cogsAccountRefId: value
            }
        });
    }

    @DataAction()
    setProductChargesRefId(value: number) {
        this.patchState({
            journalEntryMapping: {
                ...this.snapshot.journalEntryMapping,
                productChargesRefId: value
            }
        });
    }

    @DataAction()
    setAccountsReceivableRefId(value: number) {
        this.patchState({
            journalEntryMapping: {
                ...this.snapshot.journalEntryMapping,
                accountsReceivableRefId: value
            }
        });
    }

    @DataAction()
    setPromotionRebatesRefId(value: number) {
        this.patchState({
            journalEntryMapping: {
                ...this.snapshot.journalEntryMapping,
                promotionRebatesRefId: value
            }
        });
    }

    @DataAction()
    setSellingChargesRefId(value: number) {
        this.patchState({
            journalEntryMapping: {
                ...this.snapshot.journalEntryMapping,
                sellingChargesRefId: value
            }
        });
    }

    @DataAction()
    setInventoryAssetRefId(value: number) {
        this.patchState({
            journalEntryMapping: {
                ...this.snapshot.journalEntryMapping,
                inventoryAssetRefId: value
            }
        });
    }

    @DataAction()
    setCostOfGoodsSoldRefId(value: number) {
        this.patchState({
            journalEntryMapping: {
                ...this.snapshot.journalEntryMapping,
                costOfGoodsSoldRefId: value
            }
        });
    }

    @DataAction()
    setRefundedSalesRefId(value: number) {
        this.patchState({
            journalEntryMapping: {
                ...this.snapshot.journalEntryMapping,
                refundedSalesRefId: value
            }
        });
    }

    @DataAction()
    setRefundedExpansesRefId(value: number) {
        this.patchState({
            journalEntryMapping: {
                ...this.snapshot.journalEntryMapping,
                refundedExpansesRefId: value
            }
        });
    }

    @DataAction()
    setAccountReceivableCustomerRefId(value: number) {
        this.patchState({
            journalEntryMapping: {
                ...this.snapshot.journalEntryMapping,
                accountsReceivableCustomerRefId: value
            }
        });
    }

    @DataAction()
    setTaxAccountRefId(value: number) {
        this.patchState({
            accountsMapping: {
                ...this.snapshot.accountsMapping,
                taxAccountRefId: value
            }
        });
    }

    @DataAction()
    setShippingAccountRefId(value: number) {
        this.patchState({
            accountsMapping: {
                ...this.snapshot.accountsMapping,
                shippingAccountRefId: value
            }
        });
    }

    private filterAccounts(filterType: SettingRefType): QuickBooksAccount[] {
        return this.snapshot.accounts.filter(this.getAccountFilter(filterType));
    }

    private getAccountFilter(filterType: SettingRefType): (account: QuickBooksAccount) => boolean {
        if (this.snapshot.connectionStatus.connectedProduct === 'QBD') {
            return (account: QuickBooksAccount) => account.accountType.name.includes(filterType);
        } else if (this.snapshot.connectionStatus.connectedProduct === 'QBO') {
            switch (filterType) {
                case 'Income':
                    return (account: QuickBooksAccount) => account.accountType.name === 'Income' && account.detailAccountType.name === 'SalesOfProductIncome';
                case 'Asset':
                    return (account: QuickBooksAccount) => account.accountType.name === 'OtherCurrentAsset';
                case 'CostOfGoodsSold':
                    return (account: QuickBooksAccount) => account.name.toLowerCase() === 'Cost of Goods Sold'.toLocaleLowerCase();
                case 'PromotionRebates':
                    return (account: QuickBooksAccount) => account.accountType.name === 'Expense' && account.name === 'Promotion Rebates';
                case 'AccountsReceivable':
                    return (account: QuickBooksAccount) => account.accountType.name === 'AccountsReceivable';
                case 'ProductCharges':
                    return (account: QuickBooksAccount) => account.accountType.name === 'Income' && account.detailAccountType.name === 'SalesOfProductIncome';
            }
        }
        return (account: QuickBooksAccount) => true;
    }

    private setQuickBooksState(newState: QuickBooksStateModel) {
        this.patchState({
            ...newState,
            accounts: newState.accounts.sort((a1, a2) => a1.name.localeCompare(a2.name))
        });
    }
}
