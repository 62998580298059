import { OrderBoxItemModel } from "./order-box-item.model";

export class OrderBoxModel {
    id: number;
    name: string;
    orderId: number;
    items: OrderBoxItemModel[];
    width: number;
    height: number;
    length: number;
    weight: number;
    boxGroupId?: number;
    shipFromAddressId?: number;
    warehouseId?: number;
    packingBoxConfigId?: number;
    shipperBoxId?: number;
    type: OrderBoxType;
    shipmentId: number;
}

export enum OrderBoxType {
    Box = 'Box',
    Envelope = 'Envelope',
    Bag = 'Bag',
    Tube = 'Tube',
    Carrier_Box = 'Carrier_Box',
}

export enum BoxWeightUnitType {
    Oz = 'Oz',
    Lb = 'Lb'
}
