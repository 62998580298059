import { Injectable } from '@angular/core';
import { State } from '@ngxs/store';

import ShipmentModel from 'src/app/_models/self-ship/shipping/shipment.model';
import { HubClientMethods } from 'src/app/_services/real-time-connection/real-time-connection.constants';
import { RealTimeService } from 'src/app/_services/real-time-connection/real-time.service';
import { ShipmentsService } from 'src/app/_services/self-ship/shipments.service';
import OrdersEntitiesState from '../orders/orders-entities.state';
import { OrdersService } from '../../_services/orders.service';
import { Computed, DataAction, StateRepository } from '@angular-ru/ngxs/decorators';
import { createEntityCollections, EntityIdType } from '@angular-ru/cdk/entity';
import { NgxsDataEntityCollectionsRepository } from '@angular-ru/ngxs/repositories';
import BaseEntityCollectionsOptions from '../base-entity-collections-options';
import { Shipment } from '../../_entities/shipments/shipment.entity';
import EntityMap from '../../_builders/entity-map';
import EntityMapper from '../../_builders/entity.mapper';
import IEntityChange from '../../_interfaces/i-entity-change';
import { OrdersFetchFilters } from '../../_services/self-ship/self-ship-orders.service';
import { tap } from 'rxjs/operators';

@StateRepository()
@State({
    name: 'shipments',
    defaults: {
        ...createEntityCollections(),
        loading: false
    }
})
@Injectable()
export class ShipmentsEntitiesState
    extends NgxsDataEntityCollectionsRepository<ShipmentModel, EntityIdType, BaseEntityCollectionsOptions> {
    constructor(
        private realtimeService: RealTimeService,
        private shipmentService: ShipmentsService,
        private orderEntitiesState: OrdersEntitiesState,
        private ordersService: OrdersService
    ) {
        super();
    }

    // Computed

    @Computed()
    public get shipmentEntities(): Shipment[] {
        return this.entitiesArray
            .map(s => new Shipment(s));
    }

    @Computed()
    public get shipmentsEntityMap(): EntityMap<Shipment> {
        return new EntityMap(EntityMapper.mapById(this.shipmentEntities));
    }

    @Computed()
    public get ofOrder() {
        return (orderId: number) => {
            return this.shipmentEntities.filter(shipment => shipment.orderBoxes.some(box => box.orderId === orderId));
        };
    }


    // Actions

    @DataAction()
    public load(filters: OrdersFetchFilters) {
        this.patchState({ loading: true });
        this.shipmentService.fetch(filters).subscribe(shipments => {
            this.setEntitiesAll(shipments);
            this.patchState({ loading: false });
            this.patchState({ loaded: true });
        });

        this.realtimeService.registerHandler(HubClientMethods.SyncShipment, (notification: IEntityChange<ShipmentModel>) => {
            this.syncShipment(notification);
        });
    }

    @DataAction()
    public lazyLoad(filters: OrdersFetchFilters) {
        !this.snapshot.loading &&
            !this.snapshot.loaded &&
            this.load(filters);
    }

    @DataAction()
    public fetchShipmentByOrderId(orderId: number) {
        return this.shipmentService
            .fetchByOrderId(orderId)
            .pipe(tap(shipments => this.setEntitiesAll(shipments)));
    }

    @DataAction()
    public syncShipment(notification: IEntityChange<ShipmentModel>) {
        if (!notification.data) {
            this.removeOne(notification.id);
        } else {
            const shipment = notification.data;
            const orderOfShipment = this.orderEntitiesState
                .boxesById(shipment.orderBoxIds[0])
                .first
                .orderEntity;
            if (!orderOfShipment) {
                return;
            }
            const isOutOfDateRange = new Date(orderOfShipment.orderDate).getTime() >
                new Date(this.ordersService.getOrdersFetchFilterParams().toDate).getTime() ||
                new Date(orderOfShipment.orderDate).getTime() <
                new Date(this.ordersService.getOrdersFetchFilterParams().fromDate).getTime();

            if (!isOutOfDateRange && !!orderOfShipment) {
                this.upsertOne(shipment);
            }
        }
    }
}
