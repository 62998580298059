import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HelperService } from './helper.service';
// RxJS
import { environment } from 'src/environments/environment';

const FAQ_BASE_URL = 'https://faq.inventoryahead.com/index.php/wp-json/wp/v2';

@Injectable({
  providedIn: 'root'
})
export class ChatSupportService {

  botIsTyping: boolean = false;

  constructor(
    private http: HttpClient = null,
    private helperService: HelperService = null) { }

  searchFaq(searchString: string) {
    const searchUrl = `${FAQ_BASE_URL}/search?search='${searchString}'&per_page=3&page=1`;
    return this.http.get(searchUrl);
  }

  getFaq(id: number) {
    return this.http.get(`${FAQ_BASE_URL}/posts/${id}`);
  }

  sendMessage(payload: object) {
    return this.http.post(`${environment.baseUrl}/customers/createSupportRequest`, payload);
  }

  get UserState() {
    return this.helperService.currentUser;
  }
}
