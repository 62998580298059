import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export default class ResponseParsingErrorInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(req).pipe(
            catchError((error: unknown) => {
                if (error instanceof HttpErrorResponse) {
                    if (error.error instanceof SyntaxError && error.error.message.includes("Unexpected token")) {
                        console.error("JSON parsing error:", error);
                       //TODO
                       // Check livigient block, perhaps show block page so user can request to unblock.
                    }
                }
                return throwError(error);
            })
        );
    }
}
