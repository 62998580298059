import { Directive, EventEmitter, Input, Output } from "@angular/core";
import { SortDirection, SortEvent } from "../datatable.component";

const rotate: { [key: string]: SortDirection; } = { asc: 'desc', desc: '', '': 'asc' };

@Directive({
    selector: 'div[sortable]',
    host: {
        '[class.asc]': 'direction === "asc"',
        '[class.desc]': 'direction === "desc"',
        '(click)': 'rotate($event)'
    }
})
export class SortableHeader {
    @Input() sortable: string;
    @Input() direction: SortDirection = '';
    @Output() sort = new EventEmitter<SortEvent>();
    resizing = false;

    rotate(e: any) {
        if (e.target.classList[0] !== 'columnSelector' && this.sortable && !this.resizing) {
            this.direction = rotate[this.direction];
            this.sort.emit({ column: this.sortable, direction: this.direction });
        }
    }
}