export enum UploadType {
    Unspecified = 'Unspecified',
    ProductInventory = "ProductInventory",
    ProductInformation = "ProductInformation",
    ProductMainsAndSubs = "ProductMainsAndSubs",
    ProductKits = "ProductKits",
    ProductCaseConfigurations = "ProductCaseConfigurations",
    ProductSuppliers = "ProductSuppliers",
    ProductTags = "ProductTags",
    OrderInformation = "OrderInformation",
    ProductPackageBoxConfigurations = "ProductPackageBoxConfigurations"
}