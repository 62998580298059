// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// RxJS
import { Observable, BehaviorSubject, of } from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';

import { HelperService } from './helper.service';
import { environment } from 'src/environments/environment';

import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { result } from 'lodash';
import * as _ from 'lodash';

const API_GRID_STATES_URL = environment.baseUrl + '/gridstates';
// Real REST API
@Injectable()
export class GridStatesService {
	constructor(private http: HttpClient,
		private helperService: HelperService,
		private toastr: ToastrService,
		private translate: TranslateService) { }

	getAll(gridId: string): Observable<any[]> {
		const url = `${API_GRID_STATES_URL}/${gridId}`;
		return this.http.get<any>(url, { headers: this.helperService.getHTTPHeaders() })
			.pipe(map(result => {
				for (let item of result) {
					let girdColumns = item.gridColumns ? JSON.parse(item.gridColumns) : {};
					if (girdColumns.filters) {
						for (let key of Object.keys(girdColumns.filters)) {
							if (girdColumns.filters[key].type == 'Contains')
								delete girdColumns.filters[key];
						}
					}
					item.gridColumns = JSON.stringify(girdColumns);
				}
				return result;
			}), catchError(this.handleError('get')));
	}

	get(gridId: string, name: string = null): Observable<any> {
		let params: any = { gridId: gridId };
		if (name)
			params.name = name;
		return this.http.get<any>(API_GRID_STATES_URL,
			{ headers: this.helperService.getHTTPHeaders(), params: params })
			.pipe(catchError(this.handleError('get')));
	}

	set(gridState: any) {
		let gridColumns: any = null;
		if (gridState.columns) {
			gridColumns = {
				...gridState.gridColumns,
				columns: gridState.columns.map(col => {
					let gCol = { ...col, titleTemplate: null };
					delete gCol.titleTemplate;
					return gCol;
				})
			};
			gridState.gridColumns = JSON.stringify(gridColumns);
			gridState.columns = [];
		}
		return (gridState.id ? this.update(gridState) : this.create(gridState))
			.pipe(map(result => {
				if (gridColumns)
					result.gridColumns = gridColumns;
				return result;
			}));
	}

	create(gridState: any): Observable<any> {
		return this.http.post(API_GRID_STATES_URL, gridState,
		)
			.pipe(catchError(this.handleError<any>('create')));
	}

	update(gridState: any): Observable<any> {
		return this.http.put(API_GRID_STATES_URL, gridState,
		)
			.pipe(catchError(this.handleError<any>('update')));
	}

	delete(id: number) {
		return this.http.delete(`${API_GRID_STATES_URL}/${id}`,
		)
			.pipe(catchError(this.handleError<any>('delete')));
	}

	private handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			this.toastr.error(this.translate.instant('GENERAL.MESSAGES.ERROR'));
			// TODO: send the error to remote logging infrastructure
			console.error(error); // log to console instead

			// Let the app keep running by returning an empty result.
			return of(result as T);
		};
	}
}
