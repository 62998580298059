import BaseEntityMap from './base-entity-map';
import ICompositeEntityMap, {CompositeEntityId} from './i-composite-entity-map';
import {KeyWithValueOfInterface} from '../_types/type-helpers/type-helpers';
import EntityMapper from './entity.mapper';
import {FlatEntityMap} from './flat-entity-map';
import EntityMap from './entity-map';

export default class CompositeEntityMap<T> extends BaseEntityMap<T> {
    constructor(map: ICompositeEntityMap<T>, compositeKeys: KeyWithValueOfInterface<T, unknown>[]) {
        super(map);
        this.compositeKeys = compositeKeys;
    }

    public compositeKeys: KeyWithValueOfInterface<T,unknown>[];

    public get ids(): CompositeEntityId[] {
        return Object.keys(this._map) as CompositeEntityId[];
    }

    public get entities(): ICompositeEntityMap<T> {
        return this._map as ICompositeEntityMap<T>;
    }

    public concat(...maps: CompositeEntityMap<T>[]): CompositeEntityMap<T> {
        return new CompositeEntityMap(maps.reduce((results, cem) => {
            results = {...results, ...cem.entities}
            return results;
        }, {}), this.compositeKeys);
    }

    public static concat<T>(...maps: CompositeEntityMap<T>[]): CompositeEntityMap<T> {
        const compositeKeys = maps[0].compositeKeys;
        return new CompositeEntityMap(maps.reduce((results, cem) => {
            results = {...results, ...cem.entities}
            return results;
        }, {}), compositeKeys);
    }

    /** Returns a subset of the EntityMap
     *  (e.g <EntityMap>{
     *          1: {id: 1, name: John},
     *          2: {id: 2, name: Daniel},
     *          3: {id: 3, name: Micheal}
     *       }.subset(...[2, 3]));
     *       returns <EntityMap>{
     *                             2: {id: 2, name: Daniel},
     *                             3: {id: 3, name: Micheal}
     *                           }
     * @param keys
     * @return CompositeEntityMap<T>
     */
    public subset(...keys: KeyWithValueOfInterface<T,unknown>[]): CompositeEntityMap<T> {
        return new CompositeEntityMap(keys.reduce((results, key) => {
            results[key as string | number | symbol] = this._map[key];
            return results;
        }, {}), this.compositeKeys);
    }

    /** Flattens an object by returning a combined CompositeEntityMap of a specific field (whose value is of type EntityMap),
     * @param field field must be a key of the EntityType and its value must be of type EntityMap
     */
    public subEntities<V>(field: KeyWithValueOfInterface<T, V>): V {
        const returnInstance = Object.values(this._map)[0][field] instanceof EntityMap
            ? EntityMap
            : Object.values(this._map)[0][field] instanceof CompositeEntityMap
                ? CompositeEntityMap
                : undefined;
        if (!returnInstance) {
            throw new Error(`${this.subEntities.name}: value of '${field.toString()}' is not an instance of any EntityMap`);
        }
        return returnInstance.concat(...Object.values(this._map).map(entity => entity[field as string | number | symbol])) as unknown as V;
    }

    public sort(compareFn?: (a: T, b: T) => number) {
        const values: T[] = Object.values(this._map);
        const sortedMap = EntityMapper.mapByCompositeId(values.sort(compareFn), this.compositeKeys);
        return new CompositeEntityMap<T>(sortedMap, this.compositeKeys);
    }

    public filter<S extends T>(predicate: (value: T, index?: number, array?: T[]) => boolean): CompositeEntityMap<T> {
        const values = this.entitiesArray;
        const filteredMap = EntityMapper.mapByCompositeId(values.filter(predicate), this.compositeKeys);
        return new CompositeEntityMap<T>(filteredMap, this.compositeKeys);
    }

    public mapEntities<U>(callbackFn: (value: T) => U | ReadonlyArray<U>): FlatEntityMap<U> {
        return new FlatEntityMap<U>(this.ids.reduce((results, id) => {
            results[id] = [this._map[id]].map(callbackFn)[0];
            return results;
        }, {}));
    }
}
