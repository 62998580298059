import { TemplateRef } from "@angular/core";

export class GridColumnModel {
  columnIndex: number;
  name: string;
  width: number;
  visible: boolean;
  titleTemplate: TemplateRef<any>;
  freezed: string;
  permissions: string[];
  columns: string[];
}