import {AuthModule} from './pages/auth/auth.module';
import {HelperService} from './_services/helper.service';
import {ThemeModule} from './pages/theme/theme.module';

// Angular
import {BrowserModule, Title} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';
import {LOCALE_ID, NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {OverlayModule} from '@angular/cdk/overlay';
import {NgxDropzoneModule} from 'ngx-dropzone';
import { NgScrollbarModule } from 'ngx-scrollbar';

// FontAwsome
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
// Env
// NGX Permissions
import {NgxPermissionsModule} from 'ngx-permissions';
// State
import {StoresManager} from './_global/stores-manager';
import {Locations} from './_global/locations';
import {StoresService} from './_services/stores.service';
import {LocationsService} from './_services/locations.service';
// Copmponents
import {AppComponent} from './app.component';
import {ChatSupportComponent} from './pages/chat-support/chat-support.component';
// Modules
import {AppRoutingModule} from './app-routing.module';

// Highlight JS
import {HIGHLIGHT_OPTIONS} from 'ngx-highlightjs';
import * as typescript from 'highlight.js/lib/languages/typescript';
import * as scss from 'highlight.js/lib/languages/scss';
import * as xml from 'highlight.js/lib/languages/xml';
import * as json from 'highlight.js/lib/languages/json';

import {ToastrModule} from 'ngx-toastr';
import {GridStatesService} from './_services/grid-states.service';
import {PriceRules} from './_global/price-rules';
import {PriceRulesService} from './_services/settings/price-rules.service';
import {LinkRules} from './_global/link-rules';
import {LinkRulesService} from './_services/settings/link-rules.service';
// import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {BlockUIModule} from 'ng-block-ui';
import {ListingService} from './_services/listing.service';
import {Tags} from './_global/tags';
import {RainforestApiService} from './_services/rainforest-api.service';
import {RainforestApis} from './_global/rainforest-apis';
import {AuthorizeInterceptor} from './_intercepters/authorize.interceptor';
import {PopUpService} from './_services/popup.service';
import {OauthRedirectComponent} from './pages/oauth-redirect/oauth-redirect.component';
import {LoadingService} from './_services/loading.service';
import {StatesModule} from './states/states.module';
import {RealTimeService} from './_services/real-time-connection/real-time.service';
import {WarehousesService} from './_services/warehouses/warehouses.service';
import {OrdersService} from './_services/orders.service';
import RequestResiliencyInterceptor from './_intercepters/request-resiliency.interceptor';
import {CommonModule, DatePipe} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {SellingChannelsService} from './_services/settings/selling-channels.service';
import {TagsService} from './_services/settings/tags.service';
import PopOverModule from './components/pop-over/pop-over.module';
import PrintHtmlPageService from './_services/print-html-page.service';
import {ConfirmationPopoverModule} from './components/confirmation-popover/confirmation-popover.module';
import {PreAuthRedirectComponent} from './pages/pre-auth-redirect/pre-auth-redirect.component';
import {NotificationComponent} from './components/notification/notification.component';
import {UIComponentsModule} from './components/ui-components/ui-components.module';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgbDateNativeAdapter, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {StatesHelper} from './states/states.helper';
import { ImportService } from './_services/import.service';
import ResponseParsingErrorInterceptor from './_intercepters/response-parsing-error.interceptor';
import RequestTracingInterceptor from './_intercepters/request-tracing.intercepter';
import { APP_CONFIG } from 'src/environments/app.config';
import AppInitService from './_services/config/app-init.service';
import { AdvancedReorderService } from './_services/advanced-reorder.service';
import { AdvancedReorderRulesService } from './_services/advanced-reorder-rules.service';
import { AdvancedReorderRules } from './_global/advanced-reorder-rules';
import ParseRawResponseInterceptor from './_intercepters/parse-raw-response.interceptor';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';


export function hljsLanguages(): Array<{name: string, func: any}> {
    return [
        {name: 'typescript', func: typescript},
        {name: 'scss', func: scss},
        {name: 'xml', func: xml},
        {name: 'json', func: json}
    ];
}

@NgModule({
    declarations: [
        AppComponent,
        ChatSupportComponent,
        OauthRedirectComponent,
        PreAuthRedirectComponent,
        NotificationComponent
    ],
    imports: [
        StatesModule,
        BrowserAnimationsModule,
        BrowserModule,
        CommonModule,
        AppRoutingModule,
        HttpClientModule,
        NgxPermissionsModule.forRoot(),
        OverlayModule,
        AuthModule.forRoot(),
        TranslateModule.forRoot(),
        MatProgressSpinnerModule,
        ThemeModule,
        NgxDropzoneModule,
        ToastrModule.forRoot({
            positionClass: 'toast-bottom-right',
            preventDuplicates: true,
            newestOnTop: false,
        }),
        BlockUIModule.forRoot(),
        FormsModule,
        FontAwesomeModule,
        MatIconModule,
        PopOverModule,
        ConfirmationPopoverModule,
        UIComponentsModule,
        NgbModule,
        NgSelectModule,
        NgScrollbarModule,
        MatDialogModule,
        MatButtonModule
    ],
    exports: [

    ],
    providers: [
        RealTimeService,
        HelperService,
        StoresManager,
        LinkRules,
        PriceRules,
        Locations,
        Tags,
        DatePipe,
        RainforestApis,
        GridStatesService,
        StoresService,
        PriceRulesService,
        LinkRulesService,
        LocationsService,
        ListingService,
        RainforestApiService,
        PopUpService,
        SellingChannelsService,
        LoadingService,
        TagsService,
        WarehousesService,
        OrdersService,
        PrintHtmlPageService,
        Title,
        ImportService,
        StatesHelper,
        AdvancedReorderService,
        AdvancedReorderRulesService,
        AdvancedReorderRules,
        NgbDateNativeAdapter,
        {
            provide: APP_CONFIG,
            useFactory: (configService: AppInitService) => configService.appConfig,
            deps: [AppInitService]
        },
        { 
            provide: HTTP_INTERCEPTORS, 
            useClass: RequestTracingInterceptor, 
            multi: true 
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthorizeInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestResiliencyInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ParseRawResponseInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ResponseParsingErrorInterceptor,
            multi: true
        },
        {
            provide: HIGHLIGHT_OPTIONS,
            useValue: {languages: hljsLanguages}
        },
        {
            provide: LOCALE_ID,
            useValue: 'en-US'
        },
        {
            provide: Window,
            useValue: window
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
