// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// RxJS
import { Observable, BehaviorSubject, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
// Models
import { TagModel } from '../../_models/tag.model';

import { HelperService } from '../helper.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

const API_TAGS_URL = environment.baseUrl + '/tags';

@Injectable({
	providedIn: 'root'
})
export class TagsService {
	constructor(private http: HttpClient,
		private helperService: HelperService,
		private toastr: ToastrService,
		private translate: TranslateService) { }

	getAll(page: number = 1, pageSize: number = 20, sortField: string = undefined,
		sort: string = undefined, filters: any = undefined): Observable<any> {
		let params: any = { page, pageSize };
		if (sortField)
			params.sortField = sortField;
		if (sort)
			params.sort = sort;
		if (filters && Object.keys(filters).length)
			params.filters = JSON.stringify(filters);
		return this.http.get<any>(API_TAGS_URL,
			{ headers: this.helperService.getHTTPHeaders(), params: params })
			.pipe(catchError(this.handleError('getAll')));
	}

	getAllTags(): Observable<any> {
		return this.http.get<any>(`${API_TAGS_URL}/all`,)
			.pipe(catchError(this.handleError('getAll')));
	}

	getById(id: number): Observable<TagModel> {
		const url = `${API_TAGS_URL}/${id}`;
		return this.http.get<TagModel>(url,)
			.pipe(catchError(this.handleError<TagModel>(`getById id=${id}`)));
	}

	create(tag: TagModel): Observable<any> {
		return this.http.post(API_TAGS_URL, tag,)
			.pipe(catchError(this.handleError<any>('create')));
	}

	update(tag: TagModel): Observable<any> {
		return this.http.put(API_TAGS_URL, tag,)
			.pipe(catchError(this.handleError<any>('update')));
	}

	delete(id: number) {
		return this.http.delete(`${API_TAGS_URL}/${id}`,
		).pipe(
			catchError(this.handleError<TagModel>('delete'))
		);
	}

	bulkDelete(tagIds: number[]) {
		return this.http.post(`${API_TAGS_URL}/BulkDelete`, tagIds)
			.pipe(catchError(this.handleError<TagModel>('delete')));
	}

	private handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			switch (error.status) {
				case 401:
					window.location.href = '/login';
					break;
				case 422:
					this.toastr.error(this.translate.instant('TAGS.MESSAGES.ERRORS.' + error.error.reason, error.error));
					break;
				default:
					this.toastr.error(this.translate.instant('GENERAL.MESSAGES.ERROR'));
			}
			return throwError(error);
		};
	}
}
