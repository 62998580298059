export const MainHubRoute = "realTime";

export class HubServerMethods {

}

export class HubClientMethods {
    // QuickBooks
    public static readonly SendQuickBooksState = "SendQuickBooksState";
    // Order
    public static readonly SyncOrder = "SyncOrder";
    // Shipment
    public static readonly SyncShipment = "SyncShipment";
}

export interface HubInfo {
    readonly hubName?: string;
    readonly methodName: string;
}

export class StreamHubInfos {
    public static readonly RequestRates = new class implements HubInfo {
        public readonly methodName = "RequestRates"
    }

    public static readonly PurchaseLabels = new class implements HubInfo {
        public readonly methodName = "PurchaseLabels";
    }
    
    public static readonly SearchOrderAddresses = new class implements HubInfo {
        public readonly methodName = "SearchOrderAddresses"
    }

}
