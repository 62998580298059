import { Injectable } from '@angular/core';
import { NgxsSimpleChange, State } from '@ngxs/store';

import ProductModel from 'src/app/_models/products/product.model';
import { ProductsService } from 'src/app/_services/products.service';
import StoreProductsEntitiesState from './store-products/store-products-entities.state';
import { Computed, DataAction, StateRepository } from '@angular-ru/ngxs/decorators';
import { NgxsDataEntityCollectionsRepository } from '@angular-ru/ngxs/repositories';
import { createEntityCollections, EntityIdType } from '@angular-ru/cdk/entity';
import BaseEntityCollectionsOptions from '../base-entity-collections-options';
import Product from '../../_entities/products/product.entity';
import EntityMap from '../../_builders/entity-map';
import EntityMapper from '../../_builders/entity.mapper';
import { tap } from 'rxjs';

@StateRepository()
@State({
    name: 'products',
    defaults: {
        ...createEntityCollections(),
        loading: false
    }
})
@Injectable()
export default class ProductsEntitiesState
    extends NgxsDataEntityCollectionsRepository<ProductModel, EntityIdType, BaseEntityCollectionsOptions> {

    private _ngxsOnChange = (state?: ProductsEntitiesState) => { };
    private _changeObserver: typeof this.state$;

    constructor(
        private productsService: ProductsService,
        private storeProductsEntitiesState: StoreProductsEntitiesState
    ) {
        super();
    }

    ngxsOnChanges(_?: NgxsSimpleChange) {
        super.ngxsOnChanges(_);
        if (this.isInitialised && !this._changeObserver) {
            this._changeObserver = this.state$;
            this._changeObserver.subscribe(() => this._ngxsOnChange(this));
        }
    }
    // Computed
    @Computed()
    public get isLoading(): boolean {
        return this.snapshot.loading && !this.snapshot.loaded;
    }

    @Computed()
    public get productEntities(): Product[] {
        return this.entitiesArray
            .map(product => new Product(product));
    }

    @Computed()
    get productsEntityMap(): EntityMap<Product> {
        return new EntityMap<Product>(EntityMapper.mapById(this.productEntities));
    }

    // Actions
    @DataAction()
    load() {
        /* Load Products */
        this.patchState({ loading: true });
        this.productsService.fetch().subscribe(products => {
            this.setEntitiesAll(products);
            this.patchState({ loading: false, loaded: true });
        }, (error) => this.patchState({ loading: false }));
        /* Load Store Products */
        this.storeProductsEntitiesState.lazyLoad();
    }

    @DataAction()
    initialLoad() {
        this.patchState({ loading: true });
        return this.productsService.fetch().pipe(tap((products) => {
            this.setEntitiesAll(products);
            this.patchState({ loading: false, loaded: true });
        }));
    }

    @DataAction()
    lazyLoad() {
        !this.snapshot.loading &&
            !this.snapshot.loaded &&
            this.load();
    }

    public onStateChange(callback: (state?: ProductsEntitiesState) => void) {
        if (!this.snapshot.loading) {
            callback(this);
        }
        this._ngxsOnChange = callback;
    }
}
