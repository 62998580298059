import { Injectable } from '@angular/core';
import { 
    HttpClient as SignalrHttpClient, 
    HttpRequest  as SignalrHttpRequest, 
    HttpResponse as SignalrHttpResponse, 
} from "@microsoft/signalr";
import { 
    HttpClient as AngularHttpClient, 
    HttpRequest as AngularHttpRequest, 
    HttpResponse as AngularHttpResponse, 
    HttpErrorResponse as AngularHttpErrorResponse, 
    HttpContext, 
    HttpEvent, 
    HttpHeaders
} from "@angular/common/http";
import HttpContextTokens from "src/app/_intercepters/http-context-tokens";

@Injectable({
  providedIn: 'root'
})
export class SignalrHttpClientWrapper extends SignalrHttpClient {

    constructor(private internalHttpClient: AngularHttpClient) {
        super();
    }

    public async send(request: SignalrHttpRequest): Promise<SignalrHttpResponse> {
        const context = new HttpContext()
            .set(HttpContextTokens.SKIP_ERROR_HANDLING, true)
            .set(HttpContextTokens.MAX_RETRY_OVERRIDE, Infinity);
        const angularRequest = new AngularHttpRequest(
            request.method.toUpperCase() as never,
            request.url,
            request.content,
            {
                headers: new HttpHeaders(request.headers),
                responseType: 'text' as const,
                withCredentials: request.withCredentials,
                context,
            }
        );

       try {
           const response = await this.internalHttpClient.request(angularRequest).toPromise();
           return this.convertToSignalrResponse(response);
       } catch (err: unknown) {
           return this.convertToSignalrResponse(err as never);
       }
    }

    private convertToSignalrResponse(response: HttpEvent<unknown>): SignalrHttpResponse {
        if (response instanceof AngularHttpResponse){
            return new SignalrHttpResponse(response.status, response.statusText, response.body as never);
        } else if (response instanceof AngularHttpErrorResponse) {
            return new SignalrHttpResponse(response.status, response.statusText, response.error);
        }
        throw new Error(`Not Implemented for resposneType: ${response.type}`);
    }
}
