import { Injectable } from '@angular/core';
import { State } from '@ngxs/store';
import { DataAction, StateRepository } from '@angular-ru/ngxs/decorators';
import { createEntityCollections, EntityIdType } from '@angular-ru/cdk/entity';
import { NgxsDataEntityCollectionsRepository } from '@angular-ru/ngxs/repositories';
import BaseEntityCollectionsOptions from '../base-entity-collections-options';
import { HelperService } from 'src/app/_services/helper.service';
import { LocationModel } from 'src/app/_models/location.model';
import { LocationsService } from 'src/app/_services/locations.service';

@StateRepository()
@State({
    name: 'locations',
    defaults: {
        ...createEntityCollections(),
        loading: false,
        loaded: false
    }
})
@Injectable()
export default class LocationsEntitiesState
    extends NgxsDataEntityCollectionsRepository<LocationModel, EntityIdType, BaseEntityCollectionsOptions> {

    constructor(
        private locationService: LocationsService,
        private helperService: HelperService
    ) {
        super();
    }

    ngxsAfterBootstrap() {
        this.helperService.onUserIsActive(() => {
            this.load();
        });
    }

    @DataAction()
    public load() {
        this.locationService.getAll().subscribe(result => {
            this.setEntitiesAll(result);
        });
    }

    @DataAction()
    public lazyLoad() {
        !this.snapshot.loaded &&
            !this.snapshot.loading &&
            this.load();
    }
}
