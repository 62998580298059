import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {UIActionListComponent} from './ui-action-list/ui-action-list.component';
import {UIActionComponent} from './ui-action-list/ui-action/ui-action.component';
import {UIButtonGroupComponent} from './ui-button-group/ui-button-group.component';
import {UIButtonComponent} from './ui-button/ui-button.component';
import {UICardComponent} from './ui-card/ui-card.component';
import {UIDataCellComponent} from './ui-data-table/ui-data-cell/ui-data-cell.component';
import {UIDataHeaderComponent} from './ui-data-table/ui-data-header/ui-data-header.component';
import {UIDataRowComponent} from './ui-data-table/ui-data-row/ui-data-row.component';
import {UIDataTableComponent} from './ui-data-table/ui-data-table.component';
import {UIHeadingComponent} from './ui-heading/ui-heading.component';
import {UIIconComponent} from './ui-icon/ui-icon.component';
import {UILabelComponent} from './ui-label/ui-label.component';
import {UISpinnerComponent} from './ui-spinner/ui-spinner.component';
import {UITextComponent} from './ui-text/ui-text.component';
import {UISelectComponent} from './ui-select/ui-select.component';
import {UISelectOptionComponent} from './ui-select/ui-select-option/ui-select-option.component';
import {UITabsComponent} from './ui-tabs/ui-tabs.component';
import {UITabComponent} from './ui-tabs/ui-tab/ui-tab.component';
import {UIRadioGroupComponent} from './ui-radio-group/ui-radio-group.component';
import {UIRadioComponent} from './ui-radio-group/ui-radio/ui-radio.component';
import {ScrollingModule} from '@angular/cdk/scrolling';

@NgModule({
  declarations: [
    UITextComponent,
    UIHeadingComponent,
    UILabelComponent,
    UIIconComponent,
    UIActionListComponent,
    UIActionComponent,
    UIButtonComponent,
    UIButtonGroupComponent,
    UICardComponent,
    UISpinnerComponent,
    UIDataTableComponent,
    UIDataRowComponent,
    UIDataHeaderComponent,
    UIDataCellComponent,
    UISelectComponent,
    UISelectOptionComponent,
    UITabsComponent,
    UITabComponent,
    UIRadioGroupComponent,
    UIRadioComponent
  ],
  exports: [
    UITextComponent,
    UIHeadingComponent,
    UILabelComponent,
    UIIconComponent,
    UIActionListComponent,
    UIActionComponent,
    UIButtonComponent,
    UIButtonGroupComponent,
    UICardComponent,
    UISpinnerComponent,
    UIDataTableComponent,
    UIDataRowComponent,
    UIDataHeaderComponent,
    UIDataCellComponent,
    UISelectComponent,
    UISelectOptionComponent,
    UITabsComponent,
    UITabComponent,
    UIRadioGroupComponent,
    UIRadioComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        ScrollingModule
    ]
})
export class UIComponentsModule {
}
