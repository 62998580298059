import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'allChecked'
})
export class allCheckedPipe implements PipeTransform {
    transform(_items: any[] = [], selectedItems: number[] = [], keyStr: string): boolean {
        const keys = keyStr.split(",");
        return _items.length && _items.every(item => selectedItems.findIndex(si => keys.every(k => si[k.trim()] == item[k.trim()])) > -1);
    }
}