import {NgModule} from '@angular/core';
import {ReadableStatusPipe} from './processing-statuses/readable-status.pipe';
import {LatestProcessingStatusPipe} from './processing-statuses/latest-processing-status.pipe';
import {ProductByIdPipe} from './products/product-by-id.pipe';
import {ProductImageUrlPipe} from './products/product-image-url.pipe';
import {StoreProductByIdPipe} from './store-products/store-product-by-id.pipe';
import {AsOrderArrayPipe} from './orders/as-order-array.pipe';
import {AsOrderItemArrayPipe} from './orders/order-items/as-order-item-array.pipe';
import {OpenOrdersOfStorePipe} from './orders/open-orders-of-store.pipe';
import {OrdersByWarehousePipe} from './orders/orders-by-warehouse.pipe';
import {TruncatePipe} from './util-pipes/truncate.pipe';
import {TotalQuantityOrderedPipe} from './orders/total-quantity-ordered.pipe';
import {OrderTotalPipe} from './orders/order-total.pipe';
import {ShipmentsOfOrderPipe} from './shipments/shipments-of-order.pipe';
import {ProviderImageUrlPipe} from './shipping-profiles/provider-image-url.pipe';
import { DistinctPipe } from './util-pipes/distinct.pipe';
import { ToWordsPipe } from './util-pipes/to-words.pipe';
import { ProductTagPipe } from './productTag/product-tag.pipe';
import { HasPermissionPipe } from './hasPermission/has-permission.pipe';
import { GetStoreNamePipe } from './getStoreName/get-store-name.pipe';

@NgModule({
    declarations: [
        ReadableStatusPipe,
        LatestProcessingStatusPipe,
        ProductByIdPipe,
        ProductImageUrlPipe,
        StoreProductByIdPipe,
        AsOrderArrayPipe,
        AsOrderItemArrayPipe,
        OpenOrdersOfStorePipe,
        OrdersByWarehousePipe,
        TruncatePipe,
        ToWordsPipe,
        DistinctPipe,
        TotalQuantityOrderedPipe,
        OrderTotalPipe,
        ShipmentsOfOrderPipe,
        ProviderImageUrlPipe,
        ProductTagPipe,
        HasPermissionPipe,
        GetStoreNamePipe
    ],
    imports: [],
    exports: [
        ReadableStatusPipe,
        LatestProcessingStatusPipe,
        ProductByIdPipe,
        ProductImageUrlPipe,
        StoreProductByIdPipe,
        AsOrderArrayPipe,
        AsOrderItemArrayPipe,
        OpenOrdersOfStorePipe,
        OrdersByWarehousePipe,
        TruncatePipe,
        ToWordsPipe,
        DistinctPipe,
        TotalQuantityOrderedPipe,
        OrderTotalPipe,
        ShipmentsOfOrderPipe,
        ProviderImageUrlPipe,
        ProductTagPipe,
        HasPermissionPipe,
        GetStoreNamePipe
    ]
})
export class PipesModule {

}
