import { BaseModel } from "../_base.model";
import { WarehouseShelfModel } from "./warehouse-shelf.model";
import IStockLocationModel from "./stock-location.model";

export class WarehouseRackModel extends BaseModel implements IStockLocationModel<number> {
    id: number;
    name: string;
    referenceId: number;
    aisleId: number;
    shelves: WarehouseShelfModel[];
}