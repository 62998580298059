// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// RxJS
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
// Models
import { AddressModel } from '../../_models/address.model';

import { HelperService } from '../helper.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

const API_ADDRESSES_URL = environment.baseUrl + '/addresses';

@Injectable()
export class AddressesService {
	constructor(private http: HttpClient,
		private helperService: HelperService,
		private toastr: ToastrService,
		private translate: TranslateService) { }

	getAll(allFields: boolean = false): Observable<any> {
		let params: any = {};
		if (allFields)
			params.allFields = true;
		return this.http.get<any>(API_ADDRESSES_URL,
			{ headers: this.helperService.getHTTPHeaders(), params: params })
			.pipe(catchError(this.handleError('getAll')));
	}

	getById(id: number): Observable<AddressModel> {
		const url = `${API_ADDRESSES_URL}/${id}`;
		return this.http.get<AddressModel>(url,)
			.pipe(catchError(this.handleError<AddressModel>(`getById id=${id}`)));
	}

	create(address: AddressModel): Observable<any> {
		const url = `${API_ADDRESSES_URL}/items`;
		return this.http.post<AddressModel>(url, address,
		)
			.pipe(catchError(this.handleError<any>('createItem')));
	}

	update(address: AddressModel, removedLocationId: number = null): Observable<any> {
		const url = `${API_ADDRESSES_URL}/items`;
		let params: any = {};
		if (removedLocationId)
			params.removedLocationId = removedLocationId;
		return this.http.put(url, address, { headers: this.helperService.getHTTPHeaders(), params: params })
			.pipe(catchError(this.handleError<any>('update')));
	}

	delete(id: number, convertSubsToMain: boolean = false) {
		return this.http.delete(`${API_ADDRESSES_URL}/items/${id}`,
		)
			.pipe(catchError(this.handleError<AddressModel>('delete')));
	}

	private handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			switch (error.status) {
				case 401:
					window.location.href = '/login';
					break;
				case 422:
					this.toastr.error(this.translate.instant('WAREHOUSES.MESSAGES.ERRORS.' + error.error.reason, error.error));
					break;
				default:
					this.toastr.error(this.translate.instant('GENERAL.MESSAGES.ERROR'));
			}
			return throwError(error);
		};
	}
}
