import { BaseModel } from "../_base.model";
import IStockLocationModel from "./stock-location.model";
import { WarehouseBinItemModel } from "./warehouse-bin-item.model";

export class WarehouseBinModel extends BaseModel implements IStockLocationModel<number> {
    id: number;
    name: string;
    referenceId: number;
    shelfId: number;
    items: WarehouseBinItemModel[]
}