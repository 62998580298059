import {NgModule} from '@angular/core';
import {TooltipComponent} from './tooltip.component';
import {CommonModule} from '@angular/common';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    TooltipComponent
  ],
  exports: [
    TooltipComponent
  ]
})
export class TooltipModule {

}

