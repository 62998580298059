import * as _ from 'lodash';
import {PackingProcessingStatus} from 'src/app/_entities/orders/processing-statuses/packing-processing-status';
import {PickingProcessingStatus} from 'src/app/_entities/orders/processing-statuses/picking-processing-status';
import {ProcessingStatus} from 'src/app/_entities/orders/processing-statuses/processing-status';
import {ShippingProcessingStatus} from 'src/app/_entities/orders/processing-statuses/shipping-processing-status';
import ProcessingStatusHelper from 'src/app/_helpers/processing-status.helper';
import {OrderBoxItemModel} from 'src/app/_models/orders/order-boxes/order-box-item.model';
import OrderItem from '../order-item.entity';
import {StatesHelper} from '../../../states/states.helper';

export default class OrderBoxItem extends OrderBoxItemModel {

    constructor(orderBoxItem: OrderBoxItemModel) {
        super();
        _.assignIn(this, orderBoxItem);
    }


    get orderItem(): OrderItem {
        return new OrderItem(
            StatesHelper.ordersEntitiesState
                .entities[this.orderId]
                .items
                .find(item => item.id === this.orderItemId)
        );
    }

    // Statuses
    get hasNoStatusYet() {
        // tslint:disable-next-line:no-bitwise
        return !!(ProcessingStatusHelper.getProcessingStatus(this).value & ProcessingStatus.None.value);
    }

    get isBeingPicked() {
        return ProcessingStatusHelper.getProcessingStatus(this).value >= PickingProcessingStatus.Picking.value;
    }

    get isPicked() {
        return ProcessingStatusHelper.getProcessingStatus(this).value >= PickingProcessingStatus.Picked.value;
    }

    get isBeingPacked() {
        return ProcessingStatusHelper.getProcessingStatus(this).value >= PackingProcessingStatus.Packing.value;
    }

    get isPacked() {
        return ProcessingStatusHelper.getProcessingStatus(this).value >= PackingProcessingStatus.Packed.value;
    }

    get isReadyToShip() {
        return this.isPacked;
    }

    get isPartiallyShipped() {
        return ProcessingStatusHelper.getProcessingStatus(this).value >= ShippingProcessingStatus.ShippedPartial.value;
    }

    get isShipped() {
        return ProcessingStatusHelper.getProcessingStatus(this).value >= ShippingProcessingStatus.Shipped.value;
    }

    get isDelivered() {
        return false; // TODO
    }

    get latestProcessingStatus() {
        return ProcessingStatusHelper.getProcessingStatus(this);
    }

    toModel() {
        return StatesHelper.ordersEntitiesState
            .entities[this.orderId]
            .boxes
            .find(box => box.id === this.orderBoxId)
            .items
            .find(boxItem => boxItem.id === this.id);
    }
}
