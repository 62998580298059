export class StoreProductModel {
    productId: number;
    storeId: number;
    imageUrl: string;
    asin: string;
    listingId: string;
    fnsku: string;
    orders: number;
    inStock: number;
    buyBox: number;
    realTime: number;
}
