// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// RxJS
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
// Models
import { SupplierModel } from '../../_models/supplier.model';

import { HelperService } from '../helper.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

const API_SUPPLIERS_URL = environment.baseUrl + '/suppliers';

export interface SupplierDto {
	id: number;
	name: string;
	countryCode: string;
	bySea: number;
	byAir: number;
	defaultBySea: boolean;
}

@Injectable()
export class SuppliersService {
	allSuppliers: SupplierModel[];

	constructor(private http: HttpClient,
		private helperService: HelperService,
		private toastr: ToastrService,
		private translate: TranslateService) { }

	getAll(page: number = 1, pageSize: number = 20, sortField: string = undefined,
		sort: string = undefined, filters: any = undefined): Observable<any> {
		let params: any = { page: page, pageSize: pageSize };
		if (sortField)
			params.sortField = sortField;
		if (sort)
			params.sort = sort;
		if (filters)
			params.filters = JSON.stringify(filters);
		return this.http.get<any>(API_SUPPLIERS_URL,
			{ headers: this.helperService.getHTTPHeaders(), params: params })
			.pipe(catchError(this.handleError('getAll')));
	}

	getAllSuppliers(): Observable<SupplierModel[]> {
		if (this.allSuppliers) {
			return of(this.allSuppliers);
		} else {
			return this.http.get<SupplierModel[]>(`${API_SUPPLIERS_URL}/all`,
				{ headers: this.helperService.getHTTPHeaders() })
				.pipe(tap(resp => this.allSuppliers = resp || []), catchError(this.handleError<SupplierModel[]>('getAllSuppliers')));
		}
	}

	fetch(): Observable<SupplierModel[]> {
		return this.http.get<SupplierModel[]>(`${API_SUPPLIERS_URL}/fetchSuppliers`);
	}

	getById(id: number): Observable<SupplierModel> {
		const url = `${API_SUPPLIERS_URL}/${id}`;
		return this.http.get<SupplierModel>(url, { headers: this.helperService.getHTTPHeaders() }).pipe(
			catchError(this.handleError<SupplierModel>(`getById id=${id}`))
		);
	}

	create(supplier: SupplierModel): Observable<SupplierModel> {
		const url = `${API_SUPPLIERS_URL}`;
		return this.http.post<SupplierModel>(url, supplier,
			{ headers: this.helperService.getHTTPHeaders() }).pipe(
				catchError(this.handleError<any>('createItem'))
			);
	}

	update(supplier: SupplierModel): Observable<any> {
		const url = `${API_SUPPLIERS_URL}`;
		return this.http.put(url, supplier,
			{ headers: this.helperService.getHTTPHeaders() }).pipe(
				catchError(this.handleError<any>('update'))
			);
	}

	delete(id: number, convertSubsToMain: boolean = false) {
		return this.http.delete(`${API_SUPPLIERS_URL}/${id}`,
			{ headers: this.helperService.getHTTPHeaders() }).pipe(
				catchError(this.handleError<SupplierModel>('delete'))
			);
	}

	saveNotes(supplier: SupplierModel): Observable<any> {
		const url = `${API_SUPPLIERS_URL}/SaveNotes`;
		return this.http.put(url, supplier,
			{ headers: this.helperService.getHTTPHeaders() }).pipe(
				catchError(this.handleError<any>('notes'))
			);
	}

	private handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			switch (error.status) {
				case 401:
					window.location.href = '/login';
					break;
				case 422:
					this.toastr.error(this.translate.instant('SUPPLIERS.MESSAGES.ERRORS.' + error.error.reason, error.error));
					break;
				default:
					this.toastr.error(this.translate.instant('GENERAL.MESSAGES.ERROR'));
			}
			return throwError(error);
		};
	}
}
