import { Injectable } from '@angular/core';
import { NgxsAfterBootstrap, NgxsOnChanges, NgxsSimpleChange, State, Store } from '@ngxs/store';
import WarehouseCartBin from 'src/app/_entities/warehouses/carts/warehouse-cart-bin.entity';
import Warehouse from 'src/app/_entities/warehouses/warehouse.entity';
import WarehouseCartModel from 'src/app/_models/warehouses/warehouse-cart.model';
import { WarehouseModel } from 'src/app/_models/warehouses/warehouse.model';
import { HelperService } from 'src/app/_services/helper.service';
import { WarehousesService } from 'src/app/_services/warehouses/warehouses.service';
import { Computed, DataAction, StateRepository } from '@angular-ru/ngxs/decorators';
import { createEntityCollections, EntityIdType } from '@angular-ru/cdk/entity';
import { NgxsDataEntityCollectionsRepository } from '@angular-ru/ngxs/repositories';
import BaseEntityCollectionsOptions from '../base-entity-collections-options';
import EntityMap from '../../_builders/entity-map';
import WarehouseCart from '../../_entities/warehouses/carts/warehouse-cart.entity';
import WarehouseCartBinModel from '../../_models/warehouses/warehouse-cart-bin.model';
import EntityMapper from '../../_builders/entity.mapper';
import { EntityMapId } from '../../_builders/i-entity-map';
import WarehouseAisle from 'src/app/_entities/warehouses/locations/warehouse-aisle.entity';
import { WarehouseShelf } from 'src/app/_entities/warehouses/locations/warehouse-shelf.entity';
import { WarehouseRack } from 'src/app/_entities/warehouses/locations/warehouse-rack.entity';
import WarehouseBin from 'src/app/_entities/warehouses/locations/warehouse-bin.entity';

@StateRepository()
@State({
    name: 'warehouses',
    defaults: {
        ...createEntityCollections(),
        loading: false
    },
})
@Injectable()
export default class WarehousesEntitiesState
    extends NgxsDataEntityCollectionsRepository<WarehouseModel, EntityIdType, BaseEntityCollectionsOptions>
    implements NgxsAfterBootstrap, NgxsOnChanges {

    private _ngsxOnChange = (state?: WarehousesEntitiesState) => { };
    private _changeObserver: typeof this.state$;

    constructor(
        private helperService: HelperService,
        private warehouseService: WarehousesService
    ) {
        super();
    }

    ngxsOnChanges(_?: NgxsSimpleChange) {
        super.ngxsOnChanges(_);
        if (this.isInitialised && !this._changeObserver) {
            this._changeObserver = this.state$;
            this._changeObserver.subscribe(() => this._ngsxOnChange(this));
        }
    }

    @Computed()
    get warehouseEntities(): Warehouse[] {
        return this.entitiesArray
            .map(warehouse => new Warehouse(warehouse));
    }

    @Computed()
    get warehouseEntityMap(): EntityMap<Warehouse> {
        return new EntityMap<Warehouse>(
            EntityMapper.mapById(this.warehouseEntities)
        );
    }

    @Computed()
    get warehouseAislesEntityMap(): EntityMap<WarehouseAisle> {
        return new EntityMap<WarehouseAisle>(
            EntityMapper.mapById(this.entitiesArray.flatMap(w => w.warehouseAisles).map(aisle => new WarehouseAisle(aisle)))
        );
    }

    @Computed()
    get warehouseRacksEntityMap(): EntityMap<WarehouseRack> {
        return new EntityMap<WarehouseRack>(
            EntityMapper.mapById(
                this.entitiesArray
                    .flatMap(w => w.warehouseAisles)
                    .flatMap(aisle => aisle.racks)
                    .map(rack => new WarehouseRack(rack))
            )
        );
    }

    @Computed()
    get warehouseShelvesEntityMap(): EntityMap<WarehouseShelf> {
        return new EntityMap<WarehouseShelf>(
            EntityMapper.mapById(
                this.entitiesArray
                    .flatMap(w => w.warehouseAisles)
                    .flatMap(aisle => aisle.racks)
                    .flatMap(rack => rack.shelves)
                    .map(shelf => new WarehouseShelf(shelf))
            )
        );
    }

    @Computed()
    get warehouseBinsEntityMap(): EntityMap<WarehouseBin> {
        return new EntityMap<WarehouseBin>(
            EntityMapper.mapById(
                this.entitiesArray
                    .flatMap(w => w.warehouseAisles)
                    .flatMap(aisle => aisle.racks)
                    .flatMap(rack => rack.shelves)
                    .flatMap(shelf => shelf.bins)
                    .map(bin => new WarehouseBin(bin))
            )
        );
    }

    @Computed()
    get warehouseCartsEntityMap(): EntityMap<WarehouseCart> {
        return new EntityMap<WarehouseCart>(
            EntityMapper.mapById(this.entitiesArray.flatMap(w => w.warehouseCarts).map(cart => new WarehouseCart(cart)))
        );
    }

    @Computed()
    get warehouseCartBinsEntityMap(): EntityMap<WarehouseCartBin> {
        return new EntityMap<WarehouseCartBin>(
            EntityMapper.mapById(
                this.entitiesArray
                    .flatMap(w => w.warehouseCarts)
                    .flatMap(wc => wc.bins)
                    .map(bin => new WarehouseCartBin(bin))
            )
        );
    }

    @Computed()
    public get cartBinEntitiesById(): (...ids: number[]) => EntityMap<WarehouseCartBin> {
        return (...ids: number[]) => {
            return this.warehouseCartBinsEntityMap
                .subset(...ids as unknown as EntityMapId<WarehouseCartBin>[]);
        };
    }

    @Computed()
    public get cartBinsOfWarehouse() {
        return (warehouseId: number) => {
            return this.entities[warehouseId]
                .warehouseCarts
                .flatMap(wc => wc.bins)
                .map(bin => new WarehouseCartBin(bin));
        };
    }

    ngxsAfterBootstrap() {
        this.helperService.onUserIsActive(() => {
            this.load();
        });
    }

    // Actions
    @DataAction()
    load() {
        this.patchState({ loading: true });
        this.warehouseService
            .fetch()
            .subscribe(warehouses => {
                this.setEntitiesAll(warehouses);
                this.patchState({ loading: false, loaded: true });
            });
    }

    @DataAction()
    upsertCarts(...carts: WarehouseCartModel[]) {
        this.updateEntitiesMany(carts.map(cart => ({
            id: cart.warehouseId,
            changes: {
                warehouseCarts: this.entities[cart.warehouseId]
                    .warehouseCarts
                    .filter(c => c.id !== cart.id)
                    .concat([cart])
            }
        })));
    }

    @DataAction()
    upsertBins(...bins: WarehouseCartBinModel[]) {
        const allCartsMap = EntityMapper.mapById(this.entitiesArray.flatMap(warehouse => warehouse.warehouseCarts));
        this.updateEntitiesMany(bins.map(bin => ({
            id: allCartsMap[bin.warehouseCartId].warehouseId,
            changes: {
                warehouseCarts: this.entities[allCartsMap[bin.warehouseCartId].warehouseId]
                    .warehouseCarts
                    .map(wc => {
                        wc.bins = wc.bins
                            .filter(wcb => wcb.id !== bin.id)
                            .concat([bin]);
                        return wc;
                    })
            }
        })));
    }

    @DataAction()
    deleteCarts(...cartIds: number[]) {
        const allCartsMap = EntityMapper.mapById(this.entitiesArray.flatMap(warehouse => warehouse.warehouseCarts));
        this.updateEntitiesMany(cartIds.map(cartId => ({
            id: allCartsMap[cartId].warehouseId,
            changes: {
                warehouseCarts: this.entities[allCartsMap[cartId].warehouseId]
                    .warehouseCarts
                    .filter(wc => wc.id !== cartId)
            }
        })));
    }

    @DataAction()
    deleteBins(...binIds: number[]) {
        const allCartsMap = EntityMapper.mapById(this.entitiesArray.flatMap(warehouse => warehouse.warehouseCarts));
        const allBinsMap = EntityMapper.mapById((Object.values(allCartsMap) as WarehouseCartModel[]).flatMap(cart => cart.bins));
        this.updateEntitiesMany(binIds.map(binId => ({
            id: allCartsMap[allBinsMap[binId].warehouseCartId].warehouseId,
            changes: {
                warehouseCarts: this.entities[allCartsMap[allBinsMap[binId].warehouseCartId].warehouseId]
                    .warehouseCarts
                    .map(wc => {
                        wc.bins = wc.bins
                            .filter(bin => bin.id !== binId);
                        return wc;
                    })
            }
        })));
    }

    public onStateChange(callback: (state: WarehousesEntitiesState) => void) {
        this._ngsxOnChange = callback;
    }
}
