export const HttpHeaderNames = {

    CONTENT_TYPE: 'Content-Type',
    AUTHORIZATION: 'Authorization',
    ACCEPT: 'Accept',
    CACHE_CONTROL: 'Cache-Control'
}


export const HttpContentTypes = {
    APPLICATION_JSON: 'application/json'
}
