// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// RxJS
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
// Models

import { HelperService } from './helper.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { LocationModel } from '../_models/location.model';

const API_LOCATIONS_URL = environment.baseUrl + '/locations';

// Real REST API
@Injectable()
export class LocationsService {
  constructor(private http: HttpClient,
    private helperService: HelperService,
    private toastr: ToastrService,
    private translate: TranslateService) {
  }

  getAll(type: string = null): Observable<LocationModel[]> {
    let params: any = {};
    if (type) {
      params.type = type;
    }
    return this.http.get<any>(API_LOCATIONS_URL, { headers: this.helperService.getHTTPHeaders(), params: params })
      .pipe(catchError(this.handleError('getAll')));
  }

  getActivities(locationId: number, productId: number, page: number, pageSize: number,
    from: string = null, to: string = null, filters: any = {}) {
    let params: any = { page: page, pageSize: pageSize };
    if (from)
      params.from = from;
    if (to)
      params.to = to;
    if (filters)
      params.filters = JSON.stringify(filters);
    const url = `${API_LOCATIONS_URL}/${locationId}/activities/${productId}`;
    return this.http.get<any>(url, { headers: this.helperService.getHTTPHeaders(), params: params })
      .pipe(catchError(this.handleError('getActivities')));
  }

  getStoreLocations(storeId: number): Observable<any[]> {
    return this.http.get<any>(API_LOCATIONS_URL + '/GetStoreLocations/' + storeId, {
      headers: this.helperService.getHTTPHeaders()
    }).pipe(catchError(this.handleError('GetStoreLocations')));
  }

  getSublocations(locationId: number, productIds: number[]): Observable<any[]> {
    return this.http.get<any>(`${API_LOCATIONS_URL}/${locationId}/Sublocations`,
      { headers: this.helperService.getHTTPHeaders(), params: { productIds: productIds } as any })
      .pipe(catchError(this.handleError('getSublocations')));
  }
  
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      this.toastr.error(this.translate.instant('GENERAL.MESSAGES.ERROR'));
      return throwError(error);
    };
  }
}