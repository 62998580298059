import {Injectable} from '@angular/core';
import {NgxsOnChanges, NgxsSimpleChange, State} from '@ngxs/store';
import {ShippingAccountModel} from 'src/app/_models/shipping-account.model';
import {ShippingAccountsService} from 'src/app/_services/shipping-accounts.service';
import {DataAction, StateRepository} from '@angular-ru/ngxs/decorators';
import {createEntityCollections, EntityIdType} from '@angular-ru/cdk/entity';
import {NgxsDataEntityCollectionsRepository} from '@angular-ru/ngxs/repositories';
import BaseEntityCollectionsOptions from '../base-entity-collections-options';
import {tap} from 'rxjs/operators';

@StateRepository()
@State({
    name: 'shippingAccounts',
    defaults: {
        ...createEntityCollections(),
        loading: false
    }
})
@Injectable()
export class ShippingAccountsEntitiesState
    extends NgxsDataEntityCollectionsRepository<ShippingAccountModel, EntityIdType, BaseEntityCollectionsOptions>
    implements NgxsOnChanges {

    private _ngxsOnChange = (state?: ShippingAccountsEntitiesState) => {};
    private _changeObserver: typeof this.state$;

    constructor(
        private shippingAccountsService: ShippingAccountsService
    ) {
        super();
    }

    ngxsOnChanges(_?: NgxsSimpleChange) {
        super.ngxsOnChanges(_);
        if (this.isInitialised && !this._changeObserver) {
            this._changeObserver = this.state$;
            this._changeObserver.subscribe(() => this._ngxsOnChange());
        }
    }

    // Actions
    @DataAction()
    public load() {
        this.patchState({loading: true});
        return this.shippingAccountsService.fetch()
            .pipe(tap(result => {
                this.setEntitiesAll(result);
                this.patchState({ loading: false, loaded: true });
            }));
    }

    @DataAction()
    lazyLoad() {
        !this.snapshot.loading &&
        !this.snapshot.loaded &&
        this.load();
    }

    public onStateChange(callback: typeof this._ngxsOnChange) {
        this._ngxsOnChange = callback;
    }
}
