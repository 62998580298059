import { BaseModel } from './_base.model';

export class CurrencyModel extends BaseModel {
  id = 5;
  code = 'USD';
  symbol = '$';
  conversionRate = 1;
}

export const DEFAULT_CURRENCY = new CurrencyModel();
