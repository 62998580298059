import { BehaviorSubject } from "rxjs";
import { share } from "rxjs/operators";

export default class Loading {

    private readonly _loading$ = new BehaviorSubject<boolean>(false);

    public loading$ = this._loading$.asObservable().pipe(share());

    set loading(value: boolean) {
        this._loading$.next(value);
    }

    get loading() {
        return this._loading$.value;
    }

    reset() {
        this.loading = false;
    }
}
