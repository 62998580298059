import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
// RxJS
// Translate
// Auth
import { ConfirmPasswordValidator } from './confirm-password.validator';
import { AuthService } from 'src/app/_services/auth.service';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserRegisterModel } from '../../../_models/user-register.model';
import { ToastrService } from 'ngx-toastr';
import { Countries } from 'src/app/_global/countries';
import { CustomStepDefinition, Options } from 'ngx-slider-v2/options';
import { AddressModel } from 'src/app/_models/address.model';
import { CompleteRegistrationModel } from 'src/app/_models/complete-registration.model';
import { StripeCardElementOptions } from '@stripe/stripe-js';
import { StripeCardComponent, StripeFactoryService, StripeInstance } from 'ngx-stripe';
import { HelperService } from 'src/app/_services/helper.service';
import { Store } from '@ngxs/store';
import { UserActions } from 'src/app/states/user/user.actions';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, finalize } from 'rxjs/operators';
import AppInitService from 'src/app/_services/config/app-init.service';
import _ from 'lodash';
import { AppConfig } from 'src/environments/app.config';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'kt-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  registerForm: UntypedFormGroup;
  addressForm: UntypedFormGroup;
  billingForm: UntypedFormGroup;
  loading = false;
  errors: any = [];
  pricingPlans: any = { plans: [] };
  year: number = new Date().getFullYear();
  step: number = 1;
  usersOptions: Options = {
    floor: 15,
    ceil: 15,
    step: 15,
    showTicks: true,
    showTicksValues: false,
    hidePointerLabels: true,
    hideLimitLabels: true
  };
  skusOptions: Options = {
    floor: 10000,
    ceil: 30000,
    step: 10000,
    showTicks: true,
    showTicksValues: false,
    hidePointerLabels: true,
    hideLimitLabels: true
  };
  ordersOptions: Options = {
    floor: 50000,
    ceil: 200000,
    step: 25000,
    showTicks: true,
    showTicksValues: false,
    hidePointerLabels: true,
    hideLimitLabels: true
  };
  @ViewChild(StripeCardComponent) card: StripeCardComponent;

  stripeInstance: StripeInstance;

  errMessages = {
    firstName: [
      { type: 'required', message: 'First Name is required.' },
    ],
    lastName: [
      { type: 'required', message: 'Last Name is required.' }
    ],
    email: [
      { type: 'required', message: 'Email is required.' },
      { type: 'minlength', message: 'Email length.' },
      { type: 'maxlength', message: 'Email length.' },
      { type: 'required', message: 'please enter a valid email address.' }
    ],
    password: [
      { type: 'required', message: 'password is required.' },
      { type: 'pattern', message: 'Password must contain at least one digit, uppercase, lowercase, and special letter.' },
      { type: 'minlength', message: 'password length.' },
      { type: 'maxlength', message: 'password length.' }
    ],
    confirmpassword: [
      { type: 'required', message: 'password is required.' },
      { type: 'minlength', message: 'password length.' },
      { type: 'maxlength', message: 'Password cannot be longer than 25 characters.' }
    ],
  };

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '1.5',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }
  };

  constructor(
    private toastr: ToastrService,
    private stateStore: Store,
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    private fb: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    public modalService: NgbModal,
    public countries: Countries,
    private appInitService: AppInitService,
    private helperService: HelperService,
    stripeFactoryService: StripeFactoryService
  ) {
    this.appInitService.appConfig$
      .pipe(
        filter((appConfig: AppConfig) => appConfig?.isInitialized && !!appConfig?.stripeKey),
        untilDestroyed(this)
      )
      .subscribe((appConfig: AppConfig) => {
        this.stripeInstance = stripeFactoryService.create(appConfig.stripeKey);
      });
  }

  ngOnInit() {
    this.step = Number(this.route.snapshot.queryParams.step || 1);
    if (this.step > 1 && !this.helperService.currentUser) {
      this.router.navigateByUrl('', { skipLocationChange: true }).then(() => this.router.navigateByUrl('register'));
      return;
    }
    switch (this.step) {
      case 1:
        this.initRegisterForm();
        break;
      case 2:
        this.initAddressForm();
        break;
      case 3:
        if (this.helperService.currentUser.organizationName) {
          if (!this.helperService.currentUser.subscriptionStatus) {
            this.initBillingForm();
            this.auth.getPricingPlans().subscribe(result => {
              this.pricingPlans = result;

              const allowedUsers = result.customUsers.map(p => p.allowedUsers);
              this.usersOptions = {
                ...this.usersOptions,
                stepsArray: _.orderBy(allowedUsers.map(value => ({ value } as CustomStepDefinition)), x => x.value),
                floor: Math.min(...allowedUsers),
                ceil: Math.max(...allowedUsers)
              };

              const allowedSkus = result.customSkus.map(p => p.allowedSkus);
              this.skusOptions = {
                ...this.skusOptions,
                stepsArray: _.orderBy(allowedSkus.map(value => ({ value } as CustomStepDefinition)), x => x.value),
                floor: Math.min(...allowedSkus),
                ceil: Math.max(...allowedSkus)
              };

              const allowedOrders = result.customOrders.map(p => p.allowedOrders);
              this.ordersOptions = {
                ...this.ordersOptions,
                stepsArray: _.orderBy(allowedOrders.map(value => ({ value } as CustomStepDefinition)), x => x.value),
                floor: Math.min(...allowedOrders),
                ceil: Math.max(...allowedOrders)
              };

              const cheapestPlan = _.chain(result.plans ?? [])
                .orderBy(plan => plan.monthlyPrice)
                .first()
                .value();

              this.billingForm.controls.planStripId?.setValue(cheapestPlan?.stripeId);
              this.billingForm.controls.allowedUsers?.setValue(this.usersOptions.floor);
              this.billingForm.controls.allowedSkus?.setValue(this.skusOptions.floor);
              this.billingForm.controls.allowedOrders?.setValue(this.ordersOptions.floor);
            });
          }
          else
            this.router.navigateByUrl('changePlan');
        }
        else
          this.router.navigateByUrl('', { skipLocationChange: true })
            .then(() => this.router.navigate(['register'], { queryParams: { step: 2 } }));
        break;
    }
  }

  get isInvited() {
    const invitedEmail = this.route.snapshot.queryParams.invitedEmail;
    return invitedEmail && this.registerForm?.value?.email === invitedEmail;
  }

  skusSliderChanged(e: any) {
    this.billingForm.controls.allowedSkus.setValue(e.value);
  }

  ordersSliderChanged(e: any) {
    this.billingForm.controls.allowedOrders.setValue(e.value);
  }

  get customPrice(): number {
    const allowedSkusMonthlyPrice = parseFloat(this.pricingPlans.customSkus?.find(p => p.allowedSkus == this.billingForm.value.allowedSkus)?.monthlyPrice ?? 0);
    const allowedOrdersMonthlyPrice = parseFloat(this.pricingPlans.customOrders?.find(p => p.allowedOrders == this.billingForm.value.allowedOrders)?.monthlyPrice ?? 0);
    return allowedSkusMonthlyPrice + allowedOrdersMonthlyPrice;
  }

  initRegisterForm() {
    this.registerForm = this.fb.group({
      firstName: ['', Validators.compose([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(100)
      ])
      ],
      lastName: ['', Validators.compose([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(100)
      ])
      ],
      email: [this.route.snapshot.queryParams.invitedEmail, Validators.compose([
        Validators.required,
        Validators.email,
        Validators.minLength(3),
        Validators.maxLength(320)
      ]),
      ],
      password: ['', Validators.compose([
        Validators.required,
        Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)((?=.*[!@#$%^&?.*_]))[A-Za-z\d!@#$%^&?.*_]{6,}$/),
        Validators.minLength(6),
        Validators.maxLength(100)
      ])
      ],
      confirmPassword: ['', Validators.compose([
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(100)
      ])
      ],
      agree: [false, Validators.compose([Validators.required])],
      phoneNumber: ['', { validators: [Validators.required, Validators.minLength(11)] }],
    }, {
      validator: ConfirmPasswordValidator.MatchPassword
    });
  }

  initAddressForm() {
    this.addressForm = this.fb.group({
      companyName: [null, { validators: [Validators.required] }],
      addressLine1: [null, { validators: [Validators.required] }],
      addressLine2: [null],
      city: [null, { validators: [Validators.required] }],
      countryCode: [null, { validators: [Validators.required] }],
      stateCode: [null, { validators: [Validators.required] }],
      postalCode: [null, { validators: [Validators.required, Validators.minLength(3)] }],
    });
  }

  initBillingForm() {
    this.billingForm = this.fb.group({
      planStripId: [null],
      billing: ["monthly"],
      allowedUsers: [null],
      allowedSkus: [null],
      allowedOrders: [null]
    });
  }

  get selectedPlan() {
    return this.pricingPlans.plans.find(p => p.stripeId == this.billingForm.value.planStripId);
  }

  openModal(content: any) {
    this.modalService.open(content, { size: 'lg' });
  }

  submit() {
    switch (this.step) {
      case 1:
        this.savePersonalInfo();
        break;
      case 2:
        this.saveAddressInfo();
        break;
      case 3: {
        this.savePaymentInfo();
        break;
      }
    }
  }

  private savePersonalInfo() {
    this.loading = true;
    const controls = this.registerForm.controls;
    if (this.registerForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      return;
    }

    if (!controls.agree.value) {
      controls.agree.markAsTouched();
      this.toastr.error('You must agree the terms and condition', 'Warning!');
      this.loading = false;
      return;
    }
    let user = new UserRegisterModel();
    user.email = controls.email.value;
    user.firstName = controls.firstName.value;
    user.lastName = controls.lastName.value;
    user.password = controls.password.value;
    user.confirmPassword = controls.confirmPassword.value;
    user.agree = controls.agree.value;
    user.phoneNumber = controls.phoneNumber.value;
    this.auth.register(user).subscribe(result => {
      this.loading = false;
      if (result) {
        this.stateStore.dispatch(new UserActions.SetUser(result));
        this.toastr.success('Your new account has been successfully created!');
        if (result.organizationId)
          this.router.navigateByUrl('');
        else
          this.router.navigateByUrl('', { skipLocationChange: true })
            .then(() => this.router.navigate(['register'], { queryParams: { step: 2 } }));
      }
    }, err => this.loading = false);
  }

  private saveAddressInfo() {
    this.loading = true;
    const controls = this.addressForm.controls;
    if (this.addressForm.invalid) {
      Object.keys(controls).forEach(ctrlName => controls[ctrlName].markAsTouched());
      return;
    }
    let address = new AddressModel();
    Object.keys(controls).forEach(ctrlName => address[ctrlName] = controls[ctrlName].value);
    address.email = this.helperService.currentUser.email;
    address.contactName = this.helperService.currentUser.firstName + ' ' + this.helperService.currentUser.lastName;
    address.phone = this.helperService.currentUser.phoneNumber;
    address.companyName = address.companyName;
    this.auth.addAddress(address).subscribe(result => {
      if (result) {
        this.stateStore.dispatch(new UserActions.SetUser(result));
        this.toastr.success('Your company details has been successfully saved!');
      }
      this.router.navigateByUrl('', { skipLocationChange: true })
        .then(() => this.router.navigate(['register'], { queryParams: { step: 3 } }));
    });
  }

  private savePaymentInfo() {
    this.loading = true;
    const controls = this.billingForm.controls;
    if (this.billingForm.invalid) {
      Object.keys(controls).forEach(ctrlName => controls[ctrlName].markAsTouched());
      return;
    }

    this.stripeInstance
      .createToken(this.card.element)
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(result => {
        if (result.error) {
          this.toastr.error(result.error.message);
          return;
        }

        if (result.token) {
          const billing = new CompleteRegistrationModel();
          Object.keys(controls).forEach(ctrlName => billing[ctrlName] = controls[ctrlName].value);
          billing.stripeToken = result.token.id;
          this.auth.completeRegistration(billing)
            .pipe(
              finalize(() => this.loading = false),
              untilDestroyed(this)
            )
            .subscribe(result => {
              if (result) {
                this.stateStore.dispatch(new UserActions.SetUser(result));
                this.toastr.success('Your account has been successfully activated!');
              }
              this.router.navigateByUrl('/');
            });
        }
      });
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.registerForm.controls[controlName];
    if (!control) {
      return false;
    }
    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }
}
