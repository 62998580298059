import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import UserState from 'src/app/states/user/user.state';
import { StoreType } from 'src/app/_enums/stores/store-type.enum';
import Utils from 'src/app/_helpers/utils';
import { LoadingService } from 'src/app/_services/loading.service';
import { AppConfig } from 'src/environments/app.config';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import AppInitService from 'src/app/_services/config/app-init.service';
import { filter } from 'rxjs/operators';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-pre-auth-redirect',
  templateUrl: './pre-auth-redirect.component.html',
  styleUrls: ['./pre-auth-redirect.component.scss']
})
export class PreAuthRedirectComponent implements OnInit, OnDestroy {

  private readonly SHOPIFY_AUTH_BASE_URL= (shop: string) => `https://${shop}.myshopify.com/admin/oauth/authorize`;
  private readonly SHOPIFY_SCOPES = `read_assigned_fulfillment_orders,
    write_assigned_fulfillment_orders,
    write_products,write_inventory,
    write_orders,
    write_fulfillments,
    write_shipping,
    write_price_rules,
    write_discounts,
    write_marketing_events,
    read_product_listings,
    read_locations,
    read_analytics`
    .replaceAll(/\s/g, '');

  readonly storeType: StoreType;
  readonly location: Location;

  constructor(
      private route: ActivatedRoute,
      private stateStore: Store,
      private loadingService: LoadingService,
      private appInitService: AppInitService,
      @Inject(DOCUMENT) private document: Document
    ) {
      try {
        const store = this.route.snapshot.paramMap.get('store');
        this.storeType = Utils.stringToEnum(store, StoreType);
        this.location = this.document.defaultView.location;
      } catch (e) {
        console.error(e);
      }
    }

  ngOnInit(): void {
    this.appInitService.appConfig$
    .pipe(
      filter((appConfig: AppConfig ) => appConfig?.isInitialized),
      untilDestroyed(this)
    )
    .subscribe((appConfig: AppConfig) => {
      const redirectUrl = this.redirectUrlForStore(appConfig);
      if (!redirectUrl) {
        //TODO display message, return to home page
      }
      this.location.href = redirectUrl;
    });
  }

  redirectUrlForStore(appConfig: AppConfig): string | null {
    switch (this.storeType) {
      case StoreType.Shopify: 
        return this.shopifyRedirectLink(appConfig);
    }
    return;
  }

  shopifyRedirectLink(appConfig: AppConfig): string | null {
    const queryParams = this.route.snapshot.queryParamMap;
    const shop = queryParams.get('shop');
    if (/^[\w][\w-]+\.myshopify\.com$/.test(shop) === false) return;

    const baseUrl = this.SHOPIFY_AUTH_BASE_URL(shop.replace('.myshopify.com', ''));
    const tokenId = this.stateStore.selectSnapshot(UserState.getTokenId);
    const redirectUrl = `${this.location.origin}/settings/sellingchannels`;
    return `${baseUrl}?client_id=${appConfig.shopifyClientId}&scope=${this.SHOPIFY_SCOPES}&redirect_uri=${redirectUrl}&state=${tokenId}`;
  }

  ngOnDestroy(): void {
    this.loadingService.loading = false;
  }
}
