import IEntityMap from './i-entity-map';
import ICompositeEntityMap from './i-composite-entity-map';

export default class BaseEntityMap<T> {
    protected readonly _map: IEntityMap<T> | ICompositeEntityMap<T>;
    private readonly _isCompositeEntity: boolean;

    constructor(map: IEntityMap<T> | ICompositeEntityMap<T>) {
        this._map = map;
        this._isCompositeEntity = isNaN(+(Object.keys(map)[0]));
    }

    public get entitiesArray(): T[] {
        return Object.values(this._map);
    }

    public get count(): number {
        return Object.keys(this._map).length;
    }

    public get first() {
        return Object.values(this._map)[0];
    }

    public map<U>(callbackFn: (value: T, index: number, array: T[]) => U, thisArg?: any): U[] {
        return Object.values(this._map).map(callbackFn);
    }

    public flatMap<U, This = undefined>(
        callback: (this: This, value: T, index: number, array: T[]) => U | ReadonlyArray<U>,
        thisArg?: This
    ): U[] {
        return Object.values(this._map).flatMap(callback, thisArg);
    }
}
