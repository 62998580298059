import { State } from '@ngxs/store';
import { PackingBoxConfigModel } from '../../_models/packing-box-config.model';
import { Injectable } from '@angular/core';
import { BoxConfigsService } from '../../_services/box-configs.service';
import { DataAction, StateRepository } from '@angular-ru/ngxs/decorators';
import { createEntityCollections, EntityIdType } from '@angular-ru/cdk/entity';
import { NgxsDataEntityCollectionsRepository } from '@angular-ru/ngxs/repositories';
import BaseEntityCollectionsOptions from '../base-entity-collections-options';
import { Observable, tap } from 'rxjs';

@StateRepository()
@State({
    name: 'packingBoxConfigs',
    defaults: {
        ...createEntityCollections(),
        loading: false
    }
})
@Injectable()
export class BoxConfigsEntitiesState
    extends NgxsDataEntityCollectionsRepository<PackingBoxConfigModel, EntityIdType, BaseEntityCollectionsOptions> {

    constructor(
        private packingBoxConfigsService: BoxConfigsService
    ) {
        super();
    }

    createBoxConfig(payload: PackingBoxConfigModel): Observable<PackingBoxConfigModel> {
        return this.packingBoxConfigsService.create(payload).pipe(tap(resp => {
            this.addEntityOne(resp);
        }));
    }

    @DataAction()
    load() {
        this.patchState({ loading: true });
        this.packingBoxConfigsService
            .fetch()
            .subscribe(result => {
                this.upsertEntitiesMany(result);
                this.patchState({ loading: false, loaded: true });
            });
    }

}
