// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// RxJS
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { HelperService } from './helper.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { MonthlyOrdersModel } from '../_models/monthly-orders.model';
import { StoreProductModel } from '../_models/products/store-product.model';
import { MonthlyOrderModel } from '../_models/monthly-order.model';
import { AdvancedReorderModel } from '../_models/advanced-reorder.model';
import { AdvancedReordersModel } from '../_models/advanced-reorders-model';
import { AddedReorderModel, SupplierPrice } from '../_models/added-reorder.model';
import { AdvancedReorderSettingModel } from '../_models/advanced-reorder-setting.model';
import { ReorderDetailModel } from '../_models/reorder-detail.model';

const API_ADVANCED_REORDER_URL = environment.baseUrl + '/advancedreorder';

export const ADVANCED_REORDER_DISABLED_FOR_ORGS = [972];

@Injectable()
export class AdvancedReorderService {
  constructor(private http: HttpClient,
    private helperService: HelperService,
    private toastr: ToastrService,
    private translate: TranslateService) { }

  getAll(basedOn: number, basedOnMonths: string, orderFor: number, orderForMonths: string,
    adjustment: number, outOfStock: boolean, buyBox: boolean, minusAllInventory: boolean, minusLocations: number[],
    minusAllStoresInventory: boolean, minusStores: number[], minusAllStoresOverstockedInventory: boolean,
    minusOverstockedStores: number[], minusOpenPurchases: boolean, applyCustom: boolean, tagIds: number[],
    page: number, pageSize: number, sortField: string, sort: string, filters: any): Observable<AdvancedReordersModel> {
    let params: any = { page: page, pageSize: pageSize };
    let body: any = {};
    if (sortField)
      params.sortField = sortField;
    if (sort)
      params.sort = sort;
    if (filters)
      params.filters = JSON.stringify(filters);
    if (basedOn)
      body.basedOn = basedOn;
    if (basedOnMonths && basedOnMonths != '{}')
      body.basedOnMonths = basedOnMonths;
    if (orderFor)
      body.orderFor = orderFor;
    if (orderForMonths && orderForMonths != '{}')
      body.orderForMonths = orderForMonths;
    if (adjustment)
      body.adjustment = adjustment;
    if (outOfStock !== undefined)
      body.outOfStock = outOfStock;
    if (buyBox !== undefined)
      body.buyBox = buyBox;
    if (minusAllInventory)
      body.minusInventory = 'true';
    else
      body.minusInventory = minusLocations.join(',');
    if (minusAllStoresInventory)
      body.minusStoreInventory = 'true';
    else
      body.minusStoreInventory = minusStores.join(',');
    if (minusAllStoresOverstockedInventory)
      body.minusOverstockedStoreInventory = 'true';
    else
      body.minusOverstockedStoreInventory = minusOverstockedStores.join(',');
    if (minusOpenPurchases !== undefined)
      body.minusOpenPurchases = minusOpenPurchases;
    if (applyCustom !== undefined)
      body.applyCustom = applyCustom;
    if (tagIds && tagIds.length)
      params.tagIds = tagIds;
    return this.http.post<AdvancedReordersModel>(API_ADVANCED_REORDER_URL, body,
      { headers: this.helperService.getHTTPHeaders(), params: params })
      .pipe(map(res => {
        res.data = res.data.map(item => this.processItem(item, body));
        return res;
      }), catchError(this.handleError<AdvancedReordersModel>('getAll')));
  }

  getById(productId: number, basedOn: number, basedOnMonths: string, orderFor: number, orderForMonths: string,
    adjustment: number, outOfStock: boolean, buyBox: boolean, minusAllInventory: boolean, minusLocations: number[],
    minusAllStoresInventory: boolean, minusStores: number[], minusAllStoresOverstockedInventory: boolean,
    minusOverstockedStores: number[], minusOpenPurchases: boolean, applyCustom: boolean) : Observable<AdvancedReorderModel> {
    let params: any = { productId: productId };
    let body: any = {};
    if (basedOn)
      body.basedOn = basedOn;
    if (basedOnMonths && basedOnMonths != '{}')
      body.basedOnMonths = basedOnMonths;
    if (orderFor)
      body.orderFor = orderFor;
    if (orderForMonths && orderForMonths != '{}')
      body.orderForMonths = orderForMonths;
    if (adjustment)
      body.adjustment = adjustment;
    if (outOfStock !== undefined)
      body.outOfStock = outOfStock;
    if (buyBox !== undefined)
      body.buyBox = buyBox;
    if (minusAllInventory)
      body.minusInventory = 'true';
    else
      body.minusInventory = minusLocations.join(',');
    if (minusAllStoresInventory)
      body.minusStoreInventory = 'true';
    else
      body.minusStoreInventory = minusStores.join(',');
    if (minusAllStoresOverstockedInventory)
      body.minusOverstockedStoreInventory = 'true';
    else
      body.minusOverstockedStoreInventory = minusOverstockedStores.join(',');
    if (minusOpenPurchases !== undefined)
      body.minusOpenPurchases = minusOpenPurchases;
    if (applyCustom !== undefined)
      body.applyCustom = applyCustom;
    return this.http.post<AdvancedReorderModel>(`${API_ADVANCED_REORDER_URL}/GetById`, body,
      { headers: this.helperService.getHTTPHeaders(), params: params })
      .pipe(map(item => item ? this.processItem(item, body) : undefined),
      catchError(this.handleError<AdvancedReorderModel>('getById')));
  }

  getSubs(parentId: number, basedOn: number, basedOnMonths: string, orderFor: number, orderForMonths: string,
    adjustment: number, outOfStock: boolean, buyBox: boolean, minusAllInventory: boolean, minusLocations: number[],
    minusAllStoresInventory: boolean, minusStores: number[], minusAllStoresOverstockedInventory: boolean,
    minusOverstockedStores: number[], minusOpenPurchases: boolean, applyCustom: boolean, tagIds: number[],
    page: number, pageSize: number, sortField: string, sort: string, filters: any): Observable<AdvancedReordersModel> {
    let params: any = { parentId: parentId, page: page, pageSize: pageSize };
    let body: any = {};
    if (sortField)
      params.sortField = sortField;
    if (sort)
      params.sort = sort;
    if (filters)
      params.filters = JSON.stringify(filters);
    if (basedOn)
      body.basedOn = basedOn;
    if (basedOnMonths && basedOnMonths != '{}')
      body.basedOnMonths = basedOnMonths;
    if (orderFor)
      body.orderFor = orderFor;
    if (orderForMonths && orderForMonths != '{}')
      body.orderForMonths = orderForMonths;
    if (adjustment)
      body.adjustment = adjustment;
    if (outOfStock !== undefined)
      body.outOfStock = outOfStock;
    if (buyBox !== undefined)
      body.buyBox = buyBox;
    if (minusAllInventory)
      body.minusInventory = 'true';
    else
      body.minusInventory = minusLocations.join(',');
    if (minusAllStoresInventory)
      body.minusStoreInventory = 'true';
    else
      body.minusStoreInventory = minusStores.join(',');
    if (minusAllStoresOverstockedInventory)
      body.minusOverstockedStoreInventory = 'true';
    else
      body.minusOverstockedStoreInventory = minusOverstockedStores.join(',');
    if (minusOpenPurchases !== undefined)
      body.minusOpenPurchases = minusOpenPurchases;
    if (applyCustom !== undefined)
      body.applyCustom = applyCustom;
    if (tagIds && tagIds.length)
      params.tagIds = tagIds;
    return this.http.post<AdvancedReordersModel>(`${API_ADVANCED_REORDER_URL}/GetSubs`, body,
      { headers: this.helperService.getHTTPHeaders(), params: params })
      .pipe(map(res => {
        res.data = res.data.map(item => this.processItem(item, body));
        return res;
      }), catchError(this.handleError<AdvancedReordersModel>('getSubs')));
  }

  getAllListings(basedOn: number, basedOnMonths: string, orderFor: number, orderForMonths: string,
    adjustment: number, outOfStock: boolean, buyBox: boolean, minusAllInventory: boolean, minusLocations: number[],
    minusAllStoresInventory: boolean, minusStores: number[], minusAllStoresOverstockedInventory: boolean,
    minusOverstockedStores: number[], minusOpenPurchases: boolean, applyCustom: boolean, tagIds: number[], page: number,
    pageSize: number, sortField: string, sort: string, filters: any): Observable<AdvancedReordersModel> {
    let params: any = { page: page, pageSize: pageSize };
    let body: any = {};
    if (sortField)
      params.sortField = sortField;
    if (sort)
      params.sort = sort;
    if (filters)
      params.filters = JSON.stringify(filters);
    if (basedOn)
      body.basedOn = basedOn;
    if (basedOnMonths && basedOnMonths != '{}')
      body.basedOnMonths = basedOnMonths;
    if (orderFor)
      body.orderFor = orderFor;
    if (orderForMonths && orderForMonths != '{}')
      body.orderForMonths = orderForMonths;
    if (adjustment)
      body.adjustment = adjustment;
    if (outOfStock !== undefined)
      body.outOfStock = outOfStock;
    if (buyBox !== undefined)
      body.buyBox = buyBox;
    if (minusAllInventory)
      body.minusInventory = 'true';
    else
      body.minusInventory = minusLocations.join(',');
    if (minusAllStoresInventory)
      body.minusStoreInventory = 'true';
    else
      body.minusStoreInventory = minusStores.join(',');
    if (minusAllStoresOverstockedInventory)
      body.minusOverstockedStoreInventory = 'true';
    else
      body.minusOverstockedStoreInventory = minusOverstockedStores.join(',');
    if (minusOpenPurchases !== undefined)
      body.minusOpenPurchases = minusOpenPurchases;
    if (applyCustom !== undefined)
      body.applyCustom = applyCustom;
    if (tagIds && tagIds.length)
      params.tagIds = tagIds;
    return this.http.post<AdvancedReordersModel>(`${API_ADVANCED_REORDER_URL}/GetAllListings`, body,
      { headers: this.helperService.getHTTPHeaders(), params: params })
      .pipe(map(res => {
        res.data = res.data.map(item => this.processItem(item, body));
        return res;
      }), catchError(this.handleError<AdvancedReordersModel>('getAllListings')));
  }

  getListings(parentId: number, productId: number, basedOn: number, basedOnMonths: string, orderFor: number, orderForMonths: string,
    adjustment: number, outOfStock: boolean, buyBox: boolean, minusAllInventory: boolean, minusLocations: number[],
    minusAllStoresInventory: boolean, minusStores: number[], minusAllStoresOverstockedInventory: boolean,
    minusOverstockedStores: number[], minusOpenPurchases: boolean, applyCustom: boolean, tagIds: number[], page: number,
    pageSize: number, sortField: string, sort: string, filters: any): Observable<AdvancedReordersModel> {
    let params: any = { page: page, pageSize: pageSize };
    let body: any = {};
    if (parentId)
      params.parentId = parentId;
    if (productId)
      params.productId = productId;
    if (sortField)
      params.sortField = sortField;
    if (sort)
      params.sort = sort;
    if (filters)
      params.filters = JSON.stringify(filters);
    if (basedOn)
      body.basedOn = basedOn;
    if (basedOnMonths && basedOnMonths != '{}')
      body.basedOnMonths = basedOnMonths;
    if (orderFor)
      body.orderFor = orderFor;
    if (orderForMonths && orderForMonths != '{}')
      body.orderForMonths = orderForMonths;
    if (adjustment)
      body.adjustment = adjustment;
    if (outOfStock !== undefined)
      body.outOfStock = outOfStock;
    if (buyBox !== undefined)
      body.buyBox = buyBox;
    if (minusAllInventory)
      body.minusInventory = 'true';
    else
      body.minusInventory = minusLocations.join(',');
    if (minusAllStoresInventory)
      body.minusStoreInventory = 'true';
    else
      body.minusStoreInventory = minusStores.join(',');
    if (minusAllStoresOverstockedInventory)
      body.minusOverstockedStoreInventory = 'true';
    else
      body.minusOverstockedStoreInventory = minusOverstockedStores.join(',');
    if (minusOpenPurchases !== undefined)
      body.minusOpenPurchases = minusOpenPurchases;
    if (applyCustom !== undefined)
      body.applyCustom = applyCustom;
    if (tagIds && tagIds.length)
      params.tagIds = tagIds;
    return this.http.post<AdvancedReordersModel>(`${API_ADVANCED_REORDER_URL}/GetListings`, body,
      { headers: this.helperService.getHTTPHeaders(), params: params })
      .pipe(map(res => {
        res.data = res.data.map(item => this.processItem(item, body));
        return res;
      }), catchError(this.handleError<AdvancedReordersModel>('getListings')));
  }

  getDetails(parentId: number, productId: number, storeId: number, basedOn: number, basedOnMonths: string,
    orderFor: number, orderForMonths: string, adjustment: number, outOfStock: boolean, buyBox: boolean,
    minusAllInventory: boolean, minusLocations: number[], minusAllStoresInventory: boolean, minusStores: number[],
    minusAllStoresOverstockedInventory: boolean, minusOverstockedStores: number[], 
    minusOpenPurchases: boolean, applyCustom: boolean): Observable<AdvancedReorderModel> {
    let params: any = { parentId: parentId, productId: productId, storeId: storeId };
    let body: any = {};
    if (basedOn)
      body.basedOn = basedOn;
    if (basedOnMonths && basedOnMonths != '{}')
      body.basedOnMonths = basedOnMonths;
    if (orderFor)
      body.orderFor = orderFor;
    if (orderForMonths && orderForMonths != '{}')
      body.orderForMonths = orderForMonths;
    if (adjustment)
      body.adjustment = adjustment;
    if (outOfStock !== undefined)
      body.outOfStock = outOfStock;
    if (buyBox !== undefined)
      body.buyBox = buyBox;
    if (minusAllInventory)
      body.minusInventory = 'true';
    else
      body.minusInventory = minusLocations.join(',');
    if (minusAllStoresInventory)
      body.minusStoreInventory = 'true';
    else
      body.minusStoreInventory = minusStores.join(',');
    if (minusAllStoresOverstockedInventory)
      body.minusOverstockedStoreInventory = 'true';
    else
      body.minusOverstockedStoreInventory = minusOverstockedStores.join(',');

    if (minusOpenPurchases !== undefined)
      body.minusOpenPurchases = minusOpenPurchases;
    if (applyCustom !== undefined)
      body.applyCustom = applyCustom;
    return this.http.post<AdvancedReorderModel>(`${API_ADVANCED_REORDER_URL}/GetListingDetails`, body,
      { headers: this.helperService.getHTTPHeaders(), params: params })
      .pipe(map(res => this.processItem(res, body)),
      catchError(this.handleError<AdvancedReorderModel>('getDetails')));
  }

  getMonthlyOrders(productId: number, storeId: number, years: number[],
      includeLatest: boolean = true, main: boolean = false): Observable<MonthlyOrdersModel> {
    let params: any = { years: years, main: main, includeLatest: includeLatest };
    if (storeId)
        params.storeId = storeId;
    return this.http.get<MonthlyOrdersModel>(`${API_ADVANCED_REORDER_URL}/${productId}/GetMonthlyOrders`,
      { headers: this.helperService.getHTTPHeaders(), params: params })
      .pipe(map((res: MonthlyOrdersModel) => {
        res.latest = res.latest.map(item => this.processOrders(item));
        res.monthly = res.monthly.map(item => this.processOrders(item));
        return res;
      }), catchError(this.handleError<MonthlyOrdersModel>('getMonthlyOrders')));
  }

  getDailyInfo(productId: number, storeId: number, date: any): Observable<any> {
    return this.http.get(`${API_ADVANCED_REORDER_URL}/${productId}/${storeId}/${date}`,
      { headers: this.helperService.getHTTPHeaders() })
      .pipe(map((res: any) => res.map(item => {
        item.date = moment(item.date).date();
        return item;
      })),
        catchError(this.handleError('getDailyInfo')));
  }

  getAddedReorder(): Observable<AddedReorderModel[]> {
    return this.http.get<AddedReorderModel[]>(API_ADVANCED_REORDER_URL,
      { headers: this.helperService.getHTTPHeaders() })
      .pipe(map((res: AddedReorderModel[]) => res.map(item => {
        item.allPrices = item.prices.split(';').map(price => {
          var itemPrice = new SupplierPrice();
          var strArr = price.split(':');
          itemPrice.supplierId = Number(strArr[0]);
          itemPrice.price = Number(strArr[1]);
          return itemPrice;
        }).filter(i => i.supplierId && i.price);
        if (item.details)
          item.details = JSON.parse(item.details.toString());
        return item;
      })),
      catchError(this.handleError<AddedReorderModel[]>('getAddedReorder')));
  }

  addToOrder(productId: number, supplierId: number, quantity: number, details: any) : Observable<any> {
    let advancedOrder: any = { productId: productId, supplierId: supplierId,
      quantity: quantity, details: JSON.stringify(details) };
    return this.http.post(`${API_ADVANCED_REORDER_URL}/add`, advancedOrder,
      { headers: this.helperService.getHTTPHeaders() })
      .pipe(catchError(this.handleError('addToOrder')));
  }

  updateOrder(productId: number, supplierId: number, quantity: number, details: any) : Observable<any> {
    let advancedOrder: any = { productId: productId, supplierId: supplierId,
      quantity: quantity, details: JSON.stringify(details) };
    return this.http.put(API_ADVANCED_REORDER_URL, advancedOrder,
      { headers: this.helperService.getHTTPHeaders() })
      .pipe(catchError(this.handleError('updateOrder')));
  }

  totalDays(item: any) {
    return (item.selected || []).reduce((a, b) => a + (b.inStock && b.buyBox ? b.days : 0), 0);
  }

  setAllOrders(allOrders: any, data: any, itm: any, outOfStock: boolean, buyBox: boolean) {
    let percent: number = 1;
    if (outOfStock)
      percent = data.inStock?.find(i => i.productId == itm.productId && i.storeId == itm.storeId).inStock || 0;
    if (buyBox) {
      let buyBoxValue = data.buyBox?.find(i => i.productId == itm.productId && i.storeId == itm.storeId).buyBox || 0;
      if (buyBoxValue > percent)
        percent = buyBoxValue;
    }
    allOrders[itm.productId] = allOrders[itm.productId] || {};
    if (itm.storeId)
      allOrders[itm.productId][itm.storeId] = (allOrders[itm.productId][itm.storeId] || 0) + itm.orders / (percent || 1);
    else
      allOrders[itm.productId].orders = (allOrders[itm.productId].orders || 0) +  itm.orders / (percent || 1);
  }

  removeFromOrder(parentId: number, productId: number, storeId: number): Observable<any> {
    let params: any = { productId: parentId };
    if (productId)
      params.productId = productId;
    if (storeId)
      params.storeId = storeId;

    return this.http.delete(API_ADVANCED_REORDER_URL,
      { headers: this.helperService.getHTTPHeaders(), params: params })
      .pipe(catchError(this.handleError('removeFromOrder')));
  }

  removeAllOrders(): Observable<any> {
    return this.http.delete(`${API_ADVANCED_REORDER_URL}/all`,
      { headers: this.helperService.getHTTPHeaders() })
      .pipe(catchError(this.handleError('removeAllOrders')));
  }

  private processItem(item: AdvancedReorderModel, params: any) {
    item.locations = new Map<number, number>();
    item.storeProducts = [];
    for (let inv of item.inventory.split(';')) {
      let arr = inv.split(':');
      let inv1 = Number(arr[1]);
      if (inv1 > 0)
        item.locations.set(Number(arr[0]), inv1);
    }
    if (item.realTime) {
      for (let inv of item.realTime.split(';')) {
        let arr = inv.split(':');
        let rt = Number(arr[arr.length - 1]);
        if (rt > 0) {
          let sp = new ReorderDetailModel();
          sp.productId = arr.length == 3 ? Number(arr[0]) : item.productId;
          sp.storeId = arr.length > 1 ? Number(arr[arr.length - 2]) : item.storeId;
          sp.realTime = rt;
          item.storeProducts.push(sp);
        }
      }
    }
    if (item.minusInventory === undefined || item.minusInventory == 'false')
      item.minusInventory = params.minusInventory;
    if (item.minusInventory != 'true' && item.minusInventory)
      item.minusLocations = item.minusInventory.split(',').map(id => Number(id));
    if (item.minusStoreInventory === undefined || item.minusStoreInventory == 'false')
      item.minusStoreInventory = params.minusStoreInventory;
    if (item.minusStoreInventory != 'true' && item.minusStoreInventory)
      item.minusStores = item.minusStoreInventory.split(',').map(id => Number(id));
    if (item.details) {
      for (let detail of item.details.split(';')) {
        if (detail) {
          let itemDetails = detail.split(':');
          let prodId = Number(itemDetails[0]);
          let storeId = itemDetails[1] ? Number(itemDetails[1]) : undefined;
          let sp = item.storeProducts.find(sp => sp.productId == prodId && sp.storeId == storeId);
          if (!sp) {
            sp = new ReorderDetailModel();
            sp.productId = prodId;
            sp.storeId = storeId;
            item.storeProducts.push(sp);
          }
          sp.included = itemDetails[2] != 'false';
          sp.reorder = Number(itemDetails[3]);
          sp.overstock = Number(itemDetails[4]);
          sp.adjustment = Number(itemDetails[5]);
          sp.manualEntry = Number(itemDetails[6]);
        }
      }
    }
    item.tagIds = (item.tags || '').split(';').map(id => Number(id));
    return item;
  }

  private processOrders(item: MonthlyOrderModel) : MonthlyOrderModel {
    if (item.date)
      item.date = new Date(`${item.date}Z`);
    let orders = item.orders?.split(';') || [];
    delete item.orders;
    item.listings = new Array<StoreProductModel>();
    for (let data of orders) {
      let params = data.split(':');
      let listing = this.upsertListing(item, params);
      listing.asin = params[2];
      listing.orders = Number(params[3]);
    }
    let inStock = item.inStock?.split(';') || [];
    delete item.inStock;
    for (let data of inStock) {
      let params = data.split(':');
      this.upsertListing(item, params).inStock = Number(params[2]);
    }
    let buyBox = item.buyBox?.split(';') || [];
    delete item.buyBox;
    for (let data of buyBox) {
      let params = data.split(':');
      this.upsertListing(item, params).buyBox = Number(params[2]);
    }
    return item;
  }

  private upsertListing(item: MonthlyOrderModel, params: string[]) : StoreProductModel
  {
    let productId = Number(params[0]), storeId = Number(params[1]);
    let listing = item.listings.find(sp => sp.productId == productId && sp.storeId == storeId);
    if (!listing) {
      listing = new StoreProductModel();
      listing.productId = productId;
      listing.storeId = storeId;
      item.listings.push(listing);
    }
    return listing;
  }

  saveRuleSelection(ruleId: number, productId: number, storeId: number, subOnly: boolean): Observable<any> {
    let params: any = { productId: productId };
    if (ruleId)
      params.ruleId = ruleId;
    if (storeId)
      params.storeId = storeId;
    params.subOnly = subOnly == true;
		return this.http.put(`${API_ADVANCED_REORDER_URL}/SaveRuleSelection`, null,
			{ headers: this.helperService.getHTTPHeaders(), params: params })
      .pipe(catchError(this.handleError<any>('saveRuleSelection')));
	}

  addSetting(reorderSetting: AdvancedReorderSettingModel): Observable<any> {
		return this.http.post(`${API_ADVANCED_REORDER_URL}/AddSetting`, reorderSetting,
			{ headers: this.helperService.getHTTPHeaders() })
      .pipe(catchError(this.handleError<any>('addSetting')));
	}

  saveSettings(reorderSettings: AdvancedReorderSettingModel[]): Observable<any> {
		return this.http.post(`${API_ADVANCED_REORDER_URL}/SaveSettings`, reorderSettings,
			{ headers: this.helperService.getHTTPHeaders() })
      .pipe(catchError(this.handleError<any>('saveSettings')));
	}

  deleteSetting(productId: number, storeId: number, subOnly: boolean): Observable<any> {
    let params: any = { productId: productId };
    if (storeId)
      params.storeId = storeId;
    params.subOnly = subOnly == true;
		return this.http.delete(`${API_ADVANCED_REORDER_URL}/DeleteSetting`,
			{ headers: this.helperService.getHTTPHeaders(), params: params })
      .pipe(catchError(this.handleError<any>('deleteSetting')));
	}

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      switch (error.status) {
        case 401:
          window.location.href = '/login';
          break;
        // case 422:
        //   this.toastr.error(this.translate.instant('ADVANCED_REORDER.MESSAGES.ERRORS.' + error.error.reason, error.error));
        //   break;
        default:
          this.toastr.error(this.translate.instant('GENERAL.MESSAGES.ERROR'));
      }
      return throwError(error);
    };
  }
}