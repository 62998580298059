// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
// RxJS
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
// Models
import { SellingChannelModel } from '../../_models/selling-channel.model';

import { HelperService } from '../helper.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { StoreModel } from 'src/app/_models/store.model';
import IStoreWarehouseLink from '../../_interfaces/i-store-warehouse-link';
import { UpdateStoreWarehousesRequestModel } from 'src/app/_models/update-store-warehouses-request-model';
import { Params } from '@angular/router';

const API_SELLINGCHANNELS_URL = environment.baseUrl + '/sellingChannels';

@Injectable()
export class SellingChannelsService {
    constructor(
        private http: HttpClient,
        private helperService: HelperService,
        private toastr: ToastrService,
        private translate: TranslateService) {
    }

    createStore(name: string, currencyId: number): Observable<any> {
        return this.http.post(`${API_SELLINGCHANNELS_URL}/create`, {},
            { headers: this.helperService.getHTTPHeaders(), params: { name, currencyId } as any })
            .pipe(catchError(this.handleError('createStore')));
    }

    updateStore(store: StoreModel): Observable<any> {
        return this.http.put(`${API_SELLINGCHANNELS_URL}/${store.id}`, store,
            { headers: this.helperService.getHTTPHeaders() })
            .pipe(catchError(this.handleError('updateStore')));
    }

    updateStoreWarehouses(request: UpdateStoreWarehousesRequestModel): Observable<any> {
        return this.http.post(`${API_SELLINGCHANNELS_URL}/updateStoreWarehouses`, request, { headers: this.helperService.getHTTPHeaders() });
    }

    walmartConnect(walmartStoreName: any, clientID: any, clientSecret: any, walmartStoreCurrency: any, storeId: any, _onlyActiveProducts: any, isCanadaStore: boolean): Observable<any> {
        let walmartReqBody = {
            WalmartStoreName: walmartStoreName,
            ClientId: clientID,
            ClientSecret: clientSecret,
            WalmartStoreCurrency: walmartStoreCurrency,
            id: storeId,
            onlyActiveProducts: _onlyActiveProducts,
            isCanadaStore: isCanadaStore
        };
        return this.http.post<any>(`${API_SELLINGCHANNELS_URL}/walmartConnect`, walmartReqBody, { headers: this.helperService.getHTTPHeaders() });
    }

    walmartDSVConnect(storeId: any, walmartStoreName: any, walmartStoreCurrency: any, consumerId: any, privateKey: any, consumerChannelType: any, shipNode: any, _onlyActiveProducts: any): Observable<any> {
        const walmartDSVReqBody = {
            id: storeId,
            WalmartStoreName: walmartStoreName,
            WalmartStoreCurrency: walmartStoreCurrency,
            ConsumerId: consumerId,
            PrivateKey: privateKey,
            ConsumerChannelType: consumerChannelType,
            ShipNode: shipNode,
            onlyActiveProducts: _onlyActiveProducts
        };
        return this.http.post<any>(`${API_SELLINGCHANNELS_URL}/walmartDsvConnect`, walmartDSVReqBody, { headers: this.helperService.getHTTPHeaders() });
    }

    walmartCanadaConnect(storeId: any, walmartStoreName: any, consumerId: any, privateKey: any, consumerChannelType: any, _onlyActiveProducts: any): Observable<any> {
        const walmartDSVReqBody = {
            id: storeId,
            WalmartStoreName: walmartStoreName,
            ConsumerId: consumerId,
            PrivateKey: privateKey,
            ConsumerChannelType: consumerChannelType,
            onlyActiveProducts: _onlyActiveProducts
        };
        return this.http.post<any>(`${API_SELLINGCHANNELS_URL}/walmartCanadaConnect`, walmartDSVReqBody, { headers: this.helperService.getHTTPHeaders() });
    }

    bigCommerceConnect(name: any, accessToken: any, clientId: any, clientSecret: any, apiPath: any, onlyActiveProducts: any, storeId: any): Observable<any> {
        if (!storeId) {
            storeId = '';
        }
        return this.http.post<any>(`${API_SELLINGCHANNELS_URL}/bigCommerceConnect?bigCommerceStoreName=${name}&accessToken=${accessToken}&clientID=${clientId}&clientSecret=${clientSecret}&apiPath=${apiPath}&onlyActiveProducts=${onlyActiveProducts}&id=${storeId}`, {}, { headers: this.helperService.getHTTPHeaders() })
            .pipe(catchError(this.handleError<any>('bigCommerceConnect')));
    }

    checkEbayStores(name: any, email: any): Observable<any> {
        return this.http.post<any>(`${API_SELLINGCHANNELS_URL}/ebaycheck?name=${name}&email=${email}`, {}, { headers: this.helperService.getHTTPHeaders() })
            .pipe(catchError(this.handleError<any>('checkEbayStores')));
    }

    updateEbayStore(storeId: any, name: any, email: any, _onlyActiveProducts: boolean, onlyItemsWSkus: boolean): Observable<any> {
        return this.http.post<any>(`${API_SELLINGCHANNELS_URL}/updateEbayStore`, {
            storeName: name,
            id: storeId,
            onlyActiveProducts: _onlyActiveProducts,
            onlyItemsWSkus: onlyItemsWSkus
        }, { headers: this.helperService.getHTTPHeaders() });
    }

    createEbayStoreConnection(nameParam: any, emailParam: any, codeParam: any, _onlyActiveProducts: boolean, storeId: number = null, onlyItemsWSkus: boolean): Observable<any> {
        if (!storeId) {
            storeId = null;
        }
        return this.http.post<any>(`${API_SELLINGCHANNELS_URL}/createEbayConnection`, {
            name: nameParam,
            email: emailParam,
            code: codeParam,
            id: storeId,
            onlyActiveProducts: _onlyActiveProducts,
            onlyItemsWSkus: onlyItemsWSkus
        }, { headers: this.helperService.getHTTPHeaders() })
            .pipe(catchError(this.handleError<any>('createEbayConnection')));
    }

    createOverstockStoreConnection(name: any, username: any, password: any, warehouseName: any, onlyActiveProducts: any, storeId: any): Observable<any> {
        if (!storeId) {
            storeId = '';
        }
        return this.http.post<any>(`${API_SELLINGCHANNELS_URL}/overstock-connect?name=${name}&username=${username}&password=${password}&warehouseName=${warehouseName}&onlyActiveProducts=${onlyActiveProducts}&id=${storeId}`, {}, { headers: this.helperService.getHTTPHeaders() });
    }

    createAcendaConnection(payload): Observable<any> {
        let params: Params = new HttpParams();
        for (const key in payload) {
            params = params.append(key, payload[key]);
        }
        return this.http.post<any>(`${API_SELLINGCHANNELS_URL}/connect-target`, null, { params });
    }

    createAmazonAWSConnection(amazonAwsAccessKey: any, amazonAwsSecretKey: any, associateTag: any): Observable<any> {
        return this.http.post<any>(`${API_SELLINGCHANNELS_URL}/amazon-aws-connect?amazonAwsAccessKey=${amazonAwsAccessKey}&amazonAwsSecretKey=${amazonAwsSecretKey}&associateTag=${associateTag}`, {}, { headers: this.helperService.getHTTPHeaders() });
    }

    delete(id: number) {
        return this.http.delete(`${API_SELLINGCHANNELS_URL}/items/${id}`,
            { headers: this.helperService.getHTTPHeaders() }).pipe(
                catchError(this.handleError<SellingChannelModel>('delete'))
            );
    }

    connectAmazon(data: {}): Observable<unknown> {
        return this.http.post(`${API_SELLINGCHANNELS_URL}/connectAmazon`, data, { headers: this.helperService.getHTTPHeaders() })
            .pipe(
                catchError(this.helperService.handleError('connectAmazon'))
            );
    }

    etsyConnect(etsyStoreName: any, storeName: any, storeId: any, onlyActiveProducts: any): Observable<any> {
        if (!storeId) {
            storeId = '';
        }
        return this.http.post(`${API_SELLINGCHANNELS_URL}/etsyconnect?storeName=${storeName}&etsyStoreName=${etsyStoreName}&id=${storeId}&onlyActiveProducts=${onlyActiveProducts}`, {}, { headers: this.helperService.getHTTPHeaders() });
    }

    etsyConnectV3(etsyStoreName: any, storeName: any, storeId: any, onlyActiveProducts: any): Observable<any> {
        if (!storeId) {
            storeId = '';
        }
        return this.http.post(`${API_SELLINGCHANNELS_URL}/etsyconnect/v3?storeName=${storeName}&etsyStoreName=${etsyStoreName}&id=${storeId}&onlyActiveProducts=${onlyActiveProducts}`, {}, { headers: this.helperService.getHTTPHeaders() });
    }

    wishConnect(storeName: any, storeId: any, _onlyActiveProducts: any, _code: any): Observable<any> {
        if (!storeId) {
            storeId = '';
        }
        const wishReqBody = {
            name: storeName,
            code: _code,
            id: storeId,
            onlyActiveProducts: _onlyActiveProducts
        };
        return this.http.post(`${API_SELLINGCHANNELS_URL}/createWishConnection`, wishReqBody, { headers: this.helperService.getHTTPHeaders() });
    }

    wayfairConnect(store: any, id: number = null) {
        return this.http.post(`${API_SELLINGCHANNELS_URL}/wayfairConnect`,
            {
                id: id, name: store.name, onlyActiveProducts: store.onlyActiveProducts,
                wayfairStore: {
                    key: store.key,
                    secret: store.secret,
                    sandboxKey: store.sandboxKey,
                    sandboxSecret: store.sandboxSecret,
                    wayfairWarehouseId: store.wayfairWarehouseId
                }
            },
            { headers: this.helperService.getHTTPHeaders() })
            .pipe(catchError(this.handleError('wayfairConnect')));
    }

    rainforestApiConnect(rainforestApi: any, id: number = null) {
        return this.http.post(`${API_SELLINGCHANNELS_URL}/rainforestApiConnect`,
            { id: rainforestApi.id, name: rainforestApi.name, key: rainforestApi.key },
            { headers: this.helperService.getHTTPHeaders() })
            .pipe(catchError(this.handleError('rainforestApiConnect')));
    }

    shopifyVerify(code: any, hmac: any, shop: any, timestamp: any): Observable<any> {
        return this.http.get(`${API_SELLINGCHANNELS_URL}/verifyShopify?code=${code}&hmac=${hmac}&shop=${shop}&timestamp=${timestamp}`, { headers: this.helperService.getHTTPHeaders() });
    }

    getAllChannels(): Observable<any> {
        return this.http.get(API_SELLINGCHANNELS_URL, { headers: this.helperService.getHTTPHeaders() })
            .pipe(catchError(this.handleError('getAllChannels')));
    }

    jetConnect(jetStoreName: any, apiUser: any, secret: any, jetStoreCurrency: any, onlyActiveProducts: any, storeId: any): Observable<any> {
        if (!storeId) {
            storeId = '';
        }
        return this.http.post(`${API_SELLINGCHANNELS_URL}/jet-connect?jetStoreName=${jetStoreName}&apiUser=${apiUser}&secret=${secret}&jetStoreCurrency=${jetStoreCurrency}&onlyActiveProducts=${onlyActiveProducts}&id=${storeId}`, {}, { headers: this.helperService.getHTTPHeaders() });
    }

    shipstationConnect(apiKey: any, apiSecret: any, ShipStationaccountName: any, storeId: any, onlyActiveProducts: any): Observable<any> {
        if (!storeId) {
            storeId = '';
        }
        return this.http.post(`${API_SELLINGCHANNELS_URL}/shipstation-connect?apiKey=${apiKey}&apiSecret=${apiSecret}&ShipStationaccountName=${ShipStationaccountName}&id=${storeId}&onlyActiveProducts=${onlyActiveProducts}`, {}, { headers: this.helperService.getHTTPHeaders() });
    }

    etsyVerify(_oauthToken: any, _oauth_verifier: any): Observable<any> {
        return this.http.post(`${API_SELLINGCHANNELS_URL}/etsyverify`, {
            oauth_token: _oauthToken,
            oauth_verifier: _oauth_verifier
        }, { headers: this.helperService.getHTTPHeaders() });
    }

    etsyVerifyV3(_code: any, _state: any): Observable<any> {
        return this.http.post(`${API_SELLINGCHANNELS_URL}/etsyverify/v3`, {
            code: _code,
            state: _state
        }, { headers: this.helperService.getHTTPHeaders() });
    }

    amazonAdvertisingVerify(code: any, AmazonStoreId: any): Observable<any> {
        return this.http.post(`${API_SELLINGCHANNELS_URL}/amazon-advertising-connect?code=${code}&AmazonStoreId=${AmazonStoreId}`, {}, { headers: this.helperService.getHTTPHeaders() });
    }

    changeStoreLocation(storeId: any, storeLocationId: any): Observable<any> {
        let changeStoreLocationBody = { StoreId: storeId, storeLocationId: storeLocationId };
        return this.http.post<any>(`${API_SELLINGCHANNELS_URL}/changeStoreLocation`, changeStoreLocationBody, { headers: this.helperService.getHTTPHeaders() });
    }

    getStore(storeId): Observable<any> {
        return this.http.get(`${API_SELLINGCHANNELS_URL}/getStore/${storeId}`, { headers: this.helperService.getHTTPHeaders() });
    }

    getStoreWarehouseLocationLinks(): Observable<IStoreWarehouseLink[]> {
        return this.http.get<IStoreWarehouseLink[]>(`${API_SELLINGCHANNELS_URL}/GetStoreWarehouseLinks`);
    }

    createStoreWarehouseLink(storeId: number, warehouseId: number, storeLocationId?: number): Observable<IStoreWarehouseLink[]> {
        return this.http
            .post<IStoreWarehouseLink[]>(
                `${API_SELLINGCHANNELS_URL}/createStoreWarehouseLink?storeId=${storeId}&warehouseid=${warehouseId}&storeLocationId=${storeLocationId}`,
                {}
            );
    }

    deleteStoreWarehouseLink(storeId: number, warehouseId: number, storeLocationId?: number): Observable<IStoreWarehouseLink[]> {
        return this.http
            .delete<IStoreWarehouseLink[]>(
                `${API_SELLINGCHANNELS_URL}/DeleteStoreWarehouseLink?storeId=${storeId}&warehouseid=${warehouseId}&storeLocationId=${storeLocationId}`
            );
    }

    private handleError<T>(operation = 'operation', result?: T) {

        return (error: any): Observable<T> => {
            switch (error.status) {
                case 401:
                    window.location.href = '/login';
                    break;
                case 422:
                    let message = error?.error?.reason ? error.error.reason : error.error.message;
                    this.toastr.error(message, null, { timeOut: 0 });
                    break;
                default:
                    this.toastr.error(this.translate.instant('GENERAL.MESSAGES.ERROR'));
            }
            return throwError(error);
        };
    }
}
