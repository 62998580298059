import { PickListProcessType } from "src/app/_enums/self-ship/pick-list-process-type.enum";
import { PickingProcessingStatus } from "src/app/_entities/orders/processing-statuses/picking-processing-status";
import { PickListItemModel } from "./pick-list-item.model";

export class PickListModel {
    id: number;
    name: string;
    warehouseId: number;
    processing: boolean;
    processType: PickListProcessType = PickListProcessType.ByOrder;
    processingStatus: PickingProcessingStatus;
    groupId: number;
    groupName: string;
    createdDate: Date;
    ordersCount: number;
    totalQuantityOrdered: number;
    totalQuantityPicked: number;
    items: PickListItemModel[];
}