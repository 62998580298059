export default class ReferenceIdUtil {
    static readonly IS_ALPHABETIC_ONLY_REGEX = new RegExp('^[A-Za-z]+$');
    static readonly A = 'A'.charCodeAt(0);
    static readonly Z = 'Z'.charCodeAt(0);
    static readonly PRE_A = ReferenceIdUtil.A - 1;
    static readonly DIFF_A_TO_Z = ReferenceIdUtil.Z - ReferenceIdUtil.A;

    public static AlphabeticToNumeric(value: string): number {
        if (!value)
            return 0;
        if (!ReferenceIdUtil.IS_ALPHABETIC_ONLY_REGEX.test(value))
            throw (`${this.constructor.name}.${this.AlphabeticToNumeric.name}: Contains non-alphabetic Character`);
        const chars = value.toUpperCase().split('');
        const aggregated = chars.reduce((acc, current) => acc + (current.charCodeAt(0) - ReferenceIdUtil.PRE_A), 0);
        return aggregated + ((chars.length - 1) * ReferenceIdUtil.DIFF_A_TO_Z);
    }
}
