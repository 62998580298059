import * as _ from 'lodash';
import {StoreModel} from 'src/app/_models/store.model';

export default class StoreEntity extends StoreModel {

    public readonly imageUrl;

    constructor(store: StoreModel) {
        super();
        _.assignIn(this, store);
        this.imageUrl = `assets/media/icons/${this.type?.toLowerCase()}-icon.svg`;
    }
}
