// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// RxJS
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
// Models
import { ShippingProfileModel } from '../_models/shipping-profile.model';

import { HelperService } from './helper.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

const API_SHIPPING_PROFILES_URL = environment.baseUrl + '/shippingProfiles';
// Real REST API
@Injectable()
export class ShippingProfilesService {
	constructor(private http: HttpClient,
		private toastr: ToastrService,
		private translate: TranslateService) { }

	// getList(page: number, pageSize: number, sortField: string, sort: string, filters: any): Observable<any> {
	// 	const url = `${API_SHIPPING_PROFILES_URL}?page=${page}&pageSize=${pageSize}
	// 		${sortField ? `&sortField=${sortField}` : ''}${sort ? `&sort=${sort}` : ''}
	// 		&filters=${filters ? JSON.stringify(filters) : ''}`;
	// 	return this.http.get<any>(url,)
	// 		.pipe(catchError(this.handleError('getAll')));
	// }

	fetch() {
		return this.http.get<any>(`${API_SHIPPING_PROFILES_URL}`,)
			.pipe(catchError(this.handleError('getAll')));
	}

	setAsDefault(profitId: number) {
		return this.http.put<any>(`${API_SHIPPING_PROFILES_URL}/setAsDefaultProfile/${profitId}`, null);
	}

	create(profile: ShippingProfileModel): Observable<ShippingProfileModel> {
		return this.http.post(API_SHIPPING_PROFILES_URL, profile,)
			.pipe(catchError(this.handleError<any>('create')));
	}

	update(profile: ShippingProfileModel): Observable<any> {
		return this.http.put(API_SHIPPING_PROFILES_URL, profile,)
			.pipe(catchError(this.handleError<any>('update')));
	}

	delete(id: number) {
		return this.http.delete(`${API_SHIPPING_PROFILES_URL}/${id}`,)
			.pipe(catchError(this.handleError<ShippingProfileModel>('delete')));
	}

	private handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			this.toastr.error(this.translate.instant('GENERAL.MESSAGES.ERROR'));
			return throwError(error);
		};
	}
}
