import { UserStateModel } from "./user.state.model";

export namespace UserActions {

    export class Login {
        static readonly type = "[User] Login User";
        constructor(public username: string, public password: string) { }
    }

    export class Logout {
        static readonly type = "[User] Logout User";
    }

    export class SetUser {
        static readonly type = "[User] Set User";
        constructor(public user: UserStateModel) { }
    }

    export class UpdateUser {
        static readonly type = "[User] Update User";
        constructor(public user: UserStateModel) { }
    }

    export class SetLastStreamed {
        static readonly type = "[User] Set Last Streamed";
        constructor(public action: { type: string }, public date: Date = new Date()) { }
    }

    export class RegenerateUser {
        static readonly type = "[User Regenerate] Regenerate User";
    }
}