import IEntityMap, {EntityMapId} from './i-entity-map';
import ICompositeEntityMap from './i-composite-entity-map';
import {KeyWithValueOfInterface} from '../_types/type-helpers/type-helpers';

export default class EntityMapper {
    private constructor() {
    }

    public static mapById<T>(entities: T[], id: EntityMapId<T> = 'id' as EntityMapId<T>): IEntityMap<T> {
        const map: IEntityMap<T> = {};
        entities.forEach(entity => map[entity[id as string | number | symbol]] = entity);
        return map;
    }

    public static mapByCompositeId<T>(entities: T[], keys: EntityMapId<T>[]): ICompositeEntityMap<T> {
        const map: ICompositeEntityMap<T> = {};
        entities.forEach(entity => map[keys.map(key => entity[key]).join('_')] = entity);
        return map;
    }
}

