<div id="kt_header_mobile" class="kt-header-mobile  kt-header-mobile--fixed" style="z-index: 100;">
  <div class="kt-header-mobile__logo">
    <a routerLink="/">
      <img alt="Logo" style="max-width: 10rem" src="assets/images/landing/inventory-ahead-logo.svg" />
    </a>
  </div>
  <div class="kt-header-mobile__toolbar">
    <div>
      <!--begin: Search -->
      <div class="kt-header__topbar-item kt-header__topbar-item--search dropdown" id="kt_quick_search_toggle">
        <div class="kt-header__topbar-wrapper" data-toggle="dropdown" data-offset="10px,0px">
          <span class="kt-header__topbar-icon"><i class="flaticon2-search-1"></i></span>
        </div>
        <div #search class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-lg">
          <div class="kt-quick-search kt-quick-search--dropdown kt-quick-search--result-compact"
            id="kt_quick_search_dropdown">
            <form method="get" class="kt-quick-search__form">
              <div class="input-group">
                <div class="input-group-prepend"><span class="input-group-text"><i
                      class="flaticon2-search-1"></i></span></div>
                <input type="text" class="form-control kt-quick-search__input" placeholder="Search..."
                  (change)="searchChanged($event, search)">
                <div class="input-group-append"><span class="input-group-text"><i
                      class="la la-close kt-quick-search__close"></i></span></div>
              </div>
            </form>
            <div class="kt-quick-search__wrapper kt-scroll" data-scroll="true" data-height="325"
              data-mobile-height="200">
            </div>
          </div>
        </div>
      </div>
      <!--end: Search -->
    </div>
    <div class="kt-header-mobile__toolbar-toggler kt-header-mobile__toolbar-toggler--left" id="kt_aside_mobile_toggler"
      (click)="barClick(expendBar)">
      <span></span>
    </div>
    <div class="kt-header-mobile__toolbar-topbar-toggler" id="kt_header_mobile_topbar_toggler">
      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item>
          <mat-icon>perm_identity</mat-icon>
          <span>{{userFullName}}</span>
        </button>
        <button mat-menu-item (click)="signOut()">
          <mat-icon>notifications_off</mat-icon>
          <span>Sign Out</span>
        </button>
      </mat-menu>
    </div>
  </div>
</div>

<div class="kt-grid kt-grid--hor kt-grid--root">
  <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
    <kt-aside-left [expendBar]="expendBar">
      <menu-item title="Overview" url="/" icon="flaticon2-cardiogram"></menu-item>
      <menu-item [title]="'PURCHASES.PURCHASES' | translate" icon="flaticon2-shopping-cart-1"
        *ngIf="(['Purchases']| hasPermission)">
        <menu-item [title]="'PURCHASES.PURCHASES' | translate" url="/purchases"></menu-item>
        <menu-item [title]="('ADVANCED_REORDER.ADVANCED_REORDER' | translate) + ' (BETA)'" url="/advancedreorder"
          *ngIf="(['AdvancedReorder'] | hasPermission) && !helperService.isAdvancedReorderDisabledForOrganization()"></menu-item>
      </menu-item>
      <menu-item [title]="'PRODUCTS.PRODUCTS' | translate" icon="flaticon2-tag" *ngIf="(['Products'] | hasPermission)">
        <menu-item [title]="'PRODUCTS.FIELDS.INVENTORY' | translate" url="/products"
          [queryParams]="{curTab: 'inventory'}"></menu-item>
        <menu-item [title]="'PRODUCTS.LISTINGS' | translate" url="/products" [queryParams]="{curTab: 'listings'}">
        </menu-item>
        <menu-item [title]="'KITS.KITS' | translate" url="/products" [queryParams]="{curTab: 'kits'}"></menu-item>
      </menu-item>
      <menu-item [title]="'TRANSFERS.INVENTORY_TRANSFERS' | translate" icon="flaticon2-cube-1"
        *ngIf="(['InventoryTransfers'] | hasPermission) || (['FbaShipments'] | hasPermission) || (['WfsShipments'] | hasPermission)">
        <menu-item [title]="'FBA_SHIPMENTS.FBA_SHIPMENTS' | translate" url="/fba-shipments"
          *ngIf="(['FbaShipments'] | hasPermission)"></menu-item>
        <menu-item [title]="'WFS_SHIPMENTS.WFS_SHIPMENTS' | translate" url="/wfs-shipments"
          *ngIf="(['WfsShipments'] | hasPermission)"></menu-item>
        <menu-item [title]="'TRANSFERS.TRANSFERS' | translate" url="/inventory-transfers"
          *ngIf="(['InventoryTransfers'] | hasPermission)"></menu-item>
        <menu-item [title]="'AMAZON_REMOVALS.AMAZON_REMOVALS' | translate" url="/amazon-removals"
          *ngIf="(['AmazonRemovals'] | hasPermission)"></menu-item>
      </menu-item>
      <menu-item [title]="'ORDERS.SELF_SHIP_ORDERS' | translate" icon="la la-truck" url="/self-ship-orders"
        *ngIf="(['SelfShipOrders'] | hasPermission)"></menu-item>
      <menu-item [title]="'RETURNS.RETURNS' | translate" icon="la la-reply" *ngIf="(['Returns'] | hasPermission)">
        <menu-item title="Amazon Returns" url="/returns/amazon"></menu-item>
        <menu-item title="FBM order returns" url="/returns/fbm-return"></menu-item>
      </menu-item>

      <menu-item title="Reports" icon="flaticon2-line-chart" *ngIf="(['Reports'] | hasPermission)">
        <menu-item title="Profits" url="/reports/profits"></menu-item>
        <menu-item title="Sales" url="/reports/sales"></menu-item>
        <menu-item title="Inventory Balance" url="/reports/inventory-balance"></menu-item>
        <menu-item title="Amazon Settlements" url="/reports/settlements" toolTip="Coming soon"
          [disabled]="true"></menu-item>
        <menu-item [title]="'DAMAGED_INVENTORY.DAMAGED_INVENTORY' | translate"
          url="/reports/damaged-inventory"></menu-item>
        <menu-item title="Fulfilments" url="/fulfilments"></menu-item>
        <menu-item title="Download Reports" url="/upload-cv/download" [disabled]="true"
          toolTip="Coming soon"></menu-item>
      </menu-item>
      <menu-item title="Settings" icon="flaticon2-gear" url="/settings" *ngIf="(['Settings'] | hasPermission)">
      </menu-item>
      <menu-item title="Administration" icon="fa fa-tools" *ngIf="helperService.hasRole(['Global Administrator'])">
        <menu-item title="Hangfire"
          [url]="environment.baseUrl + '/hangfire?accessToken=' + helperService.currentUser.accessToken"
          target="_blank"></menu-item>
        <menu-item title="RabbitMQ" [url]="'/admin/rabbitmq/'" target="_blank"></menu-item>
        <!-- <menu-item title="MongoDB" [url]="'/admin/mongo/'" target="_blank"></menu-item> -->
        <menu-item title="Health Check"
          [url]="environment.baseUrl + '/healthchecks-ui?accessToken=' + helperService.currentUser.accessToken"
          target="_blank"></menu-item>
      </menu-item>
      <menu-item title="Help" icon="flaticon-questions-circular-button" url="https://faq.inventoryahead.com/">
      </menu-item>
    </kt-aside-left>
    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
      <kt-header [title]="header.title"></kt-header>
      <div class="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor bg-light" id="kt_content">
        <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
          <div class="row" *ngIf="helperService.isLoggedIn() && helperService.inactiveDays > 7">
            <div class="col">
              <div class="alert alert-danger alert-elevate fade show">
                <div class="alert-icon"><i class="flaticon-warning"></i></div>
                <div class="alert-text">
                  {{getMessage(helperService.subscriptionStatus)}}
                  <Span *ngIf="!helperService.hasRole(['Administrator'])"> <strong>please contact your
                      administrator</strong></Span>
                  <a *ngIf="helperService.hasRole(['Administrator'])" class="strong alert-text kt-margin-l-10"
                    [routerLink]="['/','settings', 'billing']">Check payment settings</a>
                </div>
              </div>
            </div>
          </div>
          <div class="row"
            *ngIf="helperService.isLoggedIn() && helperService.inactiveDays >0 && helperService.inactiveDays <= 7">
            <div class="col">
              <div class="alert alert-warning alert-elevate fade show">
                <div class="alert-icon"><i class="flaticon-warning"></i></div>
                <div class="alert-text">
                  {{getMessage(helperService.subscriptionStatus)}}
                  <Span *ngIf="!helperService.hasRole(['Administrator'])"> <strong>please contact your
                      administrator</strong></Span>
                  <a *ngIf="helperService.hasRole(['Administrator'])" class="strong alert-text kt-margin-l-10"
                    [routerLink]="['/','settings', 'billing']">Check payment settings</a>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="helperService.exceededDays > 7">
            <div class="col">
              <div class="alert alert-danger alert-elevate fade show">
                <div class="alert-icon"><i class="flaticon-warning"></i></div>
                <div class="alert-text">
                  {{getMessageForExceeded()}}
                  <Span *ngIf="!helperService.hasRole(['Administrator'])"> <strong>please contact your
                      administrator</strong> to upgrade your account</Span>
                  <a *ngIf="helperService.hasRole(['Administrator'])" class="strong alert-text kt-margin-l-10"
                    [routerLink]="['/','settings', 'billing']">Upgrade the account</a>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="helperService.exceededDays > 0 &&  helperService.exceededDays <= 7">
            <div class="col">
              <div class="alert alert-warning alert-elevate fade show">
                <div class="alert-icon"><i class="flaticon-warning"></i></div>
                <div class="alert-text">
                  {{getMessageForExceeded()}}
                  <span *ngIf="!helperService.hasRole(['Administrator'])"><strong> please contact your
                      administrator</strong> to upgrade your account to avoid interruption</span>
                  <a *ngIf="helperService.hasRole(['Administrator'])" class="strong alert-text kt-margin-l-10"
                    [routerLink]="['/','settings', 'billing']">Upgrade the account</a>
                </div>
              </div>
            </div>
          </div>
          <router-outlet></router-outlet>
        </div>
      </div>
      <div class="kt-footer  kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop" id="kt_footer"
        style="z-index: 10;">
        <div class="kt-container  kt-container--fluid ">
          <div class="kt-footer__copyright">
            Copyright © {{ year }} INVENTORY AHEAD LLC. All rights reserved. Version {{ appVersion ?? '-' }}
          </div>
          <div class="kt-footer__menu">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="kt_scrolltop" class="kt-scrolltop">
  <i class="fa fa-arrow-up"></i>
</div>