import { HubConnection, HubConnectionBuilder, HubConnectionState, ISubscription, LogLevel, MessageHeaders } from "@microsoft/signalr";
import { BehaviorSubject, Observable, from } from "rxjs";
import UserState from "src/app/states/user/user.state";
import { environment } from "src/environments/environment";
import { MainHubRoute } from "./real-time-connection.constants";
import { RealTimeStreamSubscriber } from "./real-time-stream-subscriber";
import CustomReconnectPolicy from "./reconnect-policy";
import { FailedToNegotiateWithServerError } from "@microsoft/signalr/dist/esm/Errors";
import { BASE_POLICY, RetryConstants } from "src/app/_intercepters/request-resiliency.interceptor";
import { ExponentialBackoff, retry } from "cockatiel";
import { getTracingHeaders } from "src/app/_intercepters/request-tracing.intercepter";
import { RealTimeConnectionDeps } from "./real-time-connection.deps";


const NO_HANDLE_STATUS_CODE_REGEX = /.*status code.+40[134].*$/gi; // 401/403/404
const ERROR_HANDLING_POLICY = BASE_POLICY.orType(
  FailedToNegotiateWithServerError, 
  err => !NO_HANDLE_STATUS_CODE_REGEX.test(err?.message)
);
const BACKOFF_STRATEGY = new ExponentialBackoff({ initialDelay: RetryConstants.INITIAL_DELAY_MS });

export default class RealTimeConnection {
  protected readonly isConnected$ = new BehaviorSubject<boolean>(false);
  public connection: HubConnection;

  constructor(
      protected deps: RealTimeConnectionDeps,
      private hubRoute = MainHubRoute
    ) { }

  protected get stateStore() {
    return this.deps.stateStore;
  }

  protected get loadingService() {
    return this.deps.loadingService;
  }

  get signalrHttpClient() {
    return this.deps.signalrHttpClient;
  }

  get onConnected() {
    return this.isConnected$.asObservable();
  }

  get connectionState() {
    return this.connection?.state;
  }

  public connect(eventListeners: { [key: string]: (data: unknown) => void } = {}, streamSubscribers:  RealTimeStreamSubscriber[] = []): Observable<void> {
    this.build();
    Object.entries(eventListeners).forEach(([key, listener]) => this.connection.on(key, listener));
    this.onConnected.subscribe(isConnected => {
        for (const subscriber of streamSubscribers) {
          if (isConnected) {
            this.stream(subscriber);
          } else if (subscriber.streamSubscriber?.closed === false) {
            subscriber.connection?.dispose();
          }
        }
    });
    return from(this.start());
  }

  public disconnect(): Observable<void> {
    if (this.connection) {
      return from(this.connection.stop());
    } else {
      return from(Promise.resolve());
    }
  }

  private build() {
    this.connection = new HubConnectionBuilder()
      .withUrl(
        `${environment.baseUrl}/${this.hubRoute}`, 
        { 
          headers: getTracingHeaders() as MessageHeaders,
          httpClient: this.signalrHttpClient,
          accessTokenFactory: () => this.stateStore.selectSnapshot(UserState.accessToken)
        }
      )
      .withAutomaticReconnect(new CustomReconnectPolicy({ errorPolicy: ERROR_HANDLING_POLICY, backoff: BACKOFF_STRATEGY}))
      .withStatefulReconnect()
      .configureLogging(LogLevel.Warning)
      .build();

    this.connection.onreconnecting(_ => this.isConnected$.next(false));
    this.connection.onreconnected(_ => this.isConnected$.next(true));
    this.connection.onclose(_ => this.isConnected$.next(false));
  }

  private async start(): Promise<void> {
    const retryPolicy = retry(ERROR_HANDLING_POLICY, { backoff: BACKOFF_STRATEGY });
    const listener =  retryPolicy.onRetry(reason => console.debug(`SignalR delay: ${reason.delay}`));

     try {
       return await retryPolicy.execute(() => this.connection.start());
     } catch (err) {
        console.error(`signalR -> Error while starting "/${this.hubRoute}": ${err}`)
     } finally {
       listener.dispose();
     }
  }

  public stream(subscriber: RealTimeStreamSubscriber): ISubscription<unknown> {
    if (this.connection?.state !== HubConnectionState.Connected) return;

    if (subscriber.beforeSubscribeAction) {
      this.stateStore.dispatch(subscriber.beforeSubscribeAction);
    }

    this.loadingService.getLoader(subscriber.streamSubscriber.callingAction.type).loading = true;

    const subscriberOnComplete = subscriber.streamSubscriber.complete;
    subscriber.streamSubscriber.complete = () => {
      subscriberOnComplete();
      this.loadingService.getLoader(subscriber.streamSubscriber.callingAction.type).loading = false;
    }

    subscriber.connection = this.connection
            .stream(subscriber.hubInfo.methodName, ...(subscriber.args ?? []))
            .subscribe(subscriber.streamSubscriber);

    return subscriber.connection;        
  }
}