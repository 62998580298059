// Angular
import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
// RxJS
import { finalize, tap } from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Auth
import { AuthService } from 'src/app/_services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
	// encapsulation: ViewEncapsulation.None
})
export class ForgotPasswordComponent implements OnInit {

   introtext:any;
   constructor(config: NgbCarouselConfig,
    private authService: AuthService,
		public toster: ToastrService,
		public translate: TranslateService,
		private router: Router,
		private fb: UntypedFormBuilder,
		private cdr: ChangeDetectorRef) {
    // customize default values of carousels used by this component tree
    config.interval = 5000;
    config.wrap = true;
    config.keyboard = false;
    config.pauseOnHover = false;
  }


  // Public params
	forgotPasswordForm: UntypedFormGroup;
	loading = false;
	errMessage: string;


	randomiseTextArray = [
		'Inventory Management is not the <span class="fw-bold">main thing</span> to  success, its the <span class="fw-bold"> ONLY</span> thing to success.',
		'A good system <span class="fw-bold">shortens</span> the road to the goal.</div>',
		'The features you need to <span class="fw-bold">Systemize</span> your business.',
		'Connected to all your <span class="fw-bold">marketplaces</span>.',
		'Systemize your <span class="fw-bold">e-commerce</span> company',
		'<span class="fw-bold">Simple</span> pricing <span class="fw-bold">practical Solutions.</span>'
	  ]
	
	  newFact() {
		const randomFact = Math.floor(Math.random() * this.randomiseTextArray.length);
		this.introtext = this.randomiseTextArray[randomFact]
	  }

	ngOnInit() {
		this.initRegistrationForm();
		this.newFact()
	}

	initRegistrationForm() {
		this.forgotPasswordForm = this.fb.group({
			email: ['', Validators.compose([
				Validators.required,
				Validators.email,
				Validators.minLength(3),
				Validators.maxLength(320)
			])
			]
		});
	}

	submit() {
		const controls = this.forgotPasswordForm.controls;
		if (this.forgotPasswordForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}
		this.loading = true;
		const email = controls['email'].value;
		this.authService.requestPassword(email).subscribe(() => {
			this.loading = false;
			this.toster.success(this.translate.instant('AUTH.FORGOT.SUCCESS'));
		}, err => {
			this.loading = false;
			this.errMessage = this.translate.instant('AUTH.MESSAGES.ERRORS.' + err.error.reason);
		});
	}

  

}
