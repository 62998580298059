// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
// RxJS
import { finalize, tap } from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Auth
import { AuthService } from 'src/app/_services/auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'kt-reset-password',
	templateUrl: './reset-password.component.html',
	encapsulation: ViewEncapsulation.None
})
export class ResetPasswordComponent implements OnInit {
	resetPasswordForm: UntypedFormGroup;
	loading = false;
	errors: any = [];
	email: string;
	token: string;

	constructor(
		private authService: AuthService,
		public translate: TranslateService,
		private toastr: ToastrService,
		private router: Router,
		private route: ActivatedRoute,
		private fb: UntypedFormBuilder,
		private cdr: ChangeDetectorRef
	) {	}

	ngOnInit() {
		this.email = this.route.snapshot.queryParams.email;
		this.token = this.route.snapshot.queryParams.token;
		if (this.email && this.token)
			this.initResetForm();
		else
			this.router.navigateByUrl('/login');
	}

	initResetForm() {
		this.resetPasswordForm = this.fb.group({
			newPassword: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(100),
        Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)((?=.*[!@#$%^&?.*_]))[A-Za-z\d!@#$%^&?.*_]{6,}$/)]],
			passwordConfimration: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(100)]]
		});
	}

	submit() {
		this.loading = true;
		if(this.resetPasswordForm.value.newPassword == null || this.resetPasswordForm.value.passwordConfimration == null) {
			this.toastr.error('All fields are required');
			this.loading = false;
			return;
		} 
		
		const controls = this.resetPasswordForm.controls;
		if (this.resetPasswordForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			this.toastr.error('Weak password');
			this.loading = false;
			return;
		}
		if(this.resetPasswordForm.value.passwordConfimration !== this.resetPasswordForm.value.newPassword || this.resetPasswordForm.value.newPassword !== this.resetPasswordForm.value.passwordConfimration) {
			this.toastr.error('Password mismatch both fields must have same password');
			this.loading = false;
			return;
		}
		this.authService.resetPassword(this.email, this.token, this.resetPasswordForm.value.newPassword)
			.subscribe(() => {
				this.loading = false;
				this.toastr.success(this.translate.instant('AUTH.RESET_PASSWORD.SUCCESS'));
				this.router.navigateByUrl('/login');
			}, err => {
				this.loading = false;
        this.router.navigateByUrl('/login');
			});
	}
}
