import {BaseModel} from '../_base.model';
import {OrderItemModel} from './order-items/order-item.model';
import {OrderBoxModel} from './order-boxes/order-box.model';
import {OrderAddress} from './order-address.model';


export class OrderModel extends BaseModel {
    id: number;
    orderNumber: string;
    orderDate: Date;
    storeId: number;
    boxes: OrderBoxModel[];
    items: OrderItemModel[] = [];
    orderAddress: OrderAddress;
    rate: number;
    shipBy: string;
}
