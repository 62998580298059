// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// RxJS
import { Observable, BehaviorSubject, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
// Models
import { PriceRuleModel } from '../../_models/price-rule.model';

import { HelperService } from '../helper.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AppStorage } from '../storage';

const API_PRICERULES_URL = environment.baseUrl + '/pricerules';

@Injectable()
export class PriceRulesService {
	constructor(private http: HttpClient,
		private helperService: HelperService,
		private toastr: ToastrService,
		private translate: TranslateService) { }

	priceRulesPage(parentId: number = undefined, page: number = 1, pageSize: number = 20, sortField: string = undefined, sort: string = undefined, filters: any = undefined, force: boolean = true): Observable<any> {
		const url = `${API_PRICERULES_URL}/PriceRulesPage?page=${page}&pageSize=${pageSize}${sortField ? `&sortField=${sortField}` : ''}${sort ? `&sort=${sort}` : ''}&filters=${filters ? JSON.stringify(filters) : ''}${parentId ? `&parentId=${parentId}` : ''}`;

		return AppStorage.cache(`getPriceRulesPage`,
			this.http.get<any>(url)
				.pipe(catchError(this.handleError('getAll'))),
			force
		);
	}

	getAll(): Observable<PriceRuleModel[]> {
		return this.http.get<any>(API_PRICERULES_URL,)
			.pipe(
				catchError(this.handleError('getAll'))
			);
	}

	getAllStores(): Observable<any> {
		const url = `${API_PRICERULES_URL}/getAllStores`;
		return this.http.get<any>(url,)
			.pipe(
				catchError(this.handleError('getAllStores'))
			);
	}

	getById(id: number): Observable<PriceRuleModel> {
		const url = `${API_PRICERULES_URL}/${id}`;
		return this.http.get<PriceRuleModel>(url,).pipe(
			catchError(this.handleError<PriceRuleModel>(`getById id=${id}`))
		);
	}

	create(priceRule: PriceRuleModel): Observable<any> {
		const url = `${API_PRICERULES_URL}/items`;
		return this.http.post<PriceRuleModel>(url, priceRule,
		).pipe(
			catchError(this.handleError<any>('createItem'))
		);
	}

	update(priceRule: PriceRuleModel): Observable<any> {
		const url = `${API_PRICERULES_URL}/items`;
		return this.http.put(url, priceRule,
		).pipe(
			catchError(this.handleError<any>('update'))
		);
	}

	assignPriceRule(ruleId: any, ids: any[]): Observable<any> {
		const url = `${API_PRICERULES_URL}/assignPriceRule?ruleId=${ruleId}&ids=${ids}`;
		return this.http.put(url, ({}),
		).pipe(
			catchError(this.handleError<any>('update'))
		);
	}

	delete(id: number, convertSubsToMain: boolean = false) {
		return this.http.delete(`${API_PRICERULES_URL}/items/${id}`,
		).pipe(
			catchError(this.handleError<PriceRuleModel>('delete'))
		);
	}

	private handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			switch (error.status) {
				case 401:
					window.location.href = '/login';
					break;
				case 422:
					this.toastr.error(this.translate.instant('PRICERULES.MESSAGES.ERRORS.' + error.error.reason, error.error));
					break;
				default:
					this.toastr.error(this.translate.instant('GENERAL.MESSAGES.ERROR'));
			}
			return throwError(error);
		};
	}
}
