import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { PopOverComponent } from "./pop-over.component";


@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        PopOverComponent
    ],
    exports: [
        PopOverComponent
    ]
})
export default class PopOverModule { }
