import * as _ from 'lodash';
import WarehouseCartModel from 'src/app/_models/warehouses/warehouse-cart.model';
import WarehouseCartBin from './warehouse-cart-bin.entity';

export default class WarehouseCart extends WarehouseCartModel {

    private readonly _cart: WarehouseCartModel;
    public readonly  warehouseCartBinEntities: WarehouseCartBin[] = [];

    constructor(cart: WarehouseCartModel) {
        super();
        _.assignIn(this, cart);
        this._cart = cart;

        this.warehouseCartBinEntities = this.bins
            .map(bin => new WarehouseCartBin(bin))
            .sort((a, b) => a.referenceId - b.referenceId);
    }

    toModel() {
        return this._cart;
    }
}
