import { PickingProcessingStatus } from "src/app/_entities/orders/processing-statuses/picking-processing-status";
import { PickedBinItemModel } from "./picked-bin-item.model";

export class PickListItemModel {
    id: number;
    pickListId: number;
    productId: number;
    orderItemIds: number[];
    pickedQuantity: number;
    orderedQuantity: number;
    stockQuantity: number;
    pickedBins: PickedBinItemModel[];
    processingStatus: PickingProcessingStatus;
    markedAsPicked: boolean;
}
