import { HttpContext, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Observable, from, throwError } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { HelperService } from "src/app/_services/helper.service";
import { ExponentialBackoff, handleType, retry } from 'cockatiel';
import HttpContextTokens from "./http-context-tokens";

export class RetryConstants {
    private constructor(){ /* */ }

    public static readonly MAX_RETRIES = 3;
    public static readonly INITIAL_DELAY_MS = 1000;
    public static readonly SERVER_UNREACHABLE_STATUS_CODES = [
        0, 
        HttpStatusCode.InternalServerError, 
        HttpStatusCode.BadGateway, 
        HttpStatusCode.ServiceUnavailable, 
        HttpStatusCode.GatewayTimeout
    ];
}

export const BASE_POLICY = handleType(
    HttpErrorResponse,
    err => RetryConstants.SERVER_UNREACHABLE_STATUS_CODES.includes(err.status)
);

@Injectable()
export default class RequestResiliencyInterceptor implements HttpInterceptor {

    constructor(
        private helperService: HelperService,
        private toastr: ToastrService,
        private translate: TranslateService
    ) { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const maxRetryOverride = request?.context?.get(HttpContextTokens.MAX_RETRY_OVERRIDE);

        const retryPolicy = retry(BASE_POLICY, 
            { 
                maxAttempts: maxRetryOverride > 0 ? maxRetryOverride : RetryConstants.MAX_RETRIES, 
                backoff: new ExponentialBackoff({ initialDelay: RetryConstants.INITIAL_DELAY_MS }) 
            });
        const listener =  retryPolicy.onRetry(reason => console.debug(`Http delay: ${reason.delay}`));

        return from(
            retryPolicy.execute(() => next.handle(request).toPromise())
        ).pipe(
            catchError(this.handleError(request)),
            finalize(() => listener.dispose())
        );
    }

    private handleError(request: HttpRequest<unknown>) {
        return (err: unknown) => {
            const requestContext = request.context;

            if (requestContext?.get(HttpContextTokens.SKIP_ERROR_HANDLING)) {
                return throwError(err);
            } 
            
            if (err instanceof HttpErrorResponse) {
              this.handleErrorResponse(err, requestContext);
            } else {
                this.toastr.error(this.translate.instant('GENERAL.MESSAGES.ERROR'));
            }
            return throwError(err);
        };
    }

    private handleErrorResponse(response: HttpErrorResponse, requestContext: HttpContext) {
        const errorReasonPrefix = requestContext?.get(HttpContextTokens.ERROR_REASON_PREFIX);
        const error = response.error;

        switch (response.status) {
            case HttpStatusCode.BadRequest:
            case HttpStatusCode.NotFound:
                break;
            case HttpStatusCode.Unauthorized:
                this.helperService.redirectToLogin();
                break;
            case HttpStatusCode.UnprocessableEntity: {
                let message;
                if (errorReasonPrefix && error?.reason && this.hasTranslation(`${errorReasonPrefix}.${error.reason}`)){
                    message = this.translate.instant(`${errorReasonPrefix}.${error.reason}`, error);
                } else if (error?.message) {
                    message = error.message;
                } else if (error && (typeof error === 'string' || error instanceof String)) {
                    message = error;
                } else {
                    message = this.translate.instant('GENERAL.MESSAGES.ERROR');
                }
                this.toastr.error(message, null, { timeOut: 0 });
                break;
            }
            default:
                this.toastr.error(this.translate.instant('GENERAL.MESSAGES.ERROR'));
        }
    }

    private hasTranslation(key: string): boolean {
        return this.translate.instant(key) !== key;
    }
}
