// Angular
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
// RxJS
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
// Models
import {StoreModel} from '../_models/store.model';
import {environment} from 'src/environments/environment';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {LoadingService} from './loading.service';

const API_STORES_URL = environment.baseUrl + '/stores';

// Real REST API
@Injectable()
export class StoresService {

    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        private loadingService: LoadingService,
        private translate: TranslateService) {
    }

    fetch(): Observable<StoreModel[]> {
        return this.http.get<StoreModel[]>(API_STORES_URL);
    }

    GetSelfShipOrdersStores(): Observable<StoreModel[]> {
        return this.http.get<any>(`${API_STORES_URL}/GetSelfShipOrdersStores`,)
            .pipe(catchError(this.handleError('getAll')));
    }

    saveFulfillmentLink(name: string, storesWOrdersIds: number[], storeToFulfillId: number, fulfillmentOptions: number[], shippingMethod: number) {
        return this.http.post<any>(`${API_STORES_URL}/FullfilmentLinks`,
            {
                Name: name,
                StoreWOrders: storesWOrdersIds,
                StoreToFulfill: storeToFulfillId,
                FulfillmentOpt: fulfillmentOptions,
                ShippingMethod: shippingMethod
            },
        );
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            this.toastr.error(this.translate.instant('GENERAL.MESSAGES.ERROR'));
            return throwError(error);
        };
    }
}
