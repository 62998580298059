import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
// RxJS
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../../_services/auth.service';
import { AuthNoticeService } from 'src/app/_services/auth-notice.service';
import { HelperService } from 'src/app/_services/helper.service';
import { Store } from '@ngxs/store';
import { UserActions } from 'src/app/states/user/user.actions';
import UserState from 'src/app/states/user/user.state';
import { UserStateModel } from 'src/app/states/user/user.state.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

/**
 * ! Just example => Should be removed in development
 */
const LOGIN_PARAMS = {
  EMAIL: '',
  PASSWORD: ''
};

@UntilDestroy({ checkProperties: true, arrayName: '_subscriptions' })
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy {
  introtext: any;

  passwordIconValue: string = 'fa fa-eye showValue';

  showPassword: boolean = false;



  randomiseTextArray = [
    'Inventory Management is not the <span class="fw-bold">main thing</span> to  success, its the <span class="fw-bold"> ONLY</span> thing to success.',
    'A good system <span class="fw-bold">shortens</span> the road to the goal.</div>',
    'The features you need to <span class="fw-bold">Systemize</span> your business.',
    'Connected to all your <span class="fw-bold">marketplaces</span>.',
    'Systemize your <span class="fw-bold">e-commerce</span> company',
    '<span class="fw-bold">Simple</span> pricing <span class="fw-bold">practical Solutions.</span>'
  ]

  newFact() {
    const randomFact = Math.floor(Math.random() * this.randomiseTextArray.length);
    this.introtext = this.randomiseTextArray[randomFact]
  }

  // Public params
  loginForm: UntypedFormGroup;
  loading = false;
  isLoggedIn$: Observable<boolean>;
  errors: any = [];
  year: number = new Date().getFullYear();
  errMessage: string;

  /**
   * Component constructor
   *
   * @param router: Router
   * @param auth: AuthService
   * @param authNoticeService: AuthNoticeService
   * @param translate: TranslateService
   * @param store: Store<AppState>
   * @param fb: UntypedFormBuilder
   * @param cdr
   * @param route
   */
  constructor(
    config: NgbCarouselConfig,
    private router: Router,
    private stateStore: Store,
    private auth: AuthService,
    private authNoticeService: AuthNoticeService,
    private translate: TranslateService,
    private fb: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private helperService: HelperService,
  ) {
    // customize default values of carousels used by this component tree
    config.interval = 5000;
    config.wrap = true;
    config.keyboard = false;
    config.pauseOnHover = false;


    // require('style-loader!../../../../assets/sass/theme/layout/style-angular.scss');
  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit(): void {
    this.initLoginForm();
    this.newFact()
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this.authNoticeService.setNotice(null);
    this.loading = false;
  }

  /**
   * Form initalization
   * Default params, validators
   */
  initLoginForm() {
    // demo message to show
    if (!this.authNoticeService.onNoticeChanged$.getValue()) {
      const initialNotice = `Use account
            <strong>${LOGIN_PARAMS.EMAIL}</strong> and password
            <strong>${LOGIN_PARAMS.PASSWORD}</strong> to continue.`;
      this.authNoticeService.setNotice(initialNotice, 'info');
    }

    this.loginForm = this.fb.group({
      email: [LOGIN_PARAMS.EMAIL, Validators.compose([
        Validators.required,
        Validators.email,
        Validators.minLength(3),
        Validators.maxLength(320) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
      ])
      ],
      password: [LOGIN_PARAMS.PASSWORD, Validators.compose([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(100)
      ])
      ]
    });
  }

  /**
   * Form Submit
   */
  submit() {
    const controls = this.loginForm.controls;
    /** check form */
    if (this.loginForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      return;
    }

    this.loading = true;

    const authData = {
      email: controls['email'].value,
      password: controls['password'].value
    };

    this.stateStore.dispatch(new UserActions.Login(authData.email, authData.password))
      .pipe(
        tap(_ => {
          const user = this.stateStore.selectSnapshot<UserStateModel>(UserState);
          if (user) {
            if (user.subscriptionStatus && user.hasPricingPlan == false)
              this.router.navigateByUrl('register?step=' + (!(user as any).organizationName ? 2 : 3));
            else
              this.router.navigateByUrl(this.route.snapshot.queryParams.returnUrl || '/');
          } else {
            this.authNoticeService.setNotice(this.translate.instant('VALIDATION.INVALID_LOGIN'), 'danger');
          }
        }),
        untilDestroyed(this),
        finalize(() => {
          this.loading = false;
          this.cdr.markForCheck();
        })
      ).subscribe(res => {
        this.loading = false;
      }, err => {
        this.loading = false;
        this.errMessage = err.error.message;
      });
  }

  /**
   * Checking control validation
   *
   * @param controlName: string => Equals to formControlName
   * @param validationType: string => Equals to valitors name
   */
  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.loginForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

  signup() {
    this.router.navigateByUrl('/register');
  }

  passwordShow() {
    this.showPassword = !this.showPassword;
    if (this.showPassword) {
      this.passwordIconValue = 'fa fa-eye-slash showValue'
    } else {
      this.passwordIconValue = 'fa fa-eye showValue';
    }
  }

}
