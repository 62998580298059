import { Pipe, PipeTransform } from '@angular/core';
import { HelperService } from 'src/app/_services/helper.service';

@Pipe({
  name: 'hasPermission',
})
export class HasPermissionPipe implements PipeTransform {

  constructor(private helperService: HelperService) { }
  transform(pageNames: string[]): boolean {
    return this.helperService.hasPermission(pageNames);
  }

}
