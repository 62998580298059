import { BaseModel } from "../_base.model";
import { WarehouseBinModel } from "./warehouse-bin.model";
import IStockLocationModel from "./stock-location.model";

export class WarehouseShelfModel extends BaseModel implements IStockLocationModel<number> {
    id: number;
    name: string;
    referenceId: number;
    rackId: number;
    bins: WarehouseBinModel[];
}