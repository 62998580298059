import { HubConnectionState } from "@microsoft/signalr";
import { Observable } from "rxjs";
import RealTimeConnection from "./real-time-connection";
import { RealTimeConnectionDeps } from "./real-time-connection.deps";
import { MainHubRoute } from "./real-time-connection.constants";
import { RealTimeStreamSubscriber } from "./real-time-stream-subscriber";

export default class RealTimeStreamConnection extends RealTimeConnection {
    constructor(
        realTimeConnectionDeps: RealTimeConnectionDeps,
        public subscriber: RealTimeStreamSubscriber
    ) {
        super(realTimeConnectionDeps, `${MainHubRoute}/${subscriber.hubInfo.hubName}`);
        this.onConnected.subscribe(isConnected => {
            if (isConnected) {
                this._stream();
            } else if (this.subscriber.streamSubscriber.closed === false) {
                this.dispose();
            }
        });
    }

    public dispose(): Observable<void> {
        if (this.subscriber.streamSubscriber.closed === false) {
            this.subscriber.connection.dispose();
        }
        return super.disconnect();
    }

    private _stream() {
        if (this.connection.state !== HubConnectionState.Connected) return;
        
        const subscriberOnComplete = this.subscriber.streamSubscriber.complete;
        this.subscriber.streamSubscriber.complete = () => {
            subscriberOnComplete();
            this.dispose();
        }
        this.stream(this.subscriber);
    }
}