import * as _ from 'lodash';
import {ProductLocationModel} from 'src/app/_models/products/product-location.model';
import ProductWarehouseModel from 'src/app/_models/products/product-warehouse.model';
import ProductModel from 'src/app/_models/products/product.model';
import StoreProduct from './store-product.entity';
import {StatesHelper} from '../../states/states.helper';

export default class Product extends ProductModel {

    constructor(product: ProductModel) {
        super();
        _.assign(this, product);
    }

    get storeProducts(): StoreProduct[] {
        return StatesHelper
            .storeProductsEntitiesState
            .entitiesArray
            .filter(sp => sp.productId === this.id)
            .map(sp => new StoreProduct(sp));
    }

    get displayImageUrl(): string {
        return this.storeProducts
            .find(sp => !!sp.imageUrl)
            ?.imageUrl;
    }

    storeProduct(storeId: number): StoreProduct {
        return new StoreProduct(
            StatesHelper
                .storeProductsEntitiesState
                .entities[`${this.id}_${storeId}`]
        );
    }

    productLocation(warehouseId: number): ProductLocationModel | undefined {
        return this.productLocations.find(location => location.warehouseId === warehouseId);
    }

    productWarehouse(warehouseId: number): ProductWarehouseModel | undefined {
        return this.productWarehouses.find(pw => pw.warehouseId === warehouseId);
    }
}
