import {Injectable} from '@angular/core';
import {Store} from '@ngxs/store';
import StoreEntity from '../_entities/stores/store.entity';
import {CurrenciesEntitiesState} from '../states/common/currencies-entities.state';
import StoresEntitiesState from '../states/stores/stores-entities.state';
import {StatesHelper} from '../states/states.helper';
import {STORE_TYPES} from '../_enums/stores/store-type.enum';
import { CurrencyModel, DEFAULT_CURRENCY } from '../_models/currency.model';


@Injectable()
export class StoresManager {
    /**
     * @deprecated For backwards compatible
     */
    storesByType: { [type: string]: StoreEntity[] } = {};
    loadRequested = false;
    prefixes = {
        AmazonUS: '.com',
        AmazonCA: '.ca',
        AmazonMX: '.com.mx',
        AmazonBR: '.com.br',

        // EU region
        AmazonUK: '.co.uk',
        AmazonDE: '.de',
        AmazonES: '.es',
        AmazonFR: '.fr',
        AmazonIT: '.it',
        AmazonPL: '.pl',
        AmazonAE: '.ae',
        AmazonEG: '.eg',
        AmazonIN: '.in',
        AmazonNL: '.nl',
        AmazonSA: '.sa',
        AmazonTR: '.com.tr',
        AmazonSE: '.se',
        AmazonBE: '.com.be',

        // Far East Region
        AmazonSG: '.sg',
        AmazonAU: '.au',
        AmazonJP: '.jp',
    };

    constructor(
        private stateStore: Store,
        public currencies: CurrenciesEntitiesState,
        public storeEntitiesState: StoresEntitiesState
    ) {

    }

    /**
     * @deprecated For backwards compatible
     */
    get storeEntities() {
        const result = this.storeEntitiesState.storeEntities;
        for (const type in this.storesByType) {
            this.storesByType[type] = result.filter(s => s.type === type);
        }
        return result;
    };

    /**
     * @deprecated For backwards compatible
     */
    get getStoresByType() {
        return (type: string) => {
            if (!Object.keys(this.storesByType).length) {
                let stores = StatesHelper.storesEntitiesState.entitiesArray.slice();
                this.storesByType = STORE_TYPES
                    .reduce((result, storeType) => {
                        result[storeType] = stores = stores.filter(store => store.type === storeType);
                        return result;
                    }, {});
            }
            return this.storesByType[type] ?? [];
        };
    }

    getAll(callBack: Function = undefined) {
        const isEmpty = !this.storeEntitiesState.snapshot.ids.length;
        if (isEmpty && callBack)
            this.storeEntitiesState.onStateChange(state => () => callBack(state.storeEntities));
        let result = this.storeEntitiesState.storeEntities;
        if (!isEmpty && callBack)
            callBack(result);
        return result;
    }

    filterStoresByType(type: string) {
        return this.storeEntitiesState
            .storesEntityMap
            .filter(s => s.type === type);
    }

    /**
     * @deprecated After signalR starts to send updates, subscribe directly to state
     */
    reload() {
        if (!this.loadRequested) {
            this.loadRequested = true;
            this.storeEntitiesState.load();
            this.loadRequested = false;
        }
    }

    /**
     * @deprecated For backwards compatible
     */
    amazonStorePrefix(amazonStoreType: string) {
        return this.prefixes[amazonStoreType];
    }

    /**
     * @deprecated For backwards compatible
     */
    public findStore(item: { storeId?: number, orderItem?: { storeId?: number } }) {
        return this.getAll().find(s => s.id == (Number(item.storeId || item.orderItem.storeId)));
    }

    /**
     * @deprecated For backwards compatible
     */
    public getCurrencyCode(item: { storeId?: number, orderItem?: { storeId?: number } }) {
        const store = this.findStore(item);
        return (this.currencies.entities[store?.currencyId ?? -1] ?? DEFAULT_CURRENCY)?.code
    }
}
