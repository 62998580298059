import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { UserRegisterModel } from '../_models/user-register.model';
import { HelperService } from './helper.service';
import { AddressModel } from '../_models/address.model';
import { CompleteRegistrationModel } from '../_models/complete-registration.model';
import { UserStateModel } from '../states/user/user.state.model';

const API_USERS_URL = environment.baseUrl + '/users';

@Injectable()
export class AuthService {

  constructor(private http: HttpClient,
    private toastr: ToastrService,
    private translate: TranslateService,
    private helperService: HelperService) { }

  // Authentication/Authorization
  login(email: string, password: string): Observable<UserStateModel> {
    return this.http.post<UserStateModel>(`${API_USERS_URL}/authenticate`, { email, password });
  }

  regenerateUser(): Observable<UserStateModel> {
      return this.http.get<UserStateModel>(API_USERS_URL + '/regenerate');
  }

  register(user: UserRegisterModel): Observable<UserStateModel> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<UserStateModel>(`${API_USERS_URL}/signUp`, user, { headers: httpHeaders })
      .pipe(map((res: UserStateModel) => res));
  }

  addAddress(address: AddressModel) {
    return this.http.post<any>(`${API_USERS_URL}/addAddress`,
      address);
  }

  completeRegistration(registerCompleteModel: CompleteRegistrationModel): Observable<any> {
    return this.http.post<any>(`${API_USERS_URL}/CompleteRegistration`,
      registerCompleteModel);
  }

  requestPassword(email: string): Observable<any> {
    return this.http.get(API_USERS_URL + '/forgot', { params: { email } });
  }

  resetPassword(email: string, token: string, newPassword: string) {
    return this.http.patch(API_USERS_URL + '/resetPassword', { email, token: encodeURI(token), newPassword });
  }

  getPricingPlans(): Observable<any> {
    return this.http.get(`${environment.baseUrl}/pricingPlans`);
  }

  support(name: string, email: string, message: string) {
    const params = { name, email, message };
    return this.http.post(`${API_USERS_URL}/support`, { params });
  }
}