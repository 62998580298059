import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GetProductPackingBoxBoxConfigPayload, PackingBoxConfigModel, SaveProductPackingBoxBoxConfigPayload } from '../_models/packing-box-config.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

const BOX_CONFIG_URL = `${environment.baseUrl}/boxConfigs`;
const PRODUCT_PACKING_BOX_CONFIG_URL = `${environment.baseUrl}/productPackingBoxConfig`;
@Injectable()
export class BoxConfigsService {
    constructor(
        private http: HttpClient
    ) { }

    public fetch(): Observable<PackingBoxConfigModel[]> {
        return this.http.get<PackingBoxConfigModel[]>(BOX_CONFIG_URL);
    }

    public create(packingBoxConfig: PackingBoxConfigModel): Observable<PackingBoxConfigModel> {
        return this.http.post<PackingBoxConfigModel>(BOX_CONFIG_URL, packingBoxConfig);
    }

    public delete(id: number): Observable<unknown> {
        return this.http.delete(`${BOX_CONFIG_URL}/${id}`);
    }

    public saveProductPackingBoxConfig(payload: SaveProductPackingBoxBoxConfigPayload[]): Observable<any> {
        return this.http.post(`${PRODUCT_PACKING_BOX_CONFIG_URL}/saveProductPackingBoxConfig`, payload);
    }
   
    public getDefaultPackingConfigForProducts(payload: GetProductPackingBoxBoxConfigPayload[]): Observable<any> {
        return this.http.post(`${PRODUCT_PACKING_BOX_CONFIG_URL}/getDefaultPackingConfigForProducts`, payload);
    }
}
