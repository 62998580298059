import { HttpClient, HttpContext } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum, RumInitConfiguration } from "@datadog/browser-rum";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import _ from "lodash";
import { BehaviorSubject, EMPTY, Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import HttpContextTokens from "src/app/_intercepters/http-context-tokens";
import UserState from "src/app/states/user/user.state";
import { APP_CONFIG_NAME, AppConfig } from "src/environments/app.config";
import { environment } from "src/environments/environment";

@UntilDestroy({ checkProperties: true })
@Injectable({
  providedIn: "root",
})
export default class AppInitService {
  private appConfigSubject = new BehaviorSubject<AppConfig>(null);

  constructor(private http: HttpClient, private stateStore: Store) {
    this.init();
  }

  public get appConfig$(): Observable<AppConfig> {
    return this.appConfigSubject.asObservable();
  }

  public get appConfig() {
    return this.appConfigSubject.getValue();
  }

  private set appConfig(value: AppConfig) {
    this.appConfigSubject.next(value);
    window[APP_CONFIG_NAME] = value;
    this.configure();
  }

  private init() {
    this.appConfig = window[APP_CONFIG_NAME] as AppConfig;

    const context = new HttpContext()
      .set(HttpContextTokens.SKIP_ERROR_HANDLING, true)
      .set(HttpContextTokens.MAX_RETRY_OVERRIDE, Infinity);
    this.http
      .get<AppConfig>(`${environment.baseUrl}/configurations`, { context })
      .pipe(
        catchError((err: unknown) => {
          console.error(err);
          return EMPTY;
        }),
        untilDestroyed(this)
      )
      .subscribe((remoteConfig) => {
        const finalConfig = _.mergeWith(
          { isInitialized: true } as AppConfig,
          window[APP_CONFIG_NAME],
          remoteConfig,
          (appConfigValue: AppConfig, windowConfigValue: AppConfig) => appConfigValue || windowConfigValue
        ) as AppConfig;
        this.appConfig = finalConfig;
      });
  }

  private configure() {
    if (this.appConfig.datadogClientId) {
      this.initDatadogRum();
      this.initDatadogLogs();
      this.stateStore
        .select(UserState)
        .pipe(untilDestroyed(this))
        .subscribe(UserState.setUserToDatadog);
    }
  }

  private initDatadogRum() {
    datadogRum.init({
      applicationId: this.appConfig.datadogApplicationId,
      clientToken: this.appConfig.datadogClientId,
      site: "datadoghq.com",
      service: "web-app",
      env: this.appConfig.serverEnv,
      version: this.appConfig.version,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      traceSampleRate: 100,
      telemetrySampleRate: 20,
      trackUserInteractions: true,
      trackFrustrations: true,
      trackResources: true,
      trackLongTasks: true,
      trackSessionAcrossSubdomains: true,
      useSecureSessionCookie: true,
      useCrossSiteSessionCookie: true,
      allowFallbackToLocalStorage: true,
      silentMultipleInit: true,
      defaultPrivacyLevel: "allow",
      allowedTracingUrls: [
        /https:\/\/.*\.inventoryahead\.com/,
        /http(s)?:\/\/localhost/,
      ],
    } as RumInitConfiguration);
    datadogRum.startSessionReplayRecording();
  }

  private initDatadogLogs() {
    datadogLogs.init({
      clientToken: this.appConfig.datadogClientId,
      site: "datadoghq.com",
      service: "web-app",
      env: this.appConfig.serverEnv,
      version: this.appConfig.version,
      forwardErrorsToLogs: true,
      forwardReports: "all",
      sessionSampleRate: 100,
      telemetrySampleRate: 20,
      silentMultipleInit: true,
      trackSessionAcrossSubdomains: true,
      useSecureSessionCookie: true,
      useCrossSiteSessionCookie: true,
    });
  }
}
