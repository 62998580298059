import {IFlatEntityMap} from './i-flat-entity-map';
import EntityMap from './entity-map';
import CompositeEntityMap from './composite-entity-map';

export class FlatEntityMap<T> {
    private readonly _map: IFlatEntityMap<T>;

    constructor(map: IFlatEntityMap<T>) {
        this._map = map;
    }

    public get ids(): number[] {
        return Object.keys(this._map).map(key => +key);
    }

    public get entityArrays() {
        return Object.values(this._map);
    }

    public get entities() {
        return this._map;
    }

    public get toEntityMap() {
        return new EntityMap<T>(this._map, undefined);
    }
}
