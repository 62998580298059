import { BaseModel } from "./_base.model";

export class UserRegisterModel extends BaseModel {
    email:string;
    password: string;
    confirmPassword: string;
    firstName: string;
    lastName: string;
    agree: boolean;
    phoneNumber: string;
}
