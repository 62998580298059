// Angular
import {Injectable, DebugElement} from '@angular/core';
import {HttpClient, HttpEvent, HttpRequest} from '@angular/common/http';
// RxJS
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
// Models

import {HelperService} from './helper.service';
import {environment} from 'src/environments/environment';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {ListProductStoresRequest, SyncAmazonRequest} from '../_models/product-store-item.model';

const API_LISTING_URL = environment.baseUrl + '/listing';

// Real REST API
@Injectable()
export class ListingService {
  constructor(private http: HttpClient,
              private helperService: HelperService,
              private toastr: ToastrService,
              private translate: TranslateService) {
  }


  putItemOnStoreProductWalmart(productId: number): Observable<any> {
    const url = `${API_LISTING_URL}/${productId}/ListOnStoreProductsWM`;
    return this.http.put(url, {},
      {headers: this.helperService.getHTTPHeaders()});
  }


  listProductsToStores(item: ListProductStoresRequest): Observable<any> {
    const url = `${API_LISTING_URL}/ListProductsToStores`;
    return this.http.post(url, item,
      {headers: this.helperService.getHTTPHeaders()});
  }

  checkCompability(productId: number): Observable<any> {
    const url = `${API_LISTING_URL}/${productId}/CheckCompability`;
    return this.http.get(url,
      {headers: this.helperService.getHTTPHeaders()});
  }

  getVariations(productId: number): Observable<any> {
    const url = `${API_LISTING_URL}/Variations/${productId}`;
    return this.http.get(url,
      {headers: this.helperService.getHTTPHeaders()});
  }

  syncAmazon(item: SyncAmazonRequest): Observable<any> {
    const url = `${API_LISTING_URL}/SyncAmazon`;
    return this.http.post(url, item,
      {headers: this.helperService.getHTTPHeaders()});
  }

  getHistory(from: Date = null, to: Date = null): Observable<any> {
    let params: any = {};
    if (from) {
      params.from = from;
    }
    if (to) {
      params.to = to;
    }
    const url = `${API_LISTING_URL}/History`;
    return this.http.get(url, {headers: this.helperService.getHTTPHeaders(), params: params});
  }
  
  getHistoryOld(from: Date = null, to: Date = null): Observable<any> {
    let params: any = {};
    if (from) {
      params.from = from;
    }
    if (to) {
      params.to = to;
    }
    const url = `${API_LISTING_URL}/HistoryOld`;
    return this.http.get(url, {headers: this.helperService.getHTTPHeaders(), params: params});
  }

  changeToFba(productId: number, storeId: number, changeToFba: boolean): Observable<any> {
    const url = `${API_LISTING_URL}/ChangeToFba`;
    return this.http.post(url, {changeToFba, productId, storeId}, {headers: this.helperService.getHTTPHeaders()});
  }

  deleteFromProductListings(id: number): Observable<any> {
    return this.http.delete<any>(`${API_LISTING_URL}/deleteFromProductListings/${id}`, {headers: this.helperService.getHTTPHeaders()})
      .pipe(catchError(this.handleError('deleteFromProductListings')));
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      switch (error.status) {
        case 401:
          window.location.href = '/login';
          break;
        case 422:
          this.toastr.error(this.translate.instant('ORDERS.MESSAGES.ERRORS.' + error.error.reason, error.error));
          break;
        case 404:
          this.toastr.error(this.translate.instant('ORDERS.MESSAGES.ERRORS.NOT_FOUND', error.error));
          break;
        default:
          this.toastr.error(this.translate.instant('GENERAL.MESSAGES.ERROR'));
      }
      return throwError(error);
    };
  }
}
