import { BaseModel } from './_base.model';

export class AddressModel extends BaseModel {
  id: number;
  contactName: string;
  companyName: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  countryCode: string;
  stateCode: string;
  postalCode: string;
  phone: string;
  email: string;
}
