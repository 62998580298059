// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { ShippingAccountModel } from '../_models/shipping-account.model';
import { ShippingCarrier, ShippingProvider } from '../_enums/self-ship/shipping-provider.enum';
import { ShippingServiceMethod } from '../_models/shipping-rule.model';

export interface ShippingAccountOutboundDTO {
  id?: number,
  name: string,
  accountNumber?: string,
  key: string,
  password?: string,
  meterNumber?: string,
  thirdPartyAccountNumber?: string,
  shippingCarrier: ShippingCarrier;
}

const API_SHIPPING_ACCOUNTS_URL = environment.baseUrl + '/shippingaccounts';

// Real REST API
@Injectable()
export class ShippingAccountsService {

  loaders: { [methodName: string]: boolean; } =
    Object.fromEntries(Object.keys(this).filter(key => typeof this[key] === 'function').map(key => [key, false]));

  constructor(private http: HttpClient) { }

  fetch(): Observable<ShippingAccountModel[]> {
    this.loaders[this.fetch.name] = true;
    return this.http.get<ShippingAccountModel[]>(`${API_SHIPPING_ACCOUNTS_URL}`,)
      .pipe(
        tap(() => {
          this.loaders[this.fetch.name] = false;
        })
      );
  }

  serviceTypeName(type: string) {
    if (type.startsWith('Amazon')) {
      return type.replace('Amazon_', 'Amazon Shipping - ');
    } else {
      return type;
    }
  }

  getById(id: number): Observable<ShippingAccountOutboundDTO> {
    return this.http.get<ShippingAccountOutboundDTO>(`${API_SHIPPING_ACCOUNTS_URL}/${id}`);
  }

  create(shippingAccount: ShippingAccountOutboundDTO) {
    return this.http.post(API_SHIPPING_ACCOUNTS_URL, shippingAccount);
  }

  update(shippingAccount: ShippingAccountOutboundDTO) {
    return this.http.put(API_SHIPPING_ACCOUNTS_URL, shippingAccount);
  }

  delete(id: number) {
    return this.http.delete(`${API_SHIPPING_ACCOUNTS_URL}/${id}`);
  }

  getShippingServiceMethod(): Observable<ShippingServiceMethod[]> {
    const API_SERVICE_METHOD = environment.baseUrl + '/ShippingServiceMethod';
    return this.http.get<any[]>(API_SERVICE_METHOD + '/getAll').pipe(map((resp) => {
      return resp.map((item, index) => ({ ...item, id: index, provider: this.getServiceProviderEnumValue(item.provider) }));
    }));
  }

  getServiceProviderEnumValue(provider: string) {
    switch (provider) {
      case 'UPS':
        return ShippingProvider.UPS;
      case 'USPS':
        return ShippingProvider.USPS;
      case 'FedEx':
        return ShippingProvider.FedEx;
      case 'ShipEngine':
        return ShippingProvider.ShipEngine;
      case 'Amazon_USPS':
        return ShippingProvider.Amazon_USPS;
      case 'Amazon_UPS':
        return ShippingProvider.Amazon_UPS;
      case 'Amazon_FedEx':
        return ShippingProvider.Amazon_FedEx;
    }
  }
}
