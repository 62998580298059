import { UntypedFormGroup } from "@angular/forms";
import * as _ from "lodash";
import { EnumType } from "./helper-types";
export default class Utils {
    private constructor() { }

    /**
     * @param lbs
     * @returns { lbs: number, oz: number }
     * Takes the amount of pounds as input and returns an object containing the breakdown of its lbs and remaining oz
     * */
    public static breakDownLbs(lbs: number): { lbs: number, oz: number } {
        return {
            lbs: ((lbs * 16) - ((lbs * 16) % 16)) / 16,
            oz: Math.round((lbs * 16) % 16),
        }
    }
    /** Chunk array into smaller bits */
    public static arrayChunk(array: any[], chunkSize: number): any[] {
        if (chunkSize == null || chunkSize == undefined) {
            console.error(`Method 'arrayChunk' requires a chunkSize parameter, none given`);
            return null;
        }
        else if (chunkSize == 0) {
            console.error(`arrayChunk() - chunkSize cannot be 0`);
            return null;
        }
        let arrayCopy: any[] = array.slice();
        const result: any[] = [];
        while (arrayCopy.length) {
            result.push(arrayCopy.slice(0, chunkSize));
            arrayCopy.splice(0, chunkSize);
        }
        return result;
    }
    /** Capatalize string */
    public static capitalize(string: string): string {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    /**
     * @returns The Truncated text from the given content in the given format
     */
    public static truncate(content: string, truncateBy: number, truncateAt: 'begining' | 'middle' | 'end'): string {
        if (!content) return;
        if (content.length <= truncateBy) return content;
        switch (truncateAt) {
            case 'begining':
                return `...${content.substring(content.length - truncateBy)}`;
            case 'middle':
                return `${content.slice(0, truncateBy / 2)}...${content.slice(content.length - truncateBy / 2)}`;
            case 'end':
                return `${content.slice(0, truncateBy)}...`;
        }
    }
    /** Get the base64 of any file given */
    public static getBase64(file: Blob): Promise<string | ArrayBuffer> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }
    /** @returns returns an array of nulls with the amount given */
    public static nullArray(amount: number): null[] {
        const nullArray: null[] = [];
        for (let i = 0; i < amount; i++) {
            nullArray.push(null);
        }
        return nullArray;
    }

    /**
     * Prevents special characters on HTML input (Called on keyDown)
     * @param event
     */
    public static preventSpecialChars(event: KeyboardEvent): void {
        const specialChars = /[^\w\- ]/gmi;
        if (specialChars.test(event.key)) {
            event.preventDefault();
        }
    }

    /**
     *
     * @param arr
     * @returns returns true or false wether the given array contains duplicates
     */
    public static hasDuplicates = (arr: any[]) => {
        return _.uniq(arr).length !== arr.length;
    }

    public static equalsAny = <T>(compareObject: T, ...args: T[]): boolean => {
        return _.includes(args, compareObject);
    }

    public static equalsAll<T>(compareObject: T[], ...values: T[]): boolean {
        return _.isEqual(compareObject.sort(), values.sort());
    }

    public static enumKeys(enumObject: { [key: string]: number | string }): string[] {
        return Object.getOwnPropertyNames(enumObject).filter(key => !(parseInt(key) >= 0));
    }

    public static snakeToRegularCase = (content: string): string => {
        if (!['string', 'undefined', 'null'].includes(typeof content)) throw (`Utils.snakeToRegularCase(${typeof content}): string expected but got ${typeof content}`);
        return content?.split('_').join(' ');
    }

    public static snakeToReg = this.memoizedFunction(this.snakeToRegularCase);

    static camelToReg(content: string) {
        if (!['string', 'undefined', 'null'].includes(typeof content)) throw (`Utils.snakeToRegularCase(${typeof content}): string expected but got ${typeof content}`);
        const result = content.replace(/([A-Z])/g, " $1");
        return result.charAt(0).toUpperCase() + result.slice(1);
    }

    public static asAny(anything: unknown) {
        return anything as any;
    }

    public static get doc() {
        return document;
    }

    public static get win() {
        return window;
    }

    public static memoizedFunction<T, K>(func: (...params: K[]) => T) {
        let cachedResult: T;
        let previousParams: K[];
        return (...params: K[]) => {
            if (!cachedResult || !_.isEqual(params, previousParams)) {
                cachedResult = func(...params);
                previousParams = _.cloneDeep(params);
            }
            return cachedResult;
        }
    }

    public static formToObj(form: UntypedFormGroup): { [key: string]: unknown } {
        const controls = form.controls;
        return Object.keys(controls).reduce((obj, key) => ({ ...obj, [key]: controls[key].value }), {});
    }

    /**
     *
     * @param el
     * @returns returns an object containg info on the absolute position of the given element
     */
    public static getOffset(el: HTMLElement): { left: number, top: number } {
        const rect = el.getBoundingClientRect();
        return {
            left: rect.left + window.scrollX,
            top: rect.top + window.scrollY
        };
    }

    public static readonly isOfValidEnumName = <T extends EnumType>(str: string, _enum: T): str is Extract<keyof T, string> => str in _enum

    public static readonly stringToEnum = <T extends EnumType>(name: string, _enum: T) => _enum[name as keyof typeof _enum];

  // Returns weather the given element has focus.
  public static elementHasFocus(element: HTMLElement): boolean {
    const customElement = (element as HTMLElement & {focused: boolean});
    if (!element) { return false; }
    return (document.activeElement === customElement || customElement.focused) && document.hasFocus();
  }

    // Returns any given phone number in the format of -- (123) 456-7890 ext. 12345... --.
    public static formatPhoneNumber(phoneNumber: string): string {
        const match = phoneNumber?.match(/([+(]?\d{0,3}[ -)])?(\d{3})[ -]?(\d{3})[ -]?(\d{4})([^0-9]{1,20}\d+)?/);
        if (match) {
            return ('(' + (match[2] || '') + ') ' + (match[3] || '') + '-' + (match[4] || '') + ' ' + (match[5] || ''));
        }
        return phoneNumber;
    }

    public static objectLength(obj: unknown & {}): number {
      return Object.keys(obj).length;
    }

    public static objectKeys(obj: unknown & {}) {
      return Object.keys(obj);
    }

    public static filterInPlace<T>(array: T[], predicate: (item: T) => boolean) {
        let j = 0;

        array.forEach((item, index) => {
            if (predicate(item)) {
                if (index!==j) array[j] = item;
                j++;
            }
        });

        array.length = j;
        return array;
    }

    /**
     * Map properties with the same name from source to a new instance of the target class.
     * @param source The source object from which to take values.
     * @param TargetClass The constructor of the class to which properties should be mapped.
     */
    static mapProperties<TSource, TTarget extends new () => TTarget>(source: TSource, TargetClass: new () => TTarget): TTarget {
        const target = new TargetClass();
        return this.mapProperties(source, target);
    }


    /**
     * Map properties with the same name from source to a new instance of the target class.
     * @param source The source object from which to take values.
     * @param target The target object to which properties should be mapped.
     */
    static mapIntoProperties<TSource extends object, TTarget extends object>(source: TSource, target: TTarget): TTarget {
        for (const prop in source) {
            if (Object.prototype.hasOwnProperty.call(source, prop) && prop in target) {
                (target as unknown)[prop] = source[prop];
            }
        }
        return target;
    }
}
