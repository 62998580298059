import _ from "lodash";
import { WarehouseBinItemModel } from "src/app/_models/warehouses/warehouse-bin-item.model";
import WarehouseBin from "./warehouse-bin.entity";
import { StatesHelper } from "src/app/states/states.helper";
import Product from "../../products/product.entity";


export default class WarehouseBinItem extends WarehouseBinItemModel {

    constructor(warehouseBinItem: WarehouseBinItemModel) {
        super();
        _.assignIn(this, warehouseBinItem);
    }

    get product(): Product {
        return StatesHelper.getEntity(StatesHelper.productsEntitiesState, this.productId, Product);
    }

    get bin(): WarehouseBin {
        return StatesHelper.warehousesEntitiesState.warehouseBinsEntityMap[this.binId];
    }
}