import { AddressModel } from '../address.model';
import WarehouseCartModel from './warehouse-cart.model';
import { BaseModel } from '../_base.model';
import { WarehouseAisleModel } from './warehouse-aisle.model';

export class WarehouseModel extends BaseModel {
  id: number;
  name: string;
  address: AddressModel;
  companyAddress: AddressModel = new AddressModel();
  active: boolean;
  type: string;
  locationHasInventory: boolean;
  isDefault: boolean;
  allowAutoAdjustment: boolean;
  autoAdjustmentDate: Date;
  link: string;
  skuField: string;
  quantityField: string;
  enabledForReorder: boolean;
  warehouseCarts: WarehouseCartModel[];
  warehouseAisles: WarehouseAisleModel[];
}