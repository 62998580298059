import { Component, Input, OnInit, Directive } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})


export class TooltipComponent implements OnInit {

  @Input() targetRef = null;
  @Input() showIf = false;
  @Input() delayTime = 200;

  hoverObserver = new BehaviorSubject(false);

  setHoveredTimeout;

  cancelHoverTimeout() {
    if (!!this.setHoveredTimeout) { clearTimeout(this.setHoveredTimeout); }
  }

  get isHovered() {
    const isHovered = !!this.targetRef && this.targetRef.matches(':hover');
    if (!isHovered) {
      this.cancelHoverTimeout();
      this.hoverObserver.next(isHovered);
      return isHovered;
    }
    else {
      this.cancelHoverTimeout();
      this.setHoveredTimeout = setTimeout(() => {
          this.hoverObserver.next(isHovered);
      }, this.delayTime ?? 0);
    }
    return this.hoverObserver.value;
  }

  tooltipLeft(tooltip: HTMLElement) {
    if (!this.targetRef) { return 0; }
    const left = (this.targetRef.getBoundingClientRect().left + this.targetRef.getBoundingClientRect().width / 2)
      - (tooltip.getBoundingClientRect().width / 2);
    return left;
  }
  tooltipTop(tooltip: HTMLElement) {
    if (!this.targetRef) { return 0; }
    const targetRef = this.targetRef;
    const top = this.isBelowHorizontalCenterLine
      ? targetRef.getBoundingClientRect().top - tooltip.getBoundingClientRect().height
      : targetRef.getBoundingClientRect().bottom;
    return top;
  }

  get isBelowHorizontalCenterLine() {
    if (!this.targetRef) { return false; }
    const targetRef = this.targetRef;
    const is = (targetRef.getBoundingClientRect().top + (targetRef.getBoundingClientRect().height / 2))
      > (document.body.getBoundingClientRect().height / 2);
    return is;
  }

 setShowIfOnHover() {
    const isHovered = !!this.targetRef && this.targetRef.matches(':hover');
    this.showIf = isHovered;
 }

  constructor() { }

  ngOnInit() {
  }

}
