import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { datadogRum } from "@datadog/browser-rum";
import { Observable } from "rxjs";
import { APP_CONFIG_NAME, AppConfig } from "src/environments/app.config";


export const getTracingHeaders = (): { [name: string]: string | string[] } => {
    const appConfig = window[APP_CONFIG_NAME] as AppConfig;
    // default to empty string since otherwise it throws exceptions
    return {
        ["X-Ia-App-Version"]: appConfig?.version ?? "",
        ["X-Ia-Build-Timestamp"]: appConfig?.lastBuild ?? "",
        ["X-Ia-Server-Env"]: appConfig?.serverEnv ?? "",
        ["X-Ia-dd_sesssion_id"]: datadogRum?.getInternalContext()?.session_id ?? "",
    };
 }

 @Injectable({
    providedIn: 'root'
})
export default class RequestTracingInterceptor implements HttpInterceptor {

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        request = request.clone({
           setHeaders: getTracingHeaders()
        });

        return next.handle(request);
    }
}