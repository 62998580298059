// Angular
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
// Angular Material
import {MatButtonModule} from '@angular/material/button';
import {MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatMenuModule} from '@angular/material/menu';
// NgBootstrap
import {NgbModule, NgbPaginationModule, NgbProgressbarModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
// Translation
import {TranslateModule} from '@ngx-translate/core';
import {AsideLeftComponent} from './aside/aside-left.component';
import {BaseComponent} from './base/base.component';
import { DatatableComponent } from './datatable/datatable.component';
import {HeaderComponent} from './header/header.component';
import {HeaderMobileComponent} from './header/header-mobile/header-mobile.component';
import {TopbarComponent} from './header/topbar/topbar.component';
import {MenuItem} from './menu-item/menu-item.components';
import {NoticeComponent} from './general/notice/notice.component';
import {ErrorComponent} from './general/error/error.component';
import {LoadingComponent} from './loading/loading.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {TileComponent} from './tile/tile.component';
import {ShortNumberPipe} from './short-number/short-number.pipe';
import {DateRangePicker} from './date-range-picker/date-range-picker.component';
import {StickyDirective} from 'src/app/_directives/sticky.directive';
import {FileUploadDlgComponent} from './file-upload-dlg/file-upload-dlg.component';
import {ProductsService} from 'src/app/_services/products.service';
import {Products} from 'src/app/_global/products';
import {NgxDropzoneModule} from 'ngx-dropzone';
import {FiltersComponent} from './filters/filters.component';
import {FiltersViewComponent} from './filters-view/filters-view.component';
import {PrintLabelsComponent} from '../products/print-labels/print-labels.component';
import {ReplaceLineBreaksPipe} from './replace-line-breaks/replace-line-breaks';
import {EditBoxConfigComponent} from './edit-box-config/edit-box-config.component';
import {EditGridStateComponent} from './edit-grid-state/edit-grid-state.component';
import {FiltersDropdownComponent} from './filters-dropdown/filters-dropdown.component';
import {GridPageComponent} from './grid-page/grid-page.component';
import {AddCustomerComponent} from '../settings/customers/add/add-customer.component';
import {DiscountInputComponent} from './discount-input/discount-input.component';
import {DiscountDisplayPipe} from './data-display/discount-display.pipe';
import {RangePickerComponent} from './range-picker/range-picker.component';
import {MatIconModule} from '@angular/material/icon';
import {TooltipModule} from '../../components/tooltip/tooltip.module';
import {AssignSublocationComponent} from '../../components/assign-sublocation/assign-sublocation.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { MonthlyPickerComponent } from '../advanced-reorder/monthly-picker/monthly-picker.component';
import { AdvancedReorderSliderComponent } from '../advanced-reorder/slider/advanced-reorder-slider.component';
import { AdvancedReorderSubsComponent } from '../advanced-reorder/subs/advanced-reorder-subs.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { PipesModule } from 'src/app/_pipes/pipes.module';
import { Datacolumn } from './datatable/datacolumn/datacolumn.component';
import { SortableHeader } from './datatable/directives/sortableHeader.directive';
import { SubRowTemplateDirective } from './datatable/directives/subRowTemplate.directive';
import { ColumnTemplateDirective } from './datatable/directives/columnTemplate.directive';
import { VisibleColumnsPipe } from './datatable/pipes/visibeColumns.pipe';
import { LookupNotFoundTemplateDirective } from './datatable/directives/lookupNotFound.directive';
import { TitleTemplateDirective } from './datatable/directives/titleTemplate.directive';
import { ColumnGridPipe } from './datatable/pipes/columnGrid.pipe';
import { IsSelectedItemPipe } from './datatable/pipes/isSelectedItem.pipe';
import { allCheckedPipe } from './datatable/pipes/allChecked.pipe';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ItemOptionsComponent } from '../advanced-reorder/item-options/item-options.component';
import { KtDatePickerModule } from 'src/app/components/kt-date-picker/kt-date-picker.module';

@NgModule({
    declarations: [
        AsideLeftComponent,
        BaseComponent,
        SortableHeader,
        DatatableComponent,
        Datacolumn,
        SubRowTemplateDirective,
        ColumnTemplateDirective,
        VisibleColumnsPipe,
        LookupNotFoundTemplateDirective,
        TitleTemplateDirective,
        HeaderComponent,
        MenuItem,
        HeaderMobileComponent,
        TopbarComponent,
        NoticeComponent,
        ErrorComponent,
        LoadingComponent,
        TileComponent,
        ShortNumberPipe,
        ReplaceLineBreaksPipe,
        DateRangePicker,
        StickyDirective,
        FileUploadDlgComponent,
        FiltersComponent,
        FiltersDropdownComponent,
        FiltersViewComponent,
        PrintLabelsComponent,
        EditBoxConfigComponent,
        EditGridStateComponent,
        GridPageComponent,
        AddCustomerComponent,
        DiscountInputComponent,
        DiscountDisplayPipe,
        RangePickerComponent,
        AssignSublocationComponent,
        PageNotFoundComponent,
        AdvancedReorderSliderComponent,
        MonthlyPickerComponent,
        ItemOptionsComponent,
		AdvancedReorderSubsComponent,
        ColumnGridPipe,
        IsSelectedItemPipe,
        allCheckedPipe,
    ],
    exports: [
        AsideLeftComponent,
        BaseComponent,
        DatatableComponent,
        Datacolumn,
        TileComponent,
        SubRowTemplateDirective,
        ColumnTemplateDirective,
        VisibleColumnsPipe,
        LookupNotFoundTemplateDirective,
        TitleTemplateDirective,
        LoadingComponent,
        ShortNumberPipe,
        ReplaceLineBreaksPipe,
        DateRangePicker,
        StickyDirective,
        FileUploadDlgComponent,
        FiltersComponent,
        FiltersDropdownComponent,
        FiltersViewComponent,
        EditBoxConfigComponent,
        EditGridStateComponent,
        GridPageComponent,
        DiscountInputComponent,
        DiscountDisplayPipe,
        NoticeComponent,
        RangePickerComponent,
        AssignSublocationComponent,
        PageNotFoundComponent,
        AdvancedReorderSliderComponent,
        MonthlyPickerComponent,
        ItemOptionsComponent,
		AdvancedReorderSubsComponent,
    ],
    providers: [
        ProductsService, Products
    ],
    imports: [
        MatMenuModule,
        MatIconModule,
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        MatProgressBarModule,
        MatTabsModule,
        MatButtonModule,
        MatTooltipModule,
        TranslateModule.forChild(),
        NgSelectModule,
        NgbProgressbarModule,
        NgbTooltipModule,
        NgbPaginationModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatFormFieldModule,
        MatInputModule,
        DragDropModule,
        NgbModule,
        NgxDropzoneModule,
        TooltipModule,
        NgScrollbarModule,
        ScrollingModule,
        PipesModule,
        MatCheckboxModule,
        KtDatePickerModule
    ]
})
export class ThemeModule {
}
