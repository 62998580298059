import { EntityIdType, createEntityCollections } from "@angular-ru/cdk/entity";
import { DataAction, StateRepository } from "@angular-ru/ngxs/decorators";
import { NgxsDataEntityCollectionsRepository } from "@angular-ru/ngxs/repositories";
import { Injectable } from "@angular/core";
import { State } from "@ngxs/store";
import { SupplierModel } from "src/app/_models/supplier.model";
import BaseEntityCollectionsOptions from "../base-entity-collections-options";
import { SuppliersService } from "src/app/_services/settings/suppliers.service";

@StateRepository()
@State({
    name: 'suppliers',
    defaults: {
        ...createEntityCollections(),
        loading: false
    }
})
@Injectable()
export class SuppliersEntitiesState extends NgxsDataEntityCollectionsRepository<SupplierModel, EntityIdType, BaseEntityCollectionsOptions> {
    
    constructor(private suppliersService: SuppliersService){
        super();
    }

    @DataAction()
    public load() {
        this.suppliersService.fetch().subscribe(result => this.setEntitiesAll(result));
    }

    @DataAction()
    public lazyLoad() {
        !this.snapshot.loaded && 
        !this.snapshot.loading && 
        this.load();
    }
}