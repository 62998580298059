import {Injectable} from '@angular/core';
import {NgxsOnChanges, NgxsOnInit, NgxsSimpleChange, State} from '@ngxs/store';
import {DateFilterSelection} from 'src/app/components/date-range-dropdown/date-filter-option';
import {IIdMap, OrdersFiltersModel, OrderSortBy} from 'src/app/_models/self-ship/orders/orders-filters.model';
import {OrderProcessingStatus} from 'src/app/_entities/orders/processing-statuses/order-processing-status';
import {Computed, DataAction, StateRepository} from '@angular-ru/ngxs/decorators';
import {NgxsDataRepository} from '@angular-ru/ngxs/repositories';
import StoresEntitiesState from '../../stores/stores-entities.state';
import WarehousesEntitiesState from '../../warehouses/warehouses-entities.state';
import {cloneDeep} from 'lodash';
import {Observable} from 'rxjs';
import * as _ from 'lodash';

@StateRepository()
@State({
    name: 'ordersFilters',
    defaults: new OrdersFiltersModel(),
})
@Injectable()
export default class OrdersFiltersState
    extends NgxsDataRepository<OrdersFiltersModel>
    implements NgxsOnChanges, NgxsOnInit {

    private _ngxsOnChange = (state: OrdersFiltersState) => {};
    private _changeObserver: Observable<OrdersFiltersModel>;

    constructor(
        private storeEntitiesState: StoresEntitiesState,
        private warehouseEntitiesState: WarehousesEntitiesState
    ) {
        super();
    }

    ngxsOnChanges(_?: NgxsSimpleChange) {
        super.ngxsOnChanges(_);
        if (this.isInitialised && !this._changeObserver) {
            this._changeObserver = this.state$;
            this._changeObserver.subscribe(() => this._ngxsOnChange(this));
        }
    }

    // Computed

    @Computed()
    public get dateSelectionName(): string {
        return DateFilterSelection[this.snapshot.dateRange.selection];
    }

    @Computed()
    public get storeNames(): string[] {
        return this.storeEntitiesState
            .entitiesArray
            .filter(store => !!this.snapshot.storeIds[store.id])
            .map((store => store.name));
    }

    @Computed()
    public get sortByFieldName(): string {
        return OrderSortBy[this.snapshot.sortBy];
    }

    @Computed()
    public get allStoresSelected() {
        return Object.keys(this.snapshot.storeIds).length === Object.keys(this.storeEntitiesState.entities).length
            || !Object.keys(this.snapshot.storeIds).length;
    }

    @Computed()
    public get allStatusesSelected() {
        return this.snapshot.orderStatuses.length === 0;
    }

    @Computed()
    public get selectedCount() {
        return Object.keys(this.snapshot.selected).length;
    }

    @Computed()
    public get warehouseFilterNames() {
        return this.warehouseEntitiesState
            .entitiesArray
            .filter(warehouse => !!this.snapshot.warehouseIds[warehouse.id])
            .map(w => w.name);
    }

    @Computed()
    public get hasProcessingStatus() {
        return (status: OrderProcessingStatus) => this.snapshot.orderStatuses.includes(status);
    }

    @Computed()
    public get storesFiltersHas(): (storeId: number) => boolean {
        return (storeId: number) => !!this.snapshot.storeIds[storeId];
    }

    @Computed()
    public get warehouseFiltersHas(): (warehouseId: number) => boolean {
        return (warehouseId: number) => !!this.snapshot.warehouseIds[warehouseId];
    }

    @Computed()
    public get selectedHas(): (orderId: number) => boolean {
        return (orderId: number) => !!this.snapshot.selected[orderId];
    }


    // Actions

    @DataAction()
    public setProcessingStatuses(statuses: OrderProcessingStatus[]) {
        this.patchState({
            orderStatuses: statuses
        });
    }

    @DataAction()
    public setSearchTerm(term: string) {
        this.patchState({
            searchTerm: term
        });
    }

    @DataAction()
    public setSortBy(field: OrderSortBy) {
        this.patchState({
            sortBy: field
        });
    }

    @DataAction()
    public toggleSortOrder() {
        const currentVal = this.snapshot.sortOrder;
        this.patchState({
            sortOrder: +!currentVal
        });
    }

    @DataAction()
    public setStoreFilter(storeIds: number[]) {
        this.patchState({
            storeIds: storeIds.reduce((results, id) => {
                results[id] = true;
                return results;
            }, {})
        });
    }

    @DataAction()
    public toggleStore(storeId: number) {
        const storeIdsMap: IIdMap = cloneDeep(this.snapshot.storeIds);
        if (storeIdsMap[storeId]) {
            delete storeIdsMap[storeId];
        } else {
            storeIdsMap[storeId] = true;
        }
        this.patchState({
            storeIds: storeIdsMap
        });
    }

    @DataAction()
    public toggleWarehouse(warehouseId: number) {
        const warehouseIdsMap = _.cloneDeep(this.snapshot.warehouseIds);
        if (warehouseIdsMap[warehouseId]) {
            delete warehouseIdsMap[warehouseId];
        } else {
            warehouseIdsMap[warehouseId] = true;
        }
        this.patchState({
            warehouseIds: warehouseIdsMap
        });
    }

    @DataAction()
    public toggleSelectOrder(orderId: number) {
        const orderIdsMap = this.snapshot.selected;
        if (orderIdsMap[orderId]) {
            delete orderIdsMap[orderId];
        } else {
            orderIdsMap[orderId] = true;
        }
        this.patchState({
            selected: orderIdsMap
        });
    }

    public onStateChange(callback: (state?: OrdersFiltersState) => void) {
        this._ngxsOnChange = callback;
    }
}
