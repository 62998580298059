import { Component, ViewEncapsulation, ViewChild, OnInit, Inject } from '@angular/core';
import { HeaderComponent } from '../header/header.component';
import { Router } from '@angular/router';
import { StoresManager } from 'src/app/_global/stores-manager';
import { HelperService } from 'src/app/_services/helper.service';
import { environment } from 'src/environments/environment';
import { Store } from '@ngxs/store';
import { UserActions } from 'src/app/states/user/user.actions';
import { AppConfig, APP_CONFIG } from 'src/environments/app.config';
import { StatesHelper } from 'src/app/states/states.helper';

@Component({
	selector: 'kt-base',
	templateUrl: './base.component.html',
	styleUrls: ['./base.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class BaseComponent implements OnInit {

	appVersion: string;
	environment = environment;
	year: number = new Date().getFullYear();
 	 expendBar:boolean =false;
	@ViewChild(HeaderComponent, { static: true }) header: HeaderComponent;
	productsService: any;
	userFullName: string;

  	constructor(
		private router: Router,
		public stores: StoresManager, 
		public helperService: HelperService,
		private stateStore: Store,
		@Inject(APP_CONFIG) appConfig: AppConfig,
        private statesHelper: StatesHelper, /* Required to instantiate. Do not remove */
	) {
		this.appVersion = appConfig?.version;
		router.events.subscribe(r => this.header.title = undefined);
	}
  	
	ngOnInit(): void {
    	const user = this.helperService.currentUser;
		if (user) {
			this.userFullName = `${user.firstName} ${user.lastName}`;
		} 
  	}

  barClick(event:boolean){
    if(event){
      this.expendBar = false;
    }else{
      this.expendBar = true;
    }
  }

  searchChanged(e: any, search: any) {
		this.productsService.getIdBySku(e.target.value).subscribe(id => {
			e.target.value = '';
			this.router.navigate(['/'], { skipLocationChange: true }).then(() =>
				this.router.navigate([`/products/${id}`]));
			search.classList.remove('show');
		});
	}

  	signOut() {
		this.stateStore.dispatch(new UserActions.Logout).subscribe(_ => window.location.href = '');
	}

	getMessage(subscriptionStatus: string) {
		const inactiveDays = this.helperService.inactiveDays;
		const accountWillBeLockedIn =  Math.abs(7 - this.helperService.inactiveDays);

		let message = '';
		if (inactiveDays >0 && inactiveDays <7) {
			message = `Your account will be disabled in ${accountWillBeLockedIn} days `
		}
		if (inactiveDays == 7){
			message = 'Your account will be disabled by the end of the day '
		}
		if (inactiveDays >7) {
			message = 'Your account has been disabled '
		}

		switch (subscriptionStatus) {
			case 'incomplete':
			case 'incomplete_expired':
			case 'incomplete':
				return message +'because your subscription is incomplete or the card was declined.';
			case 'past_due':
				return message +'because your subscription is past due.';
			case 'canceled':
				return message +'because your subscription has been canceled.';
			case 'unpaid':
				return message +'because your subscription is unpaid.';
			default:
				return message +'because your subscription has been canceled.';
		}
	  }

	  getMessageForExceeded() {
		const exceededDays = this.helperService.exceededDays;
		const accountWillBeLockedIn =  Math.abs(7 - exceededDays);

		let message = '';
		if (exceededDays >0 && exceededDays < 7) {
			message = `Your account has reached the maximum limit of products or monthly orders. Your account will be locked in ${accountWillBeLockedIn} days. `
		}
		if (exceededDays == 7){
			message = 'Your account has reached the maximum limit of products or monthly orders over 7 days ago. And will be locked by the end of the day '
		}
		if (exceededDays > 7) {
			message = 'Your account has reached the maximum limit of products or monthly orders over 7 days ago. '
		}
		return message;
	  }
}

