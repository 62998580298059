import { ClassBasedEnumBase } from "src/app/libraries/class-based-enum.lib";
import { ProcessingStatus } from "./processing-status";

export class PickingProcessingStatus extends ClassBasedEnumBase {
    
    private constructor(
        public name: string,
        public value: number,
        public description?: string) {
        super()
    }

    static readonly None = new PickingProcessingStatus('None', ProcessingStatus.None.value, 'No picking status');
    static readonly Picking = new PickingProcessingStatus('Picking', ProcessingStatus.Picking.value, ProcessingStatus.Picking.description);
    static readonly Picked = new PickingProcessingStatus('Picked', ProcessingStatus.Picked.value, ProcessingStatus.Picked.description);

    static fromValue(processingStatus: number): PickingProcessingStatus {
        switch (processingStatus) {
            case PickingProcessingStatus.None.value:
                return PickingProcessingStatus.None;
            case PickingProcessingStatus.Picking.value:
                return PickingProcessingStatus.Picking;
            case PickingProcessingStatus.Picked.value:
                return PickingProcessingStatus.Picked;
        }
    }
}