import OrdersEntitiesState from './orders/orders-entities.state';
import {ShipmentsEntitiesState} from './shipments/shipments-entities.state';
import WarehousesEntitiesState from './warehouses/warehouses-entities.state';
import PickListsEntitiesState from './self-ship/pick-lists/pick-lists-entities.state';
import ProductsEntitiesState from './products/products-entities.state';
import StoresEntitiesState from './stores/stores-entities.state';
import StoreProductsEntitiesState from './products/store-products/store-products-entities.state';
import {Injectable} from '@angular/core';
import OrdersFiltersState from './orders/orders-filters/orders-filters.state';
import { NgxsDataEntityCollectionsRepository } from '@angular-ru/ngxs/repositories';
import { CurrenciesEntitiesState } from './common/currencies-entities.state';

@Injectable({
    providedIn: 'root'
  })
export class StatesHelper {
    private static _ordersEntitiesState: OrdersEntitiesState;
    private static _shipmentsEntitiesState: ShipmentsEntitiesState;
    private static _warehousesEntitiesState: WarehousesEntitiesState;
    private static _picklistsEntitiesState: PickListsEntitiesState;
    private static _productsEntitiesState: ProductsEntitiesState;
    private static _storesEntitiesState: StoresEntitiesState;
    private static _storeProductsEntitiesState: StoreProductsEntitiesState;
    private static _ordersFiltersState: OrdersFiltersState;
    private static _currenciesEntitiesState: CurrenciesEntitiesState;

    constructor(
        ordersEntitiesState: OrdersEntitiesState,
        shipmentsEntitiesState: ShipmentsEntitiesState,
        warehousesEntitiesState: WarehousesEntitiesState,
        picklistsEntitiesState: PickListsEntitiesState,
        productsEntitiesState: ProductsEntitiesState,
        storesEntitiesState: StoresEntitiesState,
        storeProductsEntitiesState: StoreProductsEntitiesState,
        ordersFiltersState: OrdersFiltersState,
        currenciesEntitiesState: CurrenciesEntitiesState
    ) {
      StatesHelper._ordersEntitiesState = ordersEntitiesState;
      StatesHelper._shipmentsEntitiesState = shipmentsEntitiesState;
      StatesHelper._warehousesEntitiesState = warehousesEntitiesState;
      StatesHelper._picklistsEntitiesState = picklistsEntitiesState;
      StatesHelper._productsEntitiesState = productsEntitiesState;
      StatesHelper._storesEntitiesState = storesEntitiesState;
      StatesHelper._storeProductsEntitiesState = storeProductsEntitiesState;
      StatesHelper._ordersFiltersState = ordersFiltersState;
      StatesHelper._currenciesEntitiesState = currenciesEntitiesState;
    }

    public static get ordersEntitiesState() {
        return this._ordersEntitiesState;
    }

    public static get shipmentsEntitiesState() {
        return this._shipmentsEntitiesState
    }

    public static get warehousesEntitiesState() {
        return this._warehousesEntitiesState;
    }

    public static get picklistsEntitiesState() {
        return this._picklistsEntitiesState;
    }

    public static get productsEntitiesState() {
        return this._productsEntitiesState;
    }

    public static get storesEntitiesState() {
        return this._storesEntitiesState;
    }

    public static get storeProductsEntitiesState() {
        return this._storeProductsEntitiesState;
    }

    public static get ordersFiltersState() {
        return this._ordersFiltersState;
    }
  
    public static get currenciesEntitiesState() {
        return this._currenciesEntitiesState;
    }

    public static getEntity<TModel, TEntity>(
            state: NgxsDataEntityCollectionsRepository<TModel>, 
            id: string | number, 
            constructor: new (data: TModel) => TEntity
        ): TEntity | null {
        const entity = state.entities[id];
        return entity ? new constructor(entity) : null;
    }
}
