import { Pipe, PipeTransform, QueryList } from "@angular/core";
import { Datacolumn } from "../datacolumn/datacolumn.component";

@Pipe({
    name: 'columnGrid'
})
export class ColumnGridPipe implements PipeTransform {
    transform(columns: QueryList<Datacolumn>, freezed: 'center' | 'left' | 'right' | 'left_center', index: number): number {
        let total = 0;
        switch (true) {
            case freezed === 'left':
            case freezed === 'right':
                total = columns.filter(col => col.hasPermission && col.visible === true && col.freezed === freezed).length;
                break;
            case freezed === 'left_center':
                total = columns.filter(col => col.hasPermission && col.visible === true && (!col.freezed || col.freezed === 'left')).length;
                break;
            default:
                total = columns.filter(col => col.hasPermission && col.visible === true && !col.freezed).length;
        }
        return index + total + 2;
    }
}