export class ReorderDetailModel {
  productId: number;
  storeId: number;
  included: boolean;
  reorder: number;
  overstock: number;
  realTime: number;
  adjustment: number;
  manualEntry: number;
}
