import _ from "lodash";
import { WarehouseRackModel } from "src/app/_models/warehouses/warehouse-rack.model";
import { WarehouseShelf } from "./warehouse-shelf.entity";
import WarehouseAisle from "./warehouse-aisle.entity";
import { StatesHelper } from "src/app/states/states.helper";


export class WarehouseRack extends WarehouseRackModel {

    public readonly shelfEntites: WarehouseShelf[];

    constructor(warehouseRack: WarehouseRackModel) {
        super();
        _.assignIn(this, warehouseRack);

        this.shelfEntites = this.shelves.map(shelf => new WarehouseShelf(shelf));
    }

    get aisle(): WarehouseAisle {
        return StatesHelper.warehousesEntitiesState.warehouseAislesEntityMap[this.aisleId];
    }
}