export enum OrderLatestStatus {
    Unshipped,
    Shipped,
    Canceled,
    Pending,
    Processing,
    FulFillMentCreated,
    ReadyToShip,
    OnHold,
    Created
}