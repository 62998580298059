import {Component, Input} from '@angular/core';

export type PageNotFoundSubject = 'Page' | 'Product' | 'Order' | 'Order Item' | 'Order Box' | 'Product Main' | 'Product Sub' | 'Picklist' | 'Picklist Item' | 'Box Config';

@Component({
    selector: 'page-not-found',
    templateUrl: './page-not-found.component.html'
})
export class PageNotFoundComponent {
    @Input() subject: PageNotFoundSubject = 'Page';
}
