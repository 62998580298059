import _ from "lodash";
import { WarehouseAisleModel } from "src/app/_models/warehouses/warehouse-aisle.model";
import { WarehouseRack } from "./warehouse-rack.entity";
import Warehouse from "../warehouse.entity";
import { StatesHelper } from "src/app/states/states.helper";

export default class WarehouseAisle extends WarehouseAisleModel {

    public readonly rackEntities: WarehouseRack[] = [];
    
    constructor(warehouseAisle: WarehouseAisleModel) {
        super();
        _.assignIn(this, warehouseAisle);

        this.rackEntities = this.racks.map(rack => new WarehouseRack(rack));
    }

    get warehouse(): Warehouse {
        return StatesHelper.getEntity(StatesHelper.warehousesEntitiesState, this.warehouseId, Warehouse);
    }
}