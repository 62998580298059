import * as _ from 'lodash';
import { PackingProcessingStatus } from 'src/app/_entities/orders/processing-statuses/packing-processing-status';
import { PickingProcessingStatus } from 'src/app/_entities/orders/processing-statuses/picking-processing-status';
import { ProcessingStatus } from 'src/app/_entities/orders/processing-statuses/processing-status';
import { ShippingProcessingStatus } from 'src/app/_entities/orders/processing-statuses/shipping-processing-status';
import ProcessingStatusHelper from 'src/app/_helpers/processing-status.helper';
import { PickListModel } from 'src/app/_models/self-ship/pick-lists/pick-list.model';
import PickListItem from './pick-list-item.entity';

export default class PickList extends PickListModel {

    constructor(pickList: PickListModel) {
        super();

        if (_.isNumber(pickList.processingStatus)) {
            pickList.processingStatus = PickingProcessingStatus.fromValue(+pickList.processingStatus);
        }
        pickList.items.forEach(item => 
            item.processingStatus = _.isNumber(item.processingStatus) 
                ? PickingProcessingStatus.fromValue(+item.processingStatus) 
                : item.processingStatus
            );

        _.assignIn(this, pickList);
    }

    get picklistItems() {
        return this.items
            .map(item => new PickListItem(item));
    }

    // Statuses
    get hasNoStatusYet() {
        return !!(ProcessingStatusHelper.getProcessingStatus(this).value & ProcessingStatus.None.value);
    }

    get isBeingPicked() {
        return ProcessingStatusHelper.getProcessingStatus(this).value >= PickingProcessingStatus.Picking.value;
    }

    get isPicked() {
        return ProcessingStatusHelper.getProcessingStatus(this).value >= PickingProcessingStatus.Picked.value;
    }

    get isBeingPacked() {
        return ProcessingStatusHelper.getProcessingStatus(this).value >= PackingProcessingStatus.Packing.value;
    }

    get isPacked() {
        return ProcessingStatusHelper.getProcessingStatus(this).value >= PackingProcessingStatus.Packed.value;
    }

    get isReadyToShip() {
        return this.isPacked;
    }

    get isPartiallyShipped() {
        return ProcessingStatusHelper.getProcessingStatus(this).value >= ShippingProcessingStatus.ShippedPartial.value;
    }

    get isShipped() {
        return ProcessingStatusHelper.getProcessingStatus(this).value >= ShippingProcessingStatus.Shipped.value;
    }

    get isDelivered() {
        return false; // TODO
    }

    get latestProcessingStatus() {
        return ProcessingStatusHelper.getProcessingStatus(this);
    }

}
