import { SortOrder } from "src/app/_enums/general/sort-order.enum";
import { PickListItemSortBy } from "src/app/_enums/self-ship/pick-list-item-sort-by.enum";
import { PickingProcessingStatus } from "src/app/_entities/orders/processing-statuses/picking-processing-status";
import { BatchesSortBy, BatchesViewBy } from "src/app/_types/self-ship/pick-lists/pick-lists.types";
import {IIDMap} from '../../../_builders/id-map';

export default class PickListsFiltersModel {
    warehouseIds: IIDMap = {};
    statuses: PickingProcessingStatus[] = [];
    viewBy: BatchesViewBy = BatchesViewBy.statuses;
    searchTerm: string = '';
    taggedWith: number[] = [];
    sortBy: BatchesSortBy = 'Ship by date';
    sortOrder: SortOrder = SortOrder.Asc;
    selected: IIDMap = {};
    selectedBatchItemId: number;
    selectedBatchId: number;
    picklistItemSearchTerm: string = '';
    picklistItemSortBy: PickListItemSortBy = PickListItemSortBy.Aisle;
    picklistItemSortOrder: SortOrder = SortOrder.Asc;
}
