<div class="container-fluid">
    <div class="row  wrapper">
        <div class="col-md-6 quotes-wrapper d-md-block d-none ">
            <div style=" min-height: 100vh;font-size: 36px;" class=" d-flex align-items-center text-white">
                <div class="introText" >
                    <div class="mb-2"><i class="fa fa-quote-left custom-text-color" style="font-size: 28px;" aria-hidden="true"></i></div> 
                    <div [innerHTML]="introtext"></div>
                </div>
            </div>
            <!-- <ngb-carousel>
                <ng-template ngbSlide>
                    <div class="carousel-caption">
                        <div>
                            <i class="fa fa-quote-left text-info" aria-hidden="true"></i> <br>
                            Inventory Management is
                            not the <span class="fw-bold">main thing</span> to
                            success, its the <span class="fw-bold"> ONLY</span>
                            thing to success.</div>
                        <p></p>
                    </div>
                 </ng-template>
                 <ng-template ngbSlide>
                    <div class="carousel-caption">
                        <div>
                            <i class="fa fa-quote-left text-info" aria-hidden="true"></i> <br>
                            A good system <span class="fw-bold">shortens</span> the
                            road to the goal.</div>
                        <p></p>
                    </div>
                 </ng-template>
                 <ng-template ngbSlide>
                    <div class="carousel-caption">
                        <div>
                            <i class="fa fa-quote-left text-info" aria-hidden="true"></i> <br>
                            Inventory Management is
                            not the <span class="fw-bold">main thing</span> to
                            success, its the <span class="fw-bold"> ONLY</span>
                            thing to success.</div>
                        <p></p>
                    </div>
                 </ng-template>
                 <ng-template ngbSlide>
                    <div class="carousel-caption">
                        <div>
                            <i class="fa fa-quote-left text-info" aria-hidden="true"></i> <br>
                            A good system <span class="fw-bold">shortens</span> the
                            road to the goal.</div>
                        <p></p>
                    </div>
                 </ng-template>
            </ngb-carousel> -->
        </div>
        <div class="col-md-6 py-3 bg-white h-100" id="forgotWrapper">
           <div class="forgotWrapperInner">
                <div class="mt-5 title"><img src="assets/images/landing/inventory-ahead-logo.svg" height="60" alt="inventory ahead"></div>
                <div class="forgotForm row align-items-center">
                    
                    <form style="max-width: 430px;" class="col-12" method="post" [formGroup]="forgotPasswordForm">
                        <div class="alert alert-danger alert-dismissible" role="alert" *ngIf="errMessage">
                            <div class="alert-text">{{ errMessage }}</div>
                            <div class="alert-close">
                                <i class="flaticon2-cross kt-icon-sm cursor-pointer" data-dismiss="alert" (click)="errMessage = ''"></i>
                            </div>
                        </div>
                        <div class="h2 fw-bold text-black mb-5">Forgot Password?</div>
                        <div class="form-group position-relative">
                            <i class="fa fa-envelope input-form-icons" aria-hidden="true"></i>  
                            <label for="exampleInputEmail1" class="font-600 text-black">Email</label>
                            <input type="email" class="form-control" formControlName="email" type="text" autocomplete="off" placeholder="Enter email">
                          </div>
                        
                        <div class="flex">
                            <div class="w-50" style="padding-right: 0.5rem;">
                                <button type="button" class="btn btn-lg w-100 signInBtn shadow bg-white" style="margin-right: 0.2rem;" routerLink="/login" >Back</button>
                            </div>
                            <div class="w-50" style="padding-left: 0.5rem;">
                                <button type="button" (click)="submit()" [disabled]="loading" class="btn btn-lg w-100 signInBtn py-3 bg-primary-blue text-white" [ngClass]="loading ? 'kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light': ''">Reset</button>
                            </div>
                            
                        </div>
                        
                    </form>
                </div>
                <div  class="font-500">Don't have an account yet? <a routerLink="/register"><span class="text-primary-blue">Create an account</span></a></div>
           </div>
        </div>
    </div>
</div>
