import { Injectable } from '@angular/core';
import { RainforestApiService } from '../_services/rainforest-api.service';

@Injectable()
export class RainforestApis {
  rainforestApis: any[] = [];
  loaded: boolean = false;
  loadRequested: boolean = false;
  callBacks: any[] = [];

  constructor(private rainforestApiService: RainforestApiService) { }

  getAll(callBack: Function = null) {
    if (!this.loaded)
      this.reload(callBack);
    else if (callBack)
      callBack(this.rainforestApis);
    return this.rainforestApis;
  }

  reload(callBack: Function = null) {
    if (callBack)
      this.callBacks.push(callBack);
    if (!this.loadRequested) {
      this.loadRequested = true;
      this.rainforestApiService.getAll().subscribe(result => {
        this.rainforestApis = result;
        this.loaded = true;
        for (let calback of this.callBacks) {
          calback(result);
          this.callBacks = this.callBacks.filter(cb => cb != calback);
        }
        this.loadRequested = false;
      });
    }
  }
}