import * as _ from 'lodash';
import { PackingProcessingStatus } from 'src/app/_entities/orders/processing-statuses/packing-processing-status';
import { PickingProcessingStatus } from 'src/app/_entities/orders/processing-statuses/picking-processing-status';
import { ProcessingStatus } from 'src/app/_entities/orders/processing-statuses/processing-status';
import { ShippingProcessingStatus } from 'src/app/_entities/orders/processing-statuses/shipping-processing-status';
import ProcessingStatusHelper from 'src/app/_helpers/processing-status.helper';
import { PickListItemModel } from 'src/app/_models/self-ship/pick-lists/pick-list-item.model';
import OrderItem from '../orders/order-item.entity';
import PickList from './pick-list.entity';
import { StatesHelper } from '../../states/states.helper';
import Product from '../products/product.entity';
import PickedBinItem from './picked-bin-item.entity';
import Order from '../orders/order.entity';

export default class PickListItem extends PickListItemModel {

    public readonly pickedBinItems: PickedBinItem[];

    constructor(pickListItem: PickListItemModel) {
        super();
        _.assignIn(this, pickListItem);
        this.pickedBinItems = this.pickedBins
            .map(pbi => new PickedBinItem(pbi));
    }

    get orderItems(): OrderItem[] {
        return StatesHelper.ordersEntitiesState
            .entitiesArray
            .flatMap(order => order.items)
            .filter(oi => this.orderItemIds.includes(oi.id))
            .map(oi => new OrderItem(oi));
    }

    get orders(): Order[] {
        return this.orderItems.map(oi => oi.order);
    }

    get product(): Product {
        return new Product(
            StatesHelper.productsEntitiesState
                .entities[this.productId]
        );
    }

    get pickList(): PickList {
        return new PickList(
            StatesHelper.picklistsEntitiesState
                .entities[this.pickListId]
        );
    }

    // Statuses
    get hasNoStatusYet() {
        return !!(ProcessingStatusHelper.getProcessingStatus(this).value & ProcessingStatus.None.value);
    }

    get isBeingPicked() {
        return ProcessingStatusHelper.getProcessingStatus(this).value >= PickingProcessingStatus.Picking.value;
    }

    get isPicked() {
        return ProcessingStatusHelper.getProcessingStatus(this).value >= PickingProcessingStatus.Picked.value;
    }

    get isBeingPacked() {
        return ProcessingStatusHelper.getProcessingStatus(this).value >= PackingProcessingStatus.Packing.value;
    }

    get isPacked() {
        return ProcessingStatusHelper.getProcessingStatus(this).value >= PackingProcessingStatus.Packed.value;
    }

    get isReadyToShip() {
        return this.isPacked;
    }

    get isPartiallyShipped() {
        return ProcessingStatusHelper.getProcessingStatus(this).value >= ShippingProcessingStatus.ShippedPartial.value;
    }

    get isShipped() {
        return ProcessingStatusHelper.getProcessingStatus(this).value >= ShippingProcessingStatus.Shipped.value;
    }

    get isDelivered() {
        return false; // TODO
    }

    get latestProcessingStatus() {
        return ProcessingStatusHelper.getProcessingStatus(this);
    }
}
