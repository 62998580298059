import * as _ from "lodash";
import WarehouseCartBinModel from "src/app/_models/warehouses/warehouse-cart-bin.model";
import WarehouseCart from "./warehouse-cart.entity";

export default class WarehouseCartBin extends WarehouseCartBinModel {
    protected _cart: WarehouseCart;
    protected _bin: WarehouseCartBinModel;

    constructor(bin: WarehouseCartBinModel) {
        super();
        this._bin = bin;
        _.assignIn(this, bin);
    }

    get warehouseCart() {
        return this._cart;
    }

    toModel() {
        return this._bin;
    }
}