import * as _ from "lodash";
import { StoreProductModel } from "src/app/_models/products/store-product.model";

export default class StoreProduct extends StoreProductModel {

    constructor(storeProduct: StoreProductModel) {
        super();
        _.assign(this, storeProduct);
    }

}