import { Injectable } from '@angular/core';
import { State } from '@ngxs/store';
import { ProductsService } from 'src/app/_services/products.service';
import { Computed, DataAction, StateRepository } from '@angular-ru/ngxs/decorators';
import { NgxsDataEntityCollectionsRepository } from '@angular-ru/ngxs/repositories';
import { createEntityCollections, EntityIdType } from '@angular-ru/cdk/entity';
import BaseEntityCollectionsOptions from '../../base-entity-collections-options';
import { StoreProductModel } from '../../../_models/products/store-product.model';
import StoreProduct from '../../../_entities/products/store-product.entity';
import EntityMap from '../../../_builders/entity-map';
import EntityMapper from '../../../_builders/entity.mapper';
import { tap } from 'rxjs';

@StateRepository()
@State({
    name: 'storeProducts',
    defaults: {
        ...createEntityCollections(),
        loading: false
    }
})
@Injectable()
export default class StoreProductsEntitiesState
    extends NgxsDataEntityCollectionsRepository<StoreProductModel, EntityIdType, BaseEntityCollectionsOptions> {

    constructor(
        private productsService: ProductsService
    ) {
        super();
    }

    selectId(entity: StoreProductModel): string {
        return `${entity.productId}_${entity.storeId}`;
    }

    @Computed()
    public get storeProductEntities(): StoreProduct[] {
        return this.entitiesArray
            .map(sp => new StoreProduct(sp));
    }

    // Computed
    @Computed()
    public get storeProductsEntityMap(): EntityMap<StoreProduct> {
        return new EntityMap<StoreProduct>(EntityMapper.mapById(this.storeProductEntities));
    }

    // Actions
    @DataAction()
    load() {
        this.patchState({ loading: true });
        this.productsService
            .fetchStoreProducts()
            .subscribe(storeProducts => {
                this.setEntitiesAll(storeProducts);
                this.patchState({ loading: false, loaded: true });
            }, (error) => this.patchState({ loading: false }));
    }

    @DataAction()
    initialLoad() {
        this.patchState({ loading: true });
        return this.productsService
            .fetchStoreProducts()
            .pipe(tap(storeProducts => {
                this.setEntitiesAll(storeProducts);
                this.patchState({ loading: false, loaded: true });
            }));
    }

    @DataAction()
    lazyLoad() {
        !this.snapshot.loading &&
            !this.snapshot.loaded &&
            this.load();
    }
}
