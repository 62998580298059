// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// RxJS
import { Observable, BehaviorSubject, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
// Models
import { WarehouseModel } from '../../_models/warehouses/warehouse.model';

import { HelperService } from '../helper.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { BatchCreateCartsAndBins } from 'src/app/pages/settings/cart-configuration/cart-configuration.component';

const API_WAREHOUSES_URL = environment.baseUrl + '/warehouses';

@Injectable()
export class WarehousesService {
	constructor(
		private http: HttpClient,
		private helperService: HelperService,
		private toastr: ToastrService,
		private translate: TranslateService) { }

	public fetch(): Observable<WarehouseModel[]> {
		return this.http.get<WarehouseModel[]>(API_WAREHOUSES_URL);
	}

	public getById(id: number): Observable<WarehouseModel> {
		const url = `${API_WAREHOUSES_URL}/${id}`;
		return this.http.get<WarehouseModel>(url,)
			.pipe(catchError(this.handleError<WarehouseModel>(`getById id=${id}`)));
	}

	public getCsvHeaders(link: string): Observable<any> {
		return this.http.get(`${API_WAREHOUSES_URL}/csvHeaders`,
			{ headers: this.helperService.getHTTPHeaders(), params: { link: link } as any })
			.pipe(catchError(this.handleError('getCsvHeaders')));
	}

	public create(warehouse: WarehouseModel): Observable<any> {
		return this.http.post(API_WAREHOUSES_URL, warehouse,)
			.pipe(catchError(this.handleError<any>('createItem')));
	}

	public update(warehouse: WarehouseModel): Observable<any> {
		return this.http.put(API_WAREHOUSES_URL, warehouse,)
			.pipe(catchError(this.handleError<any>('update')));
	}

	public delete(id: number) {
		return this.http.delete(`${API_WAREHOUSES_URL}/${id}`,
		).pipe(
			catchError(this.handleError<WarehouseModel>('delete'))
		);
	}

	private handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			switch (error.status) {
				case 401:
					window.location.href = '/login';
					break;
				case 422:
					this.toastr.error(this.translate.instant('WAREHOUSES.MESSAGES.ERRORS.' + error.error.reason, error.error));
					break;
				default:
					this.toastr.error(this.translate.instant('GENERAL.MESSAGES.ERROR'));
			}
			return throwError(error);
		};
	}
}
