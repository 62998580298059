// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpContextToken } from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// Models
import { OldProductModel } from '../_models/old-product.model';
import ProductModel from "src/app/_models/products/product.model";
import { ProductListing } from '../_models/product-listing.model';
import { FbaShipmentDetailModel } from '../_models/fba-shipment-detail.model';
import { DaysOfSupplyModel } from '../_models/days-of-supply.model';
import { StoreProductModel } from '../_models/products/store-product.model';


import { environment } from 'src/environments/environment';
import { AppStorage } from './storage';
import HttpContextTokens from '../_intercepters/http-context-tokens';
import { ProductWarehouseByDate } from '../_models/product-warehouse-by-date.model';

const API_PRODUCTS_URL = environment.baseUrl + '/products';
const HTTP_CONTEXT = new HttpContext().set(HttpContextTokens.ERROR_REASON_PREFIX, "PRODUCTS.MESSAGES.ERRORS");

// Real REST API
@Injectable()
export class ProductsService {
  constructor(private http: HttpClient) { }


  fetch(): Observable<ProductModel[]> {
    return this.http.get<ProductModel[]>(`${API_PRODUCTS_URL}/fetch`, { context: HTTP_CONTEXT });
  }

  fetchStoreProducts(): Observable<StoreProductModel[]> {
    return this.http.get<StoreProductModel[]>(`${API_PRODUCTS_URL}/fetchStoreProducts`, { context: HTTP_CONTEXT });
  }

  /**
  * @deprecated
  */
  getAll(parentId: number): Observable<any> {
    let params: any = {};
    if (parentId)
      params.parentId = parentId;
    return this.http.get<any>(API_PRODUCTS_URL, { params, context: HTTP_CONTEXT });
  }

  getAllSkus(): Observable<any[]> {
    const url = `${API_PRODUCTS_URL}/skus`;
    return this.http.get<any[]>(url, { context: HTTP_CONTEXT });
  }

  getSkusAndTypes(): Observable<any[]> {
    const url = `${API_PRODUCTS_URL}/skusAndTypes`;
    return this.http.get<any[]>(url, { context: HTTP_CONTEXT });
  }

  getSellingPrice(productId: number, storeId: number): Observable<number> {
    return this.http.get<number>(`${API_PRODUCTS_URL}/${productId}/SellingPrice/${storeId}`, { context: HTTP_CONTEXT });
  }

  getListedSkus(): Observable<any[]> {
    return this.http.get<any[]>(`${API_PRODUCTS_URL}/listedSkus`, { context: HTTP_CONTEXT });
  }

  getLatestData(productIds: number[]) {
    return this.http.post<any>(`${API_PRODUCTS_URL}/latestSkuData`, { productIds }, { context: HTTP_CONTEXT });
  }

  getInventory(productIds: number[], locationId: number, date: string) {
    return this.http.post<any>(
      `${API_PRODUCTS_URL}/inventory`,
      { productIds, locationId, date },
      { context: HTTP_CONTEXT }
    );
  }

  getIdBySku(sku: string) {
    return this.http.get<any[]>(`${API_PRODUCTS_URL}/getIdBySku/${sku}`, { context: HTTP_CONTEXT });
  }

  getById(id: number): Observable<any> {
    return this.http.get<any>(`${API_PRODUCTS_URL}/${id}`);
  }

  getProductsTest(gridStateId: number, from: Date = null, to: Date = null, page: number, pageSize: number, sortField: string,
    sort: string, filters: any, storesIds: number[] = null, locationIds: number[] = null,
    tagIds: number[] = null, parentId: number = null, groupBy: string = null, force): Observable<any> {
    let params: any = { page: page, pageSize: pageSize };
    if (gridStateId) {
      params.gridStateId = gridStateId;
    }
    if (from)
      params.from = from;
    if (to)
      params.to = to;
    if (sortField)
      params.sortField = sortField;
    if (sort)
      params.sort = sort;
    if (storesIds && storesIds.length)
      params.storesIds = storesIds;
    if (locationIds && locationIds.length)
      params.locationIds = locationIds;
    if (tagIds && tagIds.length)
      params.tagIds = tagIds;
    if (parentId)
      params.parentId = parentId;
    if (groupBy)
      params.groupBy = groupBy;
    const url = `${API_PRODUCTS_URL}/getproductstest?filters=${encodeURIComponent(JSON.stringify(filters))}`;
    return AppStorage.cache(`getProducts`,
      this.http.get<any>(url, { params, context: HTTP_CONTEXT })
        .pipe(map(result => {
          for (let item of result.data)
            item.tags = (item.tags || '').split(';').map(id => Number(id));
          return result;
        })),
      force
    );
  }

  getSubsTest(gridStateId: number, parentId: number, groupBy: string = null, from: Date = null, to: Date = null,
    storesIds: number[] = null, locationIds: number[] = null): Observable<any> {
    let params: any = {};
    if (gridStateId) {
      params.gridStateId = gridStateId;
    }
    if (from)
      params.from = from;
    if (to)
      params.to = to;
    if (storesIds && storesIds.length)
      params.storesIds = storesIds;
    if (locationIds && locationIds.length)
      params.locationIds = locationIds;
    if (groupBy)
      params.groupBy = groupBy;
    const url = `${API_PRODUCTS_URL}/${parentId}/GetSubstest`;
    return this.http.get<any>(url, { params, context: HTTP_CONTEXT })
      .pipe(map(result => {
        for (let item of result)
          item.tags = (item.tags || '').split(';').map(id => Number(id));
        return result;
      }));
  }

  getProducts(gridStateId: number, from: Date = null, to: Date = null, page: number, pageSize: number, sortField: string,
    sort: string, filters: any, storesIds: number[] = null, locationIds: number[] = null,
    tagIds: number[] = null, parentId: number = null, groupBy: string = null, force): Observable<any> {
    let params: any = { page: page, pageSize: pageSize };
    if (gridStateId) {
      params.gridStateId = gridStateId;
    }
    if (from)
      params.from = from;
    if (to)
      params.to = to;
    if (sortField)
      params.sortField = sortField;
    if (sort)
      params.sort = sort;
    if (storesIds && storesIds.length)
      params.storesIds = storesIds;
    if (locationIds && locationIds.length)
      params.locationIds = locationIds;
    if (tagIds && tagIds.length)
      params.tagIds = tagIds;
    if (parentId)
      params.parentId = parentId;
    if (groupBy)
      params.groupBy = groupBy;
    const url = `${API_PRODUCTS_URL}/getproducts?filters=${encodeURIComponent(JSON.stringify(filters))}`;
    return AppStorage.cache(`getProducts`,
      this.http.get<any>(url, { params, context: HTTP_CONTEXT })
        .pipe(map(result => {
          for (let item of result.data) {
            item.tags = (item.tags || '').split(';').map(id => Number(id));
            item.reorder1Details = {
              listings: item.reorder1Details?.split(';').map(item => {
                let arr = item.split(':');
                return { productId: Number(arr[0]), storeId: Number(arr[1]), quantity: Number(arr[2]) };
              })
            };
            item.reorder2Details = {
              listings: item.reorder2Details?.split(';').map(item => {
                let arr = item.split(':');
                return { productId: Number(arr[0]), storeId: Number(arr[1]), quantity: Number(arr[2]) };
              })
            };
          }
          return result;
        })),
      force
    );
  }

  getSubs(gridStateId: number, parentId: number, groupBy: string = null, from: Date = null, to: Date = null,
    storesIds: number[] = null, locationIds: number[] = null): Observable<any> {
    let params: any = {};
    if (gridStateId) {
      params.gridStateId = gridStateId;
    }
    if (from)
      params.from = from;
    if (to)
      params.to = to;
    if (storesIds && storesIds.length)
      params.storesIds = storesIds;
    if (locationIds && locationIds.length)
      params.locationIds = locationIds;
    if (groupBy)
      params.groupBy = groupBy;
    const url = `${API_PRODUCTS_URL}/${parentId}/GetSubs`;
    return this.http.get<any>(url, { params, context: HTTP_CONTEXT })
      .pipe(map(result => {
        for (let item of result)
          item.tags = (item.tags || '').split(';').map(id => Number(id));
        return result;
      }));
  }

  getListings(gridStateId: number, page: number, pageSize: number, from: Date = null, to: Date = null,
    sortField: string, sort: string, filters: any, storesIds: number[], locationIds: number[] = null,
    tagIds: number[] = null, parentId: number = null, groupBy = null, force = true, onlyUserModifiedReplenished: boolean = null): Observable<any> {
    let params: any = { page: page, pageSize: pageSize };
    if (gridStateId)
      params.gridStateId = gridStateId;
    if (from)
      params.from = from;
    if (to)
      params.to = to;
    if (sortField)
      params.sortField = sortField;
    if (sort)
      params.sort = sort;
    if (storesIds && storesIds.length)
      params.storesIds = storesIds;
    if (locationIds && locationIds.length)
      params.locationIds = locationIds;
    if (tagIds && tagIds.length)
      params.tagIds = tagIds;
    if (parentId)
      params.parentId = parentId;
    if (groupBy)
      params.groupBy = groupBy;
    if (onlyUserModifiedReplenished)
      params.onlyUserModifiedReplenished = onlyUserModifiedReplenished;
    const url = `${API_PRODUCTS_URL}/getListings?filters=${encodeURIComponent(JSON.stringify(filters))}`;
    return AppStorage.cache(`getListings`,
      this.http.get<any>(url, { params, context: HTTP_CONTEXT })
        .pipe(map(result => {
          for (let item of result.data)
            item.tags = (item.tags || '').split(';').map(id => Number(id));
          return result as ProductListing;
        })),
      force
    );
  }

  getProductData(productId: number): Observable<any> {
    const url = `${API_PRODUCTS_URL}/${productId}/productdata`;
    return this.http.get<any>(url, { context: HTTP_CONTEXT });
  }

  getSalesBySku(productId: number): Observable<any> {
    const url = `${API_PRODUCTS_URL}/${productId}/SalesBySku`;
    return this.http.get<any>(url, { context: HTTP_CONTEXT });
  }

  getDaysOfSupplyByMain(productId: number): Observable<DaysOfSupplyModel[]> {
    const url = `${API_PRODUCTS_URL}/${productId}/DaysOfSupplyByMain`;
    return this.http.get<DaysOfSupplyModel[]>(url, { context: HTTP_CONTEXT });
  }

  getSalesByStore(productId: number): Observable<any> {
    const url = `${API_PRODUCTS_URL}/${productId}/SalesByStore`;
    return this.http.get<any>(url, { context: HTTP_CONTEXT });
  }

  getSoldUnits(productId: number, from: Date, to: Date,
    productIds: number[] = [], storeIds: number[] = []): Observable<any> {
    let params: any = {};
    if (from)
      params.from = from;
    if (to)
      params.to = to;
    if (productIds && productIds.length)
      params.productIds = productIds;
    if (storeIds && storeIds.length)
      params.storeIds = storeIds;
    let url = `${API_PRODUCTS_URL}/${productId}/GetSoldUnits`;
    return this.http.get<any>(url, { params, context: HTTP_CONTEXT });
  }

  getProfits(productId: number, from: Date = null, to: Date = null,
    productIds: number[] = [], storeIds: number[] = []): Observable<any> {
    let params: any = {};
    if (from)
      params.from = from;
    if (to)
      params.to = to;
    if (productIds && productIds.length)
      params.productIds = productIds;
    if (storeIds && storeIds.length)
      params.storeIds = storeIds;
    let url = `${API_PRODUCTS_URL}/${productId}/GetUnitProfits`;
    return this.http.get<any>(url, { params, context: HTTP_CONTEXT });
  }

  getRealTime(productId: number, storeId: number, from: Date = null, to: Date = null) {
    let params: any = {};
    if (from)
      params.from = from;
    if (to)
      params.to = to;
    const url = `${API_PRODUCTS_URL}/${productId}/GetRealTime/${storeId}`;
    return this.http.get<any>(url, { params, context: HTTP_CONTEXT });
  }

  getInventoryAge(productId: number) {
    const url = `${API_PRODUCTS_URL}/${productId}/inventoryAge`;
    return this.http.get<any>(url, { context: HTTP_CONTEXT });
  }

  getBestProducts(profit: Boolean): Observable<any> {
    let params: any = {};
    if (profit)
      params.profit = profit;
    const url = `${API_PRODUCTS_URL}/bestproducts`;
    return this.http.get<any>(url, { params, context: HTTP_CONTEXT });
  }

  getCostHistory(productId: number, date: string = null): Observable<any> {
    let params: any = {};
    if (date)
      params.date = date;
    return this.http.get<any>(`${API_PRODUCTS_URL}/${productId}/costHistory`, { params, context: HTTP_CONTEXT });
  }

  create(product: OldProductModel): Observable<OldProductModel> {
    return this.http.post<OldProductModel>(API_PRODUCTS_URL, product, { context: HTTP_CONTEXT });
  }

  convertToMain(id: number) {
    const url = `${API_PRODUCTS_URL}/convertToMain`;
    return this.http.patch(url, {}, { params: { id }, context: HTTP_CONTEXT });
  }

  convertToSub(id: number, ckProduct: any, baseFraction: any, subFraction: any) {
    let params: any = { id: id, mainWhereToMove: ckProduct, baseFraction: baseFraction, subFraction: subFraction };
    const url = `${API_PRODUCTS_URL}/convertToSub`;
    return this.http.post<any>(url, {}, { params, context: HTTP_CONTEXT });
  }

  update(product: OldProductModel): Observable<any> {
    return this.http.put(API_PRODUCTS_URL, product, { context: HTTP_CONTEXT });
  }

  markAsFavorite(productId: number) {
    return this.http.patch(`${API_PRODUCTS_URL}/${productId}/markAsFavorite`, {});
  }

  /**
   * @param date - yyyy-mm-dd For example 2024-8-1
   */
  getProductsQuantityForDate(date: string, warehouseId: number, productIds: number[]): Observable<ProductWarehouseByDate[]> {
    let params: any = {};
    if (date)
      params.date = date;
    if (warehouseId)
      params.warehouseId = warehouseId;
    return this.http.post<ProductWarehouseByDate[]>(`${API_PRODUCTS_URL}/getProductsQuantityForDate`, productIds || [], { params: params });
  }

  updatePriceRule(productId: number, storeId: number, priceRuleId: number, min: number, max: number, price: number) {
    let params: any = { storeId: storeId };
    if (priceRuleId > 0)
      params.priceRuleId = priceRuleId;
    if (min)
      params.min = min;
    if (max)
      params.max = max;
    if (price)
      params.price = price;
    const url = `${API_PRODUCTS_URL}/${productId}/UpdatePriceRule`;
    return this.http.patch(url, null, { params, context: HTTP_CONTEXT });
  }

  updatePriceRules(products: any, priceRuleId: number) {
    let params: any = {};
    if (priceRuleId > 0)
      params.priceRuleId = priceRuleId;
    const url = `${API_PRODUCTS_URL}/UpdatePriceRule`;
    return this.http.patch(url, products.map(x => { return { productId: x.productId, storeId: x.storeId }; }), { params, context: HTTP_CONTEXT });
  }

  updateLinkRule(productId: number, storeId: number, linkRuleId: number, available: number) {
    let params: any = { storeId: storeId };
    if (linkRuleId)
      params.linkRuleId = linkRuleId;
    if (available)
      params.available = available;
    const url = `${API_PRODUCTS_URL}/${productId}/UpdateLinkRule`;
    return this.http.patch(url, null, { params, context: HTTP_CONTEXT });
  }

  updateLinkRules(products: any[], linkRuleId: number) {
    let params: any = {};
    if (linkRuleId)
      params.linkRuleId = linkRuleId;
    const url = `${API_PRODUCTS_URL}/UpdateLinkRule`;
    return this.http.patch(url, products.map(p => { return { productId: p.productId, storeId: p.storeId }; }), { params, context: HTTP_CONTEXT });
  }

  patch(productId: number, fieldName: string, value: any) {
    let params: any = { fieldName: fieldName, value: value };
    const url = `${API_PRODUCTS_URL}/${productId}`;
    return this.http.patch(url, {}, { params, context: HTTP_CONTEXT });
  }

  markAsActive(productIds: number[], active: boolean) {
    let params: any = { productIds: productIds, active: active };
    return this.http.patch(`${API_PRODUCTS_URL}/markAsActive`, {}, { params, context: HTTP_CONTEXT });
  }

  delete(ids: number[], convertSubsToMain: boolean = true): Observable<any> {
    let params: any = { ids, convertSubs: convertSubsToMain };
    return this.http.delete(API_PRODUCTS_URL, { params, context: HTTP_CONTEXT });
  }

  getProductImage(productId): Observable<Blob> {
    const url = `${API_PRODUCTS_URL}/image/${productId}`;
    return this.http.get(url, { responseType: 'blob', context: HTTP_CONTEXT });
  }

  getOpenPOs(productId): Observable<any> {
    const url = `${environment.baseUrl}/purchases/openpos/${productId}`;
    return this.http.get(url, { context: HTTP_CONTEXT });
  }

  getProductsForStore(storeId: number, warehouseId: number, onlyFba: boolean = false, skuSearch: string = null, productIds: number[] = []): Observable<any> {
    const request = {
      storeId,
      skuSearch,
      warehouseId,
      onlyFba,
      productIds: productIds ?? []
    };
    const url = `${API_PRODUCTS_URL}/GetProductsForStore`;
    return this.http.post<any>(url, request, { context: HTTP_CONTEXT });
  }

  getProductDetailsForFbaShipmentItems(storeId: number, warehouseId: number, onlyFba: boolean = false, skuSearch: string = null, productIds: number[] = []): Observable<FbaShipmentDetailModel[]> {
    const request = {
      storeId,
      skuSearch,
      warehouseId,
      onlyFba,
      productIds: productIds ?? []
    };
    const url = `${API_PRODUCTS_URL}/GetProductsForStore`;
    return this.http.post<any>(url, request, { context: HTTP_CONTEXT });
  }

  getProductsForWFSStore(storeId: number, warehouseId: number, skuSearch: string = null,
    productIds: number[] = null): Observable<any> {
    let params: any = { storeId: storeId, };
    if (warehouseId) {
      params.warehouseId = warehouseId;
    }
    if (skuSearch)
      params.skuSearch = skuSearch;
    if (productIds && productIds.length)
      params.productIds = productIds;
    const url = `${API_PRODUCTS_URL}/GetProductsForStoreWFS`;
    return this.http.get<any>(url, { params, context: HTTP_CONTEXT });
  }

  getFnskusForStore(storeId: number, productIds: number[] = null): Observable<any> {
    let params: any = {};
    if (productIds && productIds.length)
      params.productIds = productIds;
    const url = `${API_PRODUCTS_URL}/GetFnskusForStore/${storeId}`;
    return this.http.get<any>(url, { params, context: HTTP_CONTEXT });
  }

  GetProductsForStoreByMain(storeId: number, parentId: number): Observable<any> {
    const url = `${API_PRODUCTS_URL}/${storeId}/GetProductsForStoreByMain/${parentId}`;
    return this.http.get<any>(url, { context: HTTP_CONTEXT });
  }

  addImages(id: any, file: any, fileName: string) {
    const fd = new FormData();
    fd.append('file', file);
    return this.http.post<any>(`${API_PRODUCTS_URL}/${id}/AddImage/${fileName}`, fd, { context: HTTP_CONTEXT });
  }

  downloadAttachment(productId: number, fileName: number): Observable<any> {
    const url = `${API_PRODUCTS_URL}/${productId}/downloadAttachment/${fileName}`;
    return this.http.get(url, { responseType: 'blob', context: HTTP_CONTEXT })
      .pipe(map((res) => {
        return new Blob([res]);
      }));
  }

  listImages(id: any) {
    return this.http.get<any[]>(`${API_PRODUCTS_URL}/${id}/ListImages`, { context: HTTP_CONTEXT });
  }

  getImageFromUrl(imageUrl: string) {
    return this.http.get(imageUrl, { responseType: 'blob', context: HTTP_CONTEXT })
      .pipe(map((res) => {
        return new Blob([res]);
      }));
  }

  GetCategories(): Observable<any> {
    const url = `${API_PRODUCTS_URL}/Categories`;
    return this.http.get<any>(url, { context: HTTP_CONTEXT });
  }

  deleteImageFromProduct(productId: number, imageName: string) {
    return this.http.delete(`${API_PRODUCTS_URL}/${productId}/DeleteImage/${imageName}`, { context: HTTP_CONTEXT });
  }

  UpdateReorders(productIds: number[]) {
    return this.http.put(`${API_PRODUCTS_URL}/UpdateReorders`, { productIds }, { context: HTTP_CONTEXT });
  }

  refetchProductImages(data: Array<{ productId: number, storeId: number; }>) {
    return this.http.put<string>(`${API_PRODUCTS_URL}/refetchProductImages`, data, { context: HTTP_CONTEXT });
  }

  updateProductInfo(productIds, fieldName, value) {
    const params = { productIds, fieldName, value };
    return this.http.patch(`${API_PRODUCTS_URL}/updateField`, params, { context: HTTP_CONTEXT });
  }
}
