import { Store } from "@ngxs/store";
import { LoadingService } from "../loading.service";
import { Injectable } from "@angular/core";
import { SignalrHttpClientWrapper } from "./signalr-http-client-wrapper";


@Injectable({
  providedIn: 'root'
})
export class RealTimeConnectionDeps {
  constructor(
    public stateStore: Store,
    public loadingService: LoadingService,
    public signalrHttpClient: SignalrHttpClientWrapper
  ) { }
}
