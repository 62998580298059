<div class="loading-indicator">
	<loading [loading]="loading"></loading>
</div>
<div #datatable class="table-content" *ngIf="!loading">
	<div *ngIf="expandedColumn" class="expanded-column">
		<div class="datatable-left">
			<div class="datatable-row">
				<div style="min-width: 48px; max-width: 48px;" scope="col">
				</div>
				<div *ngFor="let col of _columns | visibleColumns: true:'left'; let ix = index"
					[style.min-width.px]="col.width" [style.max-width.px]="col.width"
					[attr.colspan]="col.subColumns.length || 1" scope="col">
					<img [src]="columnsExpanded ? 'assets/media/icons/svg/collapse-col.svg' : 'assets/media/icons/svg/collapse-col2.svg'"
						*ngIf="col.name == expandedColumn" class="right" (click)="expandColumns()">
				</div>
			</div>
		</div>
		<div class="datatable-center" #expandedColumnHeader>
			<div class="datatable-row">
				<div *ngFor="let col of _columns | visibleColumns; let ix = index" [style.min-width.px]="col.width"
					[style.max-width.px]="col.width" [attr.colspan]="col.subColumns.length || 1" scope="col">
					<img [src]="columnsExpanded ? 'assets/media/icons/svg/collapse-col.svg' : 'assets/media/icons/svg/collapse-col2.svg'"
						*ngIf="col.name == expandedColumn" class="right" (click)="expandColumns()">
				</div>
			</div>
		</div>
		<div class="datatable-right" style="border: 0;">
			<div class="datatable-row">
				<div *ngFor="let col of _columns | visibleColumns: true:'right'; let ix = index"
					[style.min-width.px]="col.width" [style.max-width.px]="col.width"
					[attr.colspan]="col.subColumns.length || 1" scope="col">
					<img [src]="columnsExpanded ? 'assets/media/icons/svg/collapse-col.svg' : 'assets/media/icons/svg/collapse-col2.svg'"
						*ngIf="col.name == expandedColumn" class="right" (click)="expandColumns()">
				</div>
			</div>
		</div>
	</div>
	<div class="datatable" [ngClass]="border ? '' : 'no-border'">
		<div class="datatable-header" sticky [marginTop]="headerStickyMargin">
			<div class="datatable-left">
				<div class="datatable-row header-row" [style.height.px]="headerHeight" cdkDropList
					cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)"
					[cdkDropListDisabled]="!columnsSorting">
					<div class="datatable-td col0 master-toggle d-flex align-items-center" *ngIf="selectable || isExpandable || showRowNumber"
						[style.height.px]="headerHeight" [style.width.px]="showSelectAllHeader ? 130 : 36"
						 [ngClass]="border ? '' : ' datatable-td-h'">
						<mat-checkbox *ngIf="selectable" (change)="selectAll($event)"
							[checked]="_items | allChecked : selectedItems: key" class="small-checkbox">
							<span class="fw-bold" *ngIf="showSelectAllHeader"> {{(_items | allChecked : selectedItems : key) ?
								'Deselect All'
								: 'Select All'}}</span></mat-checkbox>
					</div>
					<div class="datatable-td" *ngFor="let col of _columns | visibleColumns: true:'left'; let ix = index"
						[ngClass]="'col' + (ix + 2) + (border ? '' : ' datatable-td-h') + ' ' + col.ngClass"
						[style.height.px]="headerHeight" [style.min-width.px]="col.width"
						[style.max-width.px]="col.width" cdkDrag (sort)="onSort($event)"
						[sortable]="(!sorting || !col.sortable == false || col.subColumns.length > 0) ? '' : col.name"
						[attr.colspan]="col.subColumns.length || 1"
						[style.text-align]="col.subColumns.length == 0 ? 'left' : 'center'" scope="col">
						<div [ngClass]="border ? '' : 'datatable-middle'" [style.height.px]="headerHeight">
							<ng-container *ngTemplateOutlet="col.titleTemplate; context: { col: col, index: ix }">
							</ng-container>
							<span *ngIf="col.titleTemplate == undefined">{{ col.title || ((col.name ||
								'').split(col.reg).join(' ') | titlecase) }}</span>
						</div>
						<div *ngIf="col.subColumns.length > 0" class="datatable-row subheader-row subcol"
							[style.height.px]="headerHeight / 2">
							<div class="datatable-td subcol" *ngFor="let subCol of col.subColumns"
								[style.height.px]="headerHeight / 2" scope="col"
								[sortable]="!sorting || subCol.sortable == false ? '' : subCol.name"
								(sort)="onSort($event)" [ngClass]="border ? '' : ' datatable-td-h'">
								<div [ngClass]="border ? '' : 'datatable-middle'" [style.height.px]="headerHeight">
									<span>{{ subCol.title || ((subCol.name || '').split(col.reg).join(' ') | titlecase)
										}}</span>
								</div>
							</div>
						</div>
						<div class="columnSelector" [style.height.px]="headerHeight"
							(mousedown)="resizerMouseDown($event, col)"></div>
					</div>
				</div>
				<div *ngIf="showFilters" class="datatable-row" [style.height.px]="rowHeight">
					<div class="datatable-td datatable-cell col0" *ngIf="selectable || isExpandable || showRowNumber"
						[style.width.px]="36" [ngClass]="border ? '' : ' datatable-td-h'"></div>
					<ng-container *ngFor="let col of _columns | visibleColumns: true:'left'; let ix = index">
						<div class="datatable-td datatable-cell" [style.min-width.px]="col.width"
							[style.max-width.px]="col.width"
							[ngClass]="'col' + (ix + 2) + (border ? '' : ' datatable-td-h') + ' ' + col.ngClass">
							<ng-container *ngTemplateOutlet="colFilter; context: { col: col }">
							</ng-container>
							<div *ngIf="col.subColumns.length > 0" class="datatable-row" [style.height.px]="rowHeight">
								<div class="datatable-td datatable-cell subcol" *ngFor="let subCol of col.subColumns"
									[ngClass]="border ? '' : ' datatable-td-h'">
									<ng-container *ngTemplateOutlet="colFilter; context: { col: subCol }">
									</ng-container>
								</div>
							</div>
							<div class="columnSelector" [style.height.px]="rowHeight"
								(mousedown)="resizerMouseDown($event, col)"></div>
						</div>
					</ng-container>
				</div>
			</div>
			<div class="datatable-center" #tableHeader>
				<div class="datatable-row header-row" [style.height.px]="headerHeight || getHeaderHeight()" cdkDropList
					cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)"
					[cdkDropListDisabled]="!columnsSorting">
					<div class="datatable-td" *ngFor="let col of _columns | visibleColumns; let ix = index"
						[class.cursor-pointer]="sorting && col.name"
						[ngClass]="'col' + (_columns | columnGrid : 'left' : ix) + (border ? '' : ' datatable-td-h') + ' ' + col.ngClass + ' ' + col.name"
						[sortable]="(!sorting || !col.sortable == false || col.subColumns.length > 0) ? '' : col.name"
						(sort)="onSort($event)" [style.min-width.px]="col.width" [style.max-width.px]="col.width"
						[style.height.px]="headerHeight" cdkDrag [attr.colspan]="col.subColumns.length || 1"
						[style.text-align]="col.subColumns.length == 0 ? 'left' : 'center'" scope="col">
						<div [ngClass]="border ? '' : 'datatable-middle'" [style.height.px]="headerHeight">
							<ng-container *ngTemplateOutlet="col.titleTemplate; context: { col: col, index: ix }">
							</ng-container>
							<span *ngIf="col.titleTemplate == undefined">{{ col.title || ((col.name ||
								'').split(col.reg).join(' ') | titlecase) }}</span>
						</div>
						<div *ngIf="col.subColumns.length > 0" class="datatable-row subheader-row subcol"
							[style.height.px]="headerHeight / 2">
							<div class="datatable-td subcol" *ngFor="let subCol of col.subColumns"
								[style.height.px]="headerHeight / 2" scope="col"
								[sortable]="!sorting || subCol.sortable == false ? '' : subCol.name"
								(sort)="onSort($event)" [ngClass]="border ? '' : ' datatable-td-h'">
								<div [ngClass]="border ? '' : 'datatable-middle'" [style.height.px]="headerHeight">
									<span>{{ subCol.title || ((subCol.name || '').split(col.reg).join(' ') | titlecase)
										}}</span>
								</div>
							</div>
						</div>
						<div class="columnSelector" [style.height.px]="headerHeight"
							(mousedown)="resizerMouseDown($event, col)" cdkDrag (cdkDragEnded)="dragEnded($event)">
						</div>
					</div>
					<div class="datatable-td datatable-cell" style="width: 100%;"></div>
				</div>
				<div *ngIf="showFilters" class="datatable-row" [style.height.px]="rowHeight">
					<ng-container *ngFor="let col of _columns | visibleColumns; let ix = index">
						<div class="datatable-td datatable-cell"
							[ngClass]="'col' + (_columns | columnGrid : 'left' : ix) + (border ? '' : ' datatable-td-h') + ' ' + col.ngClass">
							<ng-container *ngTemplateOutlet="colFilter; context: { col: col }">
							</ng-container>
							<div *ngIf="col.subColumns.length > 0" class="datatable-row" [style.height.px]="rowHeight"
								[style.min-width.px]="col.width" [style.max-width.px]="col.width">
								<div class="datatable-td datatable-cell subcol" *ngFor="let subCol of col.subColumns"
									[ngClass]="border ? '' : ' datatable-td-h'">
									<ng-container *ngTemplateOutlet="colFilter; context: { col: subCol }">
									</ng-container>
								</div>
							</div>
							<div class="columnSelector" [style.height.px]="rowHeight"
								(mousedown)="resizerMouseDown($event, col)"></div>
						</div>
					</ng-container>
					<div class="datatable-td datatable-cell" style="width: 100%;"></div>
				</div>
			</div>
			<div class="datatable-right">
				<div class="datatable-row header-row" [style.height.px]="headerHeight" cdkDropList
					cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)"
					[cdkDropListDisabled]="!columnsSorting">
					<div class="datatable-td"
						*ngFor="let col of _columns | visibleColumns: true:'right'; let ix = index; let last = last"
						[ngClass]="'col' + (_columns | columnGrid : 'left_center' : ix) + (border ? '' : ' datatable-td-h') + ' ' + col.ngClass"
						[sortable]="(!sorting || !col.sortable == false || col.subColumns.length > 0) ? '' : col.name"
						(sort)="onSort($event)"
						[style.min-width.px]="(last && (_items.length > maxVisibleRows)) ? col.width+10 : col.width"
						[style.max-width.px]="(last && (_items.length > maxVisibleRows))  ? col.width+10 : col.width"
						[style.height.px]="headerHeight" cdkDrag [attr.colspan]="col.subColumns.length || 1"
						[style.text-align]="col.subColumns.length == 0 ? 'left' : 'center'" scope="col">
						<div [ngClass]="border ? '' : 'datatable-middle'" [style.height.px]="headerHeight">
							<ng-container *ngTemplateOutlet="col.titleTemplate; context: { col, index: ix }">
							</ng-container>
							<span *ngIf="col.titleTemplate == undefined">{{ col.title || ((col.name ||
								'').split(col.reg).join(' ') | titlecase) }}</span>
						</div>
						<div *ngIf="col.subColumns.length > 0" class="datatable-row subheader-row subcol"
							[style.height.px]="headerHeight / 2">
							<div class="datatable-td subcol" *ngFor="let subCol of col.subColumns"
								[style.height.px]="headerHeight / 2" scope="col"
								[sortable]="!sorting || subCol.sortable == false ? '' : subCol.name"
								(sort)="onSort($event)" [ngClass]="border ? '' : ' datatable-td-h'">
								<div [ngClass]="border ? '' : 'datatable-middle'" [style.height.px]="headerHeight">
									<span>{{ subCol.title || ((subCol.name || '').split(col.reg).join(' ') | titlecase)
										}}</span>
								</div>
							</div>
						</div>
						<div class="columnSelector" [style.height.px]="headerHeight"
							(mousedown)="resizerMouseDown($event, col)"></div>
					</div>
				</div>
				<div *ngIf="showFilters" class="datatable-row" [style.height.px]="rowHeight">
					<ng-container *ngFor="let col of _columns | visibleColumns: true:'right'; let ix = index">
						<div class="datatable-td datatable-cell"
							[ngClass]="'col' + (_columns | columnGrid : 'left_center' : ix) + (border ? '' : ' datatable-td-h') + ' ' + col.ngClass">
							<ng-container *ngTemplateOutlet="colFilter; context: { col: col }">
							</ng-container>
							<div *ngIf="col.subColumns.length > 0" class="datatable-row" [style.height.px]="rowHeight"
								[style.min-width.px]="col.width" [style.max-width.px]="col.width">
								<div class="datatable-td datatable-cell subcol" *ngFor="let subCol of col.subColumns"
									[ngClass]="border ? '' : ' datatable-td-h'">
									<ng-container *ngTemplateOutlet="colFilter; context: { col: subCol }">
									</ng-container>
								</div>
							</div>
							<div class="columnSelector" [style.height.px]="rowHeight"
								(mousedown)="resizerMouseDown($event, col)"></div>
						</div>
					</ng-container>
				</div>
			</div>
		</div>
		<div #allRows class="datatable-content" cdkVirtualScrollingElement
			[style.height.px]="(avgRowHeight * ((_items.length <= maxVisibleRows) ? _items.length : maxVisibleRows) + 10) + expandedRowsHeight">
			<div class="datatable-left content">
				<cdk-virtual-scroll-viewport [itemSize]="avgRowHeight" [minBufferPx]="minBufferPx"
					[maxBufferPx]="maxBufferPx"
					[style.min-width.px]="leftWidth + (showSelectAllHeader ? 130 : (selectable || isExpandable || showRowNumber) ? 36 : 0)"
					[style.max-width.px]="leftWidth + (showSelectAllHeader ? 130 : (selectable || isExpandable || showRowNumber) ? 36 : 0)">
					<ng-container *cdkVirtualFor="let item of _items; let rix = index">
						<div class="datatable-row" [style.height.px]="rowHeight || item._rowHeight"
							[ngClass]="{'row-hover': highlightedRow === rix }" (mouseenter)="highlightedRow = rix"
							(mouseleave)="highlightedRow = -1">
							<div class="datatable-td datatable-cell col0 main-checkbox"
								*ngIf="selectable || isExpandable || showRowNumber"
								[ngClass]="border ? '' : ' datatable-td-h'"
								[style.width.px]="showSelectAllHeader ? 130 : 36">
								<div *ngIf="showRowNumber" class="kt-padding-5 center-text w-100">
									{{_items.indexOf(item) + 1}}
								</div>
								<div [ngClass]="{'text-center': !showSelectAllHeader}" *ngIf="selectable">
									<mat-checkbox [value]="rix" (change)="select($event, item)"
										[checked]="(item | isSelected : selectedItems : key)"
										[disabled]="disableSelect.includes(item)"
										class="small-checkbox mb-1"></mat-checkbox>
								</div>
								<div class="expand-subs-icon" *ngIf="isExpandable" (click)="expand($event, item, rix)"
									[attr.data-index]="rix"
									[ngClass]="expandedRows.includes(rix) ? 'open-status' : 'close-status'">
									<span class="expand angle-right" *ngIf="item[subsCountField] > 0">
										>
									</span>
									<span class="sub-number img-close" *ngIf="item[subsCountField] > 0">{{
										item[subsCountField] }}</span>
								</div>
							</div>
							<ng-container *ngFor="let col of _columns | visibleColumns: true:'left'; let ix = index">
								<div class="datatable-td datatable-cell opopop" [attr.align]="col.align"
									[style.height.px]="rowHeight" [style.min-width.px]="col.width"
									[style.max-width.px]="col.width" (click)="rowClicked($event, item, col)"
									[ngClass]="'col' + (ix + 2) + (border ? '' : ' datatable-td-h') + ' ' + col.ngClass"
									[class.cursor-pointer]="rowClick.observers.length">
									<div *ngIf="col.subColumns.length == 0" [ngClass]="border ? '' : 'datatable-middle'"
										[style.height.px]="rowHeight">
										<ng-container
											*ngTemplateOutlet="cell; context: { col: col, item: item, index: rix }">
										</ng-container>
									</div>
									<div *ngIf="col.subColumns.length > 0" class="datatable-row">
										<div class="datatable-td datatable-cell subcol"
											*ngFor="let subCol of col.subColumns | visibleColumns"
											[attr.align]="subCol.align" [ngClass]="border ? '' : ' datatable-td-h'">
											<ng-container
												*ngTemplateOutlet="cell; context: { col: subCol, item: item, index: rix }">
											</ng-container>
										</div>
									</div>
									<div class="columnSelector" [style.height.px]="rowHeight"
										(mousedown)="resizerMouseDown($event, col)"></div>
								</div>
							</ng-container>
						</div>
						<div class="sub-row" *ngIf="isExpandable" id="subsLeft{{rix}}"
							[style.display]="expandedRows.includes(rix) ? '' : 'none'">
							<ng-container
								*ngTemplateOutlet="subRowTemplate; context: { items: (item.subItems || {}).items }">
							</ng-container>
							<ng-container *ngIf="!subRowTemplate">
								<div *ngFor="let subItem of (item.subItems || {}).items" class="datatable-row"
									[style.height.px]="rowHeight">
									<div class="datatable-td datatable-cell col0" [style.width.px]="36"
										[ngClass]="border ? '' : ' datatable-td-h'">
									</div>
									<div class="datatable-td datatable-cell col1 sub-check"
										*ngIf="selectable || isExpandable || showRowNumber" [style.width.px]="36"
										[ngClass]="border ? '' : ' datatable-td-h'">
										<mat-checkbox *ngIf="selectable" [value]="rix"
											(change)="select($event, subItem)"
											[checked]="(subItem | isSelected : selectedItems : key)"
											class="small-checkbox ms-1 mb-1"></mat-checkbox>

										<div *ngIf="subItem.index">
											<span class="sub-number" [class.first-sub]="subItem.index === 1">{{
												subItem.index }}</span>
										</div>
									</div>
									<div *ngFor="let col of _columns | visibleColumns: true:'left'; let ix = index"
										[style.height.px]="rowHeight" [style.min-width.px]="col.width"
										[style.max-width.px]="col.width" class="datatable-td datatable-cell"
										[attr.align]="col.align" (click)="rowClicked($event, item, col)"
										[ngClass]="'col' + (_columns | columnGrid : 'left' : ix) + (border ? '' : ' datatable-td-h') + ' ' + col.ngClass"
										[class.cursor-pointer]="rowClick.observers.length">
										<div *ngIf="col.subColumns.length == 0"
											[ngClass]="border ? '' : 'datatable-middle'" [style.height.px]="rowHeight">
											<ng-container
												*ngTemplateOutlet="cell; context: { col: col, item: subItem, index: rix }">
											</ng-container>
										</div>
										<div *ngIf="col.subColumns.length > 0" class="datatable-row"
											[style.height.px]="rowHeight">
											<div class="datatable-td datatable-cell subcol"
												*ngFor="let subCol of col.subColumns | visibleColumns"
												[attr.align]="subCol.align" [ngClass]="border ? '' : ' datatable-td-h'">
												<ng-container
													*ngTemplateOutlet="cell; context: { col: subCol, item: subItem, index: rix }">
												</ng-container>
											</div>
										</div>
										<div class="columnSelector" [style.height.px]="rowHeight"
											(mousedown)="resizerMouseDown($event, col)"></div>
									</div>
								</div>
							</ng-container>
						</div>
					</ng-container>
				</cdk-virtual-scroll-viewport>
			</div>
			<div class="datatable-center content">
				<cdk-virtual-scroll-viewport [itemSize]="avgRowHeight" [minBufferPx]="minBufferPx"
					[maxBufferPx]="maxBufferPx">
					<div class="overflow-x-scroll w-100" (scroll)="scroll($event)">
						<ng-container *cdkVirtualFor="let item of _items; let rix = index">
							<div class="datatable-row" [style.height.px]="rowHeight || item._rowHeight"
								[ngClass]="{'row-hover': highlightedRow === rix }" (mouseenter)="highlightedRow = rix"
								(mouseleave)="highlightedRow = -1">
								<ng-container *ngFor="let col of _columns | visibleColumns; let ix = index">
									<div class="datatable-td datatable-cell" (click)="rowClicked($event, item, col)"
										[attr.align]="col.align" [style.min-width.px]="col.width"
										[style.max-width.px]="col.width"
										[ngClass]="'col' +  (_columns | columnGrid : 'left' : ix) + (border ? '' : ' datatable-td-h') + ' ' + col.ngClass + ' ' +col.name"
										[class.cursor-pointer]="rowClick.observers.length">
										<div *ngIf="col.subColumns.length == 0"
											[ngClass]="border ? '' : 'datatable-middle'" [style.height.px]="rowHeight">
											<ng-container
												*ngTemplateOutlet="cell; context: { col: col, item: item, index: rix }">
											</ng-container>
										</div>
										<div *ngIf="col.subColumns.length > 0" class="datatable-row"
											[style.height.px]="rowHeight">
											<div class="datatable-td datatable-cell subcol"
												*ngFor="let subCol of col.subColumns | visibleColumns"
												[attr.align]="subCol.align" [ngClass]="border ? '' : ' datatable-td-h'">
												<ng-container
													*ngTemplateOutlet="cell; context: { col: subCol, item: item, index: rix }">
												</ng-container>
											</div>
										</div>
										<div class="columnSelector" [style.height.px]="rowHeight"
											(mousedown)="resizerMouseDown($event, col)"></div>
									</div>
								</ng-container>
								<div class="datatable-td datatable-cell" style="width: 100%;"></div>
							</div>
							<div class="sub-row" *ngIf="isExpandable" id="subs{{ rix }}"
								[style.display]="expandedRows.includes(rix) ? '' : 'none'">
								<ng-container *ngIf="!subRowTemplate">
									<div *ngFor="let subItem of (item.subItems || {}).items" class="datatable-row"
										[style.height.px]="rowHeight">
										<div *ngFor="let col of _columns | visibleColumns; let ix = index"
											class="datatable-td datatable-cell" [style.height.px]="rowHeight"
											[style.min-width.px]="col.width" [style.max-width.px]="col.width"
											[ngClass]="'col' + (_columns | columnGrid : 'left' : ix) + (border ? '' : ' datatable-td-h') + ' ' + col.ngClass + ' ' + col.name"
											[attr.align]="col.align" (click)="rowClicked($event, item, col)"
											[class.cursor-pointer]="rowClick.observers.length">
											<div *ngIf="col.subColumns.length == 0"
												[ngClass]="border ? '' : 'datatable-middle'"
												[style.height.px]="rowHeight">
												<ng-container
													*ngTemplateOutlet="cell; context: { col: col, item: subItem, index: rix }">
												</ng-container>
											</div>
											<div *ngIf="col.subColumns.length > 0" class="datatable-row"
												[style.height.px]="rowHeight">
												<div class="datatable-td datatable-cell subcol"
													*ngFor="let subCol of col.subColumns | visibleColumns"
													[attr.align]="subCol.align"
													[ngClass]="border ? '' : ' datatable-td-h'">
													<ng-container
														*ngTemplateOutlet="cell; context: { col: subCol, item: subItem, index: rix }">
													</ng-container>
												</div>
											</div>
											<div class="columnSelector" [style.height.px]="rowHeight"
												(mousedown)="resizerMouseDown($event, col)"></div>
										</div>
									</div>
								</ng-container>
							</div>
						</ng-container>
					</div>
				</cdk-virtual-scroll-viewport>
			</div>
			<div class="datatable-right content">
				<cdk-virtual-scroll-viewport [itemSize]="avgRowHeight" [minBufferPx]="minBufferPx"
					[maxBufferPx]="maxBufferPx" [style.min-width.px]="rightWidth" [style.max-width.px]="rightWidth">
					<ng-container *cdkVirtualFor="let item of _items; let rix = index">
						<div class="datatable-row" [style.height.px]="rowHeight || item._rowHeight"
							[ngClass]="{'row-hover': highlightedRow === rix }" (mouseenter)="highlightedRow = rix"
							(mouseleave)="highlightedRow = -1">
							<ng-container *ngFor="let col of _columns | visibleColumns: true:'right'; let ix = index">
								<div class="datatable-td datatable-cell" [attr.align]="col.align"
									[style.height.px]="rowHeight" [style.min-width.px]="col.width"
									[style.max-width.px]="col.width"
									[ngClass]="'col' + (_columns | columnGrid : 'left_center' : ix) + (border ? '' : ' datatable-td-h') + ' ' + col.ngClass">
									<div *ngIf="col.subColumns.length == 0" [ngClass]="border ? '' : 'datatable-middle'"
										[style.height.px]="rowHeight">
										<ng-container
											*ngTemplateOutlet="cell; context: { col: col, item: item, index: rix }">
										</ng-container>
									</div>
									<div *ngIf="col.subColumns.length > 0" class="datatable-row"
										[style.height.px]="rowHeight">
										<div class="datatable-td datatable-cell subcol"
											*ngFor="let subCol of col.subColumns | visibleColumns"
											[attr.align]="subCol.align" [ngClass]="border ? '' : ' datatable-td-h'">
											<ng-container
												*ngTemplateOutlet="cell; context: { col: subCol, item: item, index: rix }">
											</ng-container>
										</div>
									</div>
									<div class="columnSelector" [style.height.px]="rowHeight"
										(mousedown)="resizerMouseDown($event, col)"></div>
								</div>
							</ng-container>
						</div>
						<div class="sub-row" *ngIf="isExpandable" id="subsRight{{rix}}"
							[style.display]="expandedRows.includes(rix) ? '' : 'none'">
							<ng-container *ngIf="!subRowTemplate">
								<div *ngFor="let subItem of (item.subItems || {}).items" class="datatable-row"
									[style.height.px]="rowHeight">
									<div *ngFor="let col of _columns | visibleColumns: true:'right'; let ix = index"
										class="datatable-td datatable-cell"
										[ngClass]="'col' + (_columns | columnGrid : 'left_center' : ix) + (border ? '' : ' datatable-td-h') + ' ' + col.ngClass + ' ' + col.name"
										[attr.align]="col.align" [style.height.px]="rowHeight"
										[style.min-width.px]="col.width" [style.max-width.px]="col.width">
										<div *ngIf="col.subColumns.length == 0"
											[ngClass]="border ? '' : 'datatable-middle'" [style.height.px]="rowHeight">
											<ng-container
												*ngTemplateOutlet="cell; context: { col: col, item: subItem, index: rix }">
											</ng-container>
										</div>
										<div *ngIf="col.subColumns.length > 0" class="datatable-row"
											[style.height.px]="rowHeight">
											<div class="datatable-td datatable-cell subcol"
												*ngFor="let subCol of col.subColumns | visibleColumns"
												[attr.align]="subCol.align" [ngClass]="border ? '' : ' datatable-td-h'">
												<ng-container
													*ngTemplateOutlet="cell; context: { col: subCol, item: subItem, index: rix }">
												</ng-container>
											</div>
										</div>
										<div class="columnSelector" [style.height.px]="rowHeight"
											(mousedown)="resizerMouseDown($event, col)"></div>
									</div>
								</div>
							</ng-container>
						</div>
					</ng-container>
				</cdk-virtual-scroll-viewport>
			</div>
		</div>
	</div>
	<div id="external"></div>
	<div class="row kt-pagination" *ngIf="total > pageSize">
		<div class="col-sm-12 col-md-5">
			<ngb-pagination [collectionSize]="total" [(page)]="page" [pageSize]="pageSize"
				(pageChange)="pageChanged($event)" [maxSize]="6" [rotate]="true"></ngb-pagination>
		</div>
		<div class="col-sm-12 col-md-7 dataTables_length kt-align-right" id="kt_table_1_length">
			<label class="m-3">
				<select class="custom-pagination-select" style="width: auto" name="pageSize" [(ngModel)]="pageSize"
					(change)="pageChanged($event)">
					<option [ngValue]="10">10</option>
					<option [ngValue]="20" selected>20</option>
					<option [ngValue]="50">50</option>
					<option [ngValue]="100">100</option>
					<option [ngValue]="500">500</option>
				</select>
				&nbsp;
				Page {{page}} of {{math.ceil((total / pageSize) || 0)}} ({{total}} items)
			</label>
			<div class="dataTables_info" role="status" aria-live="polite"></div>
		</div>
	</div>
</div>

<ng-template #cell let-col="col" let-item="item" let-index="index">
	<ng-container *ngTemplateOutlet="col.template; context: { data: item, index: index }">
	</ng-container>
	<div *ngIf="!col.template && (col.type !== 'lookup' || !col.allowEdit || (!item.editing && !editing))"
		class="input-container">
		<i *ngIf="(col.allowEdit && (item.editing || editing)) && (col.type === 'currency' || col.type === 'percent')"
			class="icon" [ngClass]="(col.type === 'currency') ? 'fa fa-dollar-sign' : 'fa fa-percent'"
			[style.padding-top.px]="(rowHeight - 13) / 2"></i>
		<input *ngIf="!(col.type === 'date' && col.allowEdit && (item.editing || editing))" type="text"
			[readonly]="!(col.allowEdit && (item.editing || editing))" class="grid-control"
			[class.editable-input-form]="col.allowEdit"
			[ngModel]="col.getValue(item, col.allowEdit && (item.editing || editing))" [style.height.px]="rowHeight"
			[ngClass]="(col.allowEdit && (item.editing || editing)) && (col.type === 'currency' || col.type === 'percent') ? 'with-icon' : ''"
			(change)="valueChanged($event, item, col)" [formControl]="col.getFormControl(index)">
		<div *ngIf="col.type === 'date' && col.allowEdit && (item.editing || editing)" class="input-group date">
			<input type="text" [matDatepicker]="datePicker" class="form-control"
				[formControl]="col.getFormControl(index)" [style.height.px]="rowHeight"
				[ngModel]="col.getValue(item, col.allowEdit && (item.editing || editing))"
				(dateChange)="valueChanged($event, item, col)">
			<mat-datepicker #datePicker></mat-datepicker>
			<div class="input-group-append">
				<span class="input-group-text">
					<mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
				</span>
			</div>
		</div>
	</div>
	<ng-select *ngIf="col.type === 'lookup' && col.allowEdit && (item.editing || editing)" [items]="col.lookupItems"
		class="datatable-lookup" [bindLabel]="col.bindLabel" [bindValue]="col.bindValue"
		(change)="valueChanged($event, item, col)" [virtualScroll]="true"
		[ngModel]="col.getValue(item, col.allowEdit && (item.editing || editing))" dropdownPosition="bottom"
		(open)="openLookup(col.getLookupIndex(editing ? index : 0, _columns))" appendTo="#external"
		[formControl]="col.getFormControl(index)" [style.width.%]="100">
		<ng-template ng-notfound-tmp let-searchTerm="searchTerm">
			<ng-container *ngTemplateOutlet="col.lookupNotFoundTemplate; context: { searchTerm: searchTerm }">
			</ng-container>
		</ng-template>
	</ng-select>
	<style type="text/css">
		ng-select .ng-select-container {
			height: 40px;
		}
	</style>
</ng-template>

<ng-template #colFilter let-col="col">
	<div class="input-container" *ngIf="col.subColumns.length == 0 && col.name" [style.min-width.px]="col.width"
		[style.max-width.px]="col.width">
		<span class="dropdown">
			<a href="#" class="btn btn-sm btn-clean btn-icon btn-icon-md" data-toggle="dropdown" aria-expanded="false">
				<i class="la la-search"></i>
			</a>
			<div class="dropdown-menu dropdown-menu-right" x-placement="bottom-end">
				<button class="dropdown-item" *ngIf="showStringFilter(col.type)" data-type="Contains"
					(click)="changeFilterType($event, col)">
					<i class="la la-search"></i>Contains</button>
				<button class="dropdown-item" *ngIf="showStringFilter(col.type)" data-type="NotContain"
					(click)="changeFilterType($event, col)">
					<i class="la la-search"></i>Does not contain</button>
				<button class="dropdown-item" *ngIf="showStringFilter(col.type)" data-type="StartsWith"
					(click)="changeFilterType($event, col)">
					<i class="la la-search"></i>Starts with</button>
				<button class="dropdown-item" *ngIf="showStringFilter(col.type)" data-type="EndsWith"
					(click)="changeFilterType($event, col)">
					<i class="la la-search"></i>Ends with</button>
				<button class="dropdown-item" *ngIf="showStringFilter(col.type) || showNumberFilter(col.type)"
					data-type="Equal" (click)="changeFilterType($event, col)"><i class="fa fa-equals"></i>Equal</button>
				<button class="dropdown-item" *ngIf="showStringFilter(col.type) || showNumberFilter(col.type)"
					data-type="NotEqual" (click)="changeFilterType($event, col)"><i class="fa fa-not-equal"></i>Does not
					equal</button>
				<button class="dropdown-item" *ngIf="showNumberFilter(col.type)" data-type="LessThan"
					(click)="changeFilterType($event, col)">
					<i class="fa fa-less-than"></i>Less than</button>
				<button class="dropdown-item" *ngIf="showNumberFilter(col.type)" data-type="GreaterThan"
					(click)="changeFilterType($event, col)"><i class="fa fa-greater-than"></i>Greater than</button>
				<button class="dropdown-item" *ngIf="showNumberFilter(col.type)" data-type="LessThanOrEqual"
					(click)="changeFilterType($event, col)"><i class="fa fa-less-than-equal"></i>Less than or equal
					to</button>
				<button class="dropdown-item" *ngIf="showNumberFilter(col.type)" data-type="GreaterThanOrEqual"
					(click)="changeFilterType($event, col)"><i class="fa fa-greater-than-equal"></i>Greater than or
					equal to</button>
			</div>
		</span>
		<input type="text" [style.height.px]="rowHeight" placeholder="Search..." (change)="filter($event, col)">
	</div>
	<div *ngIf="col.subColumns.length == 0 && !col.name" [style.min-width.px]="col.width"
		[style.max-width.px]="col.width"></div>
</ng-template>
<ng-template #columnsChooser let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">{{ 'GENERAL.TITLE.COLUMNS_CHOOSER' | translate }}</h4>
		<button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="row" cdkDropListGroup>
			<div class="col-md-6">
				<h5>{{ 'GENERAL.MESSAGES.VISIBLE_COLUMNS' | translate }}</h5>
				<div cdkDropList [cdkDropListData]="_columns | visibleColumns" class="columns-list"
					(cdkDropListDropped)="drop($event, true)">
					<div class="column-box" *ngFor="let col of _columns | visibleColumns" cdkDrag>
						{{ col.title || ((col.name || '').split(col.reg).join(' ') | titlecase) }}
					</div>
				</div>
			</div>
			<div class="col-md-6">
				<h5>{{ 'GENERAL.MESSAGES.HIDDEN_COLUMNS' | translate }}</h5>
				<div cdkDropList [cdkDropListData]="_columns | visibleColumns: false" class="columns-list"
					(cdkDropListDropped)="drop($event, false)">
					<div class="column-box" *ngFor="let col of _columns | visibleColumns: false" cdkDrag>
						{{ col.title || ((col.name || '').split(col.reg).join(' ') | titlecase) }}
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-secondary" (click)="modal.dismiss()">{{ 'GENERAL.BUTTONS.CLOSE' | translate
			}}</button>
	</div>
</ng-template>