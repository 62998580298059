<div id="kt_header" class="kt-header kt-grid kt-grid--ver kt-header--fixed" style="z-index: 20;">
	<div style="width: 16rem" class="kt-header__brand kt-grid__item " id="kt_header_brand">
		<div class="kt-header__brand-logo">
			<a routerLink="/">
				<img src="assets/images/landing/inventory-ahead-logo.svg">
			</a>
		</div>
	</div>
	<div class="kt-header-menu-wrapper kt-grid__item kt-grid__item--fluid" id="kt_header_menu_wrapper">
		<div id="kt_header_menu" class="kt-header-menu kt-header-menu-mobile kt-header-menu--layout-default">
			<ul class="kt-menu__nav">
				<li *ngIf="title" class="kt-menu__item kt-menu__item--active"><a class="kt-menu__link"><span
							class="kt-menu__link-text">{{title}}</span></a></li>
			</ul>
		</div>
	</div>
	<kt-topbar></kt-topbar>
</div>
