import { Component, Inject, ContentChildren, AfterContentInit, Input, ViewEncapsulation, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MenuItem } from '../menu-item/menu-item.components';
import { Router } from '@angular/router';

@Component({
	selector: 'kt-aside-left',
	templateUrl: './aside-left.component.html',
	styleUrls: ['./aside-left.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class AsideLeftComponent implements AfterContentInit {
	@ContentChildren(MenuItem) items: MenuItem[];
	toggled: {} = {};
	subToggled: {} = {};
	@Input() expendBar: boolean;
	expanded: boolean = false;
	isSticky: boolean = false;

	constructor(@Inject(DOCUMENT) private document: Document, public router: Router, private renderer: Renderer2) { }

	ngAfterContentInit(): void {
		for (let item of this.items)
			this.subToggled[item.id] = {};
	}

	stickAside() {
		this.isSticky = !this.isSticky;
		this.expanded ? this.minimize() : this.maximize();
	}

	maximize() {
		this.expanded = true;
		this.renderer.removeClass(this.document.body, 'kt-aside--minimize');
		this.renderer.addClass(this.document.body, 'kt-aside--minimizing');
	}
	
	minimize() {
		this.expanded = false;
		this.renderer.removeClass(this.document.body, 'kt-aside--minimizing');
		this.renderer.addClass(this.document.body, 'kt-aside--minimize');
	}

	toggle(selected: any) {
		for (let item of Object.keys(this.toggled)) {
			if (item != selected.title)
				this.toggled[item] = false;
		}
		this.toggled[selected.title] = !(this.toggled[selected.title] == true);
	}

	subToggle(main: any, selected: any) {
		let toggeled = this.subToggled[main.title];
		for (let item of Object.keys(toggeled)) {
			if (item != selected.title)
				toggeled[item] = false;
		}
		toggeled[selected.title] = !(toggeled[selected.title] == true);
	}

	hasUrl(item) {
		return item.subItems.find(sub => sub.url == this.router.url) ||
			item.subItems.find(sub => sub.subItems.find(sub2 => sub2.url == this.router.url));
	}
}
