import { ClassBasedEnumBase } from "src/app/libraries/class-based-enum.lib";
import { ProcessingStatus } from "./processing-status";

export class OrderProcessingStatus extends ClassBasedEnumBase {
    private constructor(
        public name: string,
        public value: number,
        public description?: string) {
        super()
    }

    static readonly None = new OrderProcessingStatus('None', ProcessingStatus.None.value, 'No order processing status');
    static readonly Canceled = new OrderProcessingStatus('Canceled', ProcessingStatus.Canceled.value, 'Order has been marked as Canceled');
    static readonly Pending = new OrderProcessingStatus('Pending', ProcessingStatus.Pending.value, 'Order is still Pending');
    static readonly OnHold = new OrderProcessingStatus('OnHold', ProcessingStatus.OnHold.value, 'Order has been marked as On Hold');
}