
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { KtDatePickerComponent } from './kt-date-picker.component';
import { MatDatepickerModule } from '@angular/material/datepicker';


@NgModule({
    declarations: [KtDatePickerComponent],
    imports: [
        CommonModule,
        MatDatepickerModule,
        ReactiveFormsModule,
        FormsModule,
    ],
    exports: [KtDatePickerComponent]
})
export class KtDatePickerModule {
}
