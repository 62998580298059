<loading [loading]="loading"></loading>
<div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
  <div class="kt-portlet">
    <div class="kt-portlet__body kt-portlet__body--fit">
      <div class="kt-grid kt-wizard-v3 kt-wizard-v3--white">
        <div class="kt-grid__item">
          <!--begin: Form Wizard Nav -->
          <div class="kt-wizard-v3__nav">
            <div class="kt-wizard-v3__nav-items">
              <div class="kt-wizard-v3__nav-item" [attr.data-ktwizard-state]="step == 1 ? 'current' : 'pending'">
                <div class="kt-wizard-v3__nav-body">
                  <div class="kt-wizard-v3__nav-label">
                    <span>1</span> Account Setup
                  </div>
                  <div class="kt-wizard-v3__nav-bar"></div>
                </div>
              </div>
              <ng-container *ngIf="!isInvited">
                <div class="kt-wizard-v3__nav-item" [attr.data-ktwizard-state]="step == 2 ? 'current' : 'pending'">
                  <div class="kt-wizard-v3__nav-body">
                    <div class="kt-wizard-v3__nav-label">
                      <span>2</span> Organization Details
                    </div>
                    <div class="kt-wizard-v3__nav-bar"></div>
                  </div>
                </div>
                <div class="kt-wizard-v3__nav-item" [attr.data-ktwizard-state]="step == 3 ? 'current' : 'pending'">
                  <div class="kt-wizard-v3__nav-body">
                    <div class="kt-wizard-v3__nav-label">
                      <span>3</span> Select Plan
                    </div>
                    <div class="kt-wizard-v3__nav-bar"></div>
                  </div>
                </div>                
              </ng-container>
            </div>
          </div>
          <!--end: Form Wizard Nav -->
        </div>
        <div class="kt-grid__item kt-grid__item--fluid kt-wizard-v3__wrapper">
          <!--begin: Form Wizard Form-->
          <form class="kt-form" novalidate="novalidate">
            <!--begin: Form Wizard Step 1-->
            <div class="kt-wizard-v3__content" data-ktwizard-type="step-content" *ngIf="registerForm"
              [formGroup]="registerForm" [attr.data-ktwizard-state]="step == 1 ? 'current' : 'pending'">
              <div class="kt-heading kt-heading--md">Enter your details to create your account:</div>
              <div class="kt-form__section kt-form__section--first">
                <div class="kt-wizard-v3__form">
                  <div class="form-group">
                    <label>Email</label>
                    <input type="text" class="form-control" formControlName="email">
                    <mat-error *ngIf="isControlHasError('email','required')">
                      <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
                    </mat-error>
                    <mat-error *ngIf="isControlHasError('email','email')">
                      <strong>{{ 'AUTH.VALIDATION.INVALID_FIELD' | translate }}</strong>
                    </mat-error>
                  </div>
                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>Password</label>
                        <input type="password" class="form-control" formControlName="password">
                      </div>
                      <div class="form-group">
                        <label>Confirm password</label>
                        <input type="password" class="form-control" formControlName="confirmPassword">
                        <mat-error *ngIf="isControlHasError('confirmPassword','required')">
                          <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
                        </mat-error>
                        <mat-error *ngIf="isControlHasError('confirmPassword','minlength')">
                          <strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
                        </mat-error>
                        <mat-error *ngIf="isControlHasError('confirmPassword','maxlength')">
                          <strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 320</strong>
                        </mat-error>
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <mat-error *ngIf="isControlHasError('password','required')">
                        <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
                      </mat-error>
                      <mat-error *ngIf="isControlHasError('password','pattern')">
                        <strong>Password must be at least 8 characters long and contain</strong>
                        <ul>
                          <li>at least one lowercase letter</li>
                          <li>at least one uppercase letter</li>
                          <li>at least one number</li>
                          <li>at least one non-alphanumeric character <i class="fas fa-info-circle"
                              data-toggle="tooltip" data-placement="top"
                              data-original-title="~`!@#$%^&amp;*()-_+={}[]|\;:&quot;<>,./?"></i></li>
                        </ul>
                      </mat-error>
                      <mat-error *ngIf="isControlHasError('password','minlength')">
                        <strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
                      </mat-error>
                      <mat-error *ngIf="isControlHasError('password','maxlength')">
                        <strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 320</strong>
                      </mat-error>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>First name</label>
                        <input type="text" class="form-control" formControlName="firstName">
                        <mat-error *ngIf="isControlHasError('firstName','required')">
                          <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
                        </mat-error>
                        <mat-error *ngIf="isControlHasError('firstName','minlength')">
                          <strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
                        </mat-error>
                        <mat-error *ngIf="isControlHasError('firstName','maxlength')">
                          <strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 320</strong>
                        </mat-error>
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>Last name</label>
                        <input type="text" class="form-control" formControlName="lastName">
                        <mat-error *ngIf="isControlHasError('lastName','required')">
                          <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
                        </mat-error>
                        <mat-error *ngIf="isControlHasError('lastName','minlength')">
                          <strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
                        </mat-error>
                        <mat-error *ngIf="isControlHasError('lastName','maxlength')">
                          <strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 320</strong>
                        </mat-error>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Phone</label>
                    <input class="form-control" type="number" placeholder="Phone Number" name="phoneNumber"
                      formControlName="phoneNumber">
                    <mat-error *ngIf="isControlHasError('phoneNumber','required')">
                      <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
                    </mat-error>
                    <mat-error *ngIf="isControlHasError('phoneNumber','minlength')">
                      <strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
                    </mat-error>
                    <mat-error *ngIf="isControlHasError('phoneNumber','maxlength')">
                      <strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 320</strong>
                    </mat-error>
                  </div>
                  <div class="kt-login__extra">
                    <label class="kt-checkbox kt-checkbox--brand"
                      [class.kt-checkbox--danger]="registerForm.controls.agree.touched && !registerForm.value.agree">
                      <input type="checkbox" name="agree" formControlName="agree">
                      I Agree the <a class="kt-font-brand" (click)="openModal(termsAndConditions)">Terms and conditions</a>.
                      <span></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <!--end: Form Wizard Step 1-->
            <!--begin: Form Wizard Step 2-->
            <div class="kt-wizard-v3__content" data-ktwizard-type="step-content"
              [attr.data-ktwizard-state]="step == 2 ? 'current' : 'pending'" *ngIf="addressForm" [formGroup]="addressForm">
              <div class="kt-heading kt-heading--md">Enter the Details of your Organization</div>
              <div class="kt-form__section kt-form__section--first">
                <div class="kt-wizard-v3__form">
                  <div class="form-group">
                    <label>Organization name</label>
                    <input type="text" class="form-control" formControlName="companyName">
                  </div>
                  <div class="form-group">
                    <label>Address</label>
                    <input type="text" class="form-control" formControlName="addressLine1">
                  </div>
                  <div class="form-group">
                    <label>Appartment, Suite, Etc.</label>
                    <input type="text" class="form-control" formControlName="addressLine2">
                  </div>
                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>City</label>
                        <input type="text" class="form-control" formControlName="city">
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>Zip Code</label>                    
                        <input type="text" class="form-control" formControlName="postalCode">
                      </div> 
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>Country</label>
                        <select class="form-control" formControlName="countryCode">
                          <option *ngFor="let country of countries.list" [value]="country">
                            {{ 'COUNTRIES.' + country + '.NAME' | translate }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>State/Province</label>
                        <select class="form-control" formControlName="stateCode" *ngIf="countries.getStates(addressForm.value.countryCode).length">
                          <option *ngFor="let state of countries.getStates(addressForm.value.countryCode)" [value]="state">
                            {{ 'COUNTRIES.' + addressForm.value.countryCode + '.STATES.' + state | translate }}
                          </option>
                        </select>
                        <input type="text" class="form-control" formControlName="stateCode" *ngIf="countries.getStates(addressForm.value.countryCode).length === 0">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--end: Form Wizard Step 2-->
            <!--begin: Form Wizard Step 3-->
            <div class="kt-wizard-v3__content" data-ktwizard-type="step-content" *ngIf="billingForm"
              [formGroup]="billingForm" [attr.data-ktwizard-state]="step == 3 ? 'current' : 'pending'">
              <div class="row">
                <div class="col-xl-9 kt-padding-15">
                  <div class="kt-heading kt-heading--md">Select a plan</div>
                  <div class="kt-form__section kt-form__section--first">
                    <div class="kt-wizard-v3__form">
                      <div class="d-flex">
                        <div class="no-plan">
                          <div class="number">Users</div>
                          <div class="number">SKU's</div>
                          <div class="number">Orders a month</div>
                        </div>
                        <div class="all-plans">
                          <div class="plan kt-label-font-color-3" *ngFor="let plan of pricingPlans.plans"
                            [class.selected]="billingForm.value.planStripId == plan.stripeId">
                            <div class="plan-title kt-label-font-color-1">{{plan.name}}</div>
                            <div class="price-container">
                              <div class="price-line">
                                <div class="doller-sign" *ngIf="plan.monthlyPrice">$</div>
                                <div class="plan-price" *ngIf="plan.monthlyPrice">{{ plan.monthlyPrice | number }}</div>
                                <div class="plan-price" *ngIf="!plan.monthlyPrice">-</div>
                                <div class="per-month">/ month</div>
                              </div>
                            </div>
                            <div class="kt-padding-15">
                              <button class="btn w-100" [disabled]="billingForm.value.planStripId == plan.stripeId"
                                (click)="billingForm.controls.planStripId.setValue(plan.stripeId)"
                                [ngClass]="billingForm.value.planStripId == plan.stripeId ? 'btn-secondary' : 'btn-outline-secondary'">
                                {{billingForm.value.planStripId == plan.stripeId ? 'Selected' : 'Select'}}
                              </button>
                            </div>
                            <div class="number dark">{{plan.allowedUsers | number}}</div>
                            <div class="number">{{plan.allowedSkus | number}}</div>
                            <div class="number dark">{{plan.allowedOrders | number}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="kt-heading kt-heading--md hr-t kt-padding-t-15">Or create your custom plan</div>
                  <div class="row">
                    <div class="col-4 kt-padding-15">
                      <ngx-slider [value]="billingForm.value.allowedUsers" [options]="usersOptions"></ngx-slider>
                      <ngx-slider [value]="billingForm.value.allowedSkus" [options]="skusOptions"
                        (userChangeEnd)="skusSliderChanged($event)"></ngx-slider>
                      <ngx-slider [value]="billingForm.value.allowedOrders" [options]="ordersOptions"
                        (userChangeEnd)="ordersSliderChanged($event)"></ngx-slider>
                    </div>
                    <div class="col-8">
                      <div class="custom-plan dark" [class.selected]="!billingForm.value.planStripId">
                        <div>
                          <div class="kt-padding-20">
                            <span class="strong">Users</span>
                          </div>
                          <div class="kt-padding-20">
                            <span class="strong">SKUs</span>
                          </div>
                          <div class="kt-padding-20">
                            <span class="strong">Orders</span>
                          </div>
                        </div>
                        <div class="custom-numbers">
                          <div class="kt-padding-10">
                            <input class="form-control" [value]="billingForm.value.allowedUsers  | number" readonly>
                          </div>
                          <div class="kt-padding-10">
                            <input class="form-control" [value]="billingForm.value.allowedSkus | number" readonly>
                          </div>
                          <div class="kt-padding-10">
                            <input class="form-control" [value]="billingForm.value.allowedOrders | number" readonly>
                          </div>
                        </div>
                        <div class="w-100">
                          <div class="price price-container">
                            <div class="plan-price">Custom</div>
                            <div class="price-line">
                              <div class="doller-sign" *ngIf="customPrice">$</div>
                              <div class="plan-price" *ngIf="customPrice">{{ customPrice | number }}</div>
                              <div class="plan-price" *ngIf="!customPrice">-</div>
                            </div>
                            <div class="kt-padding-15">
                              <button class="btn w-100" [disabled]="!billingForm.value.planStripId"
                                (click)="billingForm.controls.planStripId.setValue(null)"
                                [ngClass]="!billingForm.value.planStripId ? 'btn-secondary' : 'btn-outline-secondary'">
                                {{!billingForm.value.planStripId ? 'Selected' : 'Select'}}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-3 selected-plan">
                  <div class="plan-title kt-label-font-color-1">Selected Plan</div>
                  <div class="dark kt-margin-t-15 kt-padding-15">
                    <div class="plan-title kt-label-font-color-1">{{selectedPlan?.name || 'Custom'}}</div>
                    <div class="price-container">
                      <div class="price-line">
                        <div class="doller-sign" *ngIf="selectedPlan?.monthlyPrice || customPrice">$</div>
                        <div class="plan-price" *ngIf="selectedPlan?.monthlyPrice || customPrice">
                          {{ selectedPlan?.monthlyPrice || customPrice | number }}
                        </div>
                        <div class="plan-price" *ngIf="!(selectedPlan?.monthlyPrice || customPrice)">-</div>
                        <div class="per-month">/ month</div>
                      </div>
                    </div>
                  </div>
                  <div class="kt-margin-t-15 kt-margin-b-15">CHOOSE YOUR BILLING</div>
                  <div class="billing-option" [class.selected]="billingForm.value.billing === 'monthly'">
                    <label class="kt-radio kt-radio--bold" [class.kt-radio--brand]="billingForm.value.billing === 'monthly'">
                      <input #monthly type="radio" name="billing" value="monthly" formControlName="billing"> Monthly
                      <span></span>
                    </label>
                    <label class="right">
                      <span class="strong" *ngIf="selectedPlan?.monthlyPrice || customPrice">
                        ${{ selectedPlan?.monthlyPrice || customPrice | number }}
                      </span>
                      <span *ngIf="!(selectedPlan?.monthlyPrice || customPrice)">-</span>
                      /month
                    </label>
                  </div>
                  <!-- <div class="billing-option" [class.selected]="billingForm.value.billing == 'yearly'">
                    <label class="kt-radio kt-radio--bold" [class.kt-radio--brand]="billingForm.value.billing == 'yearly'">
                      <input #yearly type="radio" name="billing" value="yearly" formControlName="billing"> Yearly
                      <span></span>
                    </label>
                    <label class="right">
                      <span class="strong">${{(selectedPlan?.monthlyPrice || customPrice)*12 | number}}</span>/year
                    </label>
                  </div> -->
                  <div class="kt-margin-t-15 kt-margin-b-15">ADD YOUR CREDIT CARD DETAILS</div>
                  <div class="form-group">
                    <label>CC Number</label>
                    <div class="credit-card">
                      <ngx-stripe-card *ngIf="stripeInstance" [options]="cardOptions" [stripe]="stripeInstance">
                      </ngx-stripe-card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--end: Form Wizard Step 3-->
            <!--begin: Form Actions -->
            <div class="kt-form__actions">
              <div></div>
              <button class="btn btn-brand btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u" (click)="submit()">
                {{step == 3 || isInvited ? 'Submit and sign up' : 'Next Step'}}
              </button>
            </div>
            <!--end: Form Actions -->
          </form>
          <!--end: Form Wizard Form-->
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #termsAndConditions let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">INVENTORY AHEAD TERMS & CONDITIONS</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="text-start">
      <p>
        <strong>Welcome.</strong>&nbsp;As part of the Service, inventory ahead will provide you with use of the Service.
        Your registration for, or use of the Service shall be deemed to be your agreement to abide by this Agreement.
      </p>
      <p>
        <strong>Service Fees and Payments.</strong>&nbsp;You are agreeing to subscribe to inventory ahead Service on a
        monthly basis. You will be charged each billing period the Service Fees in effect posted on inventory ahead's
        website. Your subscription will continue and renew automatically unless terminated as per the "Termination"
        section below. You agree to pay all charges for the current billing period at the time of cancellation, whether
        or not the current billing period is only partially complete. All fees and charges are nonrefundable. inventory
        ahead may change the Service Fees then in effect, or add new fees or charges, by giving You notice in advance.
        Continued use of the Service after any such fee changes shall constitute your consent to the new fees.
      </p>
      <p>
        <strong>Postage.</strong>&nbsp;You will provide to the Company access to your carrier accounts, including USPS,
        DHL Express, etc. ("Customer Postage Accounts"). You are responsible for all shipping charges and will deal
        directly with the carrier yourself in connection with any cancellations, refunds or credits.
      </p>
      <p>
        <strong>Postage Account Registration.</strong>&nbsp;You agree to provide your authentic name, company, address
        and contact information when you register your Customer Postage Account with the Company, and have such
        information transmitted to the carriers. You agree to pay and be held liable for any sur-charges that the
        carriers may issue on the labels you printed, including any penalties, as part of under reporting of weight,
        size, or as part of other carrier rules.
      </p>
      <p>
        <strong>Charges and Credit Card.</strong>&nbsp;inventory ahead charges your credit card each billing period Your
        Service Fees for that period. You must provide inventory ahead with a valid credit card number belonging to you
        with available credit sufficient to pay the applicable Service Fees. In the event that you cancel this credit
        card or it is otherwise terminated, you must immediately provide us with a new valid credit card number.
      </p>
      <p>
        <strong>Non-Payment.</strong>&nbsp;If timely payment is not received or cannot be charged to your credit card
        for any reason, inventory ahead reserves the right to either suspend or terminate your access to the Service and
        account and terminate this Agreement. If inventory ahead receives a cancellation notice from you, you will be
        obligated to pay balance due on your account. You agree that inventory ahead may charge such unpaid fees to your
        credit card or otherwise bill you for such unpaid fees.
      </p>
      <p>
        <strong>Contact and Billing Information.</strong>&nbsp;It is your responsibility to keep valid contact and
        billing information on your account. inventory ahead will send out payment issue notices regarding your account
        in accordance with the contact information you have provided upon registration. To avoid service interruption
        due to non-payment issues, please ensure all contact information is current under the "Administration/Billing"
        section of the inventory ahead portal.
      </p>
      <p>
        <strong>Free Trials.</strong>&nbsp;To the extent inventory ahead offers new subscribers a one-time free trial to
        test the Service, each new subscriber is limited to only one trial account. Additional accounts registered by
        the subscriber will not have free trial periods. inventory ahead reserves the right to discontinue or modify the
        terms of free or promotional offer at our discretion.
      </p>
      <p>
        <strong>Restrictions.</strong>&nbsp;You are permitted to store, manipulate, analyze, reformat, print, and
        display any information or content displayed on inventory ahead’s website or provided through its Service
        ("Content") only for your personal use. Unauthorized use of the Service, or the resale of the Services, is
        expressly prohibited. You shall not copy, license, sell, transfer, make available, distribute, or assign this
        license or the Content to any third party. You also shall not create Internet "link" to the Service or "frame"
        or "mirror" any Content contained on, or accessible from, this Service on any other server or Internet-based
        device.
      </p>
      <p>
        <strong>User Accounts.</strong>&nbsp;A user account is required to access the Service and may be accessed and
        used only by those authorized individuals who are registered with inventory ahead. You are solely responsible
        for any and all activities that occur under your account and ensuring that you exit or log-off from your account
        at the end of each session of use. You shall notify inventory ahead immediately of any unauthorized use of your
        password or account or any other breach of security that is known or suspected by you. inventory ahead shall not
        be responsible for any unauthorized access to your account.
      </p>
      <p>
        <strong>License Grant.</strong> Subscriber may not sublicense or rent the Services to others. Subscriber may use
        the Services for its internal business purposes only. Subscriber may not timeshare the Services with another
        entity. Subscriber may not reverse engineer or decompile the Services. Subscriber may not modify the Services or
        make derivative works from the Services. Subscriber may not use the Services to create similar software or
        services. Subscriber shall not use the Service that Subscriber directly or indirectly licenses to others or to
        perform consulting services for others. Subscriber shall treat the Services as Confidential Information. The
        Parties agree the Agreement constitutes a license, not a sale, of the Services. Any attempt by Subscriber to
        transfer the license to the Services, except as expressly permitted by the Agreement or permitted in writing by
        Company, shall cause immediate termination of the Subscription.
      </p>
      <p>
        <strong>Account Information.</strong>&nbsp;inventory ahead does not own and will not monitor, edit, or disclose
        any information regarding you or your account without your prior permission except in accordance with this
        Agreement. Please be aware that inventory ahead does provide certain user registration and statistical
        information such as usage or user traffic patterns in aggregate form to third parties, but such information will
        not include personally identifying information and your Internet protocol address is not transmitted with each
        message sent from your inventory ahead account. inventory ahead may access your account to respond to service or
        technical problems or as stated in this Agreement.
      </p>
      <p>
        <strong>User Conduct.</strong>&nbsp;You agree to abide by all applicable local, state, national and foreign
        laws, treatises and regulations in connection with the Service. In addition, without limitation, you agree not
        to use the Service to: (a) interfere with or disrupt servers or networks connected to the Service or violate the
        regulations, policies or procedures of such networks; (b) attempt to gain unauthorized access to the Service,
        other accounts, computer systems or networks connected to the Service, through password mining or any other
        means; or (c) harass or interfere with another user's use and enjoyment of the Service.
      </p>
      <p>
        <strong>Intellectual Property Ownership; Trade Secret Property.</strong>&nbsp;The Services and any associated
        software or designs (the "Software") are and shall remain the exclusive Intellectual Property of inventory
        ahead. Subscriber does not acquire any ownership stake in the Software or Services because of this Agreement.
        Subscriber acknowledges, shall not challenge, and shall not voluntarily aid any third party in challenging
        inventory ahead’s Intellectual Property ownership of the Software and Services. inventory ahead will own and
        have all rights to any suggestions, recommendations or improvements to the Software or Services described by
        Subscriber, unless otherwise agreed to in writing.
      </p>
      <p>
        <strong>Termination.</strong>&nbsp;inventory ahead, in its sole discretion, may terminate your password, account
        or use of the Service and remove and discard any Data within the Service if you fail to comply with this
        Agreement. You may terminate your subscription by sending email request to inventory ahead Support with your
        inventory ahead Account Name. If you terminate your account at any time, you may be liable for any balance of
        your current subscription, depending on your plan type, and will not receive a refund of any part of the
        subscription rate if you terminate your account during a billing cycle or plan period. In addition, inventory
        ahead may terminate a free account if you do not first log-on within 15 days after registration. Upon
        termination of an account, your right to use such account and the Service immediately ceases. inventory ahead
        shall have no obligation to maintain any data stored in your account or to forward any data to you or any third
        party.
      </p>
      <p>
        <strong>Authorization.</strong>&nbsp;In your use of the Service, you may allow inventory ahead to access your
        third party accounts, such as Amazon or Ebay. You authorize inventory ahead to access your account as necessary
        in connection with the Service.
      </p>
      <p>
        <strong>Third Party Interaction.</strong>&nbsp;In your use of the Service, you may enter into correspondence
        with, purchase goods and/or services, or participate in promotions of advertisers or sponsors showing their
        goods and/or services through the Service. Any such activity, and any terms, conditions, warranties or
        representations associated with such activity, is solely between you and the applicable third-party. inventory
        ahead shall have no liability, obligation or responsibility for any such correspondence, purchase or promotion
        between you and any such third-party.
      </p>
      <p>
        <strong>Links to Third Party Sites.</strong>&nbsp;inventory ahead does not endorse any sites on the Internet
        which are linked through the Service. inventory ahead is providing these links to you only as a matter of
        convenience, and in no event shall inventory ahead be responsible for any content, products, or other materials
        on or available from such sites.
      </p>
      <p>
        <strong>Proprietary Rights.</strong>&nbsp;Except for the licenses granted herein, you have no right, title or
        interest in or to the Service or any Content and inventory ahead or its licensors. inventory ahead and/or other
        inventory ahead products and services referenced herein are either trademarks or registered trademarks of
        inventory ahead. The names of other companies and products mentioned herein may be the trademarks of their
        respective owners.
      </p>
      <p>
        <strong>Indemnification.</strong>&nbsp;You shall indemnify and hold inventory ahead, its licensors, and each
        such party's parents, subsidiaries, affiliates, officers, directors, employees, attorneys, and agents, harmless
        from and against any and all claims, costs, damages, losses, liabilities, and expenses (including attorneys'
        fees and costs) arising out of or in connection with your use of the Service (including the Content) or breach
        of this Agreement.
      </p>
      <p>
        <strong>Disclaimer of Warranties.</strong>&nbsp;INVENTORY AHEAD AND ITS LICENSORS, MAKE NO REPRESENTATION,
        WARRANTY, OR GUARANTY AS TO THE RELIABILITY, TIMELINESS, QUALITY, SUITABILITY, TRUTH, AVAILABILITY, ACCURACY OR
        COMPLETENESS OF THE SERVICE OR ANY CONTENT. INVENTORY AHEAD AND ITS LICENSORS DO NOT REPRESENT OR WARRANT THAT:
        (I) THE USE OF THE SERVICE WILL BE SECURE, TIMELY, UNINTERRUPTED OR ERROR-FREE OR OPERATE IN COMBINATION WITH
        ANY OTHER HARDWARE, SOFTWARE, SYSTEM OR DATA, (II) THE SERVICE WILL MEET YOUR EXPECTATIONS OR NEEDS; (III) DATA,
        FORECASTS OR OTHER INFORMATION PROVIDED BY COMPANY WILL BE ACCURATE OR RELIABLE; (IV) ANY STORED DATA WILL BE
        ACCURATE OR RELIABLE, (V) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR
        OBTAINED BY YOU THROUGH THE SERVICE WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS, (VI) ERRORS OR DEFECTS WILL BE
        CORRECTED, (VII) THE SERVICE OR THE SERVER(S) THAT MAKE THE SERVICE AVAILABLE ARE FREE OF VIRUSES OR OTHER
        HARMFUL COMPONENTS. THE SERVICE AND ALL CONTENT IS PROVIDED TO YOU STRICTLY ON AN "AS IS" BASIS. ALL CONDITIONS,
        REPRESENTATIONS AND WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, WITHOUT LIMITATION,
        ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT OF THIRD PARTY
        RIGHTS, ARE HEREBY DISCLAIMED TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW BY INVENTORY AHEAD AND ITS
        LICENSORS.
      </p>
      <p>
        <strong>Limitation of Liability.</strong>&nbsp;IN NO EVENT SHALL INVENTORY AHEAD’S AGGREGATE LIABILITY EXCEED
        THE AMOUNT ACTUALLY PAID BY YOU IN THE ONE MONTH PERIOD IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO SUCH
        CLAIM. IN NO EVENT SHALL INVENTORY AHEAD AND/OR ITS LICENSORS BE LIABLE TO ANYONE FOR ANY INDIRECT, PUNITIVE,
        SPECIAL, EXEMPLARY, INCIDENTAL, CONSEQUENTIAL OR OTHER DAMAGES OF ANY TYPE OR KIND (INCLUDING LOSS OF DATA,
        REVENUE, PROFITS, USE OR OTHER ECONOMIC ADVANTAGE) ARISING OUT OF, OR IN ANY WAY CONNECTED WITH THIS SERVICE,
        INCLUDING BUT NOT LIMITED TO YOUR USE OR INABILITY TO USE THE SERVICE, OR FOR ANY CONTENT OBTAINED FROM OR
        THROUGH THE SERVICE, EVEN IF INVENTORY AHEAD OR ITS LICENSORS HAVE BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF
        SUCH DAMAGES.
      </p>
      <p>
        <strong>Submissions.</strong>&nbsp;inventory ahead alone will own all right, title and interest, including all
        related intellectual property rights, to any suggestions, ideas, feedback, recommendations, or other information
        provided by you relating to the Service ("Submissions") and you agree to assign such Submissions to inventory
        ahead free of charge. inventory ahead may use such Submissions as it deems appropriate in its sole discretion.
      </p>
      <p>
        <strong>Notice.</strong>&nbsp;inventory ahead may give notice by means of a general notice on the Service,
        electronic mail to your e-mail address on record in inventory ahead's account information, or by written
        communication sent by first class mail to your address on record in inventory ahead's account information. You
        may give notice to inventory ahead (such notice shall be deemed given when received by inventory ahead) at any
        time by any of the following: electronic mail to <a
          href="mailto:support@inventoryahead.com">support&#64;inventoryahead.com</a>; or form the Support option on <a
          href="https://www.inventoryahead.com">https://www.inventoryahead.com</a>.
      </p>
      <p>
        <strong>Modification to Terms.</strong>&nbsp;inventory ahead reserves the right to change the terms and
        conditions of this Agreement or its policies relating to the Service at any time and shall notify you by posting
        an updated version of this Agreement on the Service. You are responsible for regularly reviewing this Agreement.
        Continued use of the Service after any such changes shall constitute your consent to such changes.
      </p>
      <p>
        <strong>Beneficiaries.</strong>&nbsp;The rights and limitations in this Agreement are also for the benefit of
        inventory ahead's licensors each of whom shall have the right to enforce its rights hereunder directly and on
        its own behalf.
      </p>
      <p>
        <strong>General.</strong>&nbsp;This Agreement will be governed by New York law and controlling United States
        federal law, without regard to the choice or conflicts of law provisions of any jurisdiction. You shall bring
        all disputes, actions, claims, or causes of action related to this Agreement or in connection with the Service
        by binding arbitration before an arbitration panel appointed under the rules of the American Arbitration
        Association. Such arbitration shall be on an individual, non-class basis. To the extent the amount in
        controversy is less than $50,000, a single arbitrator shall decide the dispute. No text or information set forth
        on any other purchase order, preprinted form or document shall add to or vary the terms and conditions of this
        Agreement. The English language version of this Agreement shall control. If any provision of this Agreement is
        held by a court of competent jurisdiction to be invalid or unenforceable, then such provision(s) shall be
        construed, as nearly as possible, to reflect the intentions of the invalid or unenforceable provision(s), with
        all other provisions remaining in full force and effect. `The failure of inventory ahead to enforce any right or
        provision in this Agreement shall not constitute a waiver of such right or provision unless acknowledged and
        agreed to by inventory ahead in writing. This Agreement comprises the entire agreement between you and inventory
        ahead and supersedes all prior or contemporaneous negotiations, discussions or agreements, whether written or
        oral, between the parties regarding the subject matter contained herein.
      </p>
      <p>
        <strong>Time Limitations.</strong> Each Party shall assert each Claim against the other Party arising from or
        related to the Services within two years of the later of the accrual of the Claim. A counterclaim is not barred
        if the Claim filed by the other Party is timely under this section. Otherwise, all Claims not made within the
        time provided by this section are waived and barred.
      </p>
      <p>
        <strong>Force Majeure</strong>&nbsp;Company shall not be liable for any failure or delay caused by events beyond
        its reasonable control.
      </p>
      <p>
        <strong>Headings.</strong>&nbsp;The headings in this Agreement are only finding aids; they shall not be
        considered in interpreting this Agreement.
      </p>
    </div>
  </div>
</ng-template>
