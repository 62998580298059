import { DeliveryExperience } from "src/app/_enums/general/delivery-experience.enum";
import { ShippingServiceMethod } from "../../shipping-rule.model";
import ShippingLabelModel from "./shipping-label.model";
import {BaseModel} from '../../_base.model';

export default class ShipmentModel extends BaseModel{
    id?: number;
    orderBoxIds: number[];
    shippingAccountId: number;
    deliveryExperience: DeliveryExperience = DeliveryExperience.Unspecified;
    shippingServiceMethod: ShippingServiceMethod;
    shippingLabels: ShippingLabelModel[];
    shippingRuleId: number;
}
