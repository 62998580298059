import { Injectable } from '@angular/core';
import { NgxsOnChanges, NgxsSimpleChange, State } from '@ngxs/store';
import { OrderModel } from 'src/app/_models/orders/order.model';
import { OrdersFiltersModel, OrderSortBy } from 'src/app/_models/self-ship/orders/orders-filters.model';
import OrdersFiltersState from './orders-filters/orders-filters.state';
import * as _ from 'lodash';
import Product from 'src/app/_entities/products/product.entity';
import Order from 'src/app/_entities/orders/order.entity';
import StoreEntity from 'src/app/_entities/stores/store.entity';
import OrderItem from 'src/app/_entities/orders/order-item.entity';
import { HubClientMethods } from 'src/app/_services/real-time-connection/real-time-connection.constants';
import { SortOrder } from 'src/app/_enums/general/sort-order.enum';
import { RealTimeService } from 'src/app/_services/real-time-connection/real-time.service';
import { OrderBoxModel } from 'src/app/_models/orders/order-boxes/order-box.model';
import { OrderBoxItemModel } from 'src/app/_models/orders/order-boxes/order-box-item.model';
import { OrdersService } from 'src/app/_services/orders.service';
import { OrderProcessingStatus } from 'src/app/_entities/orders/processing-statuses/order-processing-status';
import { ProcessingStatus } from 'src/app/_entities/orders/processing-statuses/processing-status';
import PickListItem from 'src/app/_entities/pick-lists/pick-list-item.entity';
import { OrdersFetchFilters } from 'src/app/_services/self-ship/self-ship-orders.service';
import OrderBox from '../../_entities/orders/order-boxes/order-box.entity';
import { Computed, DataAction, StateRepository } from '@angular-ru/ngxs/decorators';
import { NgxsDataEntityCollectionsRepository } from '@angular-ru/ngxs/repositories';
import { createEntityCollections, EntityIdType } from '@angular-ru/cdk/entity';
import BaseEntityCollectionsOptions from '../base-entity-collections-options';
import EntityMap from '../../_builders/entity-map';
import IEntityMap, { EntityMapId } from '../../_builders/i-entity-map';
import StoresEntitiesState from '../stores/stores-entities.state';
import EntityMapper from '../../_builders/entity.mapper';
import IEntityChange from '../../_interfaces/i-entity-change';
import { tap } from 'rxjs/operators';
import { StatesHelper } from '../states.helper';
import { IN_PROCESS_FILTERS } from 'src/app/_entities/orders/processing-statuses/order-filter-status';

export type DisplayedPicklistItem = Partial<PickListItem> & { product: Product, warehouseId: number; };

@StateRepository()
@State({
    name: 'orders',
    defaults: {
        ...createEntityCollections(),
        loading: false
    }
})
@Injectable()
export default class OrdersEntitiesState
    extends NgxsDataEntityCollectionsRepository<OrderModel, EntityIdType, BaseEntityCollectionsOptions>
    implements NgxsOnChanges {

    private _changeObserver: typeof this.state$;

    constructor(
        private realtimeService: RealTimeService,
        private ordersService: OrdersService,
        private storeEntitiesState: StoresEntitiesState,
        private ordersFiltersState: OrdersFiltersState
    ) {
        super();
    }

    ngxsOnChanges(_?: NgxsSimpleChange) {
        super.ngxsOnChanges(_);
        if (this.isInitialised && !this._changeObserver) {
            this._changeObserver = this.state$;
            this._changeObserver.subscribe(() => this._ngxsOnChange(this));
        }
    }

    @Computed()
    public get isLoading(): boolean {
        return this.snapshot.loading && !this.snapshot.loaded;
    }

    @Computed()
    public get entityById(): (id: number) => Order {
        return (id: number) => StatesHelper.getEntity(this, id, Order);
    }

    @Computed()
    public get orderEntities(): Order[] {
        return this.entitiesArray
            .map(order => new Order(order));
    }

    // Computed
    @Computed()
    public get ordersEntityMap(): EntityMap<Order> {
        return new EntityMap<Order>(this.ids.reduce((entityResults, id) => {
            entityResults[id] = new Order(this.entities[id]);
            return entityResults;
        }, {}));
    }

    @Computed()
    public get itemEntitiesOfFiltered(): OrderItem[] {
        return this._filterOrderEntities(this.orderEntities, this.ordersFiltersState.snapshot)
            .flatMap(order => order.orderItemEntities);
    }

    @Computed()
    public get openOrders(): EntityMap<Order> {
        const filters = new OrdersFiltersModel;
        filters.orderStatuses = [ProcessingStatus.Open];
        return new EntityMap<Order>(
            EntityMapper.mapById(this._filterOrderEntities(this.orderEntities, filters))
        );
    }

    @Computed()
    public get packedOrders(): EntityMap<Order> {
        const filters = new OrdersFiltersModel();
        filters.orderStatuses = [ProcessingStatus.Packed];
        return new EntityMap<Order>(
            EntityMapper.mapById(this._filterOrderEntities(this.orderEntities, filters))
        );
    }
    
    @Computed()
    public get inProcessOrders(): EntityMap<Order> {
        const filters = new OrdersFiltersModel();
        filters.orderStatuses = IN_PROCESS_FILTERS;
        return new EntityMap<Order>(
            EntityMapper.mapById(this._filterOrderEntities(this.orderEntities, filters))
        );
    }

    @Computed()
    public get shippedOrders(): EntityMap<Order> {
        const filters = new OrdersFiltersModel();
        filters.orderStatuses = [ProcessingStatus.Shipped];
        return new EntityMap<Order>(
            EntityMapper.mapById(this._filterOrderEntities(this.orderEntities, filters))
        );
    }

    @Computed()
    public get filteredReadyToShipPercentage(): number {
        if (!this.filteredEntities.length) {
            return 0;
        }
        const ordersReadyToShip = this.filteredEntities
            .filter(order => !!(order.latestProcessingStatus.value & ProcessingStatus.ReadyToShip.value));
        return Math.round((ordersReadyToShip.length * 100) / this.filteredEntities.length);
    }

    @Computed()
    public get filteredOfAllStores(): Order[] {
        return this.filteredOfStores(...this.storeEntitiesState.ids as number[]);
    }

    @Computed()
    public get storesWithOrders(): EntityMap<StoreEntity> {
        const desiredStoreIds = Array.from(new Set(this.entitiesArray.map(o => o.storeId)));
        return this.storeEntitiesState
            .storesEntityMap
            .subset(...desiredStoreIds as unknown as EntityMapId<StoreEntity>[]);
    }

    @Computed()
    public get filteredEntities(): Order[] {
        return this._filterOrderEntities(this.orderEntities, this.ordersFiltersState.snapshot);
    }

    @Computed()
    public get filteredEntityMap(): EntityMap<Order> {
        return new EntityMap<Order>(
            EntityMapper.mapById(this._filterOrderEntities(this.orderEntities, this.ordersFiltersState.snapshot))
        );
    }

    @Computed()
    public get storesWithOpenOrders(): EntityMap<StoreEntity> {
        const filters = new OrdersFiltersModel();
        filters.orderStatuses = [ProcessingStatus.Open];
        const desiredStoreIds = Array.from(new Set(this.openOrders.map(o => o.storeId)));

        return this.storeEntitiesState
            .storesEntityMap
            .subset(...desiredStoreIds as unknown as EntityMapId<StoreEntity>[]);
    }

    @Computed()
    public get selectedEntities() {
        const selectedIds = Object.keys(this.ordersFiltersState.snapshot.selected);
        return selectedIds.map(id => new Order(this.entities[+id]));
    }

    @Computed()
    public get isAllSelected(): boolean {
        return Object.keys(this.ordersFiltersState.snapshot.selected).length === this.filteredEntities.length;
    }

    @Computed()
    public get emptyStoreOrdersToFill(): null[] {
        const minNumberOfTilesToFill = 7;
        const storesWithOpenOrdersCount = this.storesWithOpenOrders.count;
        return storesWithOpenOrdersCount >= minNumberOfTilesToFill
            ? []
            : new Array(minNumberOfTilesToFill - storesWithOpenOrdersCount).fill(null);
    }

    @Computed()
    public get ordersOfStore(): (storeId: number) => Order[] {
        return (storeId: number) => this.entitiesArray
            .filter(order => order.storeId === storeId)
            .map(order => new Order(order));
    }

    @Computed()
    public get itemEntitiesInBox() {
        return (orderId: number) => {
            const order = this.entities[orderId];
            return order.boxes
                .flatMap(box => box.items)
                .map(item => new OrderItem(order.items.find(i => i.id === item.orderItemId)));
        };
    }

    @Computed()
    public get ttlItemQtyPacked() {
        return (orderId: number, orderItemId: number) => {
            const order = this.entities[orderId];
            return order.boxes
                .flatMap(box => box.items)
                .filter(item => item.orderItemId === orderItemId)
                .reduce((results, boxItem) => results + boxItem.quantity, 0);
        };
    }

    @Computed()
    public get percentageOfItemQtyInBoxes() {
        return (orderId: number, orderItemId: number) => {
            const order = this.entities[orderId];
            const totalOfItemQtyInBoxes = order.boxes
                .flatMap(box => box.items)
                .filter(item => item.orderItemId === orderItemId)
                .reduce((results, boxItem) => results + boxItem.quantity, 0);

            return Math.round((100 / order.items.find(item => item.id === orderItemId).quantityOrdered) * totalOfItemQtyInBoxes);
        };
    }

    @Computed()
    public get availableQuantityToPack() {
        return (orderId: number, orderItemId: number) => {
            const order = this.entities[orderId];
            const totalOfItemQtyInBoxes = order.boxes
                .flatMap(box => box.items)
                .filter(item => item.orderItemId === orderItemId)
                .reduce((results, boxItem) => results + boxItem.quantity, 0);

            return order.items.find(item => item.id === orderItemId).quantityOrdered - totalOfItemQtyInBoxes;
        };
    }

    @Computed()
    public get previousOrderId() {
        return (orderId: number) => {
            const orderEntityArray = this.entitiesArray;
            const orderIndex = orderEntityArray.findIndex(order => order.id === orderId);

            return (orderIndex > 0 && orderEntityArray[orderIndex - 1]?.id);
        };
    }

    @Computed()
    public get nextOrderId() {
        return (orderId: number) => {
            const orderEntityArray = this.entitiesArray;
            const orderIndex = orderEntityArray.findIndex(order => order.id === orderId);

            return (orderIndex + 1 < orderEntityArray.length && orderEntityArray[orderIndex + 1]?.id);
        };
    }

    @Computed()
    public get boxesById() {
        return (...boxIds: number[]) => {
            return new EntityMap<OrderBox>(
                EntityMapper.mapById(
                    this.entitiesArray
                        .flatMap(order => order.boxes)
                        .filter(box => boxIds.includes(box.id))
                        .map(box => new OrderBox(box))
                )
            );
        };
    }

    @Computed()
    public get itemEntitiesNotInBox() {
        return (orderId: number) => {
            const order = this.entities[orderId];
            const orderItemIdsOfBoxes = order.boxes.flatMap(ob => ob.items).map(item => item.orderItemId);
            return order.items
                .filter(item => !orderItemIdsOfBoxes.includes(item.id))
                .map(item => new OrderItem(item));
        };
    }

    @Computed()
    public get itemEntitiesNotFullyPacked() {
        return (orderId: number) => {
            const order = new Order(this.entities[orderId]);
            return order.orderItemEntities
                .filter(oi => !oi.isPacked);
        };
    }

    @Computed()
    public get itemEntitiesByStatus(): (orderId: number, statuses: OrderProcessingStatus[]) => OrderItem[] {
        return (orderId: number, statuses: OrderProcessingStatus[]) => {
            return this.entities[orderId]
                .items
                .map(item => new OrderItem(item))
                .filter(oi => statuses.some(status => status.value & oi.processingStatus.value));
        };
    }

    @Computed()
    public get orderItemsWithoutStatus(): (orderId: number, statuses: OrderProcessingStatus[]) => OrderItem[] {
        return (orderId: number, statuses: OrderProcessingStatus[]) => {
            return this.entities[orderId]
                .items
                .filter(oi => statuses.some(status => !(status.value & oi.processingStatus.value)))
                .map(item => new OrderItem(item));
        };
    }

    @Computed()
    public get filteredOfStores(): (...ids: number[]) => Order[] {
        return (...ids: number[]) => {
            const filters = {
                ...this.ordersFiltersState.snapshot,
                storeIds: ids.reduce((results, id) => {
                    results[id] = true;
                    return results;
                }, {})
            };
            return this.getByFilter(filters);
        };
    }

    @Computed()
    public get orderBoxEntitiesOfOrders(): (...orderIds: number[]) => EntityMap<OrderBox> {
        return (...orderIds: number[]) => {
            return new EntityMap<OrderBox>(
                this.entitiesArray
                    .filter(order => orderIds.includes(order.id))
                    .flatMap(order => order.boxes)
                    .reduce((boxMap, box) => {
                        boxMap[box.id] = new OrderBox(box);
                        return boxMap;
                    }, {} as IEntityMap<OrderBox>)
            );
        };
    }

    @Computed()
    public get orderItemEntityById(): (id: number) => OrderItem {
        return (id: number) => {
            const orderItemModel = this.entitiesArray
                .flatMap(order => order.items)
                .find(item => item.id === id);
            return orderItemModel ? new OrderItem(orderItemModel) : null;
        };
    }

    @Computed()
    public get getByFilter(): (filters: OrdersFiltersModel) => Order[] {
        return (filters: OrdersFiltersModel) =>
            this._filterOrderEntities(this.orderEntities, filters);
    }

    @Computed()
    public get openOrdersByStore(): (storeId: number) => Order[] {
        return (storeId: number) => {
            const filters = new OrdersFiltersModel();
            filters.storeIds[storeId] = true;
            filters.orderStatuses = [ProcessingStatus.Open];
            return this.getByFilter(filters);
        };
    }

    @Computed()
    public get ordersByWarehouse(): (warehouseId: number) => Order[] {
        return (warehouseId: number) => {
            const filters = new OrdersFiltersModel();
            filters.warehouseIds[warehouseId] = true;
            return this.getByFilter(filters);
        };
    }

    @Computed()
    public get ordersByStore(): (storeId: number) => Order[] {
        return (storeId: number) => {
            const filters = new OrdersFiltersModel();
            filters.storeIds[storeId] = true;
            return this.getByFilter(filters);
        };
    }

    @Computed()
    public get ttlQtyOfBatchItem(): (batchItem: DisplayedPicklistItem) => number {
        return (batchItem: DisplayedPicklistItem) => {
            return this.entitiesArray
                .flatMap(order => order.items)
                .filter(item => batchItem.orderItemIds.includes(item.id))
                .reduce((a, b) => a + b.quantityOrdered, 0);
        };
    }

    @Computed()
    public get filteredByStatus(): (statuses: OrderProcessingStatus[]) => Order[] {
        return (statuses: OrderProcessingStatus[]) => {
            return this.orderEntities.filter(order => statuses.includes(order.latestProcessingStatus));
        };
    }

    @Computed()
    public get storeIdToOpenOrdersMap() {
        const storeIds = this.storeEntitiesState.ids;
        let orders = this.orderEntities;
        return new EntityMap<Order[]>(
            storeIds.reduce((results, id) => {
                // results[id] = Utils.filterInPlace(orders, (order) => order.storeId === id && order.isOpen);
                /* TODO: Need to figure out how to filter orders in place to reduce the cost of the iteration - here and everywhere else */
                results[id] = orders.filter((order) => order.storeId === id && order.isOpen);
                return results;
            }, {})
        );
    }

    @DataAction()
    public load() {
        const originalAlreadyFetched = this.ordersFiltersState.snapshot.alreadyFetched;
        const newFetchParams = this.ordersService.getOrdersFetchFilterParams();
        this.patchState({ loading: true });
        this.ordersFiltersState.patchState({
            alreadyFetched: {
                shipped: newFetchParams.includeShipped,
                canceled: newFetchParams.includeCanceled,
                others: !newFetchParams.excludeOthers
            }
        });
        this.ordersService.fetch(newFetchParams)
            .subscribe({
                next: (orders) => {
                    this.upsertEntitiesMany(orders);
                    this.patchState({ loading: false, loaded: true });
                },
                error: () => {
                    this.patchState({ loading: false });
                    this.ordersFiltersState.patchState({
                        alreadyFetched: originalAlreadyFetched
                    });
                }
            });
        StatesHelper.shipmentsEntitiesState.lazyLoad(newFetchParams);
        this.realtimeService.registerHandler(HubClientMethods.SyncOrder, (notification: IEntityChange<OrderModel>) => {
            this.syncOrder(notification);
        });
    }

    @DataAction()
    lazyLoad() {
        !this.snapshot.loading &&
            !this.snapshot.loaded &&
            this.load();
    }

    @DataAction()
    public fetchSingleOrder(orderId: number) {
        return this.ordersService
            .fetchSingleOrder(orderId)
            .pipe(tap(order => {
                this.upsertOne(order);
            }));
    }

    @DataAction()
    public upsertOrderBoxes(...boxes: OrderBoxModel[]) {
        const orderIdToBoxesMap = _.groupBy(boxes, (box => box.orderId));
        this.updateEntitiesMany(Object.keys(orderIdToBoxesMap).map(orderId => ({
            id: orderId,
            changes: {
                boxes: this.entities[orderId].boxes
                    .filter(box => !orderIdToBoxesMap[orderId].map(box => box.id).includes(box.id))
                    .concat(orderIdToBoxesMap[orderId])
            }
        })));
    }

    @DataAction()
    public deleteOrderBoxes(...orderBoxes: OrderBoxModel[]) {
        this.updateEntitiesMany(orderBoxes.map(ob => ({
            id: ob.orderId,
            changes: {
                ...this.entities[ob.orderId],
                boxes: this.entities[ob.orderId].boxes
                    .filter(box => box.id !== ob.id)
            }
        })));
    }

    @DataAction()
    packItemToBox(orderBoxItem: OrderBoxItemModel) {
        if (!orderBoxItem.orderId) {
            throw new Error((`[${this.constructor.name}: ${this.packItemToBox.name}]: Cannot insert an item with invalid orderId`));
        }

        this.updateOne({
            id: orderBoxItem.orderId,
            changes: {
                ...this.entities[orderBoxItem.orderId],
                boxes: this.entities[orderBoxItem.orderId]
                    .boxes
                    .map(box => {
                        if (box.id !== orderBoxItem.orderBoxId) return box;
                        const boxClone = { ...box };
                        boxClone.items = box.items
                            .filter(bi => bi.id !== orderBoxItem.id)
                            .concat(orderBoxItem.quantity === 0 ? [] : [orderBoxItem]);
                        return boxClone;
                    })
            }
        });
    }

    public onStateChange(callback: (state?: OrdersEntitiesState) => void) {
        if (!this.snapshot.loading) {
            callback(this);
        }
        this._ngxsOnChange = callback;
    }

    private setAlreadyFetchedFilter(filters: OrdersFetchFilters) {
        this.ordersFiltersState.setState({
            ...this.ordersFiltersState.snapshot,
            alreadyFetched: {
                shipped: filters?.includeShipped ?? this.ordersService.getOrdersFetchFilterParams().includeShipped,
                canceled: filters?.includeCanceled ?? this.ordersService.getOrdersFetchFilterParams().includeCanceled,
                others: (filters?.excludeOthers ?? this.ordersService.getOrdersFetchFilterParams().excludeOthers) === false
            }
        });
    }

    @DataAction()
    private syncOrder(notification: IEntityChange<OrderModel>) {
        const order = notification.data;
        if (!order) {
            this.removeOne(notification.id);
        } else {
            this.upsertOne(order);
        }
    }

    private searchStart(string: string) {
        const searchTerm = StatesHelper.ordersFiltersState.snapshot.searchTerm.trim();
        return new RegExp(`(^|\\s)${searchTerm}`, 'gmi').test(string?.trim());
    }

    private _filterOrderEntities(orders: Order[], filters: OrdersFiltersModel): Order[] {
        const searchStart = this.searchStart;
        return orders.filter(order => {
            // Store filter
            return (
                !!filters.storeIds[order.storeId] || !Object.keys(filters.storeIds).length)
                // Warehouse filter
                && (
                    !Object.keys(filters.warehouseIds).length || Object.keys(filters.warehouseIds)
                        .some(wi => order.orderItemEntities.map(oi => oi.warehouseId).includes(+wi))
                )
                // Date range filter
                && (new Date(order.orderDate) >= filters.dateRange.from && new Date(order.orderDate) <= filters.dateRange.to)
                // Processing status filter
                && (filters.orderStatuses.some(status => status?.value & order.latestProcessingStatus?.value) || !filters.orderStatuses.length)
                // Search filters
                && (
                    searchStart(order.orderNumber)
                    || searchStart(order.orderAddress?.name)
                    || order.orderItemEntities.some(item => searchStart(item.productEntity?.sku))
                    || order.orderItemEntities.some(item => searchStart(item.productEntity?.upc))
                    || order.orderItemEntities.some(item => searchStart(item.productEntity.storeProduct(order.storeId)?.asin))
                );
            // Sort filters
        }).sort(this.getSortByLambda(filters));
    }

    private getSortByLambda(filters: OrdersFiltersModel) {
        switch (filters.sortBy) {
            case OrderSortBy['Customer_Name']:
                return (a: Order, b: Order) => a.orderAddress?.name > b.orderAddress?.name
                    ? (filters.sortOrder === SortOrder.Asc ? 1 : -1) : (filters.sortOrder === SortOrder.Asc ? -1 : 1);
            case OrderSortBy['Order_Date']:
                return (a: Order, b: Order) => a.orderDate < b.orderDate
                    ? (filters.sortOrder === SortOrder.Asc ? 1 : -1) : (filters.sortOrder === SortOrder.Asc ? -1 : 1);
            case OrderSortBy.Status:
                return (a: Order, b: Order) => a.latestProcessingStatus.value < b.latestProcessingStatus.value
                    ? (filters.sortOrder === SortOrder.Asc ? 1 : -1) : (filters.sortOrder === SortOrder.Asc ? -1 : 1);
            case OrderSortBy.Store:
                return (a: Order, b: Order) => a.store?.type < b.store?.type
                    ? (filters.sortOrder === SortOrder.Asc ? 1 : -1) : (filters.sortOrder === SortOrder.Asc ? -1 : 1);
            case OrderSortBy['Total_Quantity']:
                return (a: Order, b: Order) => a.items.reduce((a, b) => a + b.quantityOrdered, 0) > b.items.reduce((a, b) => a + b.quantityOrdered, 0)
                    ? (filters.sortOrder === SortOrder.Asc ? 1 : -1) : (filters.sortOrder === SortOrder.Asc ? -1 : 1);
        }
    }

    private _ngxsOnChange = (state?: OrdersEntitiesState) => { };
}
