import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/auth/login/login.component';
import { RegisterComponent } from './pages/auth/register/register.component';

import { BaseComponent } from './pages/theme/base/base.component';
import { AuthGuard } from './_auth/auth.guard';
import { ErrorComponent } from './pages/theme/general/error/error.component';
import { ForgotPasswordComponent } from './pages/auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/auth/reset-password/reset-password.component';
import { OauthRedirectComponent } from './pages/oauth-redirect/oauth-redirect.component';
import { PreAuthRedirectComponent } from './pages/pre-auth-redirect/pre-auth-redirect.component';
import { PageNotFoundComponent } from './pages/theme/page-not-found/page-not-found.component';
import { SelfShipResolver } from './pages/self-ship-orders/self-ship-orders.resolver';

const routes: Routes = [
  { path: 'login', component: LoginComponent, data: { disabledDefaultComponentSetup: true } },
  { path: 'register', component: RegisterComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'oauth-redirect', component: OauthRedirectComponent, data: { disabledDefaultComponentSetup: true } },
  { path: 'amazon-connect', component: OauthRedirectComponent, data: { disabledDefaultComponentSetup: true } }, //TODO Temp till Amazon unlocks account
  {
    path: 'pre-auth-redirect/:store',
    component: PreAuthRedirectComponent,
    data: { disabledDefaultComponentSetup: true }
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: BaseComponent,
    children: [
      {
        path: '',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/overview/overview.module').then(m => m.OverviewModule)
      },
      {
        path: 'purchases',
        canActivate: [AuthGuard],
        data: { permissions: ['Purchases'] },
        loadChildren: () => import('./pages/purchases/purchases.module').then(m => m.PurchasesModule)
      },
      {
        path: 'products',
        canActivate: [AuthGuard],
        data: { permissions: ['Products'] },
        loadChildren: () => import('./pages/products/products.module').then(m => m.ProductsModule)
      },
      {
        path: 'upload-cv',
        canActivate: [AuthGuard],
        data: { permissions: ['CreateProducts'] },
        loadChildren: () => import('./pages/upload-csv/upload-csv.module').then(m => m.UploadCsvModule)
      },
      {
        path: 'fba-shipments',
        canActivate: [AuthGuard],
        data: { permissions: ['FbaShipments'] },
        loadChildren: () => import('./pages/fba-shipments/fba-shipments.module').then(m => m.FbaShipmentsModule)
      },
      {
        path: 'wfs-shipments',
        canActivate: [AuthGuard],
        data: { permissions: ['WfsShipments'] },
        loadChildren: () => import('./pages/wfsshipment/wfs-shipments.module').then(m => m.WfsShipmentsModule)
      },
      {
        path: 'self-ship-orders',
        canActivate: [AuthGuard],
        data: { permissions: ['SelfShipOrders'] },
        resolve: { data: SelfShipResolver } ,
        loadChildren: () => import('./pages/self-ship-orders/self-ship-orders.module').then(m => m.SelfShipOrdersModule)
      },
      {
        path: 'fulfilments',
        canActivate: [AuthGuard],
        data: { permissions: ['SelfShipOrders'] },
        loadChildren: () => import('./pages/fulfilments/fulfilments.module').then(m => m.FulfilmentsModule)
      },
      {
        path: 'inventory-transfers',
        canActivate: [AuthGuard],
        data: { permissions: ['InventoryTransfers'] },
        loadChildren: () => import('./pages/inventory-transfers/inventory-transfers.module').then(m => m.InventoryTransfersModule)
      },
      {
        path: 'inventory-locations',
        canActivate: [AuthGuard],
        // data: { permissions: ['InventoryLocations'] },
        loadChildren: () => import('./pages/inventory-locations/inventory-locations.module').then(m => m.InventoryLocationsModule)
      },
      {
        path: 'returns',
        canActivate: [AuthGuard],
        data: { permissions: ['Returns'] },
        loadChildren: () => import('./pages/returns/returns.module').then(m => m.ReturnsModule)
      },
      {
        path: 'amazon-removals',
        canActivate: [AuthGuard],
        data: { permissions: ['AmazonRemovals'] },
        loadChildren: () => import('./pages/amazon-removals/amazon-removals.module').then(m => m.AmazonRemovalsModule)
      },
      {
        path: 'reports',
        canActivate: [AuthGuard],
        data: { permissions: ['Reports'] },
        loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule)
      },
      {
        path: 'advancedreorder',
        canActivate: [AuthGuard],
        data: { permissions: ['AdvancedReorder'] },
        loadChildren: () => import('./pages/advanced-reorder/advanced-reorder.module').then(m => m.AdvancedReorderModule)
      },
      {
        path: 'settings',
        canActivate: [AuthGuard],
        data: { permissions: ['Settings'] },
        loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsModule)
      },
      {
        path: 'advertising',
        canActivate: [AuthGuard],
        data: { permissions: ['Advertising'] },
        loadChildren: () => import('./pages/advertising/advertising.module').then(m => m.AdvertisingModule)
      },
      {
        path: 'sublocations',
        canActivate: [AuthGuard],
        data: { permissions: ['Settings'] },
        loadChildren: () => import('src/app/_old-sublocations/moved-pages-sublocations/sublocations.module').then(m => m.SublocationsModule)
      },
      { path: 'error', component: ErrorComponent },
      { path: '**', component: PageNotFoundComponent, pathMatch: 'full' }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
