import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {CurrencyModel} from '../_models/currency.model';

export const API_COMMON_URL = `${environment.baseUrl}/common`;

@Injectable()
export class CommonService {
  constructor(
    private http: HttpClient
  ) {}

  public fetchCurrencies() {
    return this.http.get<CurrencyModel[]>(`${API_COMMON_URL}/${this.fetchCurrencies.name}`);
  }
}

