import { IRetryPolicy, RetryContext } from "@microsoft/signalr";
import { ExponentialBackoff, IBackoffFactory, Policy, handleAll } from "cockatiel";
import { RetryConstants } from "src/app/_intercepters/request-resiliency.interceptor";


export default class CustomReconnectPolicy implements IRetryPolicy  {

    constructor(private options?: {
        errorPolicy?: Policy,
        maxAttempts?: number;
        backoff?: IBackoffFactory<{ attempt: number }>;
    }) {
        this.options =  { 
            errorPolicy: options?.errorPolicy ?? handleAll,
            maxAttempts: options?.maxAttempts ?? Infinity ,
            backoff: options?.backoff || new ExponentialBackoff({ initialDelay: RetryConstants.INITIAL_DELAY_MS })
        };
    }

    public nextRetryDelayInMilliseconds(retryContext: RetryContext): number {
        
        const handled = this.options.errorPolicy?.options.errorFilter(retryContext.retryReason)
        const currentAttempt = retryContext.previousRetryCount + 1;

        if (!handled || currentAttempt > this.options.maxAttempts) {
            return null;
        }

        const nextBackoff = this.options instanceof ExponentialBackoff ? 
            (this.options.backoff as ExponentialBackoff<{ attempt: number }>).next() :
            this.options.backoff.next({ attempt: currentAttempt });
        console.debug(`signalR reconnect -> attempt: {currentAttempt}], delay: ${nextBackoff.duration}`)
        return nextBackoff.duration;
    }
}
