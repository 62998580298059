import { Injectable } from '@angular/core';
import { State } from '@ngxs/store';
import { AddressModel } from 'src/app/_models/address.model';
import { AddressesService } from 'src/app/_services/settings/addresses.service';
import { DataAction, StateRepository } from '@angular-ru/ngxs/decorators';
import { createEntityCollections, EntityIdType } from '@angular-ru/cdk/entity';
import { NgxsDataEntityCollectionsRepository } from '@angular-ru/ngxs/repositories';
import BaseEntityCollectionsOptions from '../base-entity-collections-options';
import { HelperService } from 'src/app/_services/helper.service';

@StateRepository()
@State({
    name: 'addresses',
    defaults: {
        ...createEntityCollections(),
        loading: false,
        loaded: false
    }
})
@Injectable()
export default class AddressesEntitiesState
    extends NgxsDataEntityCollectionsRepository<AddressModel, EntityIdType, BaseEntityCollectionsOptions> {

    constructor(
        private addressesService: AddressesService,
        private helperService: HelperService
    ) {
        super();
    }

    ngxsAfterBootstrap() {
        this.helperService.onUserIsActive(() => {
            this.load();
        });
    }

    @DataAction()
    public load() {
        this.addressesService.getAll().subscribe(result => {
            this.setEntitiesAll(result);
        });
    }

    @DataAction()
    public lazyLoad() {
        !this.snapshot.loaded &&
            !this.snapshot.loading &&
            this.load();
    }
}
