import _ from "lodash";
import { WarehouseShelfModel } from "src/app/_models/warehouses/warehouse-shelf.model";
import WarehouseBin from "./warehouse-bin.entity";
import { StatesHelper } from "src/app/states/states.helper";
import { WarehouseRack } from "./warehouse-rack.entity";

export class WarehouseShelf extends WarehouseShelfModel {

    public readonly binsEntities: WarehouseBin[];

    constructor(warehouseShelf: WarehouseShelfModel) {
        super();
        _.assignIn(this, warehouseShelf);

        this.binsEntities = this.bins.map(bin => new WarehouseBin(bin));
    }

    get rack(): WarehouseRack {
        return StatesHelper.warehousesEntitiesState.warehouseRacksEntityMap[this.rackId];
    }
}