import { BaseModel } from "../_base.model";
import { WarehouseRackModel } from "./warehouse-rack.model";
import IStockLocationModel from "./stock-location.model";

export class WarehouseAisleModel extends BaseModel implements IStockLocationModel<string> {
    id: number;
    name: string;
    referenceId: string;
    warehouseId: number;
    racks: WarehouseRackModel[];
}