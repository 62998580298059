import { Component, Input } from '@angular/core';
import Loading from 'src/app/_global/loading';
import { LoadingService } from 'src/app/_services/loading.service';

@Component({
  selector: 'loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent {

  @Input() cover = true;
  @Input() loader: Loading = this.loadingService.getMainLoader();
  @Input()
  set loading(value: boolean) {
    this.loader.loading = value;
  }

  constructor(public loadingService: LoadingService) { }
}
