import { Injectable } from "@angular/core";

@Injectable()
export class Countries {
  all = [
    {
      code: 'US',
      name:'UNITED STATES',
      states: ['AL','AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL', 'GA', 'HI', 'ID',
        'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT',
        'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC',
        'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY']
    },
    {
      code: 'CA',
      name: 'CANADA',
      states: ['AB', 'BC', 'MB', 'NB', 'NL', 'NS', 'ON', 'PE', 'QC', 'SK']
    },
    {
      code: 'CN',
      name: 'CHINA',
      states: []
    },
    {
      code: 'UK',
      name: 'UNITED KINGDOM',
      states: ['EN', 'SC', 'WL', 'NI']
    },
    {
      code: 'DE',
      name: 'GERMANY',
      states: []
    },
    {
      code: 'FR',
      name: 'FRANCE',
      states: []
    },
    {
      code: 'ES',
      name: 'SPAIN',
      states: []
    }
  ];

  get list() {
    return this.all.map(c => c.code);
  }

  getStates(countryNameOrCode: string) {
    if (countryNameOrCode) {
      const upperCasedCode = countryNameOrCode.toUpperCase();
      const result = this.all.find(c => c.code == upperCasedCode || c.name == upperCasedCode)?.states;
      return result ?? [];
    }
    else
      return [];
  }
}