import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import UserState from '../states/user/user.state';
import { AuthService } from '../_services/auth.service';

@Injectable()
export class AuthGuard  {

  constructor(private authService: AuthService, private stateStore: Store,
              private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.stateStore.selectSnapshot(UserState);
    if (currentUser)
    {
      if ((currentUser.inactiveDays > 7
      || currentUser.exceededDays > 7) && (route as any)._routerState.url != '/'
      && (route as any)._routerState.url != '/settings/billing')
      this.router.navigate(['/']);
    }

    if (currentUser &&
      ((!route.data.permissions || (route.data.permissions && route.data.permissions.some(p => currentUser.permissions.includes(p))))
      && (!route.data.roles || (route.data.roles && route.data.roles.some(p => currentUser.roles.includes(p)))))) {
      return true;
    }
    return this.checkLogin(state.url);
  }

  checkLogin(url: string) {
    if (this.stateStore.selectSnapshot(UserState.isLoggedIn)) {
      this.router.navigate(['']);
      return true;
    }

    // Store the attempted URL for redirecting
    // this.authService.redirectUrl = url;

    // Navigate to the login page with extras
    this.router.navigate(['/login'], {queryParams: {returnUrl: url}} );
    return false;
  }
}
