export enum StoreType {
    Local,
    Amazon,
    Walmart,
    Jet,
    Ebay,
    Shopify,
    BigCommerce,
    Overstock,
    Etsy,
    Wayfair,
    ShipStation = 20,
    AmazonAws = 21,
    QuickBooksIntegration = 22,
    AmazonAdvertising = 23,
    WayfairStoreSandbox,
    Target = 27
}

export const STORE_TYPES = [
  'Local',
  'Amazon',
  'Walmart',
  'Jet',
  'Ebay',
  'Shopify',
  'BigCommerce',
  'Overstock',
  'Etsy',
  'Wayfair',
  'ShipStation',
  'AmazonAws',
  'QuickbooksIntegration',
  'AmazonAdvertising',
  'WayfairStoreSandbox',
  'Target'
] as const;
