import { ClassBasedEnumBase } from "src/app/libraries/class-based-enum.lib";
import { ProcessingStatus } from "./processing-status";

export class ShippingProcessingStatus extends ClassBasedEnumBase {
    private constructor(
        public name: string,
        public value: number,
        public description?: string) {
        super();
    }

    static readonly None = new ShippingProcessingStatus('None', ProcessingStatus.None.value, 'No shipping status');
    static readonly ShippedPartial = new ShippingProcessingStatus('ShippedPartial', ProcessingStatus.ShippedPartial.value, ProcessingStatus.ShippedPartial.description);
    static readonly Shipped = new ShippingProcessingStatus('Shipped', ProcessingStatus.Shipped.value, ProcessingStatus.Shipped.description);
}
