import { ClassBasedEnumBase } from 'src/app/libraries/class-based-enum.lib';

export class ProcessingStatus extends ClassBasedEnumBase {
    private constructor(
        public name: string,
        public value: number,
        public description?: string
    ) {
        super();
    }

    static readonly None = new ProcessingStatus('None', 1 << 0, 'Has no status yet');
    static readonly Open = new ProcessingStatus('Open', this.None.value, 'Still open');

    static readonly Canceled = new ProcessingStatus('Canceled', 1 << 1, 'Has been canceled');
    static readonly Pending = new ProcessingStatus('Pending', 1 << 2, 'Is still pending');
    static readonly OnHold = new ProcessingStatus('OnHold', 1 << 3, 'Was marked as on hold');
    static readonly Picking = new ProcessingStatus('Picking', 1 << 4, 'In the middle of picking');
    static readonly Picked = new ProcessingStatus('Picked', 1 << 5, 'Has been fully picked');
    static readonly Packing = new ProcessingStatus('Packing', 1 << 6, 'In the middle of packing');
    static readonly Packed = new ProcessingStatus('Packed', 1 << 7, 'Has been fully packed');
    static readonly ReadyToShipPartial = new ProcessingStatus('ReadyToShipPartial', 1 << 8, 'Some items are ready to be shipped');
    static readonly ReadyToShip = new ProcessingStatus('ReadyToShip', 1 << 9, 'Ready to be shipped');
    static readonly ShippedPartial = new ProcessingStatus('ShippedPartial', 1 << 10, 'Some items were shipped');
    static readonly Shipped = new ProcessingStatus('Shipped', 1 << 11, 'Has been shipped');

    static readonly ReadyToPackPartial = new ProcessingStatus('ReadyToPackPartial', this.Picking.value, 'Some items are ready to be packed');
    static readonly ReadyToPack = new ProcessingStatus('ReadyToPack', this.Picked.value, 'Ready to be packed');
    static readonly AvailableForPicking = new ProcessingStatus('AvailableForPicking', this.None.value | this.Picking.value, 'Not picked yet');
    static readonly AnyPartial = new ProcessingStatus(
        'AnyPartial',
        this.ShippedPartial.value | this.ReadyToPackPartial.value | this.ReadyToShipPartial.value,
        'Being processed'
    );
    static readonly AnyIncomplete = new ProcessingStatus(
        'AnyIncomplete',
        this.Picking.value | this.Packing.value | this.ShippedPartial.value,
        'Not done yet'
    );
    static readonly AnyComplete = new ProcessingStatus('AnyComplete', this.Picked.value | this.Packed.value | this.Shipped.value, 'Waiting for next step');
    static readonly Closed = new ProcessingStatus('Closed', this.Shipped.value | this.Canceled.value, 'Has been closed');
    static readonly NotInProcess = new ProcessingStatus('NotInProcess', this.None.value | this.Shipped.value, 'Done processing');
}