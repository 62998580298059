import { Injectable } from '@angular/core';
import { State } from '@ngxs/store';
import * as _ from 'lodash';

import Picklist from 'src/app/_entities/pick-lists/pick-list.entity';
import { SortOrder } from 'src/app/_enums/general/sort-order.enum';
import PickListsFiltersModel from 'src/app/_models/self-ship/pick-lists/pick-lists-filters.model';
import { PickListModel } from 'src/app/_models/self-ship/pick-lists/pick-list.model';
import { PickListItemSortBy } from 'src/app/_enums/self-ship/pick-list-item-sort-by.enum';
import PickListItem from 'src/app/_entities/pick-lists/pick-list-item.entity';
import { PicklistsService } from 'src/app/_services/picklists.service';
import { PickingProcessingStatus } from 'src/app/_entities/orders/processing-statuses/picking-processing-status';
import { Computed, DataAction, StateRepository } from '@angular-ru/ngxs/decorators';
import { createEntityCollections, EntityIdType } from '@angular-ru/cdk/entity';
import { NgxsDataEntityCollectionsRepository } from '@angular-ru/ngxs/repositories';
import BaseEntityCollectionsOptions from '../../base-entity-collections-options';
import EntityMap from '../../../_builders/entity-map';
import { PickListsFiltersState } from './pick-lists-filters/pick-lists-filters.state';
import { PickListItemModel } from '../../../_models/self-ship/pick-lists/pick-list-item.model';
import EntityMapper from '../../../_builders/entity.mapper';
import ProductsEntitiesState from '../../products/products-entities.state';
import Product from 'src/app/_entities/products/product.entity';
import { DisplayedPicklistItem } from '../../orders/orders-entities.state';

@StateRepository()
@State({
    name: 'pickLists',
    defaults: {
        ...createEntityCollections(),
        loading: false
    }
})
@Injectable()
export default class PickListsEntitiesState
    extends NgxsDataEntityCollectionsRepository<PickListModel, EntityIdType, BaseEntityCollectionsOptions> {
    constructor(
        private picklistsService: PicklistsService,
        private picklistFiltersState: PickListsFiltersState,
        private productsEntitiesState: ProductsEntitiesState,
    ) {
        super();
    }

    // Computed
    @Computed()
    public get isLoading(): boolean {
        return this.snapshot.loading && !this.snapshot.loaded;
    }

    @Computed()
    public get picklistEntities(): Picklist[] {
        return this.entitiesArray
            .map(pl => new Picklist(pl));
    }

    @Computed()
    public get picklistsEntityMap() {
        return new EntityMap(EntityMapper.mapById(this.picklistEntities));
    }

    @Computed()
    public get filtered() {
        return this._filterPickLists(this.picklistsEntityMap, this.picklistFiltersState.snapshot);
    }

    @Computed()
    public get batchesNotStartedYet() {
        return this.filtered.filter(pl => !!(pl.processingStatus.value & PickingProcessingStatus.None.value));
    }

    @Computed()
    public get batchesPartiallyPicked() {
        return this.filtered.filter(pl => !!(pl.processingStatus.value & PickingProcessingStatus.Picking.value));
    }

    @Computed()
    public get batchesFullyPicked() {
        return this.filtered.filter(pl => !!(pl.processingStatus.value & PickingProcessingStatus.Picked.value));
    }

    @Computed()
    public get picklistGroupedBatches() {
        return _.groupBy(this.filtered.filter(pl => !!pl.groupId).entities, pl => pl.groupId);
    }

    @Computed()
    public get pickedBatchItems(): EntityMap<DisplayedPicklistItem> {
        const pickListId = this.picklistFiltersState.snapshot.selectedBatchId;
        if (!pickListId) {
            throw new Error(`${this.constructor.name}.pickedBatchItems: No pick list id given`);
        }
        const RegexStartsWith = new RegExp(`^${this.picklistFiltersState.snapshot.picklistItemSearchTerm.trim()}`, 'gmi');
        const pickList = this.picklistsEntityMap.entities[+pickListId];
        if (pickList) {
            const itemIds = pickList.items.filter(i => !!((i.processingStatus.value) & PickingProcessingStatus.Picked.value)).map(item => item.productId);
            return this.productsEntitiesState.productsEntityMap.subset(...itemIds)
                // Search filter
                .filter(item => RegexStartsWith.test(item.sku) || RegexStartsWith.test(item.description))
                .mapEntities(product => <DisplayedPicklistItem>({ orderedQuantity: pickList.items.find((item) => item.productId === product.id)?.orderedQuantity, product })).toEntityMap;
            //     // Sort filter
            // .sort(this.picklistItemSortLambda(this.picklistFiltersState.snapshot, pickList));
        }
    }

    @Computed()
    public get pickingBatchItems(): EntityMap<DisplayedPicklistItem> {
        const pickListId = this.picklistFiltersState.snapshot.selectedBatchId;
        if (!pickListId) {
            throw new Error(`${this.constructor.name}.pickingBatchItems: No pick list id given`);
        }
        const RegexStartsWith = new RegExp(`^${this.picklistFiltersState.snapshot.picklistItemSearchTerm.trim()}`, 'gmi');
        const pickList = this.picklistsEntityMap.entities[pickListId];
        if (pickList) {
            const itemIds = pickList.items.filter(i => !!(i.processingStatus.value & PickingProcessingStatus.Picking.value) || !!(i.processingStatus.value & PickingProcessingStatus.None.value)).map(pr => pr.productId);
            return this.productsEntitiesState.productsEntityMap.subset(...itemIds)
                .filter(item => RegexStartsWith.test(item.sku) || RegexStartsWith.test(item.description))
                .mapEntities(product => <DisplayedPicklistItem>({ orderedQuantity: pickList.items.find((item) => item.productId === product.id)?.orderedQuantity, product })).toEntityMap;
            // Sort filter
            // .sort(this.picklistItemSortLambda(this.picklistFiltersState.snapshot, pickList));
        }
    }

    // Actions
    @DataAction()
    public load() {
        this.patchState({ loading: true });
        this.picklistsService.fetch().subscribe(picklists => {
            this.setEntitiesAll(picklists);
            this.patchState({ loading: false, loaded: true });
        });
    }

    @DataAction()
    lazyLoad() {
        !this.snapshot.loading &&
            !this.snapshot.loaded &&
            this.load();
    }

    @DataAction()
    public upsertPicklistItem(pickListItem: PickListItemModel) {
        this.updateOne({
            id: pickListItem.pickListId,
            changes: {
                ...this.entities[pickListItem.pickListId],
                items: this.entities[pickListItem.pickListId]
                    .items.filter(item => item.id !== pickListItem.id)
                    .concat([pickListItem])
            }
        });
    }

    private picklistItemSortLambda(filters: PickListsFiltersModel, picklist: Picklist) {
        switch (filters.picklistItemSortBy) {
            case PickListItemSortBy.Aisle:
                return (a: PickListItem, b: PickListItem) => a.product.productLocation(picklist.warehouseId)?.aisle
                    > b.product.productLocation(picklist.warehouseId)?.aisle
                    ? (filters.picklistItemSortOrder == SortOrder.Asc ? 1 : -1) : (filters.picklistItemSortOrder == SortOrder.Desc ? 1 : -1);
            case PickListItemSortBy.Qty:
                return (a: PickListItem, b: PickListItem) => a.orderedQuantity > b.orderedQuantity
                    ? (filters.picklistItemSortOrder == SortOrder.Asc ? 1 : -1) : (filters.picklistItemSortOrder === SortOrder.Desc ? 1 : -1);
            default:
                return () => null;
        }
    }

    private _filterPickLists(picklistEntities: EntityMap<Picklist>, filters: PickListsFiltersModel): EntityMap<Picklist> {
        const RegexStartsWith = new RegExp(`^${filters.searchTerm.trim()}`, 'gmi');
        return picklistEntities.filter(pl => {
            // Warehouse Filter
            return (filters.warehouseIds[pl.warehouseId] || !Object.keys(filters.warehouseIds).length)
                // Statuses Filter
                && (filters.statuses.some(status => !!(status.value & pl.processingStatus.value)) || !filters.statuses.length)
                // Search Filter
                && (RegexStartsWith.test(pl.name) || !filters.searchTerm.trim());
        });
    }
}
