import _ from 'lodash';

type ClassBasedEnum = {name: string, value: number, description: string} & ClassBasedEnumBase;
/**
 * @todo documentation
 *
 * @since {date}
 */
export abstract class ClassBasedEnumBase {
    public abstract name: string;


    // ------ Static Methods ------//

    static get keys() {
        return Object.getOwnPropertyNames(this)
            .filter(name => !['length', 'name', 'prototype', 'keys', 'values', 'descriptions'].includes(name))
            .map(name => (this[name]));
    }

    static get values(): number[] {
        return this.keys.map(key => key.value);
    }

    static get descriptions() {
        return this.keys.map(key => key.description);
    }

    /**
     * Converts a value to its corresponding Status instance.
     *
     * @param value the value to convert to Status
     * @throws RangeError, if a value that has no corresponding Status value was passed.
     * @returns the matching Status
     */
    static fromValue(val: number) {
        // Works assuming a property with this value exists.
        // Alternatively, you can search the .values array
        const value = this.keys.find(v => v.value === val);
        if (value !== undefined) {
            return value;
        }

        throw new RangeError(
            `Illegal argument passed to ${this.fromValue.name}(): ${val} does not correspond to any instance of the enum ${(this as any).prototype.constructor.name
            }`
        );
    }


    // ------ Methods ------//

    /**
     * Called when converting the Enum value to a string using JSON.Stringify.
     * Compare to the fromString() method, which deserializes the object.
     */
    public toJSON() {
        return this.name;
    }

    public readable() {
        return _.startCase(this.name);
    }
}
