/**
 * Credit: https://github.com/damienbod/angular-auth-oidc-client 
 */

import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { finalize, first, switchMap, take } from 'rxjs/operators';
import { HelperService } from './helper.service';
import { CustomValidators } from '../_helpers/validation-utils';
import { PopupResult, AuthOptions, PopupOptions } from '../_models/popup.models';
import { LoadingService } from './loading.service';

@Injectable({ providedIn: 'root' })
export class PopUpService {
  private readonly URL_VALIDATOR: RegExp;
  private readonly SAME_ORIGIN_VALIDATOR: RegExp;

  private popUp: Window;
  private handle: number;
  private resultInternal = new Subject<PopupResult>();

  private redirectUrl: string;
  private tokenId: string;

  constructor(private helperService: HelperService, private loadingService: LoadingService) {
    this.URL_VALIDATOR = CustomValidators.generatePaternRegex(CustomValidators.generateUrlPatern(false, true, true));
    this.SAME_ORIGIN_VALIDATOR = CustomValidators.generatePaternRegex(`^${location.origin}.*$`);
    this.redirectUrl = `${location.origin}/oauth-redirect`;
    this.tokenId = this.helperService.currentUser.tokenId;
  }

  get result(): Observable<PopupResult> {
    return this.resultInternal.asObservable();
  }

  authorizeWithPopUp(authOptions: AuthOptions, popupOptions?: PopupOptions) {
    this.openPopUp(authOptions, popupOptions);

    return this.result.pipe(
      first(),
      finalize(() => this.loadingService.loading = false),
      switchMap((result: PopupResult) => {
        const { userClosed, receivedUrl } = result;
        result.receivedUrlParams = this.getUrlParams(receivedUrl)
        result.invalidState = !userClosed && authOptions.stateParamName && this.tokenId !== result.receivedUrlParams.get(authOptions.stateParamName);
        return of(result);
      })
    );
  }

  openPopUp(authOptions: AuthOptions, popupOptions?: PopupOptions): void {
    const authUrl = this.getAuthUrl(authOptions);
    const optionsToPass = this.getOptions(popupOptions);
    const listener = (event: MessageEvent): void => {
      if (!event || !event.data || typeof event.data !== 'string' || !this.SAME_ORIGIN_VALIDATOR.test(event.data)) {
        return;
      }

      this.resultInternal.next(<PopupResult> { userClosed: false, receivedUrl: event.data });
      this.cleanUp(listener);
    }

    if (this.popUp) {
      this.cleanUp(listener);
    }

    this.loadingService.loading = true;
    this.popUp = window.open(authUrl, '_blank', optionsToPass);
    window.addEventListener('message', listener, false);

    this.handle = window.setInterval(() => {
      if (this.popUp.closed) {
        this.resultInternal.next(<PopupResult> { userClosed: true });
        this.cleanUp(listener);
      }
    }, 200);
  }

  sendUrlToMainWindow(url: string): void {
    if (window.opener) {
      window.opener.postMessage(url, location.origin);
    }
  }

  private cleanUp(listener: any): void {
    window.removeEventListener('message', listener, false);
    window.clearInterval(this.handle);

    if (this.popUp) {
      this.popUp.close();
      this.popUp = null;
    }
  }

  private getAuthUrl(authOptions: AuthOptions): string {

    if (!authOptions.authUrl) {
      return '';
    }

    const authParams = { ...authOptions.additionalParams ?? [] };
    const authUrl = new URL(authOptions.authUrl);

    Object.entries(authParams)
      .forEach(([key, value]) => authUrl.searchParams.append(key, encodeURIComponent(value)));

    if (authOptions.redirectParamName && !this.URL_VALIDATOR.test(authParams[authOptions.redirectParamName])) {
      authUrl.searchParams.append(authOptions.redirectParamName, `${this.redirectUrl}${authParams[authOptions.redirectParamName] || ''}`);
    }

    if (authOptions.stateParamName) {
      authUrl.searchParams.append(authOptions.stateParamName, this.tokenId);
    }

    return authUrl.toString();
  }

  private getOptions(popupOptions?: PopupOptions): string {
    const popupDefaultOptions: PopupOptions = { width: 600, height: 600, left: 50, top: 50 };
    const options: PopupOptions = { ...popupDefaultOptions, ...(popupOptions || {}) };
    options.top = window.screenTop + (window.outerHeight - options.height) / 2;
    options.left = window.screenLeft + (window.outerWidth - options.width) / 2;

    return Object.entries(options)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join(',');
  }

  private getUrlParams(url: string): URLSearchParams {
    const urlParams = url ? new URL(url).search : '';
    return new URLSearchParams(urlParams);
  }
}
