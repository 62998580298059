import * as _ from 'lodash';
import ProcessingStatusHelper from 'src/app/_helpers/processing-status.helper';
import {OrderBoxModel} from 'src/app/_models/orders/order-boxes/order-box.model';
import {Shipment} from '../../shipments/shipment.entity';
import Order from '../order.entity';
import OrderBoxItem from './order-box-item.entity';

import {ProcessingStatus} from 'src/app/_entities/orders/processing-statuses/processing-status';
import {PickingProcessingStatus} from 'src/app/_entities/orders/processing-statuses/picking-processing-status';
import {PackingProcessingStatus} from 'src/app/_entities/orders/processing-statuses/packing-processing-status';
import {ShippingProcessingStatus} from 'src/app/_entities/orders/processing-statuses/shipping-processing-status';
import Warehouse from '../../warehouses/warehouse.entity';
import {StatesHelper} from '../../../states/states.helper';

export default class OrderBox extends OrderBoxModel {

    public readonly boxItems: OrderBoxItem[];

    constructor(orderBox: OrderBoxModel) {
        super();
        _.assignIn(this, orderBox);
        this.boxItems = this.items.map(item => new OrderBoxItem(item));
    }

    get orderEntity(): Order {
        return StatesHelper.getEntity(StatesHelper.ordersEntitiesState, this.orderId, Order);
    }

    get shipment(): Shipment {
        return StatesHelper.getEntity(StatesHelper.shipmentsEntitiesState, this.shipmentId, Shipment);
    }

    get warehouse(): Warehouse {
        return StatesHelper.getEntity(StatesHelper.warehousesEntitiesState, this.warehouseId, Warehouse);
    }

    // Statuses
    get hasNoStatusYet() {
        return !!(this.latestProcessingStatus?.value & ProcessingStatus.None.value);
    }

    get isBeingPicked() {
        return this.latestProcessingStatus?.value >= PickingProcessingStatus.Picking.value;
    }

    get isPicked() {
        return this.latestProcessingStatus?.value >= PickingProcessingStatus.Picked.value;
    }

    get isBeingPacked() {
        return this.latestProcessingStatus?.value >= PackingProcessingStatus.Packing.value;
    }

    get isPacked() {
        return this.latestProcessingStatus?.value >= PackingProcessingStatus.Packed.value;
    }

    get isLabelPurchased() {
        return !!this.shipment.shippingLabels.length;
    }

    get isReadyToShip() {
        return this.isPacked;
    }

    get isPartiallyShipped() {
        return this.latestProcessingStatus?.value >= ShippingProcessingStatus.ShippedPartial.value;
    }

    get isShipped() {
        return this.latestProcessingStatus?.value >= ShippingProcessingStatus.Shipped.value;
    }

    get isDelivered() {
        return false; // TODO
    }

    get latestProcessingStatus() {
        return ProcessingStatusHelper.getProcessingStatus(this);
    }

    get isOpen() {
        return this.latestProcessingStatus?.value & ProcessingStatus.Open.value;
    }

    get isClosed() {
        return this.latestProcessingStatus?.value & ProcessingStatus.Closed.value;
    }

    toModel() {
        return StatesHelper.ordersEntitiesState.entities[this.orderId].boxes.find(box => box.id === this.id);
    }
}
