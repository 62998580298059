

export default class DateFilterOption {

    private constructor(public from: Date, public to: Date, public selection: DateFilterSelection) { }

    public static createInstance(option: DateFilterSelection): DateFilterOption {
        switch (option) {
            case DateFilterSelection.Today:
                return new DateFilterOption(
                    new Date(new Date().setHours(0, 0, 0, 0)),
                    this.endOfToday(),
                    DateFilterSelection.Today
                );
            case DateFilterSelection.Yesterday:
                return new DateFilterOption(
                    new Date(new Date(Date.now() - this.days(1)).setUTCHours(0, 0, 0, 0)),
                    this.endOfToday(),
                    DateFilterSelection.Yesterday
                );
            case DateFilterSelection["Last week"]:
                return new DateFilterOption(
                    new Date(new Date(Date.now() - this.weeks(1)).setUTCHours(0, 0, 0, 0)),
                    this.endOfToday(),
                    DateFilterSelection["Last week"]
                );
            case DateFilterSelection["Last month"]:
                return new DateFilterOption(
                    new Date(new Date(Date.now() - this.months(1)).setUTCHours(0, 0, 0, 0)),
                    this.endOfToday(),
                    DateFilterSelection["Last month"]
                );

            case DateFilterSelection["Last year"]:
                return new DateFilterOption(
                    new Date(new Date(Date.now() - this.years(1)).setUTCHours(0, 0, 0, 0)),
                    this.endOfToday(),
                    DateFilterSelection["Last year"]
                );

        }
    }

    // 
    private static days(amount: number) {
        return amount * 8.64e+7;
    }

    private static weeks(amount: number) {
        return amount * 6.048e+8;
    }

    private static months(amount: number) {
        return amount * 2.628e+9;
    }

    private static years(amount: number) {
        return amount * 3.154e+10;
    }

    private static hours(amount: number) {
        return amount * 3.6e+6;
    }

    private static minutes(amount: number) {
        return amount * 60000;
    }

    private static seconds(amount: number) {
        return amount * 1000;
    }

    private static endOfToday() {
        return new Date(new Date().setUTCHours(23, 59, 59, 999));
    }

}

export enum DateFilterSelection {
    Today,
    Yesterday,
    'Last week',
    'Last month',
    'Last year',
    Custom
}
