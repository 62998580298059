import * as _ from 'lodash';
import ReferenceIdUtil from 'src/app/_helpers/reference-id.utils';
import {WarehouseModel} from 'src/app/_models/warehouses/warehouse.model';
import WarehouseCart from './carts/warehouse-cart.entity';
import WarehouseAisle from './locations/warehouse-aisle.entity';

export default class Warehouse extends WarehouseModel {

    public readonly warehouseCartEntities: WarehouseCart[] = [];
    public readonly warehouseAisleEntities: WarehouseAisle[] = [];

    constructor(warehouse: WarehouseModel) {
        super();
        _.assignIn(this, warehouse);

        this.warehouseCartEntities = this.warehouseCarts
            .map(cart => new WarehouseCart(cart))
            .sort((a, b) => {
                return ReferenceIdUtil.AlphabeticToNumeric(a.referenceId) - ReferenceIdUtil.AlphabeticToNumeric(b.referenceId);
            });

        this.warehouseAisleEntities = this.warehouseAisles
            .map(aisle => new WarehouseAisle(aisle))
            .sort((a, b) => {
                return ReferenceIdUtil.AlphabeticToNumeric(a.referenceId) - ReferenceIdUtil.AlphabeticToNumeric(b.referenceId);
            });
    }
}
