import { Injectable } from '@angular/core';
import Papa from 'papaparse';

@Injectable({
  providedIn: 'root'
})
export class CsvFileService {

  createFile(headers: string[], data: unknown[]) {
    const csv = Papa.unparse({
      data,
      headers
    });

    const blob = new Blob([csv]);

    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);

    a.download = 'CSV Export.csv';

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  toCsvChunks<T>(data: T[], fileName: string, limit: number) {
    const chunks = this.dataToChunks(data, limit);
    const totalChunks = chunks.length;
    return chunks.map((chunk, index) => this.toCsvFormData(chunk, fileName, index+1, totalChunks));
  }

  dataToChunks<T>(data: T[], limit: number): T[][] {
    return data.reduce((chunks: T[][], _: T, i: number) => {
      if (!(i % limit)) {
        chunks.push(data.slice(i, i + limit));
      }
      return chunks;
    }, []);
  }

  toCsvFormData(chunk: unknown[], fileName: string, chunkNumber: number, totalChunks: number): FormData {
    const csv = Papa.unparse(chunk);
    const csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const formData = new FormData();
    
    if (totalChunks > 1) {
      const index = fileName.lastIndexOf('.');
      const extension = index > 0 ? fileName.slice(index) : '';
      const baseName = fileName.replace(extension, "");
      fileName = `${baseName}-${chunkNumber}of${totalChunks}${extension}`;
    }

    formData.append('file', csvData, fileName);
    return formData;
  }
}
