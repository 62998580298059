import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Location } from "@angular/common";
import { TranslationService } from './_services/translation.service';
import { locale as enLang } from './_config/i18n/en';
import { locale as chLang } from './_config/i18n/ch';
import { locale as esLang } from './_config/i18n/es';
import { locale as jpLang } from './_config/i18n/jp';
import { locale as deLang } from './_config/i18n/de';
import { locale as frLang } from './_config/i18n/fr';
import { Router } from '@angular/router';
// Services
import { ChatSupportService } from './_services/chat-support.service';
// FontAwsome 
import { Store } from '@ngxs/store';
import UserState from './states/user/user.state';
import { UntilDestroy } from '@ngneat/until-destroy';
import { HelperService } from './_services/helper.service';
import { Subscription } from 'rxjs';
import Intercom from '@intercom/messenger-js-sdk';
import { environment } from 'src/environments/environment';

Intercom({
  app_id: environment.intercomAppId,
});

@UntilDestroy({ checkProperties: true, arrayName: '_subscriptions' })
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('cancel') cancelButton: ElementRef<HTMLElement>;

  title = 'Inventory Ahead';
  disabledDefaultComponentSetup: boolean;
  loadingSubscription: Subscription;
  loading: boolean;

  constructor(
    private translationService: TranslationService,
    private location: Location,
    private router: Router,
    private stateStore: Store,
    public chatService: ChatSupportService,
    private helperService: HelperService
  ) {
    this.translationService.loadTranslations(enLang, chLang, esLang, jpLang, deLang, frLang);
  }

  ngOnInit() {
    const currentPath = this.location.path();
    const currentUser = this.stateStore.selectSnapshot(UserState);


    if (!currentPath.startsWith('/login')) {
      if (currentUser && !currentUser.organizationName
        && currentPath != '/register' && currentPath != 'register?step=2')
        this.router.navigateByUrl('register?step=2');
      if (currentUser && currentUser.organizationName && currentUser.subscriptionStatus != 'active'
        && currentUser.subscriptionStatus != 'trialing' && !currentUser.hasPricingPlan
        && currentPath != 'register?step=3')
        this.router.navigateByUrl('register?step=3');
    }

    this.loadingSubscription = this.helperService.loading$.subscribe(loading => {
      this.loading = loading;
    });
  }

  ngOnDestroy(): void {
    this.loadingSubscription.unsubscribe();
  }
}
