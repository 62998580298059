<!-- begin:: Aside -->
<button class="kt-aside-close" id="kt_aside_close_btn">
  <i class="la la-close"></i>
</button>

<div class="kt-aside kt-aside--fixed kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop" id="kt_aside"
  [class.kt-asides]="expendBar">
  <div class="collapse-icon-box cursor-pointer" (click)="stickAside()">
    <i class="fa" [ngClass]="expanded ? 'fa-angle-left' : 'fa-angle-right'"></i>
  </div>
  <!-- begin:: Aside Menu -->
  <div class="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid position-relative" id="kt_aside_menu_wrapper"
    (mouseenter)="isSticky ? null : maximize()" (mouseleave)="isSticky ? null : minimize()"> 
    <ng-scrollbar visibility="hover">
      <div id="kt_aside_menu" class="kt-aside-menu" data-ktmenu-vertical="1" data-ktmenu-scroll="1"
        data-ktmenu-dropdown-timeout="500" [ngStyle]="{'max-height': '100vh', 'position': 'relative'}">
        <ul class="kt-menu__nav">
          <ng-container *ngFor="let item of items">
            <li *ngIf="item.subItems.length == 0" class="kt-menu__item" [ngClass]="{
                  'kt-menu__item--active': this.router.url == item.url
               }" aria-haspopup="true" [ngbTooltip]="!!item.toolTip ? item.toolTip : null" tooltipClass="kt-tooltip">
              <div *ngIf="!item.url || item.disabled" [class.disabled]="item.disabled">
                <i class="kt-menu__link-icon" [ngClass]="item.icon"></i>
                <span class="kt-menu__link-text">
                  {{item.title}}
                </span>
              </div>
              <ng-container *ngIf="!item.disabled">
                <a *ngIf="item.url && item.isRouterLink" class="kt-menu__link" [target]="item.target"
                  [routerLink]="item.url" [queryParams]="item.queryParams" [class.disabled]="item.disabled">
                  <i class="kt-menu__link-icon" [ngClass]="item.icon"></i>
                  <span class="kt-menu__link-text">
                    {{item.title}}
                  </span>
                </a>
                <a *ngIf="item.url && item.isHref" class="kt-menu__link" [href]="item.url" [target]="item.target"
                  [class.disabled]="item.disabled">
                  <i class="kt-menu__link-icon" [ngClass]="item.icon"></i>
                  <span class="kt-menu__link-text">{{item.title}}
                  </span>
                </a>
              </ng-container>
            </li>
            <li #parentMenuItem *ngIf="item.subItems.length > 0" class="kt-menu__item kt-menu__item--submenu" [ngClass]="{
                  'kt-menu__item--open': toggled[item.title],
                  'kt-menu__item--active': hasUrl(item)
                }" data-ktmenu-submenu-toggle="hover">
              <!--            <div #parentMenuItem>-->
              <div *ngIf="!item.url || item.disabled" (click)="toggle(item)" class="kt-menu__link kt-menu__toggle"
                [class.disabled]="item.disabled">
                <i class="kt-menu__link-icon" [ngClass]="item.icon"></i>
                <span class="kt-menu__link-text">
                  {{item.title}}
                </span>
                <i class="kt-menu__ver-arrow la la-angle-right"></i>
              </div>
              <ng-container *ngIf="!item.disabled">
                <a *ngIf="item.url && item.isRouterLink" [routerLink]="item.url" [queryParams]="item.queryParams"
                  [target]="item.target" (click)="toggle(item)" class="kt-menu__link kt-menu__toggle"
                  [class.disabled]="item.disabled">
                  <i class="kt-menu__link-icon" [ngClass]="item.icon"></i><span class="kt-menu__link-text">
                    {{item.title}}
                  </span>
                  <i class="kt-menu__ver-arrow la la-angle-right"></i>
                </a>
                <a *ngIf="item.url && item.isHref" [href]="item.url" [target]="item.target" (click)="toggle(item)"
                  class="kt-menu__link kt-menu__toggle" [class.disabled]="item.disabled">
                  <i class="kt-menu__link-icon" [ngClass]="item.icon"></i>
                  <span class="kt-menu__link-text">
                    {{item.title}}
                  </span>
                  <i class="kt-menu__ver-arrow la la-angle-right"></i>
                </a>
              </ng-container>
              <!--Tooltip -->
              <tooltip *ngIf="!!item.toolTip" [targetRef]="parentMenuItem">
                {{item.toolTip}}
              </tooltip>
              <!--            </div>-->
              <!-- Sub menus -->
              <div class="kt-menu__submenu">
                <span class="kt-menu__arrow">

                </span>
                <ul class="kt-menu__subnav">
                  <ng-container *ngFor="let subItem of item.subItems">
                    <li *ngIf="subItem.subItems.length == 0" class="kt-menu__item" aria-haspopup="true"
                      [ngbTooltip]="!!subItem.toolTip ? subItem.toolTip : null" tooltipClass="kt-tooltip">
                      <div *ngIf="!subItem.url || subItem.disabled" class="kt-menu__link"
                        [class.disabled]="subItem.disabled">
                        <i class="kt-menu__link-bullet kt-menu__link-bullet--dot">
                          <span></span>
                        </i>
                        <span class="kt-menu__link-text">
                          {{subItem.title}}
                        </span>
                      </div>
                      <ng-container *ngIf="!subItem.disabled">
                        <a *ngIf="subItem.url && subItem.isRouterLink" [routerLink]="subItem.url"
                          [queryParams]="subItem.queryParams" [target]="subItem.target" class="kt-menu__link"
                          [class.disabled]="subItem.disabled">
                          <i class="kt-menu__link-bullet kt-menu__link-bullet--dot">
                            <span></span>
                          </i>
                          <span class="kt-menu__link-text">
                            {{subItem.title}}
                          </span>
                        </a>
                        <a *ngIf="subItem.url && subItem.isHref" [href]="subItem.url" [target]="subItem.target"
                          class="kt-menu__link" [class.disabled]="subItem.disabled">
                          <i class="kt-menu__link-bullet kt-menu__link-bullet--dot">
                            <span></span>
                          </i>
                          <span class="kt-menu__link-text">
                            {{subItem.title}}
                          </span>
                        </a>
                      </ng-container>
                    </li>
                    <li *ngIf="subItem.subItems.length > 0" class="kt-menu__item kt-menu__item--submenu" [ngClass]="{
                        'kt-menu__item--open': subToggled[item.title][subItem.title]
                      }" aria-haspopup="true" data-ktmenu-submenu-toggle="hover">
                      <a #subItemParentmenu href="javascript:;" (click)="subToggle(item, subItem)"
                        [target]="subItem.target" class="kt-menu__link kt-menu__toggle"
                        [class.disabled]="subItem.disabled" [ngbTooltip]="!!subItem.toolTip ? subItem.toolTip : null"
                        tooltipClass="kt-tooltip">
                        <i class="kt-menu__link-bullet kt-menu__link-bullet--dot">
                          <span></span>
                        </i>
                        <span class="kt-menu__link-text">
                          {{subItem.title}}
                        </span>
                        <i class="kt-menu__ver-arrow la la-angle-right"></i>
                      </a>
                      <div class="kt-menu__submenu">
                        <span class="kt-menu__arrow"></span>
                        <ul class="kt-menu__subnav">
                          <ng-container *ngFor="let subItem1 of subItem.subItems">
                            <li #subMenuSubItem class="kt-menu__item" aria-haspopup="true"
                              [ngbTooltip]="!!subItem1.toolTip ? subItem1.toolTip : null" tooltipClass="kt-tooltip">
                              <a [routerLink]="subItem1.url && subItem1.isRouterLink ? subItem1.url : null"
                                [queryParams]="subItem1.queryParams"
                                [href]="subItem1.url && subItem1.isHref ? subItem1.url : null"
                                [target]="subItem1.target" class="kt-menu__link" [class.disabled]="subItem1.disabled">
                                <i class="kt-menu__link-bullet kt-menu__link-bullet--line">
                                  <span></span>
                                </i>
                                <span class="kt-menu__link-text">
                                  {{subItem1.title}}
                                </span>
                              </a>
                            </li>
                          </ng-container>
                        </ul>
                      </div>
                    </li>
                  </ng-container>
                </ul>
              </div>
            </li>
          </ng-container>
        </ul>
      </div>
    </ng-scrollbar>
  </div>
  <!-- end:: Aside Menu -->
</div>

<!-- end:: Aside -->