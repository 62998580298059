<div class="container-fluid">
    <div class="row  wrapper">
        <div class="col-md-6 quotes-wrapper d-md-block d-none ">
            <div style=" min-height: 100vh;font-size: 36px;" class=" d-flex align-items-center text-white">
                <div class="introText" >
                    <div class="mb-2"><i class="fa fa-quote-left custom-text-color" style="font-size: 28px;" aria-hidden="true"></i></div>
                    <div [innerHTML]="introtext"></div>
                </div>
            </div>
            <!-- <ngb-carousel >
                <ng-template ngbSlide>
                    <div class="carousel-caption">
                        <div>
                            <i class="fa fa-quote-left text-info" aria-hidden="true"></i> <br>
                            Inventory Management is
                            not the <span class="fw-bold">main thing</span> to
                            success, its the <span class="fw-bold"> ONLY</span>
                            thing to success.</div>
                        <p></p>
                    </div>
                 </ng-template>
                 <ng-template ngbSlide>
                    <div class="carousel-caption">
                        <div>
                            <i class="fa fa-quote-left text-info" aria-hidden="true"></i> <br>
                            A good system <span class="fw-bold">shortens</span> the
                            road to the goal.</div>
                        <p></p>
                    </div>
                 </ng-template>
                 <ng-template ngbSlide>
                    <div class="carousel-caption">
                        <div>
                            <i class="fa fa-quote-left text-info" aria-hidden="true"></i> <br>
                            Inventory Management is
                            not the <span class="fw-bold">main thing</span> to
                            success, its the <span class="fw-bold"> ONLY</span>
                            thing to success.</div>
                        <p></p>
                    </div>
                 </ng-template>
                 <ng-template ngbSlide>
                    <div class="carousel-caption">
                        <div>
                            <i class="fa fa-quote-left text-info" aria-hidden="true"></i> <br>
                            A good system <span class="fw-bold">shortens</span> the
                            road to the goal.</div>
                        <p></p>
                    </div>
                 </ng-template>
              </ngb-carousel> -->
        </div>
        <div class="col-md-6 py-3 bg-white h-100" id="signUpWrapper">
           <div class="signUpWrapperInner">
                <div class="mt-5">
                  <a href="https://www.inventoryahead.com/">
                    <img src="assets/images/landing/inventory-ahead-logo.svg" style="height: 60px" alt="inventory ahead">
                  </a>
                </div>
                <div  class="signUpForm row align-items-center">
                    <form  class="col-12" method="post" [formGroup]="loginForm">
                        <div style="max-width: 430px;" class="alert alert-danger alert-dismissible" role="alert" *ngIf="errMessage">
                            <div class="alert-text">{{ errMessage }}</div>
                            <div class="alert-close">
                                <i class="flaticon2-cross kt-icon-sm cursor-pointer" data-dismiss="alert"
                                    (click)="errMessage = ''"></i>
                            </div>
                        </div>
                        <div style="max-width: 430px;">
                                <div class="h2 fw-bold text-black mb-5">Sign in</div>
                                <div class="form-group position-relative">
                                    <i class="fa fa-envelope input-form-icons" aria-hidden="true"></i>
                                    <label for="exampleInputEmail1" class="font-600 text-black">Email</label>
                                    <input type="email" class="form-control" formControlName="email"
                                    placeholder="Email" name="email" autocomplete="off"  aria-describedby="email-error" aria-describedby="emailHelp" placeholder="Enter email">
                                </div>
                                <div class="form-group position-relative">
                                    <i class="fa fa-lock input-form-icons" aria-hidden="true"></i>
                                    <label for="exampleInputPassword1" class="font-600 text-black">Password</label>
                                    <input [type]="showPassword ? 'text' : 'password'" class="form-control form-control-last" formControlName="password" type="password" placeholder="Password" name="password"
                                    aria-describedby="password-error">
                                    <i [class]="passwordIconValue" aria-hidden="true" (click)="passwordShow()"></i>
                                </div>
                                <div class="flex mb-3 justify-content-between">
                                    <input name="__RequestVerificationToken" type="hidden"
                                    value="CfDJ8IRJRDZFnYRIm6YHSnt_jlM5mU7GhaWkuzZOnhi4b7OBnf1KrHZEfqnP_gxWSIyy37i3K6OnmH_B1V4EmRmv6ZqCYTXPyrqp40iDOfy8GnRNEq2iDkeffhgVXjJRAVkEKJeM5fHwLXdphVCR7BhDRgk"><input
                                    name="RememberMe" type="hidden" value="false">
                                    <div class="form-group pr-3">
                                        <a routerLink="/forgot-password" class="font-600 text-primary-blue">Forgot password?</a>
                                    </div>
                                </div>
                                <!-- btn btn-lg btn-block signInBtn py-3 bg-primary-blue text-white btn-elevate -->
                                <button type="submit" (click)="submit()" [disabled]="loading" class="btn btn-lg w-100 signInBtn py-3 bg-primary-blue text-white btn-elevate" [ngClass]="loading ?  'kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light' : ''">Sign in</button>
                        </div>
                    </form>
                </div>
                <div  class="font-500">Don't have an account yet? <a routerLink="/register"><span class="text-primary-blue">Create an account</span></a></div>
           </div>
        </div>
    </div>
</div>
