export namespace Patterns {
    
    export const URL_HTTPS_PATTERN = 'https:\\/\\/';
    
    export const URL_SCHEAMA_PATTERN = URL_HTTPS_PATTERN.replace('s', '(s)?');
    
    export const URL_DOMAIN_PATTERN = `((?:[\\w-]+(?:(?:\\.[\\w-]+)+))|localhost)`;
    
    export const URL_PATH_N_QUERY_PATTERN = `([\\w.,@?^=% &:\\/ ~+#-]*[\\w@?^=%&\\/~+#-])?`;
    
    export const DISCOUNT_RATE_PATTERN = `^((\\d{0,2}(\\.\\d{1,2})?)|100)$`;
    
    export const POSITIVE_NUMBER_PATTERN = `^\\d+(\\.\\d{1,2})?$`;
    
    export const SIMPLE_BASE64_DATA_URL_PATTERN = '^data:image\/.+;base64,.+$';

    /***
     * Source: https://github.com/angular/angular/blob/main/packages/forms/src/validators.ts#L125
     */
    export const EMAIL_REGEXP = /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
}
