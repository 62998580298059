<div *ngIf="isHovered" class="flex flex-col items-center" style="position:fixed;"
    [style.left.px]="tooltipLeft(content)"
    [style.top.px]="tooltipTop(content)"
>
    <span #topPoint *ngIf="!isBelowHorizontalCenterLine" class="leading-0.7 text-transparent-black">▲</span>
    <span #content class="transparent-black py-2.5 px-4 w-max text-white rounded text-center">
        <ng-content>

        </ng-content>
    </span>
    <span #bottomPoint *ngIf="isBelowHorizontalCenterLine" t class="leading-0.7 text-transparent-black">▼</span>

</div>
