import { Injectable } from '@angular/core';
import { LocationsService } from '../_services/locations.service';
import { LocationModel } from '../_models/location.model';
import * as _ from 'lodash';

@Injectable()
export class Locations {
  locations: LocationModel[] = [];
  loaded: boolean = false;
  loadRequested: boolean = false;
  callBacks: Function[] = [];

  constructor(private locationsService: LocationsService) { }

  getAll(callBack: Function = null) {
    if (!this.loaded)
      this.reload(callBack);
    else if (callBack)
      callBack(this.locations);
    return this.locations;
  }

  reload(callBack: Function = null) {
    if (callBack)
      this.callBacks.push(callBack);
    if (!this.loadRequested) {
      this.loadRequested = true;
      this.locationsService.getAll().subscribe((result) => {
        this.locations = result;
        this.loaded = true;
        for (let calback of this.callBacks) {
          calback(result);
          this.callBacks = this.callBacks.filter(cb => cb != calback);
        }
        this.loadRequested = false;
      });
    }
  }

  getDefaultLocation(defaultLocationCallback: (location: LocationModel | null) => void, firstIfNoneFound: boolean = false) {
    this.getAll(locations => {
      let defaultLocation = locations.find(location => location.isDefault);
      if (!defaultLocation && firstIfNoneFound) {
        defaultLocation = _.first(locations);
      }
      defaultLocationCallback(defaultLocation);
    })
  }
}
