import { Injectable } from '@angular/core';
import { Selector, State } from '@ngxs/store';
import Utils from 'src/app/_helpers/utils';
import PickListsFiltersModel from 'src/app/_models/self-ship/pick-lists/pick-lists-filters.model';
import { PickingProcessingStatus } from 'src/app/_entities/orders/processing-statuses/picking-processing-status';
import { Computed, DataAction, StateRepository } from '@angular-ru/ngxs/decorators';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import WarehousesEntitiesState from '../../../warehouses/warehouses-entities.state';
import PickListItem from '../../../../_entities/pick-lists/pick-list-item.entity';
import { PickListItemSortBy } from '../../../../_enums/self-ship/pick-list-item-sort-by.enum';
import { EntityMapId } from '../../../../_builders/i-entity-map';
import PickList from '../../../../_entities/pick-lists/pick-list.entity';
import { StatesHelper } from '../../../states.helper';
import ProcessingStatusHelper, { PickingStatusHelper } from 'src/app/_helpers/processing-status.helper';

@StateRepository()
@State({
    name: 'pickListsFilters',
    defaults: new PickListsFiltersModel()
})
@Injectable()
export class PickListsFiltersState
    extends NgxsDataRepository<PickListsFiltersModel> {
    constructor(
        private warehouseEntitiesState: WarehousesEntitiesState
    ) {
        super();
    }

    // Computed

    @Computed()
    public get selectedWarehousesName() {
        const warehouseIdsLength = Object.keys(this.snapshot.warehouseIds).length;
        return (warehouseIdsLength === this.warehouseEntitiesState.ids.length || !warehouseIdsLength)
            ? 'All locations'
            : warehouseIdsLength > 1
                ? `${warehouseIdsLength} locations`
                : this.warehouseEntitiesState.entities[Object.keys(this.snapshot.warehouseIds)[0]].name;
    }

    @Computed()
    public get selectedStatusesName() {
        const statusFiltersLength = this.snapshot.statuses.length;
        if (!statusFiltersLength || statusFiltersLength === Object.keys(PickingProcessingStatus).length / 2) {
            return 'All statuses';
        } else if (statusFiltersLength === 1) {
            return PickingStatusHelper.mapPickingStatusName(this.snapshot.statuses[0]);
        } else if (statusFiltersLength > 1) {
            return `${statusFiltersLength} statuses`;
        }

    }

    @Computed()
    public get selectedBatches() {
        return StatesHelper.picklistsEntitiesState
            .picklistsEntityMap
            .subset(...Object.keys(this.snapshot.selected) as unknown as EntityMapId<PickList>[]);
    }

    @Computed()
    public get selectedBatchesIncludesEntireRange() {
        return (range: number[]) => {
            return range.every(id => this.snapshot.selected[id]);
        };
    }

    @Computed()
    public get selectedBatchItemToScan() {
        return StatesHelper.getEntity(StatesHelper.picklistsEntitiesState, this.snapshot.selectedBatchId, PickList)
            ?.picklistItems
            ?.find(item => item.id === this.snapshot.selectedBatchItemId);
    }

    @Selector()
    static getSelectedBatchItemId(state: PickListsFiltersModel): number {
      return state.selectedBatchItemId;
    }

    // Actions
    @DataAction()
    public toggleSelectBatch(batchId: number) {
        const newSelectedFilter = this.snapshot.selected;

        if (newSelectedFilter[batchId]) {
            delete newSelectedFilter[batchId];
        } else {
            newSelectedFilter[batchId] = true;
        }
        this.patchState({
            selected: newSelectedFilter
        });
    }

    @DataAction()
    public toggleSelectRange(range: number[]) {
        const newSelectedFilter = this.snapshot.selected;
        range.forEach(id => {
            if (newSelectedFilter[id]) {
                delete newSelectedFilter[id];
            } else {
                newSelectedFilter[id] = true;
            }
        });
        this.patchState({
            selected: newSelectedFilter
        });
    }

    @DataAction()
    public toggleSelectStatusFilter(status: PickingProcessingStatus) {
        let newStatusFilter = this.snapshot.statuses;
        if (this.snapshot.statuses.includes(status)) {
            newStatusFilter = newStatusFilter.filter(s => s !== status);
        } else {
            newStatusFilter.push(status);
        }
        this.patchState({
            statuses: newStatusFilter
        });
    }

    @DataAction()
    public setStatusToViewUnprocessed() {
        this.patchState({ statuses: [PickingProcessingStatus.None, PickingProcessingStatus.Picking] });
    }

    @DataAction()
    public toggleSelectLocationFilter(warehouseId: number) {
        const newWarehouseFilter = this.snapshot.warehouseIds;
        if (newWarehouseFilter[warehouseId]) {
            delete newWarehouseFilter[warehouseId];
        } else {
            newWarehouseFilter[warehouseId] = true;
        }

        this.patchState({
            warehouseIds: newWarehouseFilter
        });
    }

    @DataAction()
    public toggleViewBy() {
        this.setState({
            ...this.snapshot,
            viewBy: +!this.snapshot.viewBy
        });
    }

    @DataAction()
    public setSearchTerm(term: string) {
        this.setState({
            ...this.snapshot,
            searchTerm: term
        });
    }

    @DataAction()
    public selectBatchItem(batchItem: PickListItem) {
        this.patchState({
            selectedBatchItemId: batchItem?.id
        });
    }

    @DataAction()
    public selectBatchId(pickListId: number) {
        this.patchState({
            selectedBatchId: pickListId
        });
    }

    @DataAction()
    public selectNextBatchItem() {
        const selected = this.snapshot.selectedBatchItemId;
        const items = StatesHelper.picklistsEntitiesState.picklistsEntityMap.entities[this.snapshot.selectedBatchId]?.picklistItems;
        const currentIndex = items.findIndex(pli => pli.id === selected);
        this.patchState({
            selectedBatchItemId: (items[currentIndex + 1]?.id ?? items[currentIndex]?.id)
        });
    }

    @DataAction()
    public selectPreviousBatchItem() {
        const selected = this.snapshot.selectedBatchItemId;
        const items = StatesHelper.picklistsEntitiesState.picklistsEntityMap.entities[this.snapshot.selectedBatchId]?.picklistItems;
        const currentIndex = items.findIndex(pli => pli.id === selected);
        this.patchState({
            selectedBatchItemId: (items[currentIndex - 1]?.id ?? items[currentIndex]?.id)
        });
    }

    @DataAction()
    public setPickListItemSearchTerm(term: string) {
        this.patchState({
            picklistItemSearchTerm: term
        });
    }

    @DataAction()
    public setPicklistItemSortBy(by: PickListItemSortBy) {
        this.patchState({
            picklistItemSortBy: by
        });
    }

    @DataAction()
    public togglePicklistItemSortOrder() {
        this.patchState({
            picklistItemSortOrder: +!this.snapshot.picklistItemSortOrder
        });
    }
} 
