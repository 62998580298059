import { OrderLatestStatus } from 'src/app/_entities/orders/processing-statuses/order-latest-status';
import { OrderProcessingStatus } from 'src/app/_entities/orders/processing-statuses/order-processing-status';
import { BaseModel } from '../../_base.model';
import { OrdersFeesChargeModel } from './order-fee-charge.model';

export class OrderItemModel extends BaseModel {
    id: number;
    orderId: number;
    productId: number;
    status: Status;
    processingStatus: OrderProcessingStatus;
    quantityOrdered: number;
    quantityShipped: number;
    quantityPicked: number;
    warehouseId?: number;
    itemPrice: number;
    itemShippingCost: number;
    shippingPrice?: number;
    itemTax?: number;
    markedAsPicked: boolean;
    latestStatus: OrderLatestStatus;
    ordersFeesCharge?:  OrdersFeesChargeModel;
}

export type Status = 'Unshipped' | 'Shipped' | 'Canceled' | 'Pending' | 'Proccessing' | 'FulFillMentCreated' | 'ReadyToShip' | 'OnHold';
