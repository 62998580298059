import { Pipe, PipeTransform, QueryList } from "@angular/core";
import { Datacolumn } from "../datacolumn/datacolumn.component";

type DatacolumnType = Datacolumn[] | QueryList<Datacolumn>;

@Pipe({
  name: 'visibleColumns',
})
export class VisibleColumnsPipe implements PipeTransform {
  transform(columns: DatacolumnType, visible: Boolean = true, freezed: 'left' | 'right' = undefined): any {
    if (!columns) {
      return [];
    }
    let result = columns.filter((col) => col.hasPermission && col.visible === visible && col.freezed === freezed && col.subDatatableColumn === false);
    if (!result?.length) {
      result = columns.filter((col) => col.hasPermission && col.visible === visible && col.freezed === freezed);
    }
    return result;
  }
}