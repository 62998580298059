<div class="kt-grid  kt-grid--hor kt-grid--root  kt-login kt-login--v6 kt-login--signin" id="kt_login" style="flex: 1 auto; height: 100vh;">
	<div class="d-flex kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile" style="height: 100%;">
		<div class="kt-grid__item  kt-grid__item--order-tablet-and-mobile-2  kt-grid kt-grid--hor kt-login__aside">
			<div class="kt-login__wrapper">
				<div class="kt-login__container">
					<div class="kt-login__body">
						<div class="kt-login__logo">
							<a href="#">
								<img height="60" src="assets/images/landing/inventory-ahead-logo.svg">
							</a>
						</div>
						<div class="kt-login__signin">
							<div class="kt-login__head">
								<h3 class="kt-login__title">{{ 'AUTH.RESET_PASSWORD.TITLE' | translate }}</h3>
							</div>
							<div class="kt-login__form" [formGroup]="resetPasswordForm">
								<div class="form-group">
									<input class="form-control" formControlName="newPassword" type="Password" autocomplete="off"
										placeholder="New Password">
								</div>
								<div class="form-group">
									<input class="form-control" formControlName="passwordConfimration" type="Password" autocomplete="off"
										placeholder="New Password Confirmation">
								</div>
								<div class="kt-login__actions">
									<button (click)="submit()" [disabled]="loading" class="btn btn-brand btn-pill btn-elevate"
										[ngClass]="loading ? 'kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light': ''">
										{{ 'AUTH.RESET_PASSWORD.BUTTON' | translate }}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="kt-login__account">
					<span class="kt-login__account-msg">
						Don't have an account yet ?
					</span>&nbsp;&nbsp;
					<a routerLink="/register" id="kt_login_signup" class="kt-login__account-link">Sign Up!</a>
				</div>
			</div>
		</div>
		<div class="kt-grid__item kt-grid__item--fluid kt-grid__item--center kt-grid kt-grid--ver kt-login__content">

		</div>
	</div>
</div>