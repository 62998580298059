import DateFilterOption, { DateFilterSelection } from 'src/app/components/date-range-dropdown/date-filter-option';
import { SortOrder } from 'src/app/_enums/general/sort-order.enum';
import { OrderProcessingStatus } from 'src/app/_entities/orders/processing-statuses/order-processing-status';
import {ProcessingStatus} from '../../../_entities/orders/processing-statuses/processing-status';
import EntityMap from '../../../_builders/entity-map';

export class OrdersFiltersModel {
  storeIds: IIdMap = {};
  warehouseIds: IIdMap = {};
  // TODO LocationIds: number[];
  // TODO AssignedToIds: number[];
  dateRange: DateRange = DateFilterOption.createInstance(DateFilterSelection['Last month']);
  searchTerm = '';
  orderStatuses: OrderProcessingStatus[] = [ProcessingStatus.Open];
  // TODO TagIds: number[];
  sortBy: OrderSortBy = OrderSortBy.Order_Date;
  sortOrder: SortOrder = SortOrder.Desc;
  selected: IIdMap = {};
  alreadyFetched?: {shipped: boolean, canceled: boolean, others} =  {
    shipped: false,
    canceled: false,
    others: false
  };
  timestamp: Date;
}

export enum OrderSortBy {
    Store,
    Order_Date,
    Customer_Name,
    Total_Quantity,
    Status
}

export class DateRange {
    from: Date;
    to: Date;
    selection: DateFilterSelection;
}

export interface IIdMap {
    [key: number]: boolean;
}

