import * as _ from 'lodash';
import { PackingProcessingStatus } from 'src/app/_entities/orders/processing-statuses/packing-processing-status';
import { PickingProcessingStatus } from 'src/app/_entities/orders/processing-statuses/picking-processing-status';
import { ProcessingStatus } from 'src/app/_entities/orders/processing-statuses/processing-status';
import { ShippingProcessingStatus } from 'src/app/_entities/orders/processing-statuses/shipping-processing-status';
import ProcessingStatusHelper from 'src/app/_helpers/processing-status.helper';
import { OrderModel } from 'src/app/_models/orders/order.model';
import OrderBox from './order-boxes/order-box.entity';
import OrderItem from './order-item.entity';
import { StatesHelper } from '../../states/states.helper';
import StoreEntity from '../stores/store.entity';
import { Shipment } from '../shipments/shipment.entity';
import { StoreType } from 'src/app/_enums/stores/store-type.enum';

export default class Order extends OrderModel {
    public readonly orderItemEntities: OrderItem[] = [];
    public readonly orderBoxes: OrderBox[] = [];

    constructor(order: OrderModel) {
        super();
        _.assignIn(this, order);
        this.orderItemEntities = this.items.map(item => new OrderItem(item));
        this.orderBoxes = this.boxes?.map(box => new OrderBox(box));
    }

    get store(): StoreEntity {
        return new StoreEntity(StatesHelper.storesEntitiesState.entities[this.storeId]);
    }

    get shipments(): Shipment[] {
        const orderBoxIds = this.orderBoxes.map(box => box.id);
        return StatesHelper.shipmentsEntitiesState
            .entitiesArray
            .filter(shipment => shipment.orderBoxIds.some(id => orderBoxIds.includes(id)))
            .map(shipment => new Shipment(shipment));
    }

    // Statuses
    get isOpen() {
        return !!(ProcessingStatusHelper.getProcessingStatus(this)?.value & ProcessingStatus.Open.value);
    }

    get isClosed() {
        return !!(ProcessingStatusHelper.getProcessingStatus(this).value & ProcessingStatus.Closed.value);
    }

    get hasNoStatusYet() {
        return !!(ProcessingStatusHelper.getProcessingStatus(this).value & ProcessingStatus.None.value);
    }

    get isBeingPicked() {
        return ProcessingStatusHelper.getProcessingStatus(this).value >= PickingProcessingStatus.Picking.value;
    }

    get isPicked() {
        return ProcessingStatusHelper.getProcessingStatus(this).value >= PickingProcessingStatus.Picked.value;
    }

    get isBeingPacked() {
        return ProcessingStatusHelper.getProcessingStatus(this).value >= PackingProcessingStatus.Packing.value;
    }

    get isPacked() {
        return ProcessingStatusHelper.getProcessingStatus(this).value >= PackingProcessingStatus.Packed.value;
    }

    get isReadyToShip() {
        return this.isPacked;
    }

    get isPartiallyShipped() {
        return ProcessingStatusHelper.getProcessingStatus(this).value >= ShippingProcessingStatus.ShippedPartial.value;
    }

    get isShipped() {
        return ProcessingStatusHelper.getProcessingStatus(this).value >= ShippingProcessingStatus.Shipped.value;
    }

    get isDelivered() {
        return false; // TODO
    }

    get latestProcessingStatus() {
        return ProcessingStatusHelper.getProcessingStatus(this);
    }

    get isLocalStoreOrder() {
        return this.store?.type === StoreType[StoreType.Local];
    }
}
