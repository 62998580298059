import BaseEntityMap from './base-entity-map';
import IEntityMap, {EntityMapId} from './i-entity-map';
import {KeyWithValueOfInterface} from '../_types/type-helpers/type-helpers';
import EntityMapper from './entity.mapper';
import {FlatEntityMap} from './flat-entity-map';
import CompositeEntityMap from './composite-entity-map';

export default class EntityMap<T> extends BaseEntityMap<T> {
    public entityKey: EntityMapId<T, unknown>;

    constructor(map: IEntityMap<T>, entityKey: KeyWithValueOfInterface<T, unknown> = 'id' as KeyWithValueOfInterface<T, unknown>) {
        super(map);
        this.entityKey = entityKey;
    }

    public get ids(): number[] {
        return Object.keys(this._map).map(key => +key);
    }

    public get entities(): IEntityMap<T> {
        return this._map as IEntityMap<T>;
    }

    public static concat<T>(...maps: EntityMap<T>[]) {
        const entityKey = maps[0].entityKey;
        let results: IEntityMap<T> = {};
        maps.forEach(map => results = {...results, ...map.entities});
        return new EntityMap(results, entityKey);
    }

    public concat(...maps: EntityMap<T>[]) {
        let results: IEntityMap<T> = {};
        maps.forEach(map => results = {...results, ...map.entities});
        return new EntityMap(results, this.entityKey);
    }

    /** Returns a subset of the EntityMap
     *  (e.g <EntityMap>{
     *                      1: {id: 1, name: John},
     *                      2: {id: 2, name: Daniel},
     *                      3: {id: 3, name: Micheal}
     *                  }.subset(...[2, 3]));
     * returns:
     * <EntityMap>{
     *                2: {id: 2, name: Daniel},
     *                3: {id: 3, name: Micheal}
     *            }
     * @param keys
     * @return EntityMap<T>
     */
    public subset(...keys: EntityMapId<T, unknown>[]): EntityMap<T>;
    public subset(...keys: (string | number | symbol)[]): EntityMap<T>;
    public subset(...keys: (string | number | symbol | EntityMapId<T, unknown>)[]): EntityMap<T> {
        const subset = keys.reduce((results, key: string | number | symbol) => {
            if (this._map[key]) {
                results[key] = this._map[key];
            }
            return results;
        }, {});
        return new EntityMap(subset);
    }

    /** Flattens an object by returning a combined CompositeEntityMap of a specific field (whose value is of type EntityMap),
     * @param field field must be a key of the EntityType and its value must be of type EntityMap
     */
    public subEntities<V>(field: KeyWithValueOfInterface<T, V>): V {
        if (!this.count) { return new EntityMap<T>({}, this.entityKey) as unknown as V }

        const returnInstance = Object.values(this._map)[0][field] instanceof EntityMap
            ? EntityMap
            : Object.values(this._map)[0][field] instanceof CompositeEntityMap
                ? CompositeEntityMap
                : undefined;
        if (!returnInstance) {
            throw new Error(`${this.subEntities.name}: value of '${field.toString()}' is not an instance of any EntityMap`);
        }
        return returnInstance.concat(...Object.values(this._map).map(entity => entity[field as string | number | symbol])) as unknown as V;
    }

    public sort(compareFn?: (a: T, b: T) => number) {
        const values = this.entitiesArray;
        const sortedMap = EntityMapper.mapById(values.sort(compareFn));
        return new EntityMap<T>(sortedMap, this.entityKey);
    }

    public filter<S extends T>(predicate: (value: T, index?: number, array?: T[]) => boolean): EntityMap<T> {
        const values = this.entitiesArray;
        const filteredMap = EntityMapper.mapById(values.filter(predicate), this.entityKey);
        return new EntityMap<T>(filteredMap, this.entityKey);
    }

    public mapEntities<U>(callbackFn: (value: T) => U | ReadonlyArray<U>): FlatEntityMap<U> {
        return new FlatEntityMap<U>(this.ids.reduce((results, id) => {
            results[id] = [this._map[id]].map(callbackFn)[0];
            return results;
        }, {}));
    }
}

