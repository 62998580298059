import { BaseModel } from '../_base.model';

export class QuickBooksAccountsMapping extends BaseModel {
  itemIncomeTypeId: LineItemIncomeType;
  incomeAccountRefId: number;
  assetAccountRefId: number;
  cogsAccountRefId: number;
  taxAccountRefId: number;
  shippingAccountRefId: number;
  everythingInOneItemRefId: number;
  productChargesRefId: number;
  accountsReceivableRefId: number
}

export enum LineItemIncomeType {
    EverythingInOneItem = 1,
    Inventory = 2,
    NonInventory = 3,
}

