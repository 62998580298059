import { ClassBasedEnumBase } from "src/app/libraries/class-based-enum.lib";
import { ProcessingStatus } from "./processing-status";

export class PackingProcessingStatus extends ClassBasedEnumBase {
    private constructor(
        public name: string,
        public value: number,
        public description?: string) {
        super()
    }
    
    static readonly None = new PackingProcessingStatus('None', ProcessingStatus.None.value, 'No packing status');
    static readonly Packing = new PackingProcessingStatus('Packing', ProcessingStatus.Packing.value, ProcessingStatus.Packing.description);
    static readonly Packed = new PackingProcessingStatus('Packed', ProcessingStatus.Packed.value, ProcessingStatus.Packed.description);
}