// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// RxJS
import { Observable, BehaviorSubject, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
// Models
import { StoreModel } from '../_models/store.model';

import { HelperService } from './helper.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

const API_RAINFOREST_API_URL = environment.baseUrl + '/rainforestApi';
// Real REST API
@Injectable()
export class RainforestApiService {
	constructor(private http: HttpClient,
		private helperService: HelperService,
		private toastr: ToastrService,
		private translate: TranslateService) { }

	getAll(): Observable<StoreModel[]> {
		return this.http.get<any>(API_RAINFOREST_API_URL,)
			.pipe(catchError(this.handleError('getAll')));
	}

	private handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			this.toastr.error(this.translate.instant('GENERAL.MESSAGES.ERROR'));
			return throwError(error);
		};
	}
}
