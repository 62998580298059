
export class UserStateModel {
    userName: string;
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    accessToken: string;
    tokenId: string;
    organizationId: number;
    organizationName: string;
    subscriptionStatus: SubscriptionStatus
    hasPricingPlan: boolean;
    exceededDays: number;
    inactiveDays: number;
    roles: string[];
    permissions: string[];
}

export type SubscriptionStatus = "active" | "trialing" | "canceled" | "incomplete_expired" | "past_due";