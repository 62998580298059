// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
// RxJS
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
// Models
import { QuickBooksAccountsMapping } from '../../_models/quickbooks/quickbooks-accounts-mapping.model';

import { HelperService } from '../helper.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import EnumModel from 'src/app/_models/enum.model';
import { QuickBooksStateModel } from 'src/app/states/quickbooks/quickbooks.state.model';
import { QuickBooksJournalEntryMapping } from 'src/app/_models/quickbooks/quickbooks-journal-entry-mapping';
import { QuickBooksStoreLink } from 'src/app/_models/quickBooks-store-link.model';

const API_QUICKBOOKSSETTINGS_URL = environment.baseUrl + '/settings/quickbooks';

@Injectable({
  providedIn: 'root'
})
export class QuickbooksSettingsService {
  constructor(private http: HttpClient,
    private helperService: HelperService,
    private toastr: ToastrService,
    private translate: TranslateService) {
  }

  get(): Observable<QuickBooksStateModel> {
    return this.http.get<QuickBooksStateModel>(API_QUICKBOOKSSETTINGS_URL, { headers: this.helperService.getHTTPHeaders() })
      .pipe(
        catchError(this.helperService.handleError<QuickBooksStateModel>('getQuickbookState'))
      );
  }

  getItemIncomeTypes(): Observable<EnumModel[]> {
    const url = `${API_QUICKBOOKSSETTINGS_URL}/getItemIncomeTypes`;
    return this.http.get<EnumModel[]>(url, { headers: this.helperService.getHTTPHeaders() });
  }

  disconnect(): Observable<any> {
    const url = `${API_QUICKBOOKSSETTINGS_URL}/disconnect`;
    return this.http.delete<any>(url, { headers: this.helperService.getHTTPHeaders() });
  }

  updateQuickBooksAccountsMapping(quickBooksAccountsMapping: QuickBooksAccountsMapping): Observable<any> {
    const url = `${API_QUICKBOOKSSETTINGS_URL}/updateQuickBooksAccountsMapping`;
    return this.http.put<any>(url + `?time=${Date.now()}`, quickBooksAccountsMapping, { headers: this.helperService.getHTTPHeaders() });
  }

  updateQuickBooksJournalEntryMapping(quickBooksJournalEntryMapping: QuickBooksJournalEntryMapping): Observable<any> {
    const url = `${API_QUICKBOOKSSETTINGS_URL}/UpdateQuickBooksJournalEntryMapping`;
    return this.http.put<any>(url, quickBooksJournalEntryMapping, { headers: this.helperService.getHTTPHeaders() });
  }

  updateQuickBooksSellingChargesLink(quickBooksSellingChargesLink: Array<{ id: number, amazonSellingChargesCategoriesId: number; }>): Observable<any> {
    const url = `${API_QUICKBOOKSSETTINGS_URL}/UpdateQuickBooksSellingChargesLink`;
    return this.http.put<any>(url, quickBooksSellingChargesLink, { headers: this.helperService.getHTTPHeaders() });
  }

  updateQuickBooksStoreLinks(quickBooksStoreLinks: QuickBooksStoreLink[]): Observable<any> {
    const url = `${API_QUICKBOOKSSETTINGS_URL}/UpdateQuickBooksStoreLinks`;
    return this.http.put<any>(url, quickBooksStoreLinks, { headers: this.helperService.getHTTPHeaders() });
  }

  getQuickBooksPoStatus(purchaseId: any): Observable<any> {
    const url = `${API_QUICKBOOKSSETTINGS_URL}/getQuickBooksPoStatus/${purchaseId}`;
    return this.http.get<any>(url, { headers: this.helperService.getHTTPHeaders() });
  }

  refreshQuickBooksAccounts(): Observable<any> {
    const url = `${API_QUICKBOOKSSETTINGS_URL}/refreshQuickBooksAccounts`;
    return this.http.get<any>(url, { headers: this.helperService.getHTTPHeaders() });
  }

  downloadQwcFile(): Observable<any> {
    const url = `${API_QUICKBOOKSSETTINGS_URL}/createQwcFile`;
    return this.http.get(url, { headers: this.helperService.getHTTPHeaders(), responseType: 'blob' });
  }

  setQwcPassword(webConnectorPassword: any): Observable<any> {
    const url = `${API_QUICKBOOKSSETTINGS_URL}/setQwcPassword`;
    var params = new HttpParams().set('password', webConnectorPassword);
    return this.http.post<any>(url, params.toString(), { headers: this.helperService.getHTTPHeaders('application/x-www-form-urlencoded') });
  }

  connectQBO(params: URLSearchParams) {
    const url = `${API_QUICKBOOKSSETTINGS_URL}/connectQBO`;
    return this.http.post<any>(url, params.toString(), { headers: this.helperService.getHTTPHeaders('application/x-www-form-urlencoded') });
  }

  linkPosWithQuickBooks(purchaseIds: number[], calculateReceivedChoice: boolean) {
    const url = `${API_QUICKBOOKSSETTINGS_URL}/LinkPurchaseOrders`;
    const content = { purchaseIds, calculateReceived: calculateReceivedChoice };
    return this.http.post<any>(url, content, { headers: this.helperService.getHTTPHeaders() });
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      switch (error.status) {
        case 401:
          window.location.href = '/login';
          break;
        case 422:
          this.toastr.error(this.translate.instant('QUICKBOOKSSETTINGS.MESSAGES.ERRORS.' + error.error.reason, error.error));
          break;
        default:
          this.toastr.error(this.translate.instant('GENERAL.MESSAGES.ERROR'));
      }
      return throwError(error);
    };
  }
}
